import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import {Form, Row, Col, Input, Spin, TreeSelect} from 'antd';

import TabFormDashboardLayout from "components/layouts/tab/form";
import Select from "components/common/select";

import PlayerActionsComponent from '../../player-actions.component';

import { COMPANY_CONTROL_RULE, COMPANY_CONTROL_TYPE } from 'constants/company.constants';

import { getPlayerRegistrationForm } from "store/actions/dashboard/players/players.action";
import { getPlayerGeneralInfo } from "store/actions/dashboard/players/general.action";

import playerGeneralInfoType from "types/player/generalInfo.type";
import registrationFormType from "types/project/registrationForm.type";

import { toLowerCaseFirstLetter } from 'utils/common';
import {useLocation} from "react-router";
import useProjectType from "hooks/useProjectType";
import {getProjectSubProjects} from "store/actions/dashboard/projects/currencies.action";
import {mapSubProjectsToTree} from "utils/subProjects";
import {USER_TYPE} from "constants/user.constants";
import {savePlayerGeneralInfo} from "pages/players/edit/sections/general/api";
import {isFormChanged} from "utils/form";
import NativeTag from "components/common/nativeTag";

/** Agent Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    isLoading,
    generalInfo,
    getPlayerGeneralInfo,
    registrationForm,
    isRegistrationFormLoading,
    getPlayerRegistrationForm,
    subProjects,
    getProjectSubProjects
}) => {
    const [saveLoading, setSaveLoading] = useState(false);
    const [isFormTouched, setIsFormTouched] = useState(false);

    const { t } = useTranslation();
    const { search } = useLocation();
    const { hasLatamMode } = useProjectType();
    const searchParams = useParams();
    const entityName = (new URLSearchParams(search)).get("name");

    const [formInstance] = Form.useForm();
    const { setFieldsValue, validateFields } = formInstance;

    const subProjectId = Form.useWatch("subProjectId", formInstance);

    const treeSubProjects = useMemo(() => mapSubProjectsToTree(subProjects), [subProjects]);

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        const fields = registrationForm.filter(control => !["Password", "ConfirmPassword"].includes(control.name)).map(control => control.name)
        const values = {};
        fields.forEach(field => {
            values[toLowerCaseFirstLetter(field)] = generalInfo[toLowerCaseFirstLetter(field)]
        })

        values.subProjectId = generalInfo.subProject?.projectId;

        setFieldsValue(values)
    }, [generalInfo, registrationForm])

    /** Load Registration Form */
    useEffect(() => {
        getPlayerRegistrationForm();
    }, [])


    /** Load player general info */
    useEffect(() => {
        getPlayerGeneralInfo(searchParams.id)

        if (hasLatamMode) {
            getProjectSubProjects();
        }
    }, [hasLatamMode])

    /** Get data for select control
     * @function
     * @param {object} - control
     * @returns {array}
     * @memberOf GeneralInfoComponent
    */
    const getSelectData = control => {
        const data = {
            "ParentId": {
                items: generalInfo.parentId ? [{
                    value: generalInfo.parentId,
                    name: generalInfo.parentName
                }] : []
            },
            "CurrencyCode": {
                items: []
            }
        };
        return data[control.name];
    }

    const handleForm = () => {
        validateFields()
            .then(values => {
                setSaveLoading(true);

                savePlayerGeneralInfo({
                    subProjectId: values.subProjectId,
                    id: searchParams.id
                }).then(() => {
                    setIsFormTouched(false);
                }).finally(() => {
                    setSaveLoading(false);
                });
            })
    }

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasLatamMode,
                        disabled: !isFormTouched,
                        loading: saveLoading
                    }
                ]
            }
            actions={<PlayerActionsComponent />}
            id={generalInfo.id}
            longId={generalInfo.longId}
        >
            <Spin spinning={isLoading || isRegistrationFormLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{}}
                    onValuesChange={(changed, formValues) => {
                        setIsFormTouched(
                            isFormChanged(
                                { id: searchParams.id, subProjectId: formValues.subProjectId },
                                { id: generalInfo.id, subProjectId: generalInfo.subProject?.projectId }
                            )
                        )
                    }}
                >

                    <Row gutter={[16, 0]}>
                        {
                            registrationForm
                                .filter(control => control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN && !["Password", "ConfirmPassword", "DocumentType", "DocumentNumber"].includes(control.name))
                                .map(control => (
                                    <Col xs={24} sm={12} xl={6} key={control.name} >
                                        <Form.Item
                                            label={`${t('backoffice.registrationform.' + control.name)} ${control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED ? '*' : ''}`}
                                            name={toLowerCaseFirstLetter(control.name)}
                                            className="rt--form-item-disabled"
                                        >
                                            {
                                                control.controlType === COMPANY_CONTROL_TYPE.INPUT ?
                                                    (
                                                        <Input
                                                            disabled={true}
                                                        />
                                                    ) : control.controlType === COMPANY_CONTROL_TYPE.SELECT ? (
                                                        <Select
                                                            options={getSelectData(control).items.map(it => (
                                                                { value: it.value, text: it.name}
                                                            ))}
                                                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.registrationform.' + control.name)}`}
                                                            disabled={true}
                                                            showArrow={false}
                                                        />
                                                    ) : null
                                            }
                                        </Form.Item>
                                    </Col>
                                ))
                        }

                        {hasLatamMode && (
                            <Col
                                xs={24} sm={12} xl={6}
                                key="subProjectId"
                            >
                                <Form.Item
                                    label={`${t('backoffice.registrationform.' + "subProjectId")}`}
                                    name="subProjectId"
                                    className='rt--general-form-item'
                                    validateFirst
                                    rules={[{required: true, message: t('backoffice.validation.fieldRequired')}]}
                                >
                                    <TreeSelect
                                        title={treeSubProjects.find((option) => option.value === subProjectId)?.children.map(child => child.title).join(", ")}
                                        treeData={treeSubProjects}
                                        treeDefaultExpandAll
                                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.registrationform.' + "subProjectId")}`}
                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                        treeNodeLabelProp={"label"}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>

                </Form>


            </Spin>
        </TabFormDashboardLayout>

    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the general info of current editing player  */
    generalInfo: playerGeneralInfoType,
    /** Redux action to get player General info */
    getPlayerGeneralInfo: PropTypes.func,
    /** Redux state property, is true when registration form is loading */
    isRegistrationFormLoading: PropTypes.bool,
    /** Redux state property, represents the registration form  */
    registrationForm: PropTypes.arrayOf(registrationFormType),
    /** Redux action to get registration form */
    getPlayerRegistrationForm: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getPlayerGeneralInfo: id => {
            dispatch(getPlayerGeneralInfo(id));
        },

        getPlayerRegistrationForm: () => {
            dispatch(getPlayerRegistrationForm());
        },

        getProjectSubProjects: () => {
            dispatch(getProjectSubProjects());
        }
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.players.edit.general,
        isLoading: state.players.isLoading,
        registrationForm: state.players.registrationForm,
        isRegistrationFormLoading: state.players.isRegistrationFormLoading,
        subProjects: state.projects.edit.subProjects,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent)
