import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Form, Row, Col, Radio, Checkbox, Spin, Space } from "antd";

import Select from 'components/common/select';
import NumericInput from 'components/common/numericInput';
import TextAreaInput from 'components/common/textAreaInput';
import FavoriteAmounts from '../favoriteAmounts';

import { hasAgentNonDirectTransactionAccess } from 'utils/access';
import { isFranchisingMode, isMobile, triggerCustomEvent } from 'utils/common';
import { getUser } from 'utils/auth';
import { isResponseSuccess } from 'utils/request';

import {amountValidator, formatNotes, isWalletConfigNonRelated} from '../../../../helpers';

import useFormat from 'hooks/useFormat';
import useProjectType from 'hooks/useProjectType';
import useTransfer from '../../../../hooks/useTransfer';

import {
    getAgents,
    getAgentCurrencies,
    agentDeposit,
    agentWithdrawal,
    agentGiveCredit,
    agentDebt
} from '../../../../api';

import { WALLET_TRANSACTION_TYPE, WALLET_TYPE } from 'constants/wallet.constants';
import { USER_ROLE, USER_STATE, USER_TYPE } from 'constants/user.constants';
import { AMOUNT_REGEX } from 'constants/regex.constants';
import {
    TRANSACTION_PAYMENT_SOURCE,
    TRANSACTION_SOURCE,
    TRANSFER_ENTITY_TYPE,
    TRANSFER_OPERATION_GROUP,
    TRANSFER_POPUP_OK_ACTION_EVENT_NAME,
    TRANSFER_SUCCESS_EVENT_NAME
} from '../../../../constants';

import userInfoType from 'types/profile/userInfo.type';
import PropTypes from "prop-types";
import currencyType from "types/profile/currency.type";

const getOperationTypeByGroup = group => {
    if (group === TRANSFER_OPERATION_GROUP.DEPOSIT) {
        return WALLET_TRANSACTION_TYPE.AGENT_DEPOSIT
    } else if (group === TRANSFER_OPERATION_GROUP.WITHDRAWAL) {
        return WALLET_TRANSACTION_TYPE.AGENT_WITHDRAWAL
    } else {
        return WALLET_TRANSACTION_TYPE.AGENT_DEPOSIT
    }
}

const operationsMapping = {
    [WALLET_TRANSACTION_TYPE.AGENT_DEPOSIT]: agentDeposit,
    [WALLET_TRANSACTION_TYPE.AGENT_WITHDRAWAL]: agentWithdrawal,
    [WALLET_TRANSACTION_TYPE.AGENT_CREDIT]: agentGiveCredit,
    [WALLET_TRANSACTION_TYPE.AGENT_PAY_DEBT]: agentDebt
}

const AgentTransferForm = ({
    userInfo,
    currencies: projectCurrencies,
    currency
}) => {
    const { t } = useTranslation();

    const { transferData, setSaving, setTransferResultData } = useTransfer();

    const { formatAmount } = useFormat();
    const { hasLiteMode } = useProjectType();

    const { userId, transactionType, group } = transferData ?? {};

    const currencyCode = transferData?.currencyCode || (hasLiteMode ? projectCurrencies[0].currencyCode : null);

    const [operationType, setOperationType] = useState(transactionType ?? getOperationTypeByGroup(group));

    const [selectedAgent, setSelectedAgent] = useState(userId ?? null);

    const [agents, setAgents] = useState([])

    const [currencies, setCurrencies] = useState([])

    const [isCurrencyLoading, setIsCurrencyLoading] = useState(false);

    const [selectedCurrency, setSelectedCurrency] = useState(currencyCode ?? null)

    const { formatCurrencyWithSymbol } = useFormat();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue, getFieldValue } = formInstance;

    /** Transaction Source Type */
    const [transactionSourceType, setTransactionSourceType] = useState(TRANSACTION_SOURCE.FROM_BALANCE);

    const isDeptTransaction = operationType === WALLET_TRANSACTION_TYPE.AGENT_PAY_DEBT;
    const isDepositTransaction = operationType === WALLET_TRANSACTION_TYPE.AGENT_DEPOSIT;
    const isWithdrawalTransaction = operationType === WALLET_TRANSACTION_TYPE.AGENT_WITHDRAWAL;
    const isGiveCreditOperationAvailable = !isFranchisingMode() && !hasLiteMode && !isWalletConfigNonRelated()

    const onlyDirectChilds = !hasAgentNonDirectTransactionAccess(userInfo);

    /** Load agents */
    useEffect(() => {
        if(!userId){
            getAgents({
                state: USER_STATE.ACTIVE,
                userRole: isDeptTransaction ?
                    transactionSourceType === TRANSACTION_SOURCE.FROM_BALANCE ? USER_TYPE.AGENT : undefined :
                    operationType !== WALLET_TRANSACTION_TYPE.AGENT_WITHDRAWAL ? USER_TYPE.AGENT : undefined,
                parentId: isDeptTransaction ? userInfo.id : onlyDirectChilds ? userInfo.id : undefined
            }).then(
                result => {
                    setAgents(result);
                }
            )
        }
    }, isDeptTransaction ? [ transactionSourceType ] : [])

    const agentFields = useMemo(() => {
        return agents.map(item => (
            { value: item.id, text: item.name }
        ))
    }, [agents])

    const currencyFields = useMemo(() => {
        return currencies.map(cur => (
            { value: cur.currencyCode, text: cur.currencyCode}
        ))
    }, [currencies])

    /** Set default user Id */
    useEffect(() => {
        if (!userId) {
            let agentId = null;
            if (agents.length > 0) {
                agentId = agents[0].id;
            }

            setFieldsValue({ userId: agentId });

            setSelectedAgent(agentId);
        }
    }, [ agents ])

    /** Load currencies */
    useEffect(() => {
        if(!currencyCode && selectedAgent){
            setIsCurrencyLoading(true);
            getAgentCurrencies(selectedAgent)
                .then(
                    result => {
                        setCurrencies(result);
                        if(result.length > 0){
                            let defaultCurrency = null;
                            if(result.length === 1){
                                defaultCurrency = result[0].currencyCode;
                            } else {
                                defaultCurrency = result.find(c => c?.currency?.isDefault);
                                if(defaultCurrency){
                                    defaultCurrency = defaultCurrency.currencyCode
                                } else {
                                    defaultCurrency = result[0].currencyCode;
                                }
                            }
                            setSelectedCurrency(defaultCurrency);
                            setFieldsValue({currencyCode: defaultCurrency})
                        }
                    }
                )
                .finally(
                    () => setIsCurrencyLoading(false)
                );
            }
    }, [selectedAgent])


    /** Selected Agent Balance */
    const selectedAgentCurrencyBalance = useMemo(() => {
        const currencyData = currencies.find(c => c.currencyCode === selectedCurrency);
        if(!currencyData) return null;
        const balance = (currencyData?.balances ?? [] ).find(b => b.type === WALLET_TYPE.BALANCE)
        if(!balance) return null

        return balance.amount;
    }, [currencies, selectedCurrency])


    /** Transaction source field */
    const showTransactionSourceField = useMemo(() => {
        if(hasLiteMode || isFranchisingMode()){
            return false;
        }
        if ( isDepositTransaction || isWithdrawalTransaction ) {
            if(isWalletConfigNonRelated()){
                return true
            }
            return getUser()?.role !== USER_ROLE.ADMIN;
        }

        return isDeptTransaction;

    }, [operationType, hasLiteMode, transferData]);


    const transactionFieldOptions = useMemo(() => {
        if(isDeptTransaction){
            return [
                {
                    label: <span title={ t('backoffice.wallet.byCash') }>{
                        t('backoffice.wallet.byCash')
                    }</span>,
                    value: TRANSACTION_SOURCE.BY_CASH
                },
                {
                    label: <span title={t('backoffice.wallet.cashless')}>{
                        t('backoffice.wallet.cashless')
                    }</span>,
                    value: TRANSACTION_SOURCE.FROM_BALANCE
                }
            ]
        } else {
            return [
                {
                    label: isDepositTransaction && isWalletConfigNonRelated() ? t('backoffice.wallet.toBalance') : t('backoffice.wallet.fromBalance'),
                    value: TRANSACTION_SOURCE.FROM_BALANCE
                },
                {
                    label: isWalletConfigNonRelated() ? isDepositTransaction ?
                        t('backoffice.wallet.toCreditLine') :
                        t('backoffice.wallet.fromCreditLine') :
                    t('backoffice.wallet.fromCredit'),
                    value: TRANSACTION_SOURCE.BY_CASH
                }
            ]
        }
    }, [operationType, transferData])

    /** Pay debt field */
    const showPayDebtField = useMemo(() => {
        if(hasLiteMode){
            return false;
        }
        if ( isDepositTransaction || isWithdrawalTransaction ) {
            return !isWalletConfigNonRelated();
        }

        return false;

    }, [operationType, hasLiteMode, transferData]);


    /** Show Payment source field */
    const showPaymentSourceField = useMemo(() => {
        return getUser()?.role === USER_ROLE.AGENT && operationType === WALLET_TRANSACTION_TYPE.AGENT_PAY_DEBT && transactionSourceType === TRANSACTION_SOURCE.FROM_BALANCE;
    }, [operationType, transferData, transactionSourceType]);

    /** Set default value for transaction source */
    useEffect(() => {
        if (showTransactionSourceField) {
            setFieldsValue({
                transactionSource: TRANSACTION_SOURCE.FROM_BALANCE
            })
        }
    }, [showTransactionSourceField])

    /** Set default value for pay dept */
    useEffect(() => {
        if (showPayDebtField) {
            setFieldsValue({
                payDebt: isFranchisingMode()
            })
        }
    }, [showPayDebtField])

    /** Set default value for payment source */
    useEffect(() => {
        if (showPaymentSourceField) {
            setFieldsValue({
                paymentSource: TRANSACTION_PAYMENT_SOURCE.FROM_BALANCE
            })
        }
    }, [showPaymentSourceField])


    const handleAgentChange = value => {
        setSelectedAgent(value);
    }

    const handleCurrencyChange = value => {
        setSelectedCurrency(value);
    }

    const handleFavAmountClick = value => {
        const currentValue = getFieldValue("amount") ?? 0;
        setFieldsValue({amount: Number(currentValue) + value})
    }

    const onTransferRequest = data => {
        if (isResponseSuccess(data)) {
            const resp = data.value;
            if(resp){
                setTransferResultData(resp?.wallets);
                triggerCustomEvent(
                    TRANSFER_SUCCESS_EVENT_NAME,
                    {
                        transactionType : operationType,
                        entityType: TRANSFER_ENTITY_TYPE.AGENT,
                        wallets: resp?.wallets
                    }
                );
            }
        }
    }

    const handler = useCallback(() => {
        validateFields()
            .then(data => {
                setSaving(true);
                // debugger;

                const d = {
                    notes: formatNotes(data.notes),
                    amount: Number(data.amount),
                    agentId: userId ? userId : data.userId,
                    currencyCode: hasLiteMode ? currencyCode : data.currencyCode
                }

                let transactionSource;

                if(showTransactionSourceField){
                    transactionSource = data.transactionSource;
                } else if(isFranchisingMode() && isDeptTransaction){
                    transactionSource = TRANSACTION_SOURCE.BY_CASH
                } else if(hasLiteMode){
                    transactionSource = TRANSACTION_SOURCE.FROM_BALANCE;
                }

                if(isDeptTransaction){
                    d.transactionSource = transactionSource;
                }

                operationsMapping[operationType]?.(d, {
                    paymentSource: data.paymentSource,
                    transactionSource: transactionSource,
                    payDebt: data.payDebt,
                    franchisingMode: isFranchisingMode(),
                    isWalletConfigNonRelated: isWalletConfigNonRelated(),
                    userRole: getUser()?.role
                })
                    .then(data => onTransferRequest(data))
                    .finally(() => setSaving(false));

            }).catch(ex => {
                console.log(ex)
            })
    }, [operationType, showPayDebtField, showPaymentSourceField, showTransactionSourceField, hasLiteMode, currencyCode]);

    /** On Transfer */
    useEffect(() => {
        document.addEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);
        }
    }, [handler])

    return (
        <Form
            className="rt--form"
            form={formInstance}
            colon={false}
            requiredMark={false}
            layout="vertical"
            initialValues={{
                currencyCode: currencyCode ?? null
            }}
        >
            <Row gutter={[16, 0]}>
                {
                    (
                        !group && !transactionType
                    ) && (
                        <Col span={24}>
                            <div className='rt--mb-24'>
                                <Form.Item
                                    label=""
                                    className='rt--form-item-inline rt--form-item-radio-buttons'
                                >
                                    <Radio.Group
                                        optionType="button"
                                        options={
                                            [
                                                {
                                                    label: <span title={t('backoffice.wallet.deposit')}>{t('backoffice.wallet.deposit')}</span>,
                                                    value: WALLET_TRANSACTION_TYPE.AGENT_DEPOSIT
                                                },
                                                {
                                                    label: <span title={t('backoffice.wallet.withdrawal')}>{t('backoffice.wallet.withdrawal')}</span>,
                                                    value: WALLET_TRANSACTION_TYPE.AGENT_WITHDRAWAL
                                                },
                                                ... (
                                                    isGiveCreditOperationAvailable ? [
                                                        {
                                                            label: <span title={t('backoffice.wallet.giveCredit')}>{t('backoffice.wallet.giveCredit')}</span>,
                                                            value: WALLET_TRANSACTION_TYPE.AGENT_CREDIT
                                                        },
                                                    ] : []
                                                )
                                            ]
                                        }
                                        value={operationType}
                                        onChange={e => setOperationType(e.target.value)}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                    )
                }

                {
                    showTransactionSourceField && (
                        <Col span={24}>
                            <Form.Item
                                label={isDeptTransaction ? "" : `${t("backoffice.wallet.source")} *`}
                                name="transactionSource"
                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                className={isDeptTransaction ? "rt--form-item-inline rt--form-item-radio-buttons" : "rt--form-item-radio"}
                            >
                                <Radio.Group
                                    options={transactionFieldOptions}
                                    onChange={e => setTransactionSourceType(e.target.value)}
                                    optionType={isDeptTransaction ? "button" : undefined}
                                />
                            </Form.Item>
                        </Col>
                    )
                }

                {
                    showPayDebtField && (
                        <Col span={24}>
                            <div className="rt--flex-inline rt--align-center rt--form-item-checkbox rt--mb-14">
                                <Form.Item
                                    className='rt--form-item-inline rt--form-item-without-margin'
                                    name="payDebt"
                                    valuePropName='checked'
                                >
                                    <Checkbox
                                        disabled={isFranchisingMode()}
                                    />
                                </Form.Item>
                                <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.wallet.payDebt')}</span>
                            </div>
                        </Col>
                    )
                }

                {
                    showPaymentSourceField && (
                        <Col span={24}>
                            <Form.Item
                                label={`${t("backoffice.wallet.paymentSource")} *`}
                                name="paymentSource"
                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                className='rt--form-item-radio'
                            >
                                <Radio.Group
                                    options={[
                                        { label: t('backoffice.wallet.fromBalance'), value: TRANSACTION_PAYMENT_SOURCE.FROM_BALANCE },
                                        { label: t('backoffice.wallet.fromCredit'), value: TRANSACTION_PAYMENT_SOURCE.FROM_CREDIT },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }

                {
                    !userId && (
                        <Col span={24}>
                            <Form.Item
                                label={`${isFranchisingMode() ? t('backoffice.common.betshopOwner') : t('backoffice.common.agent')}*`}
                                name="userId"
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') }
                                ]}
                            >
                                <Select
                                    placeholder={`${t("backoffice.common.select")} ${isFranchisingMode() ? t('backoffice.common.betshopOwner') : t('backoffice.common.agent')}`}
                                    options={agentFields}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    onChange={handleAgentChange}
                                    search={true}
                                />
                            </Form.Item>
                        </Col>
                    )
                }

                {
                    !isCurrencyLoading ? (
                        <Fragment>
                            {
                                selectedAgentCurrencyBalance !== null && (
                                    <Col span={24}>
                                        <div className='rt--transfer-info rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16 rt--mb-16 rt--flex rt--justify-between rt--align-center'>
                                            <span>{t("backoffice.common.balance")}:</span>
                                            <span>{formatAmount(selectedAgentCurrencyBalance, selectedCurrency)}</span>
                                        </div>
                                    </Col>
                                )
                            }
                            <Col span={24}>
                                {
                                    hasLiteMode ? (
                                        <Form.Item
                                            label={`${formatCurrencyWithSymbol(t("backoffice.wallet.amountToSend"), currency)} *`}
                                            name="amount"
                                            rules={[
                                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                                { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                { validator: amountValidator }
                                            ]}
                                            validateFirst
                                            className="rt--general-form-item"
                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                        >

                                            <NumericInput
                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                autoFocus={!isMobile()}
                                            />
                                        </Form.Item>
                                    ) : (
                                        <Form.Item
                                            label={`${t('backoffice.wallet.amountToSend')} *`}
                                            className="rt--form-item-without-margin rt--spend-amount"
                                        >
                                            <Space.Compact
                                                className='rt--transfer-amount'
                                            >
                                                <Form.Item
                                                    className='rt--transfer-amount-currency'
                                                    rules={[
                                                        { required: true, message: t('backoffice.validation.fieldRequired') },
                                                    ]}
                                                    name="currencyCode"
                                                >
                                                    <Select
                                                        placeholder="CUR"
                                                        value={selectedCurrency?.toUpperCase()}
                                                        onChange={handleCurrencyChange}
                                                        options={currencyFields}
                                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                        showArrow={!Boolean(currencyCode)}
                                                        disabled={Boolean(currencyCode)}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name="amount"
                                                    rules={[
                                                        { required: true, message: t('backoffice.validation.fieldRequired') },
                                                        { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                        { validator: amountValidator }
                                                    ]}
                                                    validateFirst
                                                    className="rt--transfer-amount-amount rt--general-form-item"
                                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                >

                                                    <NumericInput
                                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                        autoFocus={!isMobile()}
                                                    />
                                                </Form.Item>

                                            </Space.Compact>
                                        </Form.Item>
                                    )
                                }
                            </Col>
                            <Col span={24}>
                                <FavoriteAmounts
                                    handleClick={handleFavAmountClick}
                                    entityType={TRANSFER_ENTITY_TYPE.AGENT}
                                    currencyCode={selectedCurrency?.toUpperCase()}
                                />
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label={t('backoffice.wallet.notes')}
                                    name="notes"
                                    rules={[
                                        {max: 1000, message: t('backoffice.validation.fieldInvalid')}
                                    ]}
                                    className="rt--form-item-without-margin rt--general-form-item"
                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                                >
                                    <TextAreaInput
                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                                        maxLength={1000}
                                        rows={6}
                                    />
                                </Form.Item>
                            </Col>

                        </Fragment>
                    ) : (
                        <div className='rt--width-full rt--transfer-loading'>
                            <Spin />
                        </div>
                    )
                }
            </Row>

        </Form>
    )
}

/** AgentTransferForm propTypes
    * PropTypes
*/
AgentTransferForm.propTypes = {
    /** Redux state property, the user info */
    userInfo: userInfoType,
    currencies: PropTypes.arrayOf(currencyType),
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
        currencies: state.profile.currencies,
        currency: state.profile.currency,
    }
}

export default connect(mapStateToProps, null)(AgentTransferForm)
