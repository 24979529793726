export const VOUCHER_GENERAL_DETAILS = {
    BETSHOP_NAME: 1,
    CASHIER_USERNAME: 2,
    TERMINAL_NAME: 4,
    VOUCHER_ID: 8,
}

export const VOUCHER_INFO_DETAILS = {
    VOUCHER_CODE: 1,
    VOUCHER_AMOUNT: 2,
    PAYOUT_DATE: 4,
    EXPIRATION_DATE: 8,
}

export const VOUCHER_PREVIEW_VALUES = {
    BETSHOP_NAME: "Name",
    CASHIER_USERNAME: "Cashier",
    TERMINAL_NAME: "Terminal",
    VOUCHER_ID: 12345,
    VOUCHER_CODE: 98765,
    VOUCHER_AMOUNT: "115 EUR",
    CREATED_AT: "2020-12-12 12:00",
    EXPIRATION_DATE: "2020-12-12 12:00",
}
