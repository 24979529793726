import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import Select from "components/common/select";
import Input from 'components/common/input';

import { setRetailPendingBetHistoryFilters, getRetailPendingBetHistory } from "store/actions/dashboard/betHistory/retailPendings.action";

import { getPossibleParentsName, isNonAnonymousBetsActivated } from 'utils/common';

import { FILTER_PROVIDER } from "constants/filter.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { BET_TYPE } from 'constants/bet.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

/** Pending Bets Page Filters Component */
const Filters = ({
    setRetailPendingBetHistoryFilters,
    getRetailPendingBetHistory,
    filters,
    globalProjectId
}) => {
    const { t } = useTranslation();

    const [ betshopNames, getBetshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP, 
        autoGet: false
    });
    const [ cashierNames, getCashierNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.CASHIER, 
        autoGet: false
    });
    const [ agentNames, getAgentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT, 
        autoGet: false
    });

    const belongsToFieldOptions = useMemo(() => {
        const options = getPossibleParentsName({
            globalProjectId,
            agentNames,
        })

        return [{value: null, text: t("backoffice.common.all")}].concat(options.map(o => ({
            value: o.id,
            text: o.name
        })))
    }, [globalProjectId, agentNames])

    return (
        <FiltersWrapper
            loadFn={() => getRetailPendingBetHistory()}
            setFiltersFn={filters => setRetailPendingBetHistoryFilters(filters)}
            filters={filters}
            updateProps={[globalProjectId]}
            searchFieldName="betSlipId"
            onInit={() => {
                getBetshopNames();
                getCashierNames();
                getAgentNames({
                    excludeAgent: false
                });
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.bets.belongsTo')}
                        name="belongsTo"
                    >
                        <Select
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.bets.belongsTo')}`}
                            options={belongsToFieldOptions}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.bets.betshopNameOrId')}
                        name="betShopNameOrId"
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.bets.betshopNameOrId')}`}
                            items={betshopNames}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.bets.cashierNameOrId')}
                        name="cashierUserNameOrId"
                    >
                        <AutoComplete
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.bets.cashierNameOrId')}`}
                            items={cashierNames}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                {isNonAnonymousBetsActivated(globalProjectId) && (
                    <Col span={24} >
                        <Form.Item
                            label={t('backoffice.bets.playerUserName')}
                            className='rt--general-form-item'
                            name="playerUserNameOrId"
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.bets.playerUserName')}`}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.bets.playerUserName')}`}
                                maxLength={50}
                            />
                        </Form.Item>
                    </Col>
                )}

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.bets.provider')}
                        name="providertype"
                    >
                        <Select
                            options={[
                                { value: FILTER_PROVIDER.ALL, text: t("backoffice.common.all") },
                                { value: FILTER_PROVIDER.SPORTBOOK, text: t("backoffice.common.Sportsbook") },
                                { value: FILTER_PROVIDER.VIRTUAL_SPORTS, text: t("backoffice.common.DGVirtuals") },
                                { value: FILTER_PROVIDER.GOLDEN_RACE, text: t("backoffice.common.GoldenRace") }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.provider')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t("backoffice.bets.type")}
                        name="type"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t("backoffice.common.all") },
                                    { value: BET_TYPE.SINGLE, text: t("backoffice.bets.single") },
                                    { value: BET_TYPE.MULTI, text: t("backoffice.bets.multi") },
                                    { value: BET_TYPE.SYSTEM, text: t("backoffice.bets.system") }
                                ]
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.type')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get retail pending bet histpry */
    getRetailPendingBetHistory: PropTypes.func,
    /** Redux action to set retail pending bet history filters */
    setRetailPendingBetHistoryFilters: PropTypes.func,
    /** Redux state property, retail pending bet history filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string
}

const mapDispatchToProps = dispatch => (
    {
        getRetailPendingBetHistory: () => {
            dispatch(getRetailPendingBetHistory())
        },
        setRetailPendingBetHistoryFilters: filters => {
            dispatch(setRetailPendingBetHistoryFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        globalProjectId: state.common.globalProjectId,
        filters: state.betHistory.retail.pendings.filters,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);