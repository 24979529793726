import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import { Col, Row, Form, Switch } from 'antd';

import Modal from 'components/common/modal';
import Select from 'components/common/select';
import ImageUploader from 'components/common/imageUploader';
import Input from 'components/common/input';
import DatePicker from 'components/common/datePicker';
import RichEditor from "components/common/richEditor";

import useDate from 'hooks/useDate';

import { isFunction } from 'utils/common';
import { PROMOTION_STATUS, TERMINAL_SPORTBOOKS_ITEM, TERMINAL_SPORTBOOKS_ITEM_TRANSLATIONS, TERMINAL_VIRTUAL_SPORT_ITEM, TERMINAL_VIRTUAL_SPORT_ITEM_TRANSLATIONS } from 'constants/terminalCustomize.constants';
import { PROJECT_PROVIDER_TYPE } from 'constants/project.constants';
import { IMAGE_TYPE, POPUP_SIZE } from 'constants/common.constants';

import { getPromotionStatus } from '../../../helpers';

const FORM_FIELD_NAMES = {
    TITLE: "title",
    PRODUCT: "product",
    PROVIDER: "provider",
    BANNER: "banner",
    THUMBNAIL: "thumbnail",
    DATE: "date",
    BUTTON_ACTIVE: "buttonActive",
    BUTTON_NAME: "buttonName",
    BRIEF: "brief"
}

const PromotionAddEditComponent = ({
    onClose,
    onOk,
    isSaving,
    data={}
}) => {
    const { t } = useTranslation();

    const { dateService } = useDate();

    const isEditMode = data?.mode === "edit";
    
    const [ selectedProvider, setSelectedProvider ] = useState( data.provider ?? null)

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;


    useEffect(() => {
        if(isEditMode){
            setFieldsValue({
                [FORM_FIELD_NAMES.TITLE] : data.title,
                [FORM_FIELD_NAMES.BUTTON_ACTIVE] : data.buttonActive,
                [FORM_FIELD_NAMES.BUTTON_NAME] : data.buttonName,
                [FORM_FIELD_NAMES.PRODUCT] : data.provider ? data.product ? String(data.product) : "*" : undefined,
                [FORM_FIELD_NAMES.PROVIDER] : data.provider,
                [FORM_FIELD_NAMES.BRIEF] : data.brief,
                [FORM_FIELD_NAMES.DATE] : [
                    data.startDate ? dateService.makeUtcDateToLocal(data.startDate) : "",
                    data.endDate ? dateService.makeUtcDateToLocal(data.endDate) : "",
                ]
            })
        } else {
            setFieldsValue({
                [FORM_FIELD_NAMES.BUTTON_ACTIVE] : false,
                [FORM_FIELD_NAMES.BUTTON_NAME] : "",
            })
        }
    }, [])


    const productOptions = useMemo(() => {
        if(selectedProvider === null) return [];
        if(selectedProvider === PROJECT_PROVIDER_TYPE.SPORTBOOK){
            return [
                { value: "*", text: t("backoffice.terminals.sport")},
                ...Object.values(TERMINAL_SPORTBOOKS_ITEM).map(item => (
                    { value: item, text: TERMINAL_SPORTBOOKS_ITEM_TRANSLATIONS[item]}
                ))
            ]
        } else if( selectedProvider === PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS){
            return [
                {value: "*", text: t("backoffice.terminals.virtualSport")},
                ...Object.values(TERMINAL_VIRTUAL_SPORT_ITEM).map(item => (
                    { value: item, text: TERMINAL_VIRTUAL_SPORT_ITEM_TRANSLATIONS[item]}
                ))
            ]
        }

    }, [selectedProvider, t])

    const handleProviderChange = value => {
        setSelectedProvider(value);

        setFieldsValue({
            [FORM_FIELD_NAMES.PRODUCT] : undefined
        })
    }

    const handleForm = () => {
        validateFields()
            .then(fieldsValue => {
                if (isFunction(onOk)) {
                    const [ startDate, endDate ] = fieldsValue[FORM_FIELD_NAMES.DATE] ?? [];

                    const requestBody = {
                        title: fieldsValue[FORM_FIELD_NAMES.TITLE],
                        buttonActive: fieldsValue[FORM_FIELD_NAMES.BUTTON_ACTIVE],
                        buttonName: fieldsValue[FORM_FIELD_NAMES.BUTTON_NAME],
                        brief: fieldsValue[FORM_FIELD_NAMES.BRIEF],
                        thumbnail: fieldsValue[FORM_FIELD_NAMES.THUMBNAIL],
                        banner: fieldsValue[FORM_FIELD_NAMES.BANNER],
                        product : fieldsValue[FORM_FIELD_NAMES.PRODUCT] === "*" ? "" : (fieldsValue[FORM_FIELD_NAMES.PRODUCT] ?? ""),
                        provider: fieldsValue[FORM_FIELD_NAMES.PROVIDER] ?? "",
                        startDate: dateService.toISOString(startDate) ?? null,
                        endDate: dateService.toISOString(endDate) ?? null
                    }

                    if(data.rowId){
                        requestBody.rowId = data.rowId;
                    }

                    onOk(requestBody);
                    onClose();
                }

            }).catch(ex => {
                console.log(ex)
            })
    }

    const isDurationFieldDisabled = () => {
        if(!isEditMode){
            return [false, false]
        }
        const promotionStatus = getPromotionStatus(data);

        return [
            promotionStatus !== PROMOTION_STATUS.UPCOMING,
            promotionStatus === PROMOTION_STATUS.FINISHED
        ]
    }

    return (
        <Modal
            title={ isEditMode ? t('backoffice.terminals.editPromotion') : t('backoffice.terminals.addPromotion')}
            cancelText={t('backoffice.common.cancel')}
            okText={ isEditMode ? t('backoffice.common.save') : t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.BIGEST}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.terminals.title')} *`}
                            name={FORM_FIELD_NAMES.TITLE}
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.terminals.title')}`}
                                maxLength={25}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.terminals.duration')} *`}
                            name={FORM_FIELD_NAMES.DATE}
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                            className={isEditMode && getPromotionStatus(data) === PROMOTION_STATUS.FINISHED ? "rt--form-item-disabled" : ""}
                        >
                            <DatePicker.RangePicker
                                placeholder={[t('backoffice.common.from'), t('backoffice.common.to')]}
                                format={dateService.getFormat(true)}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                showTime={true}
                                allowClear={false}
                                suffixIcon={<i className='icon-date rt--font-bigest' />}
                                separator={<div className='rt--datepicker-separator' />}
                                className="rt--datepicker rt--datepicker-withtime"
                                popupClassName="rt--datepicker-popup rt--datepicker-withtime-popup"
                                disabled={isDurationFieldDisabled()}
                                disabledDate={ current => current && dateService.isBefore(current, dateService.getNow())}
                                onKeyDown={(e) => {
                                    const allowedKeys = [
                                        'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
                                        'Delete', '.', '-',
                                    ];
                                    const isNumber = /^\d$/.test(e.key);
                                    
                                    
                                    const isPaste = (e.ctrlKey || e.metaKey) && e.key === 'v';
                            
                                    if (!isNumber && !allowedKeys.includes(e.key) && !isPaste) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.terminals.thumbnail')} *`}
                            name={FORM_FIELD_NAMES.THUMBNAIL}
                            rules={[
                                { required: data.mode === "add", message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <ImageUploader
                                size={3 * 1024 * 1024}
                                acceptedFormats={ [ IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG, IMAGE_TYPE.WEBP, IMAGE_TYPE.SVG ]}
                                defaultFile={
                                    data.thumbnail ? {
                                        url: `${import.meta.env.SYSTEM_CDN_URL}/${data.thumbnail.toLowerCase()}`,
                                        status: "done",
                                        percent: 100
                                    } : null
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={`${t('backoffice.terminals.banner')} *`}
                            name={FORM_FIELD_NAMES.BANNER}
                            rules={[
                                { required: data.mode === "add", message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <ImageUploader
                                size={3 * 1024 * 1024}
                                acceptedFormats={ [ IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG, IMAGE_TYPE.WEBP, IMAGE_TYPE.SVG ]}
                                defaultFile={
                                    data.banner ? {
                                        url: `${import.meta.env.SYSTEM_CDN_URL}/${data.banner.toLowerCase()}`,
                                        status: "done",
                                        percent: 100
                                    } : null
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                            <Form.Item
                                name={FORM_FIELD_NAMES.BUTTON_ACTIVE}
                                valuePropName="checked"
                                className="rt--form-item-without-margin"
                            >
                                <Switch />
                            </Form.Item>
                            <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.terminals.activateButton')}</label>
                        </div>
                    </Col>
                    <Col span={8}>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                const disabled = !getFieldValue(FORM_FIELD_NAMES.BUTTON_ACTIVE);
                                return (
                                    <Form.Item
                                        label={`${t('backoffice.terminals.buttonText')} *`}
                                        name={FORM_FIELD_NAMES.BUTTON_NAME}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    const isButtonActive = getFieldValue(FORM_FIELD_NAMES.BUTTON_ACTIVE);
                                                    if(!isButtonActive){
                                                        return Promise.resolve();
                                                    }
                                                    if (!value?.trim()) {
                                                        return Promise.reject(t('backoffice.validation.fieldRequired'))
                                                    }
                                                    return Promise.resolve();
                                                }
                                            })
                                        ]}
                                        className={disabled ? "rt--form-item-disabled" : ""}
                                        dependencies={[FORM_FIELD_NAMES.BUTTON_ACTIVE]}
                                    >
                                        <Input
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.terminals.buttonText')}`}
                                            maxLength={30}
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>    
                    </Col>
                    <Col span={8}>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                const disabled = !getFieldValue(FORM_FIELD_NAMES.BUTTON_ACTIVE);
                                return (
                                    <Form.Item
                                        label={`${t('backoffice.terminals.provider')} *`}
                                        name={FORM_FIELD_NAMES.PROVIDER}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    const isButtonActive = getFieldValue(FORM_FIELD_NAMES.BUTTON_ACTIVE);
                                                    if(!isButtonActive){
                                                        return Promise.resolve();
                                                    }
                                                    if (!value) {
                                                        return Promise.reject(t('backoffice.validation.fieldRequired'))
                                                    }
                                                    return Promise.resolve();
                                                }
                                            })
                                        ]}
                                        className={disabled ? "rt--form-item-disabled" : ""}
                                        dependencies={[FORM_FIELD_NAMES.BUTTON_ACTIVE]}
                                    >
                                        <Select
                                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.terminals.provider')}`}
                                            options={[
                                                { value: PROJECT_PROVIDER_TYPE.SPORTBOOK, text: t("backoffice.terminals.sport")},
                                                { value: PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS, text: t("backoffice.terminals.virtualSport")}
                                            ]}
                                            onChange={handleProviderChange}
                                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>    
                    </Col>
                    <Col span={8}>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                const disabled = !getFieldValue(FORM_FIELD_NAMES.BUTTON_ACTIVE);
                                return (
                                    <Form.Item
                                        label={`${t('backoffice.terminals.item')} *`}
                                        name={FORM_FIELD_NAMES.PRODUCT}
                                        className={selectedProvider === null || disabled ? " rt--form-item-disabled" : ""}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    const isButtonActive = getFieldValue(FORM_FIELD_NAMES.BUTTON_ACTIVE);
                                                    if(!isButtonActive){
                                                        return Promise.resolve();
                                                    }
                                                    if (!value) {
                                                        return Promise.reject(t('backoffice.validation.fieldRequired'))
                                                    }
                                                    return Promise.resolve();
                                                }
                                            })
                                        ]}
                                        dependencies={[FORM_FIELD_NAMES.BUTTON_ACTIVE]}
                                    >
                                        <Select
                                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.terminals.item')}`}
                                            options={productOptions}
                                            disabled={selectedProvider === null || disabled}
                                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                        />
                                    </Form.Item>
                                )
                            }}
                        </Form.Item>    
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.terminals.brief')} *`}
                            className="rt--general-form-item"
                            name={FORM_FIELD_NAMES.BRIEF}
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <RichEditor
                                maxCharacterLimit={30000}
                                initialContent={data.brief ?? ""}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

/** PromotionAddEditComponent propTypes
    * PropTypes
*/
PromotionAddEditComponent.propTypes = {
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Fires on popup add button clicked */
    onOk: PropTypes.func,
    /** true when saving data */
    isSaving: PropTypes.bool,
    /** Data */
    data: PropTypes.object
}

export default PromotionAddEditComponent;