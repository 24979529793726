import { useState, createElement, useCallback, useMemo } from "react";

import useUnsavedChanges from "./useUnsavedForm";

import { UNSAVED_FORM_PAGE_TYPE } from "constants/common.constants";
import { hasValue } from "utils/common";

const constructEditorSectionList = ({
    sectionsObj,
    sectionToggleFn,
    predefinedData,
    additionalProps = {},
}) => {

    const sectionList = Object.entries(predefinedData)
        .map(([sectionName, sectionData]) => {
            const sectionDataFromServer = sectionsObj?.[sectionName];
            const props = {
                ...additionalProps
            };

            if (!Boolean(sectionDataFromServer)) {
                return {
                    ...sectionData,
                    content: createElement(sectionData.content, props),
                }
            }

            if (Boolean(sectionDataFromServer.dynamicData)) {
                props.data = JSON.parse(sectionDataFromServer.dynamicData);
            }

            if (Boolean(sectionDataFromServer.files) && hasValue(sectionDataFromServer.files)) {
                props.files = sectionDataFromServer.files
            }

            return {
                ...sectionData,
                content: createElement(sectionData.content, props),
                enabled: sectionDataFromServer.enabled,
                onSectionToggle: (isChecked) => {
                    sectionToggleFn({
                        type: sectionData.sectionType,
                        enabled: isChecked,
                        sectionName
                    })
                }
            }
        });

    return sectionList;
}

const useCustomizeItems = ({
    sections,
    onSectionToggle,
    stylesData,
    sectionsData
}) => {

    const [unsavedTabs, setUnsavedTabs] = useState({
        customizeSections: [],
        customizeStyles: [],
    });

    const { setToStore: setToStoreCustomizeSections } = useUnsavedChanges(UNSAVED_FORM_PAGE_TYPE.CUSTOMIZE_SECTIONS);
    const { setToStore: setToStoreCustomizeStyles } = useUnsavedChanges(UNSAVED_FORM_PAGE_TYPE.CUSTOMIZE_STYLES);

    const changeTabSavedStatus = useCallback((status, key, type) => {
        if (status && unsavedTabs[type].indexOf(key) === -1) {
            setUnsavedTabs(prevState => {
                type === UNSAVED_FORM_PAGE_TYPE.CUSTOMIZE_SECTIONS
                    ? setToStoreCustomizeSections(true)
                    : setToStoreCustomizeStyles(true)

                return {
                    ...prevState,
                    [type]: [...prevState[type], key]
                }
            });
        } else if (!status) {
            setUnsavedTabs(prevState => {
                type === UNSAVED_FORM_PAGE_TYPE.CUSTOMIZE_SECTIONS
                    ? setToStoreCustomizeSections(false)
                    : setToStoreCustomizeStyles(false)


                return {
                    ...prevState,
                    [type]: prevState[type].filter(t => t !== key)
                }
            });
        }
    }, [unsavedTabs])

    /** Sidebar sections items */
    const { sectionList, stylesList } = useMemo(() => {
        if (!Boolean(sections)) {
            return { sectionList: [], stylesList: [] }
        }

        const sectionList = constructEditorSectionList({
            predefinedData: sectionsData,
            sectionsObj: sections,
            sectionToggleFn: onSectionToggle,
            additionalProps: {
                onTabChange: changeTabSavedStatus
            }
        });

        const stylesList = constructEditorSectionList({
            predefinedData: stylesData,
            additionalProps: {
                onTabChange: changeTabSavedStatus
            }
        });

        return { sectionList, stylesList }
    }, [sections, changeTabSavedStatus])

    return { sectionList, stylesList, unsavedTabs }

}
 
export default useCustomizeItems;