import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Spin } from 'antd';

import NumericInput from 'components/common/numericInput';
import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import { getProjectFavoriteAmounts, saveProjectFavoriteAmounts } from "store/actions/dashboard/projects/favoriteAmounts.action";

import useProjectType from 'hooks/useProjectType';

import { isFormChanged } from "utils/form";
import { hasPermission } from 'utils/permissions';
import { isFranchisingMode, isMobile } from 'utils/common';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import favoriteAmountsType from "types/project/favoriteAmounts.type";
import { AMOUNT_REGEX } from 'constants/regex.constants';

const FIELDS_INDEXES = Array.from(Array(4).keys());

/** Project Edit Page FavAmountsAndLimits Tab Favorite amounts subtab Component */
const FavoriteAmountsComponent = ({
    getProjectFavoriteAmounts,
    saveProjectFavoriteAmounts,
    isSaving,
    isLoading,
    favoriteAmounts,
    availableCurrencies,
    handleCurrencyChange,
    currency,
    onTabChange
}) => {
    const { t } = useTranslation();

    const { hasLiteMode, hasRetail, hasAgentSystem, hasTerminal } = useProjectType();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const userCanModify = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_LIMITS, action: PERMISSION_ACTION.MODIFY });

    /** Set default values */
    useEffect(() => {
        setFieldsValue(favoriteAmounts)
    }, [favoriteAmounts])

    /** Get project favorite Amounts */
    useEffect(() => {
        if (currency) {
            getProjectFavoriteAmounts(currency);

        }
    }, [currency])

    /** Fires when form submitted
       * @function
       * @memberOf FavoriteAmountsComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const d = {
                    agentFavoriteAmounts: [],
                    playerFavoriteAmounts: [],
                    betShopFavoriteAmounts: [],
                    voucherFavoriteAmounts: []
                }
                if(data.agentFavoriteAmounts){
                    d.agentFavoriteAmounts = data.agentFavoriteAmounts.filter(v => v || v === 0)
                }

                if(data.playerFavoriteAmounts){
                    d.playerFavoriteAmounts = data.playerFavoriteAmounts.filter(v => v || v === 0)
                }

                if(data.betShopFavoriteAmounts){
                    d.betShopFavoriteAmounts = data.betShopFavoriteAmounts.filter(v => v || v === 0)
                }

                if(data.voucherFavoriteAmounts){
                    d.voucherFavoriteAmounts = data.voucherFavoriteAmounts.filter(v => v || v === 0)
                }

                saveProjectFavoriteAmounts({
                    ...d,
                    currencyCode: currency?.toUpperCase()
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    const onFormValuesChange = (changed, formValues) => {
        const initialValues = {}, values = {};
        if(hasAgentSystem){
            initialValues.agentFavoriteAmounts = favoriteAmounts.agentFavoriteAmounts.filter(v => v || v === 0);
            values.agentFavoriteAmounts = formValues.agentFavoriteAmounts.filter(v => v || v === 0);
        }

        if(!isFranchisingMode()){
            initialValues.playerFavoriteAmounts = favoriteAmounts.playerFavoriteAmounts.filter(v => v || v === 0);
            values.playerFavoriteAmounts = formValues.playerFavoriteAmounts.filter(v => v || v === 0);
        }

        if(hasRetail){
            initialValues.betShopFavoriteAmounts = favoriteAmounts.betShopFavoriteAmounts.filter(v => v || v === 0);
            values.betShopFavoriteAmounts = formValues.betShopFavoriteAmounts.filter(v => v || v === 0);
        }

        if(hasTerminal){
            initialValues.voucherFavoriteAmounts = favoriteAmounts.voucherFavoriteAmounts.filter(v => v || v === 0);
            values.voucherFavoriteAmounts = formValues.voucherFavoriteAmounts.filter(v => v || v === 0);
        }

        setIsFormTouched(isFormChanged(initialValues, values));
    }

    const minAmountValidator = (value) => {
        if (!value) {
            return Promise.resolve();
        }

        const amount = Number(value);

        if (amount < 1) {
            return Promise.reject(t('backoffice.validation.mustBeMore').replace('%X%', 1));
        }

        return Promise.resolve();
   }


    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])


    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: userCanModify,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
            dropdown={
                {
                    value: currency,
                    onChange: value => handleCurrencyChange(value, isFormTouched, setIsFormTouched),
                    items: Object.keys(availableCurrencies).map(item => ({
                        key: item.toLowerCase(),
                        value: item.toUpperCase()
                    })),
                    small: true,
                    disabled: hasLiteMode
                }
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{}}
                    onValuesChange={onFormValuesChange}
                >
                    <Row gutter={[isMobile() ? 0 : 24, 0]}>
                        {
                            hasAgentSystem && (
                                <Col xs={24} sm={12} xl={8}>
                                    <h4
                                        className='rt--form-section-title rt--title rt--mb-24 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {
                                            isFranchisingMode() ? t("backoffice.projects.betshopOwnerTransaction") : t("backoffice.projects.agentTransaction")
                                        }
                                    </h4>
                                    <Row gutter={[16, 0]}>
                                        {
                                            FIELDS_INDEXES.map(fieldIndex => (
                                                <Col xs={24} sm={24} xl={12} key={fieldIndex}>
                                                    <Form.Item
                                                        label=""
                                                        name={["agentFavoriteAmounts", fieldIndex]}
                                                        rules={[
                                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                            { validator: (_, value) => minAmountValidator(value)}
                                                        ]}
                                                        className="rt--general-form-item rt--general-form-item-without-label"
                                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                    >
                                                        <NumericInput
                                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            ))
                                        }
                                    </Row>    
                                </Col>
                            )
                        }

                        {
                            !isFranchisingMode() && (
                                <Col xs={24} sm={12} xl={8}>
                                    <h4
                                        className='rt--form-section-title rt--title rt--mb-24 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {
                                            t("backoffice.projects.playerTransaction")
                                        }
                                    </h4>
                                    <Row gutter={[16, 0]}>
                                        {
                                            FIELDS_INDEXES.map(fieldIndex => (
                                                <Col xs={24} sm={24} xl={12} key={fieldIndex}>
                                                    <Form.Item
                                                        label=""
                                                        name={["playerFavoriteAmounts", fieldIndex]}
                                                        rules={[
                                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                            { validator: (_, value) => minAmountValidator(value)}
                                                        ]}
                                                        className="rt--general-form-item rt--general-form-item-without-label"
                                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                    >
                                                        <NumericInput
                                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            )
                        }

                        {
                            hasRetail && (
                                <Col xs={24} sm={12} xl={8}>
                                    <h4
                                        className='rt--form-section-title rt--title rt--mb-24 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {
                                            t("backoffice.projects.betshopTransaction")
                                        }
                                    </h4>
                                    <Row gutter={[16, 0]}>
                                        {
                                            FIELDS_INDEXES.map(fieldIndex => (
                                                <Col xs={24} sm={24} xl={12} key={fieldIndex}>
                                                    <Form.Item
                                                        label=""
                                                        name={["betShopFavoriteAmounts", fieldIndex]}
                                                        rules={[
                                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                            { validator: (_, value) => minAmountValidator(value)}
                                                        ]}
                                                        className="rt--general-form-item rt--general-form-item-without-label"
                                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                    >
                                                        <NumericInput
                                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            )
                        }

                        {
                            hasTerminal && (
                                <Col xs={24} sm={12} xl={8}>
                                    <h4
                                        className='rt--form-section-title rt--title rt--mb-24 rt--mt-8 rt--font-bold rt--font-biger'>
                                        {
                                            t("backoffice.projects.vouchers")
                                        }
                                    </h4>
                                    <Row gutter={[16, 0]}>
                                        {
                                            FIELDS_INDEXES.map(fieldIndex => (
                                                <Col xs={24} sm={24} xl={12} key={fieldIndex}>
                                                    <Form.Item
                                                        label=""
                                                        name={["voucherFavoriteAmounts", fieldIndex]}
                                                        rules={[
                                                            { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                            { validator: (_, value) => minAmountValidator(value)}
                                                        ]}
                                                        className="rt--general-form-item rt--general-form-item-without-label"
                                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                    >
                                                        <NumericInput
                                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            )
                        }
                    </Row>
                </Form>

            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** FavoriteAmountsComponent propTypes
    * PropTypes
*/
FavoriteAmountsComponent.propTypes = {
    /** Redux state property, is true when favorite amounts is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when favorite amounts is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the favorite amounts of current editing projects  */
    favoriteAmounts: favoriteAmountsType,
    /** Redux action to save project favorite amounts */
    saveProjectFavoriteAmounts: PropTypes.func,
    /** Redux action to get project favorite amounts */
    getProjectFavoriteAmounts: PropTypes.func,
    /** Redux state property, represents the object of available currencies of company */
    availableCurrencies: PropTypes.object,
    /** Fires on currency change */
    handleCurrencyChange: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        saveProjectFavoriteAmounts: data => {
            dispatch(saveProjectFavoriteAmounts(data));
        },

        getProjectFavoriteAmounts: (currencyCode) => {
            dispatch(getProjectFavoriteAmounts(currencyCode))
        }
    }
)

const mapStateToProps = state => {
    return {
        favoriteAmounts: state.projects.edit.favoriteAmounts,
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteAmountsComponent)
