import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import { Button, Spin } from 'antd';
import Table from 'components/common/table';
import Confirmation from 'components/common/confirmation';

import PromotionAddEditComponent from './promotion-add-edit-component';
import Empty from '../empty';

import { TERMINAL_SECTION_TYPE } from 'constants/terminalCustomize.constants';

import {
    getTerminalSectionItems,
    addTerminalPromotion,
    saveTerminalPromotion,
    removeTerminalSectionItem,
    changeTerminalSectionItemState,
    reorderTerminalSectionItems
} from 'store/actions/dashboard/terminalCustomize/sections.action';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { rowSortingHandler, sortByOrder } from '../../../helpers';

import { tableColumns } from './columns';


const maxItemsCount = 20;

const Promotions = ({
    data,
    isLoading,
    isSaving,
    getTerminalSectionItems,
    addTerminalPromotion,
    saveTerminalPromotion,
    removeTerminalSectionItem,
    changeTerminalSectionItemState,
    reorderTerminalSectionItems,
    customizeLanguage,
}) => {
    const { t } = useTranslation();

    const [addEditPopupData, setAddEditPopupData] = useState(false);

    const [deleteConfirmationData, setDeleteConfirmationData] = useState({
        isVisible: false,
        rowId: null
    })

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showAddEditPopup = data => {
        setAddEditPopupData({
            isVisible: true,
            data
        })
    }

    const hideAddEditPopup = () => {
        setAddEditPopupData({
            isVisible: false,
            data: null
        })
    }

    const handlePromotionAddEdit = data => {
        if(addEditPopupData.data?.mode === "edit"){
            handlePromotionEdit(data)
        } else {
            handlePromotionAdd(data)
        }
    }

    const handlePromotionAdd = data => {
        addTerminalPromotion(data);
    }

    const handlePromotionEdit = data => {
        saveTerminalPromotion(data);
    }

    const showDeleteConfirmation = (rowData) => {
        setDeleteConfirmationData({
            rowId: rowData.rowId,
            isVisible: true,
        })
    }

    const hideDeleteConfirmation = () => {
        setDeleteConfirmationData({
            rowId: null,
            isVisible: false,
        })
    }

    const handlePromotionDelete = () => {
        removeTerminalSectionItem(TERMINAL_SECTION_TYPE.PROMOTIONS, deleteConfirmationData.rowId);
        hideDeleteConfirmation();
    }

    const handleTableSwitcherChange = (isChecked, record) => {
        changeTerminalSectionItemState(TERMINAL_SECTION_TYPE.PROMOTIONS, record.rowId, isChecked)
    }

    const handleRowSort = (from, to) => {
        const orders = rowSortingHandler(from, to, tableData);
        reorderTerminalSectionItems(TERMINAL_SECTION_TYPE.PROMOTIONS, orders)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ 
            mainColumns: tableColumns,
        });
    }, [])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [
        {
            title: t("backoffice.common.edit"),
            icon: "icon-edit",
            onClick: record => {
                showAddEditPopup({
                    ...record,
                    mode: "edit"
                })
            }
        },
        {
            title: t("backoffice.common.delete"),
            icon: "icon-trash",
            onClick: showDeleteConfirmation,
            disabled: record => !record.deletable
        },
    ]

    //#endregion

    // Get Feutured Promotions
    useEffect(() => {
        getTerminalSectionItems(TERMINAL_SECTION_TYPE.PROMOTIONS);
    }, [customizeLanguage]);

    const tableData = useMemo(() => sortByOrder(data), [data])

    const activeItemsCount = useMemo(() => data.filter(item => item.enabled).length, [data])

    return (
        <Spin spinning={isLoading || isSaving}>
             <div className='rt--flex rt--justify-end rt--mb-16'>
                <Button
                    type="primary"
                    className="rt--button rt--button-main"
                    onClick={() => showAddEditPopup({
                        mode: "add"
                    })}
                    disabled={activeItemsCount >= maxItemsCount}
                    icon={<i className='icon-plus rt--font-bigest' />}
                >
                    <span className='rt--font-normal'>
                        {t('backoffice.terminals.addPromotion')}
                    </span>
                </Button>
            </div>

            <div className="rt--customize-table">
                <Table
                    data={tableData}
                    renderEmpty={() => (
                        <Empty 
                            text={t('backoffice.terminals.noPromotions')}
                            description={t('backoffice.terminals.addPromotion')}
                        />
                    )}
                    columns={mainTableColumns}
                    noPagination={true}
                    actions={tableRowActions}
                    switcher={{
                        valueIndex: "enabled",
                        dataIndex: "enabled",
                        disabled: record => !record.editable || ( !record.enabled && activeItemsCount >= maxItemsCount),
                        onChange: handleTableSwitcherChange,
                    }}
                    uniqueKey="rowId"
                    sortable={true}
                    sortableData={tableData}
                    onRowSort={handleRowSort}
                />

                {addEditPopupData.isVisible && (
                    <PromotionAddEditComponent
                        onClose={hideAddEditPopup}
                        onOk={handlePromotionAddEdit}
                        data={addEditPopupData.data}
                        isSaving={isSaving}
                    />
                )}

                <Confirmation
                    title={t('backoffice.terminals.deletePromotion')}
                    message={t('backoffice.terminals.deletePromotionMessage')}
                    onOk={handlePromotionDelete}
                    onCancel={hideDeleteConfirmation}
                    isVisible={deleteConfirmationData.isVisible}
                />
            </div>
        </Spin>
    )
}

/** Promotions propTypes
 * PropTypes
*/
Promotions.propTypes = {
    data: PropTypes.array,
    /** Redux state property, is true when loading terminal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when terminal saving */
    isSaving: PropTypes.bool,
    /** Redux action to get terminal promotions */
    getTerminalSectionItems: PropTypes.func,
    /** Redux action to add terminal promotions */
    addTerminalPromotion: PropTypes.func,
    /** Redux action to save terminal promotions */
    saveTerminalPromotion: PropTypes.func,
    /** Redux action to remove terminal section item */
    removeTerminalSectionItem: PropTypes.func,
    /** Redux action to change terminal section item state */
    changeTerminalSectionItemState: PropTypes.func,
    /** Redux action to reorder terminal section items */
    reorderTerminalSectionItems: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
    getTerminalSectionItems: sectionType => {
        dispatch(getTerminalSectionItems(sectionType))
    },
    addTerminalPromotion: requestBody => {
        dispatch(addTerminalPromotion(requestBody))
    },
    saveTerminalPromotion: requestBody => {
        dispatch(saveTerminalPromotion(requestBody))
    },
    removeTerminalSectionItem: (sectionType, rowId) => {
        dispatch(removeTerminalSectionItem(sectionType, rowId))
    },
    changeTerminalSectionItemState: (sectionType, rowId, enabled) => {
        dispatch(changeTerminalSectionItemState(sectionType, rowId, enabled))
    },
    reorderTerminalSectionItems: (sectionType, rowOrders) => {
        dispatch(reorderTerminalSectionItems(sectionType, rowOrders))
    }
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.terminalCustomize.customizeLanguage,
        isLoading: state.terminalCustomize.isLoading,
        isSaving: state.terminalCustomize.isSaving,
        data: state.terminalCustomize.sections[TERMINAL_SECTION_TYPE.PROMOTIONS].data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);