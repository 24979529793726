import React from 'react';

export const tableColumns = [
    {
        title: "backoffice.terminals.title",
        dataIndex: "title"
    },
    {
        title: "backoffice.terminals.banner",
        dataIndex: "file",
        render: value => {
            return value ? (
                <div className="rt--table-col-img rt--mt-6 rt--mb-6">
                    <img src={`${import.meta.env.SYSTEM_CDN_URL}/${value}`.toLowerCase()} alt="attachment" />
                </div>
            ) : "-"
        },
    },
]