import {AGENT_COMMISSION_STATE} from "constants/agent.constants";

const mapSettingsToForm = ({ agentSettings, parentSettings }) => (
    agentSettings.categories.reduce((categoryAcc, category, categoryIndex) => {
        const parentCategory = parentSettings?.categories[categoryIndex];

        let state = category.state;

        if (parentCategory && parentCategory.state === AGENT_COMMISSION_STATE.INACTIVE) {
            state = AGENT_COMMISSION_STATE.INACTIVE;
        }

        categoryAcc[category.id] = {
            state,
            commissionPercent: category.commissionPercent,
            providers: category.providers.reduce((providerAcc, provider, providerIndex) => {
                const parentProvider = parentCategory?.providers[providerIndex];

                let state = provider.state;

                if (parentProvider && parentProvider.state === AGENT_COMMISSION_STATE.INACTIVE) {
                    state = AGENT_COMMISSION_STATE.INACTIVE;
                }

                providerAcc[provider.id] = {
                    state,
                    commissionPercent: provider.commissionPercent
                };

                return providerAcc;
            }, {})
        };

        return categoryAcc;
    }, {})
)

export default mapSettingsToForm;
