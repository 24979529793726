import { isFranchisingMode } from "utils/common";
import { getUser } from "utils/auth";

import { WALLET_OPERATION_TYPE } from "constants/wallet.constants";
import { USER_ROLE } from "constants/user.constants";

export const getAvailableOperationTypes = ({ hasRetail, hasAgentSystem, hasLiteMode }) => {
    const isAgentUser = getUser().role === USER_ROLE.AGENT;
    const isWalletConfigNonRelated = getUser().isStandartPaymentWithRelatedWallets === false

    if (isFranchisingMode()) {
        if (isAgentUser) {
            return [
                WALLET_OPERATION_TYPE.AGENT_BETSHOP_DEPOSIT,
                WALLET_OPERATION_TYPE.AGENT_BETSHOP_WITHDRAWAL,
                WALLET_OPERATION_TYPE.SUPER_AGENT_PAY_DEBT_AGENT_BY_CASH,
                WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE_PAY_DEBT,
                WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT
            ];
        }

        return [
            ...(
                hasAgentSystem ? [
                    WALLET_OPERATION_TYPE.RECALCULATE,
                    WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE_PAY_DEBT,
                    WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT,
                ] : []
            ),
            WALLET_OPERATION_TYPE.AGENT_BETSHOP_DEPOSIT,
            WALLET_OPERATION_TYPE.AGENT_BETSHOP_WITHDRAWAL,
            ...(
                hasAgentSystem ? [
                    WALLET_OPERATION_TYPE.AGENT_COMISSION_CALCULATION,
                    WALLET_OPERATION_TYPE.AGENT_COMISSION_RECALCULATION,
                    WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_BY_CASH,
                ] : []
            ),
            WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_DEPOSIT_AGENT,
            WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_WITHDRAW_AGENT,
            WALLET_OPERATION_TYPE.ROLLBACK
        ]
    }

    if (hasLiteMode) {
        const res = [
            WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE,
            WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE,
            WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_FROM_BALANCE,
            WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_BALANCE,
            WALLET_OPERATION_TYPE.ROLLBACK
        ];

        if (!isAgentUser) {
            res.unshift(
                WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_DEPOSIT_AGENT,
                WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_WITHDRAW_AGENT
            )
        }

        return res;
    }

    return [
        WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE,
        ...(
            isWalletConfigNonRelated ? [
                WALLET_OPERATION_TYPE.AGENT_DEPOSIT_TO_CREDIT_LINE
            ] : []
        ),
        WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_CREDIT,
        ...(
            !isWalletConfigNonRelated ? [
                WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_BALANCE_PAY_DEBT,
                WALLET_OPERATION_TYPE.AGENT_DEPOSIT_FROM_CREDIT_PAY_DEBT
            ] : []
        ),
        WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE,
        ...(
            isWalletConfigNonRelated ? [
                WALLET_OPERATION_TYPE.AGENT_WITHDRAW_FROM_CREDIT_LINE
            ] : []
        ),
        ...(
            !isWalletConfigNonRelated ? [
                WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_BALANCE_PAY_DEBT,
            ] : []
        ),
        WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_CREDIT,
        ...(
            !isWalletConfigNonRelated ? [
                WALLET_OPERATION_TYPE.AGENT_WITHDRAWAL_FROM_CREDIT_PAY_DEBT,
                WALLET_OPERATION_TYPE.AGENT_GIVE_CREDIT,
            ] : []
        ),
        WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_BY_CASH,
        WALLET_OPERATION_TYPE.AGENT_COMISSION_CALCULATION,
        WALLET_OPERATION_TYPE.AGENT_COMISSION_RECALCULATION,
        WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_NON_CASH,
        WALLET_OPERATION_TYPE.AGENT_DEBT_PAYOUT_FROM_BALANCE,
        ...(
            hasRetail ? [
                WALLET_OPERATION_TYPE.AGENT_BETSHOP_DEPOSIT,
                WALLET_OPERATION_TYPE.AGENT_BETSHOP_WITHDRAWAL,
            ] : []
        ),
        WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_FROM_BALANCE,
        ...(
            !isWalletConfigNonRelated ? [
                WALLET_OPERATION_TYPE.PLAYER_DEPOSIT_WITH_DEBT,
            ] : []
        ),
        WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_BALANCE,
        ...(
            !isWalletConfigNonRelated ? [
                WALLET_OPERATION_TYPE.PLAYER_WITHDRAWAL_TO_DEBT,
            ] : []
        ),
        ...(!isAgentUser
            ? [
                WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_DEPOSIT_AGENT,
                WALLET_OPERATION_TYPE.SUPER_AGENT_BALANCE_WITHDRAW_AGENT
            ]
            : []
        ),
        WALLET_OPERATION_TYPE.SUPER_AGENT_PAY_DEBT_AGENT_BY_CASH,
        WALLET_OPERATION_TYPE.SUPER_AGENT_PAY_DEBT_AGENT,
        WALLET_OPERATION_TYPE.ROLLBACK
    ]
}
