import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { isMobile } from "utils/common";

import { refreshToken, getUser } from 'utils/auth';

import {
    SET_TERMINALS_ACTION_BEFORE,
    SET_TERMINALS_ACTION_FINISH,
    SET_TERMINALS,
    SET_TERMINALS_SORTING,
    SET_TERMINALS_FILTERS,
    SET_TERMINALS_SAVE_ACTION_BEFORE,
    SET_TERMINALS_SAVE_ACTION_FINISH
} from "../../../../actionTypes";

export const setTerminalsActionBefore = () => ({
    type: SET_TERMINALS_ACTION_BEFORE,
});

export const setTerminalsActionFinished = () => ({
    type: SET_TERMINALS_ACTION_FINISH,
});

export const setTerminalsSaveActionBefore = () => ({
    type: SET_TERMINALS_SAVE_ACTION_BEFORE,
});

export const setTerminalsSaveActionFinished = () => ({
    type: SET_TERMINALS_SAVE_ACTION_FINISH,
});

const setTerminals = (terminals, add) => ({
    type: SET_TERMINALS,
    payload: { terminals, add },
});

export const setTerminalsSorting = sorting => ({
    type: SET_TERMINALS_SORTING,
    payload: { sorting },
});

export const setTerminalsFilters = filters => ({
    type: SET_TERMINALS_FILTERS,
    payload: { filters },
});


export const getTerminals = nextPage => {
    return (dispatch, getState) => {

        const page = nextPage === "first" ? 1 : nextPage === "next" ? getState().terminals.sorting.page + 1 : getState().terminals.sorting.page;
        let params = {
            ...getState().terminals.sorting,
            ...getState().terminals.filters,
            page: page
        };

        if(page > 1 && getState().terminals.total <= (page - 1 ) * getState().terminals.sorting.limit){
            return Promise.resolve();
        }

        if(nextPage === "next" && getState().terminals.total === 0){
            return Promise.resolve();
        }

        dispatch(setTerminalsActionBefore());

        return axios({
            url: ApiUrls.GET_TERMINALS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: terminals } }) => {
                dispatch(setTerminals(terminals, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setTerminalsSorting({ ...getState().terminals.sorting, page: page }));
                dispatch(setTerminalsActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalsActionFinished());
            });
    }
}

export const createTerminal = (terminal, onSuccess) => {
    return (dispatch) => {
        dispatch(setTerminalsSaveActionBefore());
        return axios({
            url: ApiUrls.CREATE_TERMINAL,
            method: Methods.POST,
            data: {
                ...terminal
            },
        })
            .then(({ data }) => {
                dispatch(setTerminalsSaveActionFinished());
                if (isResponseSuccess(data)) {

                    const token = getUser()?.refreshToken;

                    if (!getUser()?.hasPrevious) {
                        refreshToken(token)
                            .then(() => {
                                onSuccess && onSuccess(data.value);
                            });
                    } else {
                        onSuccess && onSuccess(data.value)
                    }
                }
            })
            .catch(() => {
                dispatch(setTerminalsSaveActionFinished());
            });
    };
};
