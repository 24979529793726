export const mapSubProjectsToTree = (subProjects) => {
    return subProjects.map(subProject => {
        const children = subProject.domains.map(domain => ({
            type: 'group',
            title: domain,
            value: domain,
            key: domain,
            disabled: true,
            checkable: false,
            selectable: false
        }));

        const label = `${children.map(child => child.title)[0]} ${children.length > 1 ? `(${children.length})` : ""}`;

        return ({
            title: subProject.projectName,
            value: subProject.projectId,
            key: subProject.projectId,
            label,
            children
        });
    })
}
