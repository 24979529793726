import React from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

import Tabs from 'components/common/tabs';

import GeneralInfoComponent from '../edit/sections/general';
import CommissionPlansComponent from '../..//commissionPlans';
import CurrenciesComponent from '../edit/sections/currencies';
import LimitSettingsComponent from '../edit/sections/limitSettings';
import AdditionalAccessesComponent from '../edit/sections/additionalAccesses';
import PermissionsAndGroupsComponent from '../../../userManagement/users/edit/sections/permissionsAndGroups';
import WalletsComponent from '../edit/sections/wallets';
import SessionsComponent from "../edit/sections/sessions";
import AgentPaymentsComponent from '../edit/sections/payments';

import Paths from 'constants/path.constants';
import { USER_TYPE, USER_ROLE } from "constants/user.constants";
import { AGENT_TYPE } from "constants/agent.constants"
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { getUser } from "utils/auth"
import { isFranchisingMode } from 'utils/common';

import useProjectType from 'hooks/useProjectType';
import CommissionComponent from "pages/agentSystem/agents/edit/sections/commission";

/** Agent Edit Page Component */
const AgentEditComponent = () => {
    const { t } = useTranslation();
    const { search } = useLocation();

    const entityRole = (new URLSearchParams(search)).get("role");

    const { hasLiteMode, hasLatamMode } = useProjectType();

    const openedFromPerformanceReport = (new URLSearchParams(search)).get("fromPerformanceReport");

    const items = [
        {
            title: t("backoffice.agents.general"),
            permissions: [{ resource: PERMISSION_RESOURCE.AGENT_GENERALINFO, action: PERMISSION_ACTION.VIEW }],
            component: <GeneralInfoComponent />
        },
        {
            title: t("backoffice.agents.wallets"),
            permissions: [{ resource: getUser()?.role === USER_ROLE.ADMIN ? PERMISSION_RESOURCE.AGENT_WALLETS : PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.VIEW }],
            component: <WalletsComponent />
        },
        {
            title: t("backoffice.agents.commission"),
            permissions: [{ resource: getUser()?.role === USER_ROLE.ADMIN ? PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN : PERMISSION_RESOURCE.AGENT, action: PERMISSION_ACTION.VIEW }],
            component: <CommissionComponent />,
            disabled: !hasLatamMode
        },
        {
            title: t("backoffice.agents.commissionPlans"),
            permissions: [{ resource: PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN, action: PERMISSION_ACTION.VIEW }],
            component: <CommissionPlansComponent agentType={AGENT_TYPE.AGENT} />,
            pathParams: {name: "commissionPlanName", id: "commissionPlanId"},
            disabled: hasLiteMode
        },
        {
            title: t("backoffice.agents.currencies"),
            permissions: [{ resource: PERMISSION_RESOURCE.AGENT_CURRENCIES, action: PERMISSION_ACTION.VIEW }],
            component: <CurrenciesComponent />,
            disabled: hasLiteMode
        },
        {
            title: t("backoffice.agents.limits"),
            permissions: [{ resource: PERMISSION_RESOURCE.AGENT_LIMITS, action: PERMISSION_ACTION.VIEW }],
            component: <LimitSettingsComponent />,
            disabled: Number(entityRole) !== USER_TYPE.AGENT || hasLiteMode
        },
        {
            title: t("backoffice.agents.permissionsAndGroups"),
            permissions: [{ resource: PERMISSION_RESOURCE.AGENT_PERMISSIONS, action: PERMISSION_ACTION.VIEW }],
            component: <PermissionsAndGroupsComponent permissionResource={PERMISSION_RESOURCE.AGENT_PERMISSIONS} userType={USER_TYPE.AGENT}/>,
            disabled: hasLatamMode
        },
        {
            title: t("backoffice.agents.additionalAccesses"),
            permissions: [{ resource: PERMISSION_RESOURCE.AGENT_ADDITIONAL_ACCESSES, action: PERMISSION_ACTION.VIEW }],
            component: <AdditionalAccessesComponent />,
            disabled: Number(entityRole) !== USER_TYPE.AGENT || isFranchisingMode()
        },
        {
            title: t("backoffice.agents.sessions"),
            permissions: [{ resource: PERMISSION_RESOURCE.AGENT_SESSIONS, action: PERMISSION_ACTION.VIEW }],
            component: <SessionsComponent />
        },
        {
            title: t("backoffice.agents.payments"),
            permissions: [{ resource: PERMISSION_RESOURCE.AGENT_PAYMENT, action: PERMISSION_ACTION.VIEW }],
            component: <AgentPaymentsComponent />,
            disabled: isFranchisingMode() || hasLiteMode,
            pathParams: { name: "bankName", id: "bankId" }
        },
    ]

    return (
        <Tabs
            items={items}
            mainPage={
                {
                    title: openedFromPerformanceReport ? t('backoffice.menu.performanceReport') : isFranchisingMode() ? t('backoffice.menu.betshopOwners') : t('backoffice.menu.agents'),
                    path: openedFromPerformanceReport ? Paths.REPORTS_PERFORMANCE_REPORTS : isFranchisingMode() ? Paths.BETSHOP_OWNERS : Paths.AGENTS
                }
            }
        />
    )
}


export default AgentEditComponent;
