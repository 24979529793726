import React from 'react';
import PropTypes from "prop-types";

import searchType from "../../../../../types/components/search.type";
import columnsButtonType from "../../../../../types/components/columnsButton.type";
import exportButtonType from "../../../../../types/components/exportButton.type";
import buttonType from "../../../../../types/components/button.type";
import breadcrumbsType from "../../../../../types/components/breadcrumbs.type";

import TabTableDashboardLayout from "../../../../../components/layouts/tab/table";
import MainDashboardLayout from "../../../../../components/layouts/main";

import {isMobile} from "utils/common";

const SportBetsLayout = ({
    isInTab,
    children,
    ...props
 }) => {

    if (isInTab) {
        const tabProps = {
            header: props.header,
            extraContent: props.extraContent.content,
        };


        return (
            <TabTableDashboardLayout {...tabProps} isContentScrollable={isMobile()}>
                {children}
            </TabTableDashboardLayout>
        )
    }

    return (
        <MainDashboardLayout {...props}>
            {children}
        </MainDashboardLayout>
    );
};

SportBetsLayout.propTypes = {
    isInTab: PropTypes.bool,
    children: PropTypes.node,
    header: PropTypes.shape({
        search: PropTypes.shape(searchType),
        filters: PropTypes.node,
        columns: PropTypes.shape(columnsButtonType),
        export: PropTypes.shape(exportButtonType),
        buttons: PropTypes.arrayOf(PropTypes.shape(buttonType)),
        breadcrumbs: PropTypes.shape(breadcrumbsType),
        extra: PropTypes.node,
        leftContent: PropTypes.node,
    }),
    dropdowns: PropTypes.arrayOf(PropTypes.object),
    extraContent: PropTypes.shape({
        content: PropTypes.node
    })
}

export default SportBetsLayout;
