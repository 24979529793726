import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import Table from 'components/common/table';
import Empty from '../../empty';

import AgeRestrictionPopupComponent from './ageRestriction-popup-component';

import { TERMINAL_FOOTER_ITEM_TYPE, TERMINAL_SECTION_TYPE } from 'constants/terminalCustomize.constants';

import {
    getTerminalSectionItems,
    changeTerminalSectionItemState,
    saveTerminalAgeRestriction,
} from 'store/actions/dashboard/terminalCustomize/sections.action';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { tableColumns } from './columns';


const FooterGeneral = ({
    data,
    getTerminalSectionItems,
    changeTerminalSectionItemState,
    saveTerminalAgeRestriction,
    customizeLanguage,
    isSaving
}) => {
    const { t } = useTranslation();

    const [ageRestrictionPopup, setAgeRestrictionPopup] = useState({
        isVisible: false,
        age: null
    })


    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showAgeRestrictionPopup = age => {
        setAgeRestrictionPopup({
            isVisible: true,
            age
        })
    }

    const hideAgeRestrictionPopup = () => {
        setAgeRestrictionPopup({
            isVisible: false,
            age: null
        })
    }

    const handleAgeRestrictionChange = data => {
        saveTerminalAgeRestriction(data)
    }

    const handleEdit = record => {
        if(record.type === TERMINAL_FOOTER_ITEM_TYPE.AGE_RESTRICTION){
            showAgeRestrictionPopup(record.value)
        }
    }

    const handleTableSwitcherChange = (isChecked, record) => {
        changeTerminalSectionItemState(TERMINAL_SECTION_TYPE.FOOTER_GENERAL, record.rowId, isChecked)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ 
            mainColumns: tableColumns,
        });
    }, [])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [
        {
            title: t("backoffice.common.edit"),
            icon: "icon-edit",
            onClick: handleEdit,
            disabled: record => record.type !== TERMINAL_FOOTER_ITEM_TYPE.AGE_RESTRICTION
        },
    ]

    //#endregion

    // Get Header Items
    useEffect(() => {
        getTerminalSectionItems(TERMINAL_SECTION_TYPE.FOOTER_GENERAL);
    }, [customizeLanguage]);

    return (
        <div className="rt--customize-table rt--mt-24">
            <Table
                data={data}
                renderEmpty={() => <Empty />}
                columns={mainTableColumns}
                noPagination={true}
                actions={tableRowActions}
                switcher={{
                    valueIndex: "enabled",
                    dataIndex: "enabled",
                    disabled: record => !record.editable,
                    onChange: handleTableSwitcherChange,
                }}
                uniqueKey="rowId"
            />
            {
                ageRestrictionPopup.isVisible && (
                    <AgeRestrictionPopupComponent 
                        isSaving={isSaving}
                        onClose={hideAgeRestrictionPopup}
                        onOk={handleAgeRestrictionChange}
                        age={ageRestrictionPopup.age}
                    />
                )
            }
        </div>
    )
}

/** FooterGeneral propTypes
 * PropTypes
*/
FooterGeneral.propTypes = {
    data: PropTypes.array,
    /** Redux action to get terminal footer items */
    getTerminalSectionItems: PropTypes.func,
    /** Redux action to change terminal section item state */
    changeTerminalSectionItemState: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Redux state property, is true when saving data */
    isSaving: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
    getTerminalSectionItems: sectionType => {
        dispatch(getTerminalSectionItems(sectionType))
    },
    changeTerminalSectionItemState: (sectionType, rowId, enabled) => {
        dispatch(changeTerminalSectionItemState(sectionType, rowId, enabled))
    },
    saveTerminalAgeRestriction: data => {
        dispatch(saveTerminalAgeRestriction(data))
    },
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.terminalCustomize.customizeLanguage,
        data: state.terminalCustomize.sections[TERMINAL_SECTION_TYPE.FOOTER_GENERAL].data,
        isSaving: state.terminalCustomize.isSaving,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterGeneral);