import {
    SET_VOUCHER_ACTION_BEFORE,
    SET_VOUCHER_ACTION_FINISH,
    SET_VOUCHER,
    SET_CURRENT_VOUCHER,
    SET_VOUCHER_SAVE_ACTION_BEFORE,
    SET_VOUCHER_SAVE_ACTION_FINISH,
    SET_VOUCHER_LOGO
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_VOUCHER_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_VOUCHER_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_VOUCHER_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true
            }
        case SET_VOUCHER_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false
            }
        case SET_VOUCHER:
            return {
                ...state,
                voucher: payload.ticket
            }
        case SET_CURRENT_VOUCHER:
            return {
                ...state,
                currentVoucher: payload.ticket
            }
        case SET_VOUCHER_LOGO:
            return {
                ...state,
                voucher: {
                    ...state.voucher,
                    ticketLogoId: payload.ticketLogoId
                }
            }
        default:
            return state;
    }
};
