import React, { useMemo, useState, useEffect, Fragment, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Form, Row, Col, Radio, Space, Spin } from "antd";

import Select from 'components/common/select';
import NumericInput from 'components/common/numericInput';
import TextAreaInput from 'components/common/textAreaInput';
import FavoriteAmounts from '../favoriteAmounts';

import {
    getBetshops,
    getBetshopCurrency,
    betShopDeposit,
    betShopWithdrawal,
    betShopLimitAdjustment,
    resetBetShopLimit
} from "../../../../api";

import useTransfer from '../../../../hooks/useTransfer';

import { getUser } from 'utils/auth';
import { isFranchisingMode, isMobile, triggerCustomEvent } from 'utils/common';
import { hasBetshopNonDirectTransactionAccess } from 'utils/access';
import { isResponseSuccess } from 'utils/request';

import { amountValidator, formatNotes } from "../../../../helpers";

import { USER_ROLE } from 'constants/user.constants';
import { WALLET_TRANSACTION_TYPE } from 'constants/wallet.constants';
import { AMOUNT_REGEX } from 'constants/regex.constants';
import { TRANSFER_ENTITY_TYPE, TRANSFER_OPERATION_GROUP, TRANSACTION_AMOUNT_ACTION_TYPE, TRANSFER_POPUP_OK_ACTION_EVENT_NAME, TRANSFER_SUCCESS_EVENT_NAME } from '../../../../constants';

import userInfoType from 'types/profile/userInfo.type';



const getOperationTypeByGroup = group => {
    if (group === TRANSFER_OPERATION_GROUP.DEPOSIT) {
        return WALLET_TRANSACTION_TYPE.BETSHOP_DEPOSIT
    } else if (group === TRANSFER_OPERATION_GROUP.WITHDRAWAL) {
        return WALLET_TRANSACTION_TYPE.BETSHOP_WITHDRAWAL
    } else {
        return WALLET_TRANSACTION_TYPE.BETSHOP_DEPOSIT
    }
}

const operationsMapping = {
    [WALLET_TRANSACTION_TYPE.BETSHOP_DEPOSIT]: betShopDeposit,
    [WALLET_TRANSACTION_TYPE.BETSHOP_WITHDRAWAL]: betShopWithdrawal,
    [WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_ADJUSTMENT]: betShopLimitAdjustment,
    [WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_RESET]: resetBetShopLimit
}

const BetshopTransferForm = ({
    userInfo
}) => {

    const { t } = useTranslation();

    const [formInstance] = Form.useForm();

    const { setFieldsValue, getFieldValue, validateFields } = formInstance;

    const { transferData, setSaving, setTransferResultData } = useTransfer();

    const { group, userId, currencyCode, transactionType } = transferData ?? {};

    const [operationType, setOperationType] = useState(transactionType ?? getOperationTypeByGroup(group));

    const [betshops, setBetshops] = useState([]);

    const [selectedBetshop, setSelectedBetshop] = useState(userId ?? null)

    const [selectedCurrency, setSelectedCurrency] = useState(currencyCode ?? null)

    const [isCurrencyLoading, setIsCurrencyLoading] = useState(false);

    /** Get Betshops */
    useEffect(() => {
        if(!userId){
            getBetshops(!hasBetshopNonDirectTransactionAccess(userInfo) ? userInfo.id : undefined)
                .then(
                    result => {
                        setBetshops(result);
                    }
                )
        }
    }, [])

    const betshopFields = useMemo(() => {
        return betshops.map(item => (
            { value: item.id, text: item.name }
        ))
    }, [betshops])

    /** Set default user Id */
    useEffect(() => {
        if (!userId) {
            let betshopId = null;
            if (betshops.length > 0) {
                betshopId = betshops[0].id;
            }

            setFieldsValue({ userId: betshopId });
            setSelectedBetshop(betshopId);
        }
    }, [ betshops ])

    /** Load currency */
    useEffect(() => {
        if(!currencyCode && selectedBetshop && operationType !== WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_RESET){
            setIsCurrencyLoading(true);
            getBetshopCurrency(selectedBetshop)
                .then(
                    result => setSelectedCurrency(result)
                )
                .finally(
                    () => setIsCurrencyLoading(false)
                );
            }
    }, [selectedBetshop])

    const handleFavAmountClick = value => {
        const currentValue = getFieldValue("amount") ?? 0;
        setFieldsValue({amount: Number(currentValue) + value})
    }

    const handleBetshopChange = value => {
        setSelectedBetshop(value);
    }

    const onTransferRequest = data => {
        if (isResponseSuccess(data)) {
            const resp = data.value;
            if(resp){
                setTransferResultData(resp?.wallets);
                triggerCustomEvent(
                    TRANSFER_SUCCESS_EVENT_NAME,
                    {
                        transactionType : operationType,
                        entityType: TRANSFER_ENTITY_TYPE.BETSHOP,
                        wallets: resp?.wallets
                    }
                );
            }
        }
    }

    const handler = useCallback(() => {
        validateFields()
            .then(data => {
                setSaving(true);

                const d = {
                    notes: formatNotes(data.notes),
                    betShopId: userId ? userId : data.userId
                }

                if (operationType !== WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_RESET) {
                    d.amount = Number(data.amount);
                }

                if (operationType === WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_ADJUSTMENT){
                    if(data.amountActionType === TRANSACTION_AMOUNT_ACTION_TYPE.REDUCE){
                        d.amount = 0 - Number(data.amount);
                    }

                }

                operationsMapping[operationType]?.(d)
                    .then(data => onTransferRequest(data))
                    .finally(() => setSaving(false));

            }).catch(ex => {
                console.log(ex)
            })
    }, [operationType])

    /** On Transfer */
    useEffect(() => {
        document.addEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);
        }
    }, [handler])

    return (
        <Form
            className="rt--form"
            form={formInstance}
            colon={false}
            requiredMark={false}
            layout="vertical"
            initialValues={{
                amountActionType: TRANSACTION_AMOUNT_ACTION_TYPE.ADD
            }}
        >
            <Row gutter={[16, 0]}>
                {
                    (
                        !group && !transactionType
                    ) && (
                        <Col span={24}>
                            <div className='rt--mb-24'>
                                <Form.Item
                                    label=""
                                    className='rt--form-item-inline rt--form-item-radio-buttons'
                                >
                                    <Radio.Group
                                        optionType="button"
                                        options={
                                            [
                                                {
                                                    label: <span title={t('backoffice.wallet.deposit')}>{t('backoffice.wallet.deposit')}</span>,
                                                    value: WALLET_TRANSACTION_TYPE.BETSHOP_DEPOSIT
                                                },
                                                {
                                                    label: <span title={t('backoffice.wallet.withdrawal')}>{t('backoffice.wallet.withdrawal')}</span>,
                                                    value: WALLET_TRANSACTION_TYPE.BETSHOP_WITHDRAWAL
                                                },
                                                ... (
                                                    getUser()?.role === USER_ROLE.ADMIN && isFranchisingMode() ? [
                                                        {
                                                            label: <span title={t('backoffice.wallet.limitAdjustment')}>{t('backoffice.wallet.limitAdjustment')}</span>,
                                                            value: WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_ADJUSTMENT
                                                        },
                                                    ] : []
                                                )
                                            ]
                                        }
                                        value={operationType}
                                        onChange={e => setOperationType(e.target.value)}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                    )
                }

                {
                    operationType === WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_ADJUSTMENT ?
                        (
                            <Col span={24}>
                                <Form.Item
                                    label={t('backoffice.wallet.amountAdjustment')}
                                    name="amountActionType"
                                    className='rt--form-item-inline rt--form-item-radio-buttons'
                                >
                                    <Radio.Group
                                        options={[
                                            { label: t('backoffice.wallet.increase'), value: TRANSACTION_AMOUNT_ACTION_TYPE.ADD },
                                            { label: t('backoffice.wallet.decrease'), value: TRANSACTION_AMOUNT_ACTION_TYPE.REDUCE }
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                        ) : null
                }
                {
                    !userId && (
                        <Col span={24}>
                            <Form.Item
                                label={`${t('backoffice.common.betshop')}*`}
                                name="userId"
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') }
                                ]}
                            >
                                <Select
                                    placeholder={`${t("backoffice.common.select")} ${t('backoffice.common.betshop')}`}
                                    options={betshopFields}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    onChange={handleBetshopChange}
                                    search={true}
                                />
                            </Form.Item>
                        </Col>
                    )
                }

                {
                    operationType !== WALLET_TRANSACTION_TYPE.BETSHOP_LIMIT_RESET ?
                        (
                            !isCurrencyLoading ? (
                                <Fragment>
                                    <Col span={24}>
                                        <Form.Item
                                            label={`${t('backoffice.wallet.amountToSend')} *`}
                                            className="rt--form-item-without-margin rt--spend-amount"
                                        >
                                            <Space.Compact
                                                className='rt--transfer-amount'
                                            >
                                                <Form.Item
                                                    className='rt--transfer-amount-currency'
                                                >
                                                    <Select
                                                        placeholder="CUR"
                                                        value={selectedCurrency?.toUpperCase()}
                                                        onChange={value => setSelectedCurrency(value)}
                                                        options={[]}
                                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                        showArrow={false}
                                                        disabled={true}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name="amount"
                                                    rules={[
                                                        { required: true, message: t('backoffice.validation.fieldRequired') },
                                                        { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                        { validator: amountValidator }
                                                    ]}
                                                    validateFirst
                                                    className="rt--transfer-amount-amount rt--general-form-item"
                                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                >

                                                    <NumericInput
                                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                        autoFocus={!isMobile()}
                                                    />
                                                </Form.Item>


                                            </Space.Compact>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <FavoriteAmounts
                                            handleClick={handleFavAmountClick}
                                            entityType={TRANSFER_ENTITY_TYPE.BETSHOP}
                                            currencyCode={selectedCurrency?.toUpperCase()}
                                        />
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            label={t('backoffice.wallet.notes')}
                                            name="notes"
                                            rules={[
                                                {max: 1000, message: t('backoffice.validation.fieldInvalid')}
                                            ]}
                                            className="rt--form-item-without-margin rt--general-form-item"
                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                                        >
                                            <TextAreaInput
                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                                                maxLength={1000}
                                                rows={6}
                                            />
                                        </Form.Item>
                                    </Col>

                                </Fragment>
                            ) : (
                                <div className='rt--width-full rt--transfer-loading'>
                                    <Spin />
                                </div>
                            )
                        ) : null
                }
            </Row>
        </Form>
    )
}

/** BetshopTransferForm propTypes
    * PropTypes
*/
BetshopTransferForm.propTypes = {
    /** Redux state property, the user info */
    userInfo: userInfoType
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
    }
}

export default connect(mapStateToProps, null)(BetshopTransferForm)
