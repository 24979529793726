import React, { useEffect, useState, Fragment, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import Dropdown from "components/common/dropdown";
import Search from "components/common/search";
import MobileDropdown from "./mobileDropdown";

import { changeProject } from 'store/actions/dashboard/profile/userInfo.action';

import { isMobile, binaryToFlags } from 'utils/common';
import { PROJECT_TYPE } from "constants/project.constants"
import { USER_ROLE, USER_TYPE } from "constants/user.constants";
import { SEARCH_TYPE } from 'components/common/search/constants';

import Paths from 'constants/path.constants';
import { getUser } from 'utils/auth';

import companyType from "types/company/company.type";

const PATHS_TO_SHOW_DROPDOWN_FOR_AGENTS = [
    Paths.AGENTS,
    Paths.AGENTS_EDIT + "/:id",
    Paths.BETSHOP_OWNERS,
    Paths.BETSHOP_OWNERS_EDIT + "/:id",
    Paths.NETWORK,
    Paths.REPORTS_COMMISSION_CALCULATIONS,
    Paths.REPORTS_AGENT_CALCULATION_HISTORY + "/:id",
    Paths.DEVELOPER_GENERATIONS,
]

const PATHS_TO_SHOW_DROPDOWN_FOR_RETAIL = [
    Paths.BETSHOPS,
    Paths.BETSHOPS_EDIT + "/:id",
    Paths.CASHIERS,
    Paths.CASHIERS_EDIT + "/:id",
    Paths.BETSHOP_MANAGERS,
    Paths.BETSHOP_MANAGERS_EDIT + "/:id",
]

const PATHS_TO_SHOW_DROPDOWN_DISABLED = [
    Paths.COMPANIES_EDIT + "/:id",
    Paths.PROJECTS_EDIT + "/:id",
    Paths.AGENTS_EDIT + "/:id",
    Paths.BETSHOP_OWNERS_EDIT + "/:id",
    Paths.PLAYERS_EDIT + "/:id",
    Paths.BETSHOPS_EDIT + "/:id",
    Paths.CASHIERS_EDIT + "/:id",
    Paths.BETSHOP_MANAGERS_EDIT + "/:id",
    Paths.TERMINALS_EDIT + "/:id",
    Paths.USERS_EDIT + "/:id",
    Paths.PERMISSION_GROUPS_EDIT + "/:id",
    Paths.REPORTS_AGENT_CALCULATION_HISTORY + "/:id",
    Paths.REPORTS_CASHIER_PERFORMANCE_REPORT + "/:id"
]

const PATHS_TO_SHOW_COMPANIES_WITHOUT_PROJECT = [
    Paths.COMPANIES,
    Paths.COMPANIES_EDIT + "/:id",
    Paths.PROJECTS,
    Paths.PROJECTS_EDIT + "/:id",
    Paths.USERS,
    Paths.USERS_EDIT + "/:id",
    Paths.USER_LOGS,
    Paths.TRANSLATIONS_RETAIL,
    Paths.TRANSLATIONS_BO,
    Paths.SETTINGS_CURRENCIES,
    Paths.SETTINGS_LANGUAGES,
    Paths.DEVELOPER_ERRORS,
    Paths.DEVELOPER_REQUESTS,
    Paths.DEVELOPER_JOBS,
    Paths.DEVELOPER_MONITORING,
    Paths.DEVELOPER_PLATFORM_TEST,
    Paths.DEVELOPER_CALCULATE_REPORTS,
    Paths.DEVELOPER_POST_DEPLOYMENT_ACTIONS,
    Paths.DEVELOPER_DB_CONNECTIONS,
    Paths.PERMISSION_REQUESTS,
]

/** Companies global filter dropdown component on Header */
const GlobalCompaniesDropdown = ({
    changeProject,
    globalCompanyId,
    globalProjectId,
    companies
}) => {
    const { t } = useTranslation();

    const searchInputRef = useRef(null);

    const [companiesDropdownVisible, setCompaniesDropdownVisible] = useState(false);
    const [projectsDropdownVisible, setProjectsDropdownVisible] = useState(false);

    const location = useLocation();
    const params = useParams();

    const [searchCompanyValue, setSearchCompanyValue] = useState("");
    const [searchProjectValue, setSearchProjectValue] = useState("");

    const userRole = getUser()?.role;

    const shouldShowDropdown = userRole === USER_ROLE.ADMIN || userRole === USER_ROLE.ACCESS_MANAGER;

    const shouldShowProjectsDropdown = userRole === USER_ROLE.ADMIN;

    /** Function to get projects
       * @function
       * @param {string} id
       * @returns {array}
       * @memberOf GlobalCompaniesDropdown
   */
    const getProjects = id => {
        const cId = id || globalCompanyId;

        const company = companies.find(c => c.id === cId);
        if (!company) return [];
        return company.projects || [];
    }

    /** Filter projects by type
       * @function
       * @returns {array}
       * @memberOf GlobalCompaniesDropdown
   */
    const getProjectsForType = id => {
        const cId = id || globalCompanyId;
        let p = location.pathname;
        if (params.id) {
            p = location.pathname.replace(params.id, ":id")
        }
        let result = []
        const showOnlyForAgents = PATHS_TO_SHOW_DROPDOWN_FOR_AGENTS.includes(p) && !PATHS_TO_SHOW_DROPDOWN_FOR_RETAIL.includes(p);
        const showOnlyForRetail = !PATHS_TO_SHOW_DROPDOWN_FOR_AGENTS.includes(p) && PATHS_TO_SHOW_DROPDOWN_FOR_RETAIL.includes(p);

        if (!showOnlyForAgents && !showOnlyForRetail) {
            result = [...getProjects(cId)];
        }

        if (showOnlyForAgents) {
            result = getProjects(cId).filter(p => binaryToFlags(Object.values(PROJECT_TYPE), p.type).includes(PROJECT_TYPE.AGENT_SYSTEM))
        }

        if (showOnlyForRetail) {
            result = getProjects(cId).filter(p => binaryToFlags(Object.values(PROJECT_TYPE), p.type).includes(PROJECT_TYPE.RETAIL_SYSTEM))
        }

        return result;
    }

    const filterProjectsBySearch = (projects) =>
        projects.filter(p =>
            !searchProjectValue ||
            p.id.toLowerCase() === searchProjectValue.toLowerCase() ||
            p.name.toLowerCase().includes(searchProjectValue.toLowerCase())
        )


    /** Filter companies by type
       * @function
       * @returns {array}
       * @memberOf GlobalCompaniesDropdown
   */
    const getCompaniesForType = () => {
        if (userRole === USER_ROLE.ACCESS_MANAGER) {
            return getCompanies();
        }

        let p = location.pathname;
        let search = location.search;
        if (params.id) {
            p = location.pathname.replace(params.id, ":id")
        }
        if (
            PATHS_TO_SHOW_COMPANIES_WITHOUT_PROJECT.includes(p) ||
            Paths.PERMISSIONS + `?type=${USER_TYPE.ADMIN}` === p + search ||
            Paths.PERMISSION_GROUPS + `?type=${USER_TYPE.ADMIN}` === p + search ||
            (p + search).includes((Paths.PERMISSION_GROUPS_EDIT + "/:id" + `?type=${USER_TYPE.ADMIN}`))
        ) {
            return getCompanies();
        }
        return getCompanies().filter(c => getProjectsForType(c.id).length > 0)
    }


    /** Fires on dropdown change
       * @function
       * @param {string}
       * @param {string} type - company/project
       * @memberOf GlobalCompaniesDropdown
   */
    const onChange = (value, type) => {
        if (type === "company") {
            const companyId = value;
            let projectId;
            const c = companies.find(c => c.id === value);

            if (c && c.projects && c.projects[0]) {
                if (c.projects && c.projects[0]) {
                    projectId = c.projects[0].id;
                }
            }
            changeProject(companyId, projectId);
            setSearchCompanyValue("");
            setCompaniesDropdownVisible(false);
        } else {
            changeProject(globalCompanyId, value);
            setSearchProjectValue("");
            setProjectsDropdownVisible(false);
        }
    }

    /** Function to get filtered companies
     * @function
     * @returns {array}
     * @memberOf GlobalCompaniesDropdown
    */
    const getCompanies = () => companies.filter(c => !searchCompanyValue || c.id.toLowerCase() === searchCompanyValue.toLowerCase() || c.name.toLowerCase().includes(searchCompanyValue.toLowerCase()))

    /** Set global company id to current editing entity company Id */
    useEffect(() => {
        const companyId = (new URLSearchParams(location.search)).get("cid");
        const projectId = (new URLSearchParams(location.search)).get("pid");

        if (companyId && companyId !== globalCompanyId) {
            let newProjectId;
            if (projectId) {
                newProjectId = projectId;
            } else {
                const c = companies.find(c => c.id === companyId);
                if (c && c.projects && c.projects[0]) {
                    newProjectId = c.projects[0].id;
                }
            }
            changeProject(companyId, newProjectId);
        }
    }, [location.search])


    /** Function to detect when the dropdown should be disabled
       * @function
       * @returns {boolean}
       * @memberOf GlobalCompaniesDropdown
   */
    const isDropdownDisabled = () => {
        let p = location.pathname;
        if (params.id) {
            p = location.pathname.replace(params.id, ":id")
        }
        return PATHS_TO_SHOW_DROPDOWN_DISABLED.includes(p)
    };

    /** Focus Search input */
    useEffect(() => {
        if (projectsDropdownVisible) {
            setTimeout(() => {
                searchInputRef?.current?.focus()
            }, 100)
        }
    }, [projectsDropdownVisible])

    const selectedCompany = useMemo(() => {
        return companies.find(p => p.id === globalCompanyId)
    }, [companies, globalCompanyId])

    const selectedProject = useMemo(() => {
        if(!globalProjectId || !globalCompanyId) return null;
        const projects = selectedCompany?.projects || [];
        return projects.find(p => p.id === globalProjectId)
    }, [globalProjectId, selectedCompany])

    const projects = filterProjectsBySearch(getProjectsForType())

    return shouldShowDropdown ? (
        <div className={"rt--companies-dropdown rt--flex rt--align-center" + (isDropdownDisabled() ? " rt--companies-dropdown-disabled" : "")}>
            {
                isMobile() ? (
                    <MobileDropdown
                        companies={getCompaniesForType()}
                        projects={projects}
                        searchCompanyValue={searchCompanyValue}
                        searchProjectValue={searchProjectValue}
                        setSearchCompanyValue={setSearchCompanyValue}
                        setSearchProjectValue={setSearchProjectValue}
                        disabled={isDropdownDisabled()}
                    />
                ) : (
                    <Fragment>
                        <span className='rt--companies-dropdown-title rt--title rt--font-normal rt--font-regular rt--pr-8'>{t("backoffice.companies.company")}:</span>
                        <div className='rt--companies-dropdown-group rt--flex rt--align-center'>

                            <Dropdown
                                disabled={isDropdownDisabled()}
                                menu={{
                                    onClick: e => e.key && onChange(e.key, "company"),
                                    className: "rt--companies-dropdown-menu",
                                    selectedKeys: [globalCompanyId, "search"],
                                    items: (
                                        getCompaniesForType().length > 0
                                            ? getCompaniesForType().map(c => (
                                                {
                                                    key: c.id,
                                                    label: (
                                                        <>
                                                            <span className='rt--title rt--font-regular rt--font-normal'>{c.name}</span>
                                                            {
                                                                (isMobile() && c.id === globalCompanyId) && <i className='icon-ok rt--font-bigest' />
                                                            }
                                                        </>
                                                    )
                                                }
                                            ))
                                            : (
                                                [
                                                    {
                                                        key: "notFound",
                                                        label: t("backoffice.common.notFound"),
                                                        disabled: true,
                                                        className: "rt--companies-dropdown-empty"
                                                    }
                                                ]
                                            )
                                    )
                                }}
                                dropdownRender={(menu) => (
                                    <Fragment>
                                        <div className='rt--companies-dropdown-search rt--pl-8 rt--pr-8 rt--pt-8 rt--pb-8'>
                                            <Search
                                                type={SEARCH_TYPE.INPUT}
                                                onChange={value => setSearchCompanyValue(value)}
                                                value={searchCompanyValue}
                                            />
                                        </div>

                                        {menu}
                                    </Fragment>
                                )}
                                title={t("backoffice.companies.company")}
                                popupVisible={companiesDropdownVisible}
                                onVisibleChange={visible => setCompaniesDropdownVisible(visible)}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            >
                                <div className="rt--companies-dropdown-content rt--flex rt--align-center rt--justify-between rt--pl-12 rt--pr-4">
                                    <span className='rt--title rt--font-normal rt--font-bold rt--pr-4'>{selectedCompany?.name ?? ""}</span>
                                    <i className='icon-down-small rt--font-bigest' />
                                </div>
                            </Dropdown>

                            {
                                shouldShowProjectsDropdown && (
                                    <Dropdown
                                        disabled={isDropdownDisabled()}
                                        menu={{
                                            onClick: e => e.key && onChange(e.key, "project"),
                                            className: "rt--companies-dropdown-menu",
                                            selectedKeys: [globalProjectId, "search"],
                                            items: (
                                                projects.length > 0
                                                    ? projects.map(c => (
                                                        {
                                                            key: c.id,
                                                            label: (
                                                                <>
                                                                    <span className='rt--title rt--font-regular rt--font-normal'>{c.name}</span>
                                                                    {
                                                                        (isMobile() && c.id === globalProjectId) && <i className='icon-ok rt--font-bigest' />
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    ))
                                                    : (
                                                        [
                                                            {
                                                                key: "notFound",
                                                                label: t("backoffice.common.notFound"),
                                                                disabled: true,
                                                                className: "rt--companies-dropdown-empty"
                                                            }
                                                        ]
                                                    )
                                            )
                                        }}
                                        dropdownRender={(menu) => (
                                            <Fragment>
                                                <div className='rt--companies-dropdown-search rt--pl-8 rt--pr-8 rt--pt-8 rt--pb-8'>
                                                    <Search
                                                        type={SEARCH_TYPE.INPUT}
                                                        onChange={value => setSearchProjectValue(value)}
                                                        value={searchProjectValue}
                                                        inputRef={searchInputRef}
                                                    />
                                                </div>

                                                {menu}
                                            </Fragment>
                                        )}
                                        popupVisible={projectsDropdownVisible}
                                        onVisibleChange={visible => setProjectsDropdownVisible(visible)}
                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    >
                                        <div className="rt--companies-dropdown-content rt--companies-dropdown-content-last rt--flex rt--align-center rt--justify-between rt--pl-12 rt--pr-4 rt--ml-1">
                                            <span className='rt--title rt--font-normal rt--font-bold rt--pr-4'>
                                                {
                                                    globalProjectId ? (selectedProject?.name ?? "") :
                                                        <span className='rt--companies-dropdown-content-placeholder rt--title rt--font-normal rt--font-regular'>{`-- ${t("backoffice.common.choose")} ${t("backoffice.common.project")} --`}</span>
                                                }
                                            </span>
                                            <i className='icon-down-small rt--font-bigest' />
                                        </div>
                                    </Dropdown>
                                )
                            }

                        </div>
                    </Fragment>
                )
            }


        </div>
    ) : <div />
}

/** GlobalCompaniesDropdown propTypes
    * PropTypes
*/
GlobalCompaniesDropdown.propTypes = {
    /** Redux state property, represents the array of all companies  */
    companies: PropTypes.arrayOf(companyType),
    /** Redux state property, represents global company id */
    globalCompanyId: PropTypes.string,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to change global company/project id */
    changeProject: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        changeProject: (companyId, projectId) => {
            dispatch(changeProject(companyId, projectId));
        }
    }
)

const mapStateToProps = state => {
    return {
        companies: state.profile.userInfo.companies,
        globalCompanyId: state.common.globalCompanyId,
        globalProjectId: state.common.globalProjectId
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    GlobalCompaniesDropdown
);
