import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import { Switch } from 'antd';

import ContentBlockSkeleton from './contentBlockSkeleton';

import { classNames } from 'utils/common';

import menuItemsType from 'types/customize/menuItems.type';
import Tooltip from 'components/common/tooltip';

const ContentBlock = ({
    items,
    activeItemKey,
    alreadyRenderedSectionKeys,
}) => {
    const { t } = useTranslation();

    if (items.length === 0) {
        return (
            <ContentBlockSkeleton />
        )
    }

    return (
        <>
            {items.map(item => {
                if (!alreadyRenderedSectionKeys.includes(item.key)) {
                    return null
                }

                return (
                    <div
                        key={item.key}
                        className={(classNames(
                            "rt--customize-widget-main-content rt--flex-equal",
                            item.key === activeItemKey && "rt--customize-widget-main-content-active"
                        ))}
                    >
                        <div className="rt--customize-widget-main-content-header rt--flex rt--align-cneter">
                            <h4 className="rt--title rt--font-bold rt--font-big">
                                {t(item.text)}
                            </h4>
                            {
                                item.info && (
                                    <Tooltip
                                        title={t(item.info)}
                                    >
                                            <i className="icon-info rt--font-big" />
                                    </Tooltip>
                                )
                            }
                        </div>
                        <div className="rt--customize-widget-main-content-block rt--flex rt--flex-col rt--justify-between">
                            {item.content}
                        </div>
                    </div>
                )
            })}
        </>
    )
}

/** ContentBlock propTypes
 * PropTypes
*/
ContentBlock.propTypes = {
    /** Items that should be rendered in menu */
    items: PropTypes.arrayOf(menuItemsType),
    /** key of active item */
    activeItemKey: PropTypes.string,
    alreadyRenderedSectionKeys: PropTypes.array
};

export default ContentBlock