import React, { useState, Fragment, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import SearchPlayer from './search';
import DepositPlayer from './deposit';
import WithdrawalPlayer from './withdrawal';
import PlayerInfo from './info';

import { triggerCustomEvent } from 'utils/common';

import useTransfer from '../../../hooks/useTransfer';

import { EPOS_TRANSFER_FORM_STEP, TRANSFER_OPERATION_GROUP, TRANSFER_POPUP_EPOS_PLAYER_FOUND_EVENT_NAME } from '../../../constants';

const EposTransferForm = () => {

    const { t } = useTranslation();

    const [currentStep, setCurrentStep] = useState(EPOS_TRANSFER_FORM_STEP.SEARCH);

    const [player, setPlayer] = useState(null);

    const { transferData, isSaving } = useTransfer();

    const { group } = transferData ?? {};

    const handleBackButtonClick = () => {
        if (!isSaving) {
            triggerCustomEvent(
                TRANSFER_POPUP_EPOS_PLAYER_FOUND_EVENT_NAME,
                { info: null }
            );
        }
    }

    useEffect(() => {
        const handler = event => {
            const info = event?.detail?.info;
            if(info){
                setPlayer(info);
                setCurrentStep(EPOS_TRANSFER_FORM_STEP.FORM)
            } else {
                setPlayer(null);
                setCurrentStep(EPOS_TRANSFER_FORM_STEP.SEARCH)
            }
        }

        document.addEventListener(TRANSFER_POPUP_EPOS_PLAYER_FOUND_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_POPUP_EPOS_PLAYER_FOUND_EVENT_NAME, handler);
        }
    }, [])

    return currentStep === EPOS_TRANSFER_FORM_STEP.SEARCH ? (
        <SearchPlayer />
    ) : (
        <Fragment>
            <div
                className='rt--flex rt--align-center rt--mb-8 rt--transfer-back'
                onClick={handleBackButtonClick}
            >
                <i className='icon-left rt--font-bigest' />
                <span className='rt--title rt--font-normal rt--font-bold rt--pl-2 rt--pr-2'>{t("backoffice.common.back")}</span>
            </div>
            {
                player && <PlayerInfo player={player} />
            }
            {
                group === TRANSFER_OPERATION_GROUP.DEPOSIT ? (
                    <DepositPlayer
                        player={player}
                    />
                ) : (
                    <WithdrawalPlayer
                        player={player}
                    />
                )
            }
        </Fragment>
    )
}

export default EposTransferForm;
