export const AGENT_TYPE = {
    AGENT: 1,
    SUPER_AGENT: 2
}

export const REPARENT_ENTITY_TYPE = {
    AGENT: 1,
    BETSHOP: 2,
    PLAYER: 3
}

export const CREATION_FORM_FIELD_NAMES_ENUM = {
    USERNAME: "UserName",
    PASSWORD: "Password",
    CONFIRM_PASSWORD: "ConfirmPassword",
    PARENT_ID: "ParentId",
    CURRENCY_CODES: "CurrencyCodes",
    EMAIL: "Email",
    FIRST_NAME: "FirstName",
    LAST_NAME: "LastName",
    MIDDLE_NAME: "MiddleName",
    PHONE_NUMBER: "PhoneNumber",
    GENDER: "Gender",
    ADDRESS: "Address",
    COUNTRY: "Country",
    ZIP_CODE: "ZipCode",
}

export const CREATION_FORM_FIELDS_ORDER = [
    CREATION_FORM_FIELD_NAMES_ENUM.USERNAME,
    CREATION_FORM_FIELD_NAMES_ENUM.PASSWORD,
    CREATION_FORM_FIELD_NAMES_ENUM.CONFIRM_PASSWORD,
    CREATION_FORM_FIELD_NAMES_ENUM.PARENT_ID,
    CREATION_FORM_FIELD_NAMES_ENUM.CURRENCY_CODES,
    CREATION_FORM_FIELD_NAMES_ENUM.EMAIL,
    CREATION_FORM_FIELD_NAMES_ENUM.FIRST_NAME,
    CREATION_FORM_FIELD_NAMES_ENUM.LAST_NAME,
    CREATION_FORM_FIELD_NAMES_ENUM.MIDDLE_NAME,
    CREATION_FORM_FIELD_NAMES_ENUM.PHONE_NUMBER,
    CREATION_FORM_FIELD_NAMES_ENUM.GENDER,
    CREATION_FORM_FIELD_NAMES_ENUM.ADDRESS,
    CREATION_FORM_FIELD_NAMES_ENUM.COUNTRY,
    CREATION_FORM_FIELD_NAMES_ENUM.ZIP_CODE,
]

export const AGENTS_LITE_DEFAULT_COLUMNS = ['wallets'];

export const AGENT_COMMISSION_STATE = {
    ACTIVE: 1,
    INACTIVE: 2
};

