import axios from "axios";
import ApiUrls from "constants/api.constants";
import Methods from "constants/methods.constants";

export const getCommissionDetails = (id) => {
    return axios({
        url: ApiUrls.GET_AGENT_COMMISSION_SETTINGS,
        method: Methods.GET,
        params: {
            id
        }
    })
}

export const saveCommissionDetails = (data) => {
    return axios({
        url: ApiUrls.SAVE_AGENT_COMMISSION_SETTINGS,
        method: Methods.POST,
        data
    })
}
