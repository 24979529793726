import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import Table from 'components/common/table';
import EditCertificatePopupComponent from './editCertificate-popup-component';
import Empty from '../../empty';

import { TERMINAL_SECTION_TYPE } from 'constants/terminalCustomize.constants';

import {
    getTerminalSectionItems,
    changeTerminalSectionItemState,
    saveTerminalCertificate
} from 'store/actions/dashboard/terminalCustomize/sections.action';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { tableColumns } from './columns';


const FooterCertificates = ({
    data,
    getTerminalSectionItems,
    changeTerminalSectionItemState,
    saveTerminalCertificate,
    customizeLanguage,
    isSaving
}) => {
    const { t } = useTranslation();

    const [editCertificatePopup, setEditCertificatePopup] = useState({
        isVisible: false,
        data: null
    })


    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showEditCertificatePopup = data => {
        setEditCertificatePopup({
            isVisible: true,
            data
        })
    }

    const hideEditCertificatePopup = () => {
        setEditCertificatePopup({
            isVisible: false,
            data: null
        })
    }

    const handleEditCertificate = data => {
        saveTerminalCertificate(data)
    }

    const handleEdit = record => {
        showEditCertificatePopup(record)
    }

    const handleTableSwitcherChange = (isChecked, record) => {
        changeTerminalSectionItemState(TERMINAL_SECTION_TYPE.FOOTER_CERTIFICATES, record.rowId, isChecked)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ 
            mainColumns: tableColumns,
        });
    }, [])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [
        {
            title: t("backoffice.common.edit"),
            icon: "icon-edit",
            onClick: handleEdit,
        },
    ]

    //#endregion

    // Get Header Items
    useEffect(() => {
        getTerminalSectionItems(TERMINAL_SECTION_TYPE.FOOTER_CERTIFICATES);
    }, [customizeLanguage]);

    return (
        <div className="rt--customize-table rt--mt-24">
            <Table
                data={data}
                renderEmpty={() => <Empty />}
                columns={mainTableColumns}
                noPagination={true}
                actions={tableRowActions}
                switcher={{
                    valueIndex: "enabled",
                    dataIndex: "enabled",
                    disabled: record => !record.editable || !record.file,
                    onChange: handleTableSwitcherChange,
                }}
                uniqueKey="rowId"
            />
            {
                editCertificatePopup.isVisible && (
                    <EditCertificatePopupComponent 
                        isSaving={isSaving}
                        onClose={hideEditCertificatePopup}
                        onOk={handleEditCertificate}
                        data={editCertificatePopup.data}
                    />
                )
            }
        </div>
    )
}

/** FooterCertificates propTypes
 * PropTypes
*/
FooterCertificates.propTypes = {
    data: PropTypes.array,
    /** Redux action to get terminal certificates */
    getTerminalSectionItems: PropTypes.func,
    /** Redux action to change terminal section item state */
    changeTerminalSectionItemState: PropTypes.func,
    /** Redux action to save terminal certificate */
    saveTerminalCertificate: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Redux state property, is true when saving data */
    isSaving: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
    getTerminalSectionItems: sectionType => {
        dispatch(getTerminalSectionItems(sectionType))
    },
    changeTerminalSectionItemState: (sectionType, rowId, enabled) => {
        dispatch(changeTerminalSectionItemState(sectionType, rowId, enabled))
    },
    saveTerminalCertificate: data => {
        dispatch(saveTerminalCertificate(data))
    },
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.terminalCustomize.customizeLanguage,
        data: state.terminalCustomize.sections[TERMINAL_SECTION_TYPE.FOOTER_CERTIFICATES].data,
        isSaving: state.terminalCustomize.isSaving,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterCertificates);