//#region react
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Col, Form, Row, Spin } from 'antd';

import { getTerminalFontFamily, saveTerminalFontFamily } from "store/actions/dashboard/terminalCustomize/styles.action";

import Select from "components/common/select";

import { isFormChanged } from "utils/form";
import { sendToTerminalIframe } from "utils/terminal";

import { TERMINAL_UPDATE_TYPE, SECTION_NAMES } from "constants/terminalCustomize.constants";
import { CUSTOMIZE_FONT_FAMILIES } from "constants/customize.constants";

/** Terminal Customize Page Sections Fonts Editor Component */
const FontsEditor = ({
    fontFamily,
    getTerminalFontFamily,
    saveTerminalFontFamily,
    isSaving,
    isLoading
}) => {
    const { t } = useTranslation();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    /** Load font family */
    useEffect(() => {
        getTerminalFontFamily();
    }, [])

    useEffect(() => {
        setFieldsValue({
            fontFamily
        });
    }, [fontFamily])

    /** Fires when form submitted
       * @function
       * @memberOf FontsEditor
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveTerminalFontFamily(data.fontFamily);
                setIsFormTouched(false);
            }).catch(() => { })
    }

    return (
        <Fragment>
            <Spin spinning={isLoading}>
                <Form
                    className="rt--form"
                    form={formInstance}
                    colon={false}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(_, formValues) => {
                        sendToTerminalIframe(TERMINAL_UPDATE_TYPE.FONT_FAMILY, { fontFamily: formValues['fontFamily'] });
                        setIsFormTouched(isFormChanged({ fontFamily: fontFamily }, { ...formValues }))
                    }}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t("backoffice.terminals.fontFamily")}
                                name="fontFamily"
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') },
                                ]}
                            >
                                <Select
                                    options={
                                        CUSTOMIZE_FONT_FAMILIES.map(f => ({ value: f, text: f }))
                                    }
                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.terminals.fontFamily")}`}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                </Form>
            </Spin>
            <div className="rt--flex rt--justify-end rt--align-center">
                <Button
                    type="primary"
                    className="rt--button rt--button-primary"
                    onClick={handleForm}
                    loading={isSaving}
                    disabled={!isFormTouched}
                >
                    <span>{t("backoffice.common.save")}</span>
                </Button>
            </div>
        </Fragment>
    );
};

/** FontsEditor propTypes
 * PropTypes
*/
FontsEditor.propTypes = {
    /** Redux state promerty, terminal font family */
    fontFamily: PropTypes.string,
    /** Redux action to get terminal font family */
    getTerminalFontFamily: PropTypes.func,
    /** Redux action to save terminal font family */
    saveTerminalFontFamily: PropTypes.func,
    /** Redux state property, is true when terminal saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    getTerminalFontFamily: () => {
        dispatch(getTerminalFontFamily())
    },
    saveTerminalFontFamily: fontFamily => {
        dispatch(saveTerminalFontFamily(fontFamily))
    }
});

const mapStateToProps = (state) => {
    return {
        isSaving: state.terminalCustomize.isSaving,
        fontFamily: state.terminalCustomize.fontFamily,
        isLoading: state.terminalCustomize.isLoading,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FontsEditor);
