import axios from "axios";
import ApiUrls from "constants/api.constants";
import Methods from "constants/methods.constants";

export const resendAgent = (ids) => {
    return axios({
        url: ApiUrls.RESEND_PLAYERS_AGENT,
        method: Methods.POST,
        data: {
            ids
        },
    })
}
