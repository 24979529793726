import React, {useMemo} from 'react';
import LineChart from "components/common/lineChart";
import {
    DATA_TYPES,
    ENTITY_TYPE,
    FINANCIAL_DASHBOARD_FILTER_NAMES,
    TYPE_TO_TOTAL_PROP_MAP
} from "pages/dashboard/constants";
import useFinancialLineChart from "pages/dashboard/hooks/useFinancialLineChart";
import useFormat from "hooks/useFormat";
import useDate from "hooks/useDate";
import useProjectType from "hooks/useProjectType";
import {constructLineChartConfigs} from "utils/lineChart";
import {
    getCheckPointsTooltipConfigs,
    getLegendConfigs,
    getMainLineConfigs,
    getXAxisConfigs
} from "utils/lineChart/configCreators";
import {getFinancialTooltipHTML, getOperationsLineChartData, getOperationsLineChartTooltip} from "utils/dashboard";
import i18n from "translations/config";
import useRetailLineChart from "pages/dashboard/hooks/useRetailLineChart";

const getLinesData = ({
    dataType,
    hasRetail,
    hasLiteMode,
    timePeriod,
    chartData,
    playerLineColor,
    betshopLineColor,
    dateService
}) => {
    if (dataType === DATA_TYPES.REGISTERED_PLAYERS) {
        const playerLineData = getOperationsLineChartData(chartData, timePeriod, "count", dateService);

        return {
            linesData: { playerLineData },
            lineConfigs: [
                getMainLineConfigs({
                    data: typeof playerLineData.values === 'function' ? [] : playerLineData.values,
                    name: i18n.t("backoffice.dashboard.player"),
                    mainLineColor: playerLineColor,
                }),
            ]
        };
    }

    if (dataType === DATA_TYPES.DEPOSIT_AMOUNT) {
        const playerLineData = getOperationsLineChartData(chartData, timePeriod, "depositAmount", dateService);

        return {
            linesData: { playerLineData },
            lineConfigs: [
                getMainLineConfigs({
                    data: typeof playerLineData.values === 'function' ? [] : playerLineData.values,
                    name: i18n.t("backoffice.dashboard.player"),
                    mainLineColor: playerLineColor,
                }),
            ]
        };
    }

    if (dataType === DATA_TYPES.WITHDRAW_AMOUNT) {
        const playerLineData = getOperationsLineChartData(chartData, timePeriod, "withdrawAmount", dateService);

        return {
            linesData: { playerLineData },
            lineConfigs: [
                getMainLineConfigs({
                    data: typeof playerLineData.values === 'function' ? [] : playerLineData.values,
                    name: i18n.t("backoffice.dashboard.player"),
                    mainLineColor: playerLineColor,
                }),
            ]
        };
    }

    if ([DATA_TYPES.COMMISSIONS, DATA_TYPES.NET_DEPOSIT].includes(dataType)) {
        const playerLineData = getOperationsLineChartData(chartData, timePeriod, "amount", dateService);

        return {
            linesData: { playerLineData },
            lineConfigs: [
                getMainLineConfigs({
                    data: typeof playerLineData.values === 'function' ? [] : playerLineData.values,
                    name: i18n.t("backoffice.dashboard.player"),
                    mainLineColor: playerLineColor,
                })
            ]
        }
    }

    if (hasRetail) {
        const playerLineData = getOperationsLineChartData(chartData, timePeriod, "amount", dateService);
        const betshopLineData = getOperationsLineChartData(chartData, timePeriod, "amount", dateService);

        return {
            linesData: { playerLineData, betshopLineData },
            lineConfigs: [
                getMainLineConfigs({
                    data: typeof playerLineData.values === 'function' ? [] : playerLineData.values,
                    name: i18n.t("backoffice.dashboard.betShop"),
                    mainLineColor: playerLineColor,
                }),
            ]
        }
    }

    const playerLineData = getOperationsLineChartData(chartData, timePeriod, "amount", dateService);

    return {
        linesData: { playerLineData },
        lineConfigs: [
            getMainLineConfigs({
                data: typeof playerLineData.values === 'function' ? [] : playerLineData.values,
                mainLineColor: playerLineColor,
                name: i18n.t("backoffice.dashboard.player"),
            })
        ]
    }

}

const calcTotalAmountForPeriod = (chart, prop = 'amount') => {
    return chart.reduce((acc, data) => acc + data[prop], 0);
}

const RetailLineChart = ({
    title,
    dataType,
    playerLineColor,
    betshopLineColor,
    disableLegend = false,
    filters,
    updateLastUpdatedDate,
    isActive,
    calcTotalAmount,
}) => {
    const { chartData, isLoading } = useRetailLineChart(dataType, filters, updateLastUpdatedDate, isActive);
    const { formatAmount, formatCurrencyWithSymbol, getCurrencySymbol } = useFormat();

    const { dateService } = useDate();

    const { hasRetail, hasLiteMode } = useProjectType();

    const calcTotal = calcTotalAmount || calcTotalAmountForPeriod;

    let prefix = filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY];

    if (hasLiteMode) {
        prefix = getCurrencySymbol(filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY]);
    }

    const configs = useMemo(() => {
        const { linesData, lineConfigs } = getLinesData({
            dataType,
            playerLineColor,
            betshopLineColor,
            hasRetail,
            hasLiteMode,
            timePeriod: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD],
            chartData: chartData,
            dateService
        });

        return constructLineChartConfigs({
            legend: disableLegend ? undefined : getLegendConfigs(),
            xAxisConfigs: getXAxisConfigs({
                checkPoints: linesData.playerLineData.categories,
            }),
            checkPointsTooltipConfigs: getCheckPointsTooltipConfigs({
                tooltipHTMLGenerator: (element) => (
                    dataType === DATA_TYPES.REGISTERED_PLAYERS
                        ? (
                            getOperationsLineChartTooltip({
                                element,
                                chartData: linesData.playerLineData,
                                timePeriod: filters.timePeriod,
                                entityType: ENTITY_TYPE.PLAYER,
                                dateService
                            })
                        ) : (
                            getFinancialTooltipHTML(
                                element,
                                {
                                    hasLiteMode,
                                    chartData: chartData,
                                    currencyCode: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY],
                                    dataType,
                                    timePeriod: filters[FINANCIAL_DASHBOARD_FILTER_NAMES.TIME_PERIOD],
                                    dates: element.series.name === i18n.t("backoffice.dashboard.betShop") ? linesData.betshopLineData?.dates : linesData.playerLineData.dates,
                                    counts: element.series.name === i18n.t("backoffice.dashboard.betShop") ? linesData.betshopLineData?.counts : linesData.playerLineData.counts,
                                    formatAmount,
                                    formatCurrencyWithSymbol,
                                    dateService
                                }
                            )
                        )
                )
            }),
            lines: lineConfigs,
        })
    }, [chartData, dateService]);

    const total = calcTotal(chartData, TYPE_TO_TOTAL_PROP_MAP[dataType]);

    return (
        <LineChart
            title={title}
            configs={configs}
            hideChart={chartData.length === 0}
            isLoading={isLoading}
            total={(
                dataType === DATA_TYPES.REGISTERED_PLAYERS
                    ? total
                    : `${prefix} ${formatAmount(total, filters[FINANCIAL_DASHBOARD_FILTER_NAMES.CURRENCY])}`
            )}
        />
    )
};

export default RetailLineChart;
