import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";
import { sendToTerminalIframe } from "utils/terminal";

import {
    setTerminalCustomizeActionBefore,
    setTerminalCustomizeActionFinished,
    setTerminalCustomizeSaveActionBefore,
    setTerminalCustomizeSaveActionFinished
} from "./common.action";

import {
    SET_TERMINAL_CUSTOMIZE_DEFAULT_LANGUAGES,
    SET_TERMINAL_CUSTOMIZE_LANGUAGES,
    SET_TERMINAL_CUSTOMIZE_SECTION_DATA
} from 'store/actionTypes';

import { TERMINAL_SECTION_TYPE, TERMINAL_UPDATE_TYPE } from "constants/terminalCustomize.constants";

//#region ------------------------------------------ SECTIONS -----------------------------------------//

const setTerminalSectionData = (data, sectionType) => ({
    type: SET_TERMINAL_CUSTOMIZE_SECTION_DATA,
    payload: { data, sectionType }
})

export const getTerminalSectionItems = sectionType => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeActionBefore());

        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.GET_TERMINAL_SECTION_ITEMS,
            method: Methods.GET,
            params: {
                sectionType,
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setTerminalSectionData(
                        value,
                        sectionType,
                    )
                );
                dispatch(setTerminalCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeActionFinished());
            })
    }
}

export const removeTerminalSectionItem = (sectionType, rowId) => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.REMOVE_TERMINAL_SECTION_ITEM,
            method: Methods.POST,
            data: {
                rowId,
                sectionType,
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(
                        setTerminalSectionData(
                            data.value,
                            sectionType,
                        )
                    );

                    sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                        sectionType,
                        data: data.value
                    })
                }
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

export const changeTerminalSectionItemState = (sectionType, rowId, enabled) => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.CHANGE_TERMINAL_SECTION_ITEM_STATE,
            method: Methods.POST,
            data: {
                rowId,
                sectionType,
                enabled,
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(
                        setTerminalSectionData(
                            data.value,
                            sectionType,
                        )
                    );

                    sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                        sectionType,
                        data: data.value
                    })
                }
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

export const reorderTerminalSectionItems = (sectionType, rowOrders) => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.REORDER_TERMINAL_SECTION_ITEMS,
            method: Methods.POST,
            data: {
                rowOrders: rowOrders,
                sectionType,
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(
                        setTerminalSectionData(
                            data.value,
                            sectionType,
                        )
                    );

                    sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                        sectionType,
                        data: data.value
                    })
                }
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

//#endregion


//#region ----------------------------------------- LANGUAGES -----------------------------------------//

const setTerminalLanguages = (languages) => ({
    type: SET_TERMINAL_CUSTOMIZE_LANGUAGES,
    payload: languages
})

const changeTerminalDefaultLanguage = (newDefaultLanguageCode) => ({
    type: SET_TERMINAL_CUSTOMIZE_DEFAULT_LANGUAGES,
    payload: newDefaultLanguageCode
})

export const getTerminalLanguages = () => {
    return dispatch => {
        dispatch(setTerminalCustomizeActionBefore());

        return axios({
            url: ApiUrls.GET_TERMINAL_LANGUAGES,
            method: Methods.GET,
        })
            .then(({ data: { value: availableLanguagesObj } }) => {
                dispatch(setTerminalLanguages(availableLanguagesObj));
                dispatch(setTerminalCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeActionFinished());
            })
    }
}

export const saveTerminalLanguages = (requestBody) => {
    return dispatch => {
        dispatch(setTerminalCustomizeSaveActionBefore());

        return axios({
            url: ApiUrls.SAVE_TERMINAL_LANGUAGE,
            method: Methods.POST,
            data: requestBody
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(setTerminalLanguages(data.value));

                    sendToTerminalIframe(TERMINAL_UPDATE_TYPE.LANGUAGES_LIST, {
                        languages: data.value
                    })
                }
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

export const setTerminalDefaultLanguage = (requestBody) => {
    return dispatch => {
        dispatch(setTerminalCustomizeSaveActionBefore());

        return axios({
            url: ApiUrls.SET_TERMINAL_DEFAULT_LANGUAGE,
            method: Methods.POST,
            data: requestBody
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    dispatch(changeTerminalDefaultLanguage(requestBody.languageCode));
                    sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DEFAULT_LANGUAGE_CODE, {
                        language: requestBody.languageCode
                    })
                }
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

//#endregion

//#region ------------------------------------------ HEADER -----------------------------------------//

export const addTerminalHeaderItem = requestBody => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.ADD_TERMINAL_HEADER_ITEM,
            method: Methods.POST,
            data: {
                ...requestBody,
                languageCode: selectedLanguageCode,
            }
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setTerminalSectionData(
                        value,
                        TERMINAL_SECTION_TYPE.HEADER,
                    ),
                );

                sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                    sectionType: TERMINAL_SECTION_TYPE.HEADER,
                    data: value
                })

                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

//#endregion

//#region ------------------------------------------ Featured Products -----------------------------------------//

export const addTerminalFeaturedProduct = requestBody => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        const formData = new FormData();

        Object.keys(requestBody).forEach((key) => {
            formData.append(key, requestBody[key]);
        });

        formData.append("languageCode", selectedLanguageCode)

        return axios({
            url: ApiUrls.ADD_TERMINAL_FEATURED_PRODUCT,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setTerminalSectionData(
                        value,
                        TERMINAL_SECTION_TYPE.FEATURED_PRODUCTS,
                    ),
                );

                sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                    sectionType: TERMINAL_SECTION_TYPE.FEATURED_PRODUCTS,
                    data: value
                })

                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

export const saveTerminalFeaturedProduct = requestBody => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        const formData = new FormData();

        Object.keys(requestBody).forEach((key) => {
            formData.append(key, requestBody[key]);
        });

        formData.append("languageCode", selectedLanguageCode)

        return axios({
            url: ApiUrls.EDIT_TERMINAL_FEATURED_PRODUCT,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setTerminalSectionData(
                        value,
                        TERMINAL_SECTION_TYPE.FEATURED_PRODUCTS,
                    ),
                );

                sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                    sectionType: TERMINAL_SECTION_TYPE.FEATURED_PRODUCTS,
                    data: value
                })

                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

//#endregion

//#region ------------------------------------------ Promotions -----------------------------------------//

export const addTerminalPromotion = requestBody => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        const formData = new FormData();

        Object.keys(requestBody).forEach((key) => {
            formData.append(key, requestBody[key]);
        });

        formData.append("languageCode", selectedLanguageCode)

        return axios({
            url: ApiUrls.ADD_TERMINAL_PROMOTION,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setTerminalSectionData(
                        value,
                        TERMINAL_SECTION_TYPE.PROMOTIONS,
                    ),
                );

                sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                    sectionType: TERMINAL_SECTION_TYPE.PROMOTIONS,
                    data: value
                })

                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

export const saveTerminalPromotion = requestBody => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());
        
        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        const formData = new FormData();

        Object.keys(requestBody).forEach((key) => {
            formData.append(key, requestBody[key]);
        });

        formData.append("languageCode", selectedLanguageCode)

        return axios({
            url: ApiUrls.EDIT_TERMINAL_PROMOTION,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setTerminalSectionData(
                        value,
                        TERMINAL_SECTION_TYPE.PROMOTIONS,
                    ),
                );

                sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                    sectionType: TERMINAL_SECTION_TYPE.PROMOTIONS,
                    data: value
                })

                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

//#endregion


//#region ------------------------------------------ Terms And Conditinos -----------------------------------------//

export const getTerminalTermsAndConditions = () => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeActionBefore());

        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.GET_TERMINAL_TERMS_AND_CONDITIONS,
            method: Methods.GET,
            params: {
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setTerminalSectionData(
                        value,
                        TERMINAL_SECTION_TYPE.TERMS_AND_CONDITIONS,
                    )
                );
                dispatch(setTerminalCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeActionFinished());
            })
    }
}

export const saveTerminalTermsAndConditions = requestBody => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());
        
        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.SAVE_TERMINAL_TERMS_AND_CONDITIONS,
            method: Methods.POST,
            data: {
                ...requestBody,
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setTerminalSectionData(
                        value,
                        TERMINAL_SECTION_TYPE.TERMS_AND_CONDITIONS,
                    ),
                );

                sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                    sectionType: TERMINAL_SECTION_TYPE.TERMS_AND_CONDITIONS,
                    data: value
                })

                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

//#endregion

//#region ------------------------------------------ Certificates -----------------------------------------//

export const saveTerminalCertificate = requestBody => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());
        
        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        const formData = new FormData();

        Object.keys(requestBody).forEach((key) => {
            formData.append(key, requestBody[key]);
        });

        formData.append("languageCode", selectedLanguageCode)

        return axios({
            url: ApiUrls.EDIT_TERMINAL_CERTIFICATE,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setTerminalSectionData(
                        value,
                        TERMINAL_SECTION_TYPE.FOOTER_CERTIFICATES,
                    ),
                );

                sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                    sectionType: TERMINAL_SECTION_TYPE.FOOTER_CERTIFICATES,
                    data: value
                })

                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

//#endregion

//#region ------------------------------------------ Age Restriction -----------------------------------------//

export const saveTerminalAgeRestriction = requestBody => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());
        
        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.SAVE_TERMINAL_AGE_RESTRICTION,
            method: Methods.POST,
            data: {
                ...requestBody,
                languageCode: selectedLanguageCode
            }
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setTerminalSectionData(
                        value,
                        TERMINAL_SECTION_TYPE.FOOTER_GENERAL,
                    ),
                );
          
                sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                    sectionType: TERMINAL_SECTION_TYPE.FOOTER_GENERAL,
                    data: value
                })

                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

//#region ------------------------------------------ Second Screen -----------------------------------------//

export const addTerminalSecondScreenBanner = requestBody => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());

        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        const formData = new FormData();

        Object.keys(requestBody).forEach((key) => {
            formData.append(key, requestBody[key]);
        });

        formData.append("languageCode", selectedLanguageCode)

        return axios({
            url: ApiUrls.ADD_TERMINAL_SECOND_SCREEN_BANNER,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setTerminalSectionData(
                        value,
                        TERMINAL_SECTION_TYPE.SECOND_SCREEN,
                    ),
                );

                sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                    sectionType: TERMINAL_SECTION_TYPE.SECOND_SCREEN,
                    data: value
                })

                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

export const saveTerminalSecondScreenBanner = requestBody => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());
        
        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        const formData = new FormData();

        Object.keys(requestBody).forEach((key) => {
            formData.append(key, requestBody[key]);
        });

        formData.append("languageCode", selectedLanguageCode)

        return axios({
            url: ApiUrls.EDIT_TERMINAL_SECOND_SCREEN_BANNER,
            method: Methods.POST,
            headers: {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            },
            data: formData
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setTerminalSectionData(
                        value,
                        TERMINAL_SECTION_TYPE.SECOND_SCREEN,
                    ),
                );

                sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                    sectionType: TERMINAL_SECTION_TYPE.SECOND_SCREEN,
                    data: value
                })

                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

export const saveTerminalSecondScreenSettings = requestBody => {
    return (dispatch, getState) => {
        dispatch(setTerminalCustomizeSaveActionBefore());
        
        const state = getState();
        const selectedLanguageCode = state.terminalCustomize.customizeLanguage;

        return axios({
            url: ApiUrls.SAVE_TERMINAL_SECOND_SCREEN_SETTINGS,
            method: Methods.POST,
            data: {
                ...requestBody,
                languageCode: selectedLanguageCode,
            }
        })
            .then(({ data: { value } }) => {
                dispatch(
                    setTerminalSectionData(
                        value,
                        TERMINAL_SECTION_TYPE.SECOND_SCREEN_CONFIG,
                    ),
                );

                sendToTerminalIframe(TERMINAL_UPDATE_TYPE.DYNAMIC_DATA, {
                    sectionType: TERMINAL_SECTION_TYPE.SECOND_SCREEN_CONFIG,
                    data: value
                })

                dispatch(setTerminalCustomizeSaveActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeSaveActionFinished());
            })
    }
}

//#endregion