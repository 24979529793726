//#region react
import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';

import { Popover } from "antd";

/** Customize Page Sidebar Item Component */
const SidebarItem = ({
    item
}) => {

    const elementRef = useRef(null)

    const [popupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        const handleClick = e => {
            if(
                !e.target.closest("." + item.popupClassName) && 
                !elementRef.current.contains(e.target) &&
                !e.target.closest(".ant-select-dropdown") &&
                !e.target.closest(".ant-picker-dropdown") &&
                !e.target.closest(".ant-modal-root") &&
                !e.target.closest(".ant-dropdown") && 
                !e.target.closest(".tox-tiered-menu")
            ){
                setPopupVisible(false)
            }
        }

        document.addEventListener("mousedown", handleClick, true);

        return () => {
            document.removeEventListener("mousedown", handleClick, true);
        }
    }, [])

    return (
        <Popover
            placement="rightTop"
            trigger="click"
            overlayClassName={"rt--customize-sidebar-item-popup " + item.popupClassName}
            zIndex={100}
            content={item.content}
            open={popupVisible}
        >
            <div 
                className={`rt--customize-sidebar-item ${item.unsaved ? "rt--customize-sidebar-item-unsaved" : ""}`}
                onClick={() => setPopupVisible(!popupVisible)}
                ref={elementRef}
            >
                <i className={item.icon + " rt--font-bigest"} />
            </div>
        </Popover>
    );
};

/** SidebarItem propTypes
    * PropTypes
*/
SidebarItem.propTypes = {
    /** Sidebar items */
    item: PropTypes.shape({
        content: PropTypes.node,
        icon: PropTypes.string,
        unsaved: PropTypes.bool,
        popupClassName: PropTypes.string
    })
}

export default SidebarItem;