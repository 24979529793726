export const AGENTS = "agents";
export const AGENT_CURRENCIES = "agentCurrencies";
export const AGENT_LITE = "agentLite";
export const REAL_TIME_SPORT_BETS = "realTimeSportBets";
export const RETAIL_PENDING_BETS = "retailPendingBets";
export const COMPANIES = "companies";
export const COMPANY_LANGUAGES = "companyLanguages";
export const JOBS = "jobs";
export const REQUESTS = "requests";
export const PAYMENT_HISTORY = "paymentHistory";
export const PAYMENT_REQUESTS = "paymentRequests";
export const PLAYER_BETS = "playersBets";
export const PLAYER_TRANSACTIONS_HISTORY_LITE = "playerTransactionsHistoryLite";
export const PROJECTS = "projects";
export const PROJECT_CURRENCIES = "projectCurrencies";
export const AGENT_CALCULATION_REPORT = "agentCalculationReport";
export const TRANSACTIONS_REPORT = "transactionsReport";
export const BETSHOPS = "betshops";
export const TERMINALS = "terminals";
export const BETSHOP_IP_ACCESS = "betshopIPAccess";
export const SESSIONS = "sessions";
export const SYSTEM_CURRENCY = "systemCurrencies";
export const SYSTEM_LANGUAGES = "systemLanguages";
export const ADMIN_PERMISSION_GROUPS = "adminPermissionGroups";
export const ADMIN_PERMISSION_GROUPS_EDIT = "adminPermissionGroupsEdit";
export const AGENT_PERMISSION_GROUPS = "agentPermissionGroups";
export const ADMIN_PERMISSION_GROUP_EDIT = "adminPermissionGroupEdit";
export const PERMISSION_REQUEST_HISTORY = "permissionRequestHistory";
export const PENDING_PERMISSION_REQUESTS = "pendingPermissionRequest";
export const WALLET_HISTORY = "walletHistory";
export const COMMISSION_PLAN_LIST = "commissionPlanList";
export const PROJECT_NETWORK = "projectNetwork";
export const PROJECT_BANK_LIST = "projectBankList";
export const AGENT_BANK_LIST = "agentBankList";
export const AGGREGATED_BETS = "aggregatedBets";
export const ONLINE_BETSLIPS = "onlineBetslips";
export const RETAIL_SETTLED_BETSLIPS = "retailSettledBetslips";
export const PLAYERS = "players";
export const PLAYERS_LITE = "playersLite";
export const BETSHOP_PERFORMANCE_REPORT = "betshopPerformanceReport";
export const CASHIER_PERFORMANCE_REPORT = "cashierPerformanceReport";
export const COMMISSION_CALCULATIONS = "commissionCalculations";
export const PLAYER_PERFORMANCE_REPORT = "playerPerformanceReport";
export const BETSHOP_MANAGERS = "betshopManagers";
export const CASHIERS = "cashiers";
export const USER_LOGS = "userLogs";
export const FAILED_TRANSACTIONS = "failedTransactions";
export const ACCESS_MANAGERS = "accessManagers";
export const ADMINS = "admins";
export const COMISSION_AGENTS = "commissionAgents";
export const NEW_PRODUCT_REPORT_AGENT = "newProductReportAgent";
export const NEW_PRODUCT_REPORT_PLAYER = "newProductReportPlayer";
