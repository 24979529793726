import React, {useEffect} from 'react';

import {useTranslation} from "react-i18next";
import useProjectType from "hooks/useProjectType";
import {useNavigate} from "react-router";

import Tabs from "components/common/tabs";

import AgentComponent from "pages/reports/newProductReport/sections/agent";
import PlayerComponent from "pages/reports/newProductReport/sections/player";

import Paths from "constants/path.constants";

const NewProductReportComponent = () => {
    const { t } = useTranslation();

    const { hasLiteMode } = useProjectType();
    const navigate = useNavigate();

    useEffect(() => {
        if (hasLiteMode) {
            navigate(Paths.REPORTS_PRODUCT_REPORT);
        }
    }, [hasLiteMode, navigate]);

    const items = [
        {
            title: t("backoffice.reports.agent"),
            component: <AgentComponent />
        },
        {
            title: t("backoffice.reports.player"),
            component: <PlayerComponent />
        }
    ];

    if (hasLiteMode) {
        return null;
    }

    return (
        <Tabs
            items={items}
            mainPage={{ title: t('backoffice.menu.newProductReport'), path: Paths.PLAYERS }}
        />
    );
};

export default NewProductReportComponent;
