import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';

import { Spin } from "antd";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import { getTerminalInfo } from "../api";

import { isMobile } from "utils/common";
import { hasPermission } from "utils/permissions";

import PATHS from "constants/path.constants";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

import useDate from "hooks/useDate";

import terminalImage from 'assets/images/terminal.png';

const UIBuilder = () => {

    const { t } = useTranslation();

    const { dateService } = useDate();

    const isMobileDevice = isMobile();

    const navigate = useNavigate();
    const searchParams = useParams();

    const [isLoading, setIsLoading] = useState(false);

    const [terminalInfo, setTerminalInfo] = useState({});

    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_TERMINALS, action: PERMISSION_ACTION.MODIFY });

    useEffect(() => {
        getTerminalInfo()
            .then(value => {
                setTerminalInfo(value);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    return (
        <SubTabFormDashboardLayout>
            <Spin
                spinning={isLoading}
            >
                 <div className={'rt--terminal-builder rt--flex ' + (isMobileDevice ? 'rt--flex-col' : 'rt--flex-row')}>
                    <div className={'rt--terminal-builder-img ' + (isMobileDevice ? 'rt--flex rt--justify-between' : '')}>
                        <img src={terminalImage} alt='img' />
                        
                        <div className='rt--terminal-builder-img-content'>
                            <div
                                className='rt--terminal-builder-img-content-preview'
                                onClick={() => {
                                    window.open(`${import.meta.env.SYSTEM_SSBT_URL}?isPreview=true&projectId=${searchParams.id}&language=EN`, "_blank")
                                }}
                            >
                                <i className="icon-eye rt--font-bigest" />
                            </div>
                            {hasModifyPermission && (
                                <div
                                    className='rt--terminal-builder-img-content-edit'
                                    onClick={() => {
                                        navigate(`${PATHS.TERMINAL_CUSTOMIZE}/${searchParams.id}`); 
                                    }}
                                >
                                    <i className="icon-edit rt--font-bigest" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={'rt--terminal-builder-cols rt--flex rt--flex-col rt--flex-equal ' + (isMobileDevice ? 'rt--mt-16' : 'rt--ml-16')}>
                        <div className='rt--flex rt--flex-col rt--mb-12'>
                            <span className='rt--terminal-builder-title rt--font-bigest rt--font-extra-bold'>{terminalInfo.name}</span>
                        </div>
                            <div className={"rt--flex " + (isMobileDevice ? 'rt--flex-col' : '')}>
                            <div className='rt--flex'>
                                <div className='rt--terminal-builder-section rt--flex rt--mr-12 rt--flex-col'>
                                    <div className="rt--flex">
                                        <span className={'rt--terminal-builder-section-title rt--text-light ' + (isMobileDevice ? 'rt--mb-4' : 'rt--mr-4 rt--mb-4')}>{t("backoffice.projects.lastUpdatedAt")}:</span>
                                    </div>
                                    <span>{ terminalInfo.modifiedAt ? dateService.format(terminalInfo.modifiedAt, true) : "-"}</span>
                                </div>
                                <div className='rt--terminal-builder-section rt--flex rt--flex-col rt--align-left'>
                                    <div className={"rt--flex"}>
                                        <span className={'rt--terminal-builder-section-title rt--text-light ' + (isMobileDevice ? 'rt--mb-4' : 'rt--mr-4 rt--mb-4')}>{t("backoffice.projects.lastUpdatedBy")}:</span>
                                    </div>
                                    <span>{ terminalInfo.modifiedBy ?? '-' }</span>
                                </div>
                            </div>
                            {
                                isMobileDevice && <div className="rt--terminal-builder-divider  rt--mt-16 rt--mb-16" />
                            }
                            <div className={'rt--flex rt--terminal-builder-section-second '  + (!isMobileDevice ? 'rt--pl-12 rt--ml-12' : '')}>
                                <div className='rt--terminal-builder-section rt--flex rt--mr-12 rt--flex-col'>
                                    <div className="rt--flex">
                                        <span className={'rt--terminal-builder-section-title rt--text-light rt--mb-4' + (!isMobileDevice ? ' rt--mr-4' : '')}>{t("backoffice.projects.lastPublishedAt")}:</span>
                                    </div>
                                    <span>{ terminalInfo.publishedAt ? dateService.format(terminalInfo.publishedAt, true) : "-"}</span>
                                </div>
                                <div className='rt--terminal-builder-section rt--flex rt--flex-col rt--align-left'>
                                    <div className="rt--flex">
                                        <span className={'rt--terminal-builder-section-title rt--text-light rt--mb-4' + (!isMobileDevice ? ' rt--mr-4' : '')}>{t("backoffice.projects.lastPublishedby")}:</span>
                                    </div>
                                    <span>{ terminalInfo.publishedBy ?? '-' }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

export default UIBuilder;