import React from 'react';
import i18n from "translations/config";

import { binaryToFlags } from "utils/common";

import {
    COMMISSION_PLAN_SOURCE,
    COMMISSION_PLAN_SOURCE_DIRECTION,
    COMMISSION_PLAN_TYPE
} from "constants/commission.constants";
import { PROJECT_PROVIDER_TYPE } from "constants/project.constants";

const SOURCE_COLUMN_TITLES = {
    [COMMISSION_PLAN_SOURCE.NGR]: "ngr",
    [COMMISSION_PLAN_SOURCE.GGR]: "ggr",
    [COMMISSION_PLAN_SOURCE.TURNOVER]: "turnover",
    [COMMISSION_PLAN_SOURCE.DEPOSIT]: "deposit",
    [COMMISSION_PLAN_SOURCE.WITHDRAWAL]: "withdrawal",
};

const COMMISSION_PLAN_TYPE_NAMES = {
    [COMMISSION_PLAN_TYPE.DIRECT]: "direct",
    [COMMISSION_PLAN_TYPE.NETWORK]: "network",
}
// TODO: use in agents commission columns
const constructObjByProvidersIdAndName = (gameProviders) => {
    return gameProviders.reduce((acc, provider) => {
        acc[provider.id] = provider.name;

        return acc;
    }, {});
}

export const getTableColumns = (additionalProps) => {
    const {
        franchisingMode,
        gameProviders,
        hasRetail
    } = additionalProps;

    const gameProvidersObj = constructObjByProvidersIdAndName(gameProviders);

    return [
        {
            title: "backoffice.commissioncalculations.commissionId",
            dataIndex: "id",
            copy: true,
            alwaysVisible: true,
            mobileLevel: 2,
        },
        {
            title: "backoffice.commissioncalculations.commissionName",
            dataIndex: "name",
            alwaysVisible: true,
            mobileLevel: 1,
        },
        {
            title: "backoffice.commissioncalculations.from",
            dataIndex: "from",
            isDateTime: true,
        },
        {
            title: "backoffice.commissioncalculations.to",
            dataIndex: "to",
            isDateTime: true,
        },
        {
            title: "backoffice.commissioncalculations.source",
            dataIndex: "source",
            mobileLevel: 3,
            render: value => i18n.t(`backoffice.commissionplans.${SOURCE_COLUMN_TITLES[value]}`),
        },
        {
            title: "backoffice.commissioncalculations.sourceAmount",
            dataIndex: "sourceAmount",
            isNumeric: true,
            mobileLevel: 4,
        },
        {
            title: "backoffice.commissioncalculations.calculatedAt",
            dataIndex: "calculatedAt",
            isDateTime: true,
        },
        {
            title: "backoffice.commissioncalculations.calculatedAmount",
            dataIndex: "amount",
            isNumeric: true,
            mobileLevel: 5,
        },

        {
            title: "backoffice.commissioncalculations.currency",
            dataIndex: "currencyCode",
            mobileLevel: 6,
        },
        {
            title: "backoffice.commissioncalculations.sourceDirection",
            dataIndex: "sourceDirection",
            multi: true,
            multiMapper: value => {
                if (!Boolean(value)) {
                    return [];
                }

                const sourceDirections = binaryToFlags(
                    Object.values(COMMISSION_PLAN_SOURCE_DIRECTION),
                    value
                )

                return (
                    sourceDirections.map((direction) => (
                        direction === COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS
                            ? i18n.t("backoffice.commissionplans.players")
                            : i18n.t("backoffice.commissionplans.betshops")
                    )
                    )
                )
            }
        },
        {
            title: "backoffice.commissioncalculations.incomeType",
            dataIndex: "type",
            disabledForFranchisingMode: true,
            render: value => (
                Boolean(COMMISSION_PLAN_TYPE_NAMES[value])
                    ? i18n.t(`backoffice.commissionplans.${COMMISSION_PLAN_TYPE_NAMES[value]}`)
                    : i18n.t("backoffice.commissionplans.mix")
            )
        },
        {
            title: "backoffice.commissionplans.onlineProviders",
            dataIndex: "onlineProviders",
            multi: true,
            disabledForFranchisingMode: true,
            multiMapper: value => value ? value.map(v => gameProvidersObj[v]) : [],
        },
        hasRetail && {
            title: "backoffice.commissionplans.retailProviders",
            dataIndex: "retailProviders",
            multi: true,
            multiMapper: value => {
                if (!Boolean(value)) {
                    return [];
                }

                const providerTypes = binaryToFlags(
                    Object.values(PROJECT_PROVIDER_TYPE),
                    value
                )

                return (
                    providerTypes.map((type) => (
                        type === PROJECT_PROVIDER_TYPE.SPORTBOOK ? 
                            i18n.t("backoffice.common.Sportsbook") : 
                        type === PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS ? 
                            i18n.t("backoffice.common.DGVirtuals") :
                        type === PROJECT_PROVIDER_TYPE.GOLDEN_RACE ?
                            i18n.t("backoffice.common.GoldenRace") : ""
                        )
                    )
                )
            },
        },
        {
            title: (
                franchisingMode
                    ? "backoffice.commissioncalculations.betshopOwners"
                    : "backoffice.commissioncalculations.agents"
            ),
            dataIndex: "count",
        },
    ]
}

export const expandColumns = [
    {
        title: "backoffice.commissioncalculations.commissionId",
        dataIndex: "id",
        copy: true,
        mobileLevel: 1,
    },
    {
        title: 'backoffice.commissioncalculations.calculatedAt',
        dataIndex: "calculatedAt",
        isDateTime: true,
        mobileLevel: 2
    },
    {
        title: "backoffice.commissioncalculations.sourceAmount",
        dataIndex: "sourceAmount",
        isNumeric: true,
        mobileLevel: 3,
    },
    {
        title: "backoffice.commissioncalculations.calculatedAmount",
        dataIndex: "amount",
        isNumeric: true,
        mobileLevel: 4,
    },
    {
        title: 'backoffice.commissioncalculations.currency',
        dataIndex: "currencyCode",
        mobileLevel: 5,
    }
];