import React from 'react';
import i18n from "translations/config";

import { isFranchisingMode } from 'utils/common';
import { defaultQueryStringGenerator } from 'utils/notifications';

import { PERMISSION_RESOURCE } from './permissions.constants';
import PATHS from './path.constants';

export const ERROR_NOTIFICATION_CONTENT = {
    title: "Unknown Notification",
    description: (
        <p className="rt--error-text">
            We apologize for the error,
            notification are temporarily unavailable,
            but we are working to fix it. Thank you for your understanding
        </p>
    )
}

export const NOTIFICATION_STATE_ENUM = {
    UNREAD: 1,
    READ: 2
}

const NOTIFICATION_ACTIONS_ENUM = {
    ADD: "Add",
    REMOVE: "Remove",
    CREATE: "Create",
    MODIFY: "Modify",
    ATTENTION: "Attention",
    TRANSACTION: "Transaction"
}

const OBJECT_TYPES_ENUM = {
    // Reparent
    NEW_PARENT: "NewParent",
    OLD_PARENT: "OldParent",
    AGENT: "Agent",

    // Transaction
    DEPOSIT: "Deposit",
    WITHDRAWAL: "Withdrawal",
    COMMISSION_PLAN: "Commission_Plan",
    ROLLBACK: "Rollback"
}

export const RESOURCES_ENUM = {
    GENERAL_INFO: "GeneralInfo",
    WALLETS: "Wallets",
    LIMITS: "Limits",
    CURRENCY: "Currency",
    ADDITIONAL_ACCESS: "AdditionalAccess",
    PERMISSIONS_GROUPS: "PermissionsGroups",
    COMMISSION_PLAN: "Commission_Plan",
    PLAYER: "Player",
    BETSHOP: "Betshop",
    AGENT: "Agent",
    PASSWORD_SETTINGS: "PasswordSettings",
    TOKEN_SETTINGS: "TokenSettings",
    AUTHENTICATION_SETTINGS: "AuthenticationSettings",
    NETWORK: "Network",
    PROJECT: "Project",
    REGISTRATION_FORM_AGENT: "RegistrationFormAgent",
    REGISTRATION_FORM_PLAYER: "RegistrationFormPlayer",
    COMPANY_ACCESS: "Company_Access",
    PROJECT_ACCESS: "Project_Access",
    PAYMENT_CONFIG: "PaymentConfig",
    BETSHOP_OWNER: "BetShopOwner",
    CONFIGS: "Configs",
    VS_BET_CONFIGS: "VSBetConfigs"
}

export const NOTIFICATION_TYPES_ENUM = {
    COMPANY: 1,
    PROJECT: 2,
    LIMIT_EXCEEDED: 3,
    COMMISSION_PLAN: 4,
    PAYMENT_REQUEST: 5,
    REQUEST_PROCESSED: 6,
    ACCESS_MANAGER_REQUEST: 7,
    AGENT: 8,
    NON_DIRECT: 9,
    WALLET_TRANSACTION: 10,
    REPARENT: 11,
    AGENT_LIMIT_EXCEEDED: 12,
}

export const NOTIFICATION_INFO_TYPES = [
    NOTIFICATION_TYPES_ENUM.LIMIT_EXCEEDED
]

const TRANSLATION_SIGNS_ENUM = {
    RESOURCE: "%X%",
    USERNAME: "%Y%", // User performing the action
    OBJECT_NAME: "%Z%", // User/Object on which the action was performed
    AMOUNT: "%K%",
}

export const NOTIFICATION_SOUND_TYPES = {
    DEFAULT: 1,
    SILENT: 2
}

export const PREDEFINED_DATA_BY_TYPE = {
    //#region TYPE - COMPANY
    [NOTIFICATION_TYPES_ENUM.COMPANY]: {

        // ACTION - MODIFY
        [NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
            titleTranslationSource: "backoffice.notifications.companyModifyTitle",
            descriptionTranslationSource: "backoffice.notifications.companyModifyDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                )
            },
        },
    },
    //#endregion

    //#region TYPE - PROJECT
    [NOTIFICATION_TYPES_ENUM.PROJECT]: {

        // ACTION - MODIFY
        [NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
            titleTranslationSource: "backoffice.notifications.projectModifyTitle",
            descriptionTranslationSource: "backoffice.notifications.projectModifyDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                )
            },
        },

        // ACTION - ADD
        [NOTIFICATION_ACTIONS_ENUM.ADD]: {
            titleTranslationSource: "backoffice.notifications.projectAddTitle",
            descriptionTranslationSource: "backoffice.notifications.projectAddDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                )
            },
        },

        // ACTION - REMOVE
        [NOTIFICATION_ACTIONS_ENUM.REMOVE]: {
            titleTranslationSource: "backoffice.notifications.projectRemoveTitle",
            descriptionTranslationSource: "backoffice.notifications.projectRemoveDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                )
            },
        },
    },
    //#endregion

    //#region TYPE - LIMIT_EXCEEDED
    [NOTIFICATION_TYPES_ENUM.LIMIT_EXCEEDED]: {

        // ACTION - DEPOSIT
        [NOTIFICATION_ACTIONS_ENUM.DEPOSIT]: {
            titleTranslationSource: "",
            descriptionTranslationSource: "backoffice.notifications.",
        },

        // ACTION - WITHDRAWAL
        [NOTIFICATION_ACTIONS_ENUM.WITHDRAWAL]: {
            titleTranslationSource: "",
            descriptionTranslationSource: "backoffice.notifications.",
        },

        // ACTION - ATTENTION
        [NOTIFICATION_ACTIONS_ENUM.ATTENTION]: {
            titleTranslationSource: "backoffice.notifications.limitExceededAttentionTitle",
            descriptionTranslationSource: "backoffice.notifications.limitExceededAttentionDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
                        .replace(TRANSLATION_SIGNS_ENUM.AMOUNT, data.threshold)
                )
            },
        },
    },
    //#endregion

    //#region TYPE - AGENT_LIMIT_EXCEEDED
    [NOTIFICATION_TYPES_ENUM.AGENT_LIMIT_EXCEEDED]: {
        // ACTION - ATTENTION
        [NOTIFICATION_ACTIONS_ENUM.ATTENTION]: {
            titleTranslationSource: "backoffice.notifications.limitExceededAttentionTitle",
            descriptionTranslationSource: "backoffice.notifications.agentSubtreeLimitExceededAttentionDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.agent)
                        .replace(TRANSLATION_SIGNS_ENUM.AMOUNT, data.allowedmaxpositivecreditsubtree)
                )
            },
        }
    },
    //#endregion

    //#region TYPE - COMMISSION_PLAN
    [NOTIFICATION_TYPES_ENUM.COMMISSION_PLAN]: {

        // ACTION - MODIFY
        [NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
            titleTranslationSource: "backoffice.notifications.commissionPlanModifyTitle",
            descriptionTranslationSource: "backoffice.notifications.commissionPlanModifyDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.querydata?.commissionPlanName)
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                )
            },
        },

        // ACTION - ADD
        [NOTIFICATION_ACTIONS_ENUM.ADD]: {
            titleTranslationSource: "backoffice.notifications.commissionPlanAddTitle",
            descriptionTranslationSource: "backoffice.notifications.commissionPlanAddDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.querydata?.commissionPlanName)
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                )
            },
        },

        // ACTION - REMOVE
        [NOTIFICATION_ACTIONS_ENUM.REMOVE]: {
            titleTranslationSource: "backoffice.notifications.commissionPlanRemoveTitle",
            descriptionTranslationSource: "backoffice.notifications.commissionPlanRemoveDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.querydata?.commissionPlanName)
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                )
            },
        },

        // ACTION - CREATE
        [NOTIFICATION_ACTIONS_ENUM.CREATE]: {
            titleTranslationSource: "backoffice.notifications.commissionPlanCreateTitle",
            descriptionTranslationSource: "backoffice.notifications.commissionPlanCreateDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.querydata?.commissionPlanName)
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                )
            },
        },
    },
    //#endregion

    //#region TYPE - ACCESS_MANAGER_REQUEST
    [NOTIFICATION_TYPES_ENUM.ACCESS_MANAGER_REQUEST]: {

        //ACTION - ADD
        [NOTIFICATION_ACTIONS_ENUM.ADD]: {
            titleTranslationSource: "backoffice.notifications.accessManagerAddTitle",
            descriptionTranslationSource: "backoffice.notifications.accessManagerAddDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                        .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
                )
            }
        },

        //ACTION - REMOVE
        [NOTIFICATION_ACTIONS_ENUM.REMOVE]: {
            titleTranslationSource: "backoffice.notifications.accessManagerDeleteTitle",
            descriptionTranslationSource: "backoffice.notifications.accessManagerDeleteDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                        .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
                )
            }
        },

        //ACTION - MODIFY
        [NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
            titleTranslationSource: "backoffice.notifications.accessManagerModifyTitle",
            descriptionTranslationSource: "backoffice.notifications.accessManagerModifyDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                        .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
                )
            }
        },
    },
    //#endregion

    //#region TYPE - AGENT
    [NOTIFICATION_TYPES_ENUM.AGENT]: {

        // ACTION - MODIFY
        [NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
            titleTranslationSource: "backoffice.notifications.userModifyTitle",
            descriptionTranslationSource: "backoffice.notifications.userModifyDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                )
            },
        },

        // ACTION - ADD
        [NOTIFICATION_ACTIONS_ENUM.ADD]: {
            titleTranslationSource: "backoffice.notifications.userAddTitle",
            descriptionTranslationSource: "backoffice.notifications.userAddDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                )
            },
        },

        // ACTION - REMOVE
        [NOTIFICATION_ACTIONS_ENUM.REMOVE]: {
            titleTranslationSource: "backoffice.notifications.userRemoveTitle",
            descriptionTranslationSource: "backoffice.notifications.userRemoveDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                )
            },
        },
    },
    //#endregion

    //#region TYPE - NON_DIRECT
    [NOTIFICATION_TYPES_ENUM.NON_DIRECT]: {

        // ACTION - MODIFY
        [NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
            titleTranslationSource: "backoffice.notifications.nonDirectModifyTitle",
            descriptionTranslationSource: "backoffice.notifications.nonDirectModifyDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                        .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
                )
            },
        },

        // ACTION - CREATE
        [NOTIFICATION_ACTIONS_ENUM.CREATE]: {
            titleTranslationSource: "backoffice.notifications.nonDirectCreateTitle",
            descriptionTranslationSource: "backoffice.notifications.nonDirectCreateDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                        .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
                )
            },
        },

        // ACTION - TRANSACTION
        [NOTIFICATION_ACTIONS_ENUM.TRANSACTION]: {

            // OBJECT_TYPE - DEPOSIT
            [OBJECT_TYPES_ENUM.DEPOSIT]: {
                titleTranslationSource: "backoffice.notifications.nonDirectTransactionDepositTitle",
                descriptionTranslationSource: "backoffice.notifications.nonDirectTransactionDepositDescription",
                titleMaker: (data, translationSource) => {
                    return (
                        i18n.t(translationSource)
                            .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                    )
                },
                descriptionMaker: (data, translationSource) => {
                    return (
                        i18n.t(translationSource)
                            .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                            .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                            .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
                            .replace(TRANSLATION_SIGNS_ENUM.AMOUNT, data.amount)
                    )
                },
            },

            // OBJECT_TYPE - WITHDRAWAL
            [OBJECT_TYPES_ENUM.WITHDRAWAL]: {
                titleTranslationSource: "backoffice.notifications.nonDirectTransactionWithdrawalTitle",
                descriptionTranslationSource: "backoffice.notifications.nonDirectTransactionWithdrawalDescription",
                titleMaker: (data, translationSource) => {
                    return (
                        i18n.t(translationSource)
                            .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                    )
                },
                descriptionMaker: (data, translationSource) => {
                    return (
                        i18n.t(translationSource)
                            .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                            .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                            .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
                            .replace(TRANSLATION_SIGNS_ENUM.AMOUNT, data.amount)
                    )
                },
            },

            // OBJECT_TYPE - ROLLBACK
            [OBJECT_TYPES_ENUM.ROLLBACK]: {
                titleTranslationSource: "backoffice.notifications.nonDirectTransactionRollbackTitle",
                descriptionTranslationSource: "backoffice.notifications.nonDirectTransactionRollbackDescription",
                titleMaker: (data, translationSource) => {
                    return (
                        i18n.t(translationSource)
                            .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                    )
                },
                descriptionMaker: (data, translationSource) => {
                    return (
                        i18n.t(translationSource)
                            .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                            .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                            .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
                            .replace(TRANSLATION_SIGNS_ENUM.AMOUNT, data.amount)
                    )
                },
            },
        }
    },
    //#endregion

    //#region TYPE - REPARENT
    [NOTIFICATION_TYPES_ENUM.REPARENT]: {

        // ACTION - MODIFY
        [NOTIFICATION_ACTIONS_ENUM.MODIFY]: {

            // OBJECT_TYPE - NEW_PARENT
            [OBJECT_TYPES_ENUM.NEW_PARENT]: {
                titleTranslationSource: "backoffice.notifications.reparentTitle",
                descriptionTranslationSource: "backoffice.notifications.reparentNewParentDescription",
                titleMaker: (data, translationSource) => {
                    return (
                        i18n.t(translationSource)
                            .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                    )
                },
                descriptionMaker: (data, translationSource) => {
                    const transaction = data.resource === RESOURCES_ENUM.AGENT ? translationSource : "backoffice.notifications.reparentNewParentDescriptionBetshopPlayer";

                    return (
                        i18n.t(transaction)
                            .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                            .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                            .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
                    )
                },
            },

            // OBJECT_TYPE - OLD_PARENT
            [OBJECT_TYPES_ENUM.OLD_PARENT]: {
                titleTranslationSource: "backoffice.notifications.reparentTitle",
                descriptionTranslationSource: "backoffice.notifications.reparentOldParentDescription",
                titleMaker: (data, translationSource) => {
                    return (
                        i18n.t(translationSource)
                            .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                    )
                },
                descriptionMaker: (data, translationSource) => {
                    const transaction = data.resource === RESOURCES_ENUM.AGENT ? translationSource : "backoffice.notifications.reparentOldParentDescriptionBetshopPlayer";

                    return (
                        i18n.t(transaction)
                            .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                            .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                            .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
                    )
                },
            },

            // OBJECT_TYPE - AGENT
            [OBJECT_TYPES_ENUM.AGENT]: {
                titleTranslationSource: "backoffice.notifications.reparentTitle",
                descriptionTranslationSource: "backoffice.notifications.reparentAgentDescription",
                titleMaker: (data, translationSource) => {
                    return (
                        i18n.t(translationSource)
                            .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                    )
                },
                descriptionMaker: (data, translationSource) => {
                    return (
                        i18n.t(translationSource)
                            .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                            .replace(TRANSLATION_SIGNS_ENUM.OBJECT_NAME, data.objectname)
                    )
                },
            },
        },
    },
    //#endregion

    //#region TYPE - WALLET_TRANSACTION
    [NOTIFICATION_TYPES_ENUM.WALLET_TRANSACTION]: {
        [NOTIFICATION_ACTIONS_ENUM.MODIFY]: {
            titleTranslationSource: "backoffice.notifications.walletTransactionModifyTitle",
            descriptionTranslationSource: "backoffice.notifications.walletTransactionModifyDescription",
            titleMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                )
            },
            descriptionMaker: (data, translationSource) => {
                return (
                    i18n.t(translationSource)
                        .replace(TRANSLATION_SIGNS_ENUM.RESOURCE, i18n.t(`backoffice.resources.${data.resource}`))
                        .replace(TRANSLATION_SIGNS_ENUM.USERNAME, data.username)
                        .replace(TRANSLATION_SIGNS_ENUM.AMOUNT, data.amount)
                )
            },
        }
    }
    //#endregion
}

export const PATHS_FOR_EACH_TYPE_BY_RESOURCE = {
    //#region - AGENT
    [NOTIFICATION_TYPES_ENUM.AGENT]: {
        [RESOURCES_ENUM.GENERAL_INFO]: {
            permissionResource: PERMISSION_RESOURCE.AGENT_GENERALINFO,
            path: isFranchisingMode() ? PATHS.BETSHOP_OWNERS_EDIT : PATHS.AGENTS_EDIT,
            hash: "#tab=0",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.CURRENCY]: {
            permissionResource: PERMISSION_RESOURCE.AGENT_CURRENCIES,
            path: isFranchisingMode() ? PATHS.BETSHOP_OWNERS_EDIT : PATHS.AGENTS_EDIT,
            hash: "#tab=3",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.LIMITS]: {
            permissionResource: PERMISSION_RESOURCE.AGENT_LIMITS,
            path: isFranchisingMode() ? PATHS.BETSHOP_OWNERS_EDIT : PATHS.AGENTS_EDIT,
            hash: "#tab=4",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.ADDITIONAL_ACCESS]: {
            permissionResource: PERMISSION_RESOURCE.AGENT_ADDITIONAL_ACCESSES,
            path: isFranchisingMode() ? PATHS.BETSHOP_OWNERS_EDIT : PATHS.AGENTS_EDIT,
            hash: "#tab=6",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.PERMISSIONS_GROUPS]: {
            permissionResource: PERMISSION_RESOURCE.AGENT_PERMISSIONS,
            path: isFranchisingMode() ? PATHS.BETSHOP_OWNERS_EDIT : PATHS.AGENTS_EDIT,
            hash: "#tab=5&subtab=0",
            queryStringGenerator: defaultQueryStringGenerator,
        },
    },
    //#endregion

    //#region - COMPANY
    [NOTIFICATION_TYPES_ENUM.COMPANY]: {
        [RESOURCES_ENUM.GENERAL_INFO]: {
            permissionResource: PERMISSION_RESOURCE.COMPANY_GENERALINFO,
            path: PATHS.COMPANIES_EDIT,
            hash: "#tab=0",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.PASSWORD_SETTINGS]: {
            permissionResource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS,
            path: PATHS.COMPANIES_EDIT,
            hash: "#tab=1&subtab=0",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.TOKEN_SETTINGS]: {
            permissionResource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS,
            path: PATHS.COMPANIES_EDIT,
            hash: "#tab=1&subtab=1",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.AUTHENTICATION_SETTINGS]: {
            permissionResource: PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS,
            path: PATHS.COMPANIES_EDIT,
            hash: "#tab=1&subtab=2",
            queryStringGenerator: defaultQueryStringGenerator,
        },
    },
    //#endregion

    //#region - PROJECT
    [NOTIFICATION_TYPES_ENUM.PROJECT]: {
        [RESOURCES_ENUM.GENERAL_INFO]: {
            permissionResource: PERMISSION_RESOURCE.PROJECT_GENERALINFO,
            path: PATHS.PROJECTS_EDIT,
            hash: "#tab=0",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.CURRENCY]: {
            permissionResource: PERMISSION_RESOURCE.PROJECT_CURRENCIES,
            path: PATHS.PROJECTS_EDIT,
            hash: "#tab=1",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.WALLETS]: {
            permissionResource: PERMISSION_RESOURCE.PROJECT_WALLETS,
            path: PATHS.PROJECTS_EDIT,
            hash: "#tab=3",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.LIMITS]: {
            permissionResource: PERMISSION_RESOURCE.PROJECT_LIMITS,
            path: PATHS.PROJECTS_EDIT,
            hash: "#tab=4",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.AGENT]: {
            permissionResource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_FORM,
            path: PATHS.PROJECTS_EDIT,
            hash: "#tab=5&subtab=0",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.BETSHOP_OWNER]: {
            permissionResource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_FORM,
            path: PATHS.PROJECTS_EDIT,
            hash: "#tab=5&subtab=0",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.PLAYER]: {
            permissionResource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_FORM,
            path: PATHS.PROJECTS_EDIT,
            hash: "#tab=5&subtab=1",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.CONFIGS]: {
            permissionResource: PERMISSION_RESOURCE.PROJECT_REGULATIONS,
            path: PATHS.PROJECTS_EDIT,
            hash: "#tab=6&subtab=0",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.PAYMENT_CONFIG]: {
            permissionResource: PERMISSION_RESOURCE.PROJECT_PAYMENT,
            path: PATHS.PROJECTS_EDIT,
            hash: "#tab=8",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.VS_BET_CONFIGS]: {
            permissionResource: PERMISSION_RESOURCE.PROJECT_VS_BET_LIMITS,
            path: PATHS.PROJECTS_EDIT,
            hash: "#tab=4&subtab=2",
            queryStringGenerator: defaultQueryStringGenerator,
        }
    },
    //#endregion

    //#region - NON_DIRECT
    [NOTIFICATION_TYPES_ENUM.NON_DIRECT]: {
        // [RESOURCES_ENUM.AGENT]: {
        //     permissionResource: PERMISSION_RESOURCE.AGENT_GENERALINFO,
        //     path: PATHS.AGENTS_EDIT,
        //     hash: "#tab=0",
        //     queryStringGenerator: defaultQueryStringGenerator,
        // },
        [RESOURCES_ENUM.PLAYER]: {
            permissionResource: PERMISSION_RESOURCE.PLAYER_GENERALINFO,
            path: PATHS.PLAYERS_EDIT,
            hash: "#tab=0",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.BETSHOP]: {
            permissionResource: PERMISSION_RESOURCE.BETSHOP_GENERALINFO,
            path: PATHS.BETSHOPS_EDIT,
            hash: "#tab=0",
            queryStringGenerator: defaultQueryStringGenerator,
        },
    },
    //#endregion

    //#region - COMMISSION_PLAN
    [NOTIFICATION_TYPES_ENUM.COMMISSION_PLAN]: {
        [RESOURCES_ENUM.AGENT]: {
            permissionResource: PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN,
            path: PATHS.AGENTS_EDIT,
            hash: "#tab=2",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.PROJECT]: {
            permissionResource: PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN,
            path: PATHS.PROJECTS_EDIT,
            hash: "#tab=2&subtab=1",
            queryStringGenerator: defaultQueryStringGenerator,
        },
    },
    //#endregion

    //#region - WALLET_TRANSACTION
    [NOTIFICATION_TYPES_ENUM.WALLET_TRANSACTION]: {
        [RESOURCES_ENUM.COMMISSION_PLAN]: {
            permissionResource: PERMISSION_RESOURCE.AGENT_WALLETS,
            path: PATHS.AGENTS_EDIT,
            hash: "#tab=1",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.AGENT]: {
            permissionResource: PERMISSION_RESOURCE.AGENT,
            path: PATHS.AGENTS_EDIT,
            hash: "#tab=1",
            queryStringGenerator: defaultQueryStringGenerator,
        },
        [RESOURCES_ENUM.PROJECT]: {
            permissionResource: PERMISSION_RESOURCE.PROJECT_WALLETS,
            path: PATHS.PROJECTS_EDIT,
            hash: "#tab=3",
            queryStringGenerator: defaultQueryStringGenerator,
        },
    },
    //#endregion

    //#region - ACCESS_MANAGER_REQUEST
    [NOTIFICATION_TYPES_ENUM.ACCESS_MANAGER_REQUEST]: {
        [RESOURCES_ENUM.PERMISSIONS_GROUPS]: {
            permissionResource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
            path: PATHS.PERMISSION_REQUESTS,
        },
        [RESOURCES_ENUM.COMMISSION_PLAN]: {
            permissionResource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
            path: PATHS.PERMISSION_REQUESTS,
        },
        [RESOURCES_ENUM.PROJECT_ACCESS]: {
            permissionResource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
            path: PATHS.PERMISSION_REQUESTS,
        },
        [RESOURCES_ENUM.COMPANY_ACCESS]: {
            permissionResource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
            path: PATHS.PERMISSION_REQUESTS,
        },
        [RESOURCES_ENUM.GENERAL_INFO]: {
            permissionResource: PERMISSION_RESOURCE.PERMISSION_REQUESTS,
            path: PATHS.PERMISSION_REQUESTS,
        },
    }
    //#endregion
}
