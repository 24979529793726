import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import DateRangePicker from "components/common/dateRangePicker";

import { getTerminals, setTerminalsFilters } from "store/actions/dashboard/retail/terminals/terminals.action";

import { USER_ROLE, USER_TYPE } from "constants/user.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { TERMINAL_STATE } from 'constants/terminal.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';
import useProjectType from 'hooks/useProjectType';

import { getUser } from 'utils/auth';
import { getGlobalProjectData } from 'utils/common';

/** Terminals Page Filters Component */
const Filters = ({
    setTerminalsFilters,
    getTerminals,
    filters,
    globalProjectId,
}) => {
    const { t } = useTranslation();

    const { role } = getUser();

    const { hasAgentSystem } = useProjectType();

    const { name: globalProjectName } = getGlobalProjectData(globalProjectId);

    const hasOwner = hasAgentSystem && role !== USER_ROLE.AGENT;

    const [ agentNames, getAgentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        actionParams: { userRole: USER_TYPE.AGENT, excludeAgent: false },
        autoGet: false,
        disable: !hasOwner
    });

    const [ betshopNames, getBetshopNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.BETSHOP, 
        autoGet: false
    });

    const ownerOptions = useMemo(() => {
        let options = [{ value: "", text: t('backoffice.common.all') }]

        if(role === USER_ROLE.ADMIN){
            options.push(
                { value: globalProjectId, text:globalProjectName }
            )
        }

        options = options.concat(agentNames.map(item => (
            { value: item.id, text: item.name }
        )))

        return options;
    }, [agentNames, t, role, globalProjectId, globalProjectName])


    return (
        <FiltersWrapper
            loadFn={getTerminals}
            setFiltersFn={setTerminalsFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: false },
                { name: "modified", time: false },
            ]}
            searchFieldName="nameOrId"
            onInit={() => {
                getAgentNames();
                getBetshopNames();
            }}
        >
            <Row gutter={[16, 0]}>
                {
                    hasOwner && (
                        <Col span={24}>
                            <Form.Item
                                label={t('backoffice.terminals.owner')}
                                name="ownerId"
                            >
                                <Select
                                    options={ownerOptions}
                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.terminals.owner")}`}
                                    search={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
                
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.terminals.betshop')}
                        name="betShopId"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') },
                                    ...betshopNames.map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                                ]
                            }
                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.terminals.betshop")}`}
                            search={true}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.status')}
                        name="state"
                    >
                        <Select
                            options={[
                                { value: "", text: t('backoffice.common.all') },
                                { value: TERMINAL_STATE.INACTIVE, text: t('backoffice.common.incative') },
                                { value: TERMINAL_STATE.ACTIVE, text: t('backoffice.common.active') },
                                { value: TERMINAL_STATE.BLOCKED, text: t('backoffice.common.blocked') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.creationDate')}
                        name="date"
                    >
                        <DateRangePicker showTime={false} />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.lastUpdateDate')}
                        name="modified"
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker showTime={false} />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get terminals */
    getTerminals: PropTypes.func,
    /** Redux action to set terminals filters */
    setTerminalsFilters: PropTypes.func,
    /** Redux state property, terminals filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
}

const mapDispatchToProps = dispatch => (
    {
        getTerminals: () => {
            dispatch(getTerminals())
        },
        setTerminalsFilters: filters => {
            dispatch(setTerminalsFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.terminals.filters,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);
