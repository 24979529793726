import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setAgentsActionBefore, setAgentsActionFinished, setAgentsSaveActionBefore, setAgentsSaveActionFinished } from './agents.action';

import { SET_AGENT_ADDITIONAL_ACCESSES } from "../../../../actionTypes";

const setAgentAdditionalAccesses = data => ({
    type: SET_AGENT_ADDITIONAL_ACCESSES,
    payload: { data }
})

export const getAgentAdditionalAccesses = id => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.GET_AGENT_ADDITIONAL_ACCESSES,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data : {value: accesses}}) => {
            accesses["allowCreateAgents"] = accesses["allowCreateAgent"];

            dispatch(setAgentAdditionalAccesses(accesses))
            dispatch(setAgentsActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

export const saveAgentAdditionalAccesses = data => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AGENT_ADDITIONAL_ACCESSES,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);

                data.value["allowCreateAgents"] = data.value["allowCreateAgent"];
                dispatch(setAgentAdditionalAccesses(data.value));
            }
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}
