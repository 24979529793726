import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { deleteTerminalImage, setTerminalImage } from 'store/actions/dashboard/terminalCustomize/image.action';

import ImageUploader from "components/common/imageUploader";

import { sendToTerminalIframe } from 'utils/terminal';

import ApiUrls from 'constants/api.constants';
import { TERMINAL_UPDATE_TYPE } from 'constants/terminalCustomize.constants';

import { isFunction } from 'utils/common';

const getDefaultImgPath = (imgPath) => {
    if (Boolean(imgPath)) {
        return {
            url: `${import.meta.env.SYSTEM_CDN_URL}/${imgPath.toLowerCase()}`,
            status: "done",
            percent: 100
        }
    }

    return null;
}

const CustomizeImgUploader = ({
    sectionName,
    sectionType,
    fileType,
    imgPath,
    onSuccess,

    // redux
    customizeLanguage,
    deleteTerminalImage,
    setTerminalImage,

    ...restProps
}) => {
    const { t } = useTranslation();

    const handleSuccessfulUpload = response => {
        const newImgPath = response.value;
        setTerminalImage(newImgPath, sectionType, sectionName, fileType, customizeLanguage);

        sendToTerminalIframe(TERMINAL_UPDATE_TYPE.IMAGE, {
            fileType,
            sectionType,
            language: customizeLanguage,
            path: newImgPath
        })

        if (isFunction(onSuccess)) {
            onSuccess(newImgPath);
        }
    }

    const handleImgDeletion = () => {
        deleteTerminalImage(
            sectionType,
            sectionName,
            fileType,
            customizeLanguage,
            () => {
                sendToTerminalIframe(TERMINAL_UPDATE_TYPE.IMAGE, {
                    sectionType,
                    fileType,
                    language: customizeLanguage,
                    path: null
                })
            }
        )
    }

    const requestBodyForUpload = {
        sectionType,
        fileType,
        languageCode: customizeLanguage,
    }

    return (
        <ImageUploader
            {...restProps}
            uploadUrl={`${import.meta.env.SYSTEM_API_URL}${ApiUrls.UPLOAD_TERMINAL_IMAGE}`}
            data={requestBodyForUpload}
            size={1024*1024}
            disablePreview={true}
            onSuccess={handleSuccessfulUpload}
            defaultFile={getDefaultImgPath(imgPath)}
            updateProps={[imgPath]}
            fileBuilder={file => ({
                url: `${import.meta.env.SYSTEM_CDN_URL}/${file}`.toLowerCase()
            })}
            remove={{
                handler: handleImgDeletion
            }}
        />
    );
}

/** CustomizeImgUploader propTypes
 * PropTypes
*/
CustomizeImgUploader.propTypes = {
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Redux action to delete terminal image */
    deleteTerminalImage: PropTypes.func,
    /** Redux action to save terminal image */
    setTerminalImage: PropTypes.func,
    /** Image URL */
    imgPath: PropTypes.string,
    /** Section Type */
    sectionType: PropTypes.number,
    /** File Type */
    fileType: PropTypes.number,
    /** Section Name */
    sectionName: PropTypes.string,
    /** Fires when img successfully uploaded  */
    onSuccess: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    deleteTerminalImage: (sectionType, sectionName, fileType, languageCode, onSuccess) => {
        dispatch(deleteTerminalImage(sectionType, sectionName, fileType, languageCode, onSuccess))
    },
    setTerminalImage: (imgPath, sectionType, sectionName, fileType, customizeLanguage) => {
        dispatch(setTerminalImage(imgPath, sectionType, sectionName, fileType, customizeLanguage))
    },
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.terminalCustomize.customizeLanguage
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeImgUploader);