const PATHS = {
    DEFAULT: "/",
    REDIRECT: "/redirect",
    LOGIN: "/login",
    RESET_PASSWORD: "/login/resetPassword",
    PROFILE: "/profile",
    COMPANIES: "/companies",
    DASHBOARD: "/dashboard",
    DASHBOARD_OPERATIONAL: "/operational",
    DASHBOARD_FINANCIAL: "/financial",
    COMPANIES_EDIT: "/companies/edit",
    PROJECTS: "/projects",
    PROJECTS_EDIT: "/projects/edit",
    AGENTS: "/agents",
    AGENTS_EDIT: "/agents/edit",
    BETSHOP_OWNERS: "/betshopOwners",
    BETSHOP_OWNERS_EDIT: "/betshopOwners/edit",
    NETWORK: "/network",
    REPORTS_AGENT_TRANSACTIONS: "/report/agentTransactions",
    REPORTS_BETSHOP_OWNER_TRANSACTIONS: "/report/betshopOwnerTransactions",
    REPORTS_CASHIER_TRANSACTIONS: "/report/cashierTransactions",
    REPORTS_BETSHOP_TRANSACTIONS: "/report/betshopTransactions",
    REPORTS_COMMISSION_CALCULATIONS: "/report/commissionCalculations",
    REPORTS_PERFORMANCE_REPORTS: "/report/performanceReport",
    REPORTS_AGENT_PERFORMANCE_REPORT: "/report/agentPerformanceReport",
    REPORTS_AGENT_PERFORMANCE_REPORT_EDIT: "/report/agentPerformanceReport/edit",
    REPORTS_PLAYER_PERFORMANCE_REPORT: "/report/playerPerformanceReport",
    REPORTS_BETSHOP_PERFORMANCE_REPORT: "/report/betshopPerformanceReport",
    REPORTS_CASHIER_PERFORMANCE_REPORT: "/report/cashierPerformanceReport",
    REPORTS_PRODUCT_REPORT: "/report/productReport",
    NEW_REPORTS_PRODUCT_REPORT: "/report/newProductReport",
    REPORTS_AGENT_CALCULATION_HISTORY: "/report/agentCalculationHistory",
    PLAYERS: "/players",
    PLAYERS_EDIT: "/players/edit",
    REAL_TIME_SPORT_BETS: "/betslips/realTimeSportBets",
    ONLINE_BETHISTORY: "/betslips/online",
    RETAIL_BETHISTORY: "/betslips/retail",
    BETSHOPS: "/betshops",
    BETSHOPS_EDIT: "/betshops/edit",
    CASHIERS: "/cashiers",
    CASHIERS_EDIT: "/cashiers/edit",
    BETSHOP_MANAGERS: "/betshopManagers",
    BETSHOP_MANAGERS_EDIT: "/betshopManagers/edit",
    TERMINALS: "/terminals",
    TERMINALS_EDIT: "/terminals/edit",
    USERS: "/users",
    USERS_EDIT: "/users/edit",
    ACCESS_MANAGERS: "/accessManagers",
    ACCESS_MANAGERS_EDIT: "/accessManagers/edit",
    PERMISSIONS: "/permissions",
    PERMISSION_GROUPS: "/permissionGroups",
    PERMISSION_GROUPS_EDIT: "/permissionGroups/edit",
    PERMISSION_REQUESTS: "/permissionRequests",
    TRANSLATIONS_RETAIL: "/cms/retailTranslations",
    TRANSLATIONS_BO: "/cms/BOTranslations",
    TRANSLATIONS_WIDGET: "/cms/widgetTranslations",
    TRANSLATIONS_AGENT: "/cms/agentTranslations",
    PAYOUT_TICKET: "/cms/payoutTicket",
    VOUCHER: "/cms/voucher",
    SETTINGS_CURRENCIES: "/settings/currencies",
    SETTINGS_LANGUAGES: "/settings/languages",
    FAILED_TRANSACTIONS: "/failedTransactions",
    USER_LOGS: "/userLogs",
    DEVELOPER_ERRORS: "/developer/errors",
    DEVELOPER_REQUESTS: "/developer/requests",
    DEVELOPER_JOBS: "/developer/jobs",
    DEVELOPER_MONITORING: "/developer/monitoring",
    DEVELOPER_PLATFORM_TEST: "/developer/platformTest",
    DEVELOPER_GENERATIONS: "/developer/generations",
    DEVELOPER_DB_CONNECTIONS: "/developer/dbConnections",
    DEVELOPER_CALCULATE_REPORTS: "/developer/calculateReports",
    DEVELOPER_POST_DEPLOYMENT_ACTIONS: "/developer/postDeploymentActions",
    PAYMENTS_REQUESTS: "/payments/requests",
    PAYMENT_HISTORY: "/payments/history",
    CUSTOMIZE: "/customize",
    TERMINAL_CUSTOMIZE: "/customize/terminal"
}

export default PATHS;
