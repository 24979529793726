import React from 'react';
import PromotionStatus from './status';

import { makeImagePath } from 'pages/terminalCustomize/helpers';

export const tableColumns = [
    {
        title: "backoffice.terminals.title",
        dataIndex: "title"
    },
    {
        title: "backoffice.terminals.startDate",
        dataIndex: "startDate",
        isDateTime: true
    },
    {
        title: "backoffice.terminals.endDate",
        dataIndex: "endDate",
        isDateTime: true
    },
    {
        title: "backoffice.terminals.status",
        dataIndex: "status",
        render: (_, record) => <PromotionStatus promotion={record} endDate={record} /> 
    },
    {
        title: "backoffice.terminals.thumbnail",
        dataIndex: "thumbnail",
        render: value => {
            return value ? (
                <div className="rt--table-col-img rt--mt-6 rt--mb-6">
                    <img src={makeImagePath(value)} alt="attachment" />
                </div>
            ) : "-"
        },
    },
]