import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isMobile } from "utils/common";

import { setBetHistoryActionBefore, setBetHistoryActionFinished } from "./betHistory.action";

import {
    SET_ONLINE_BET_HISTORY,
    SET_ONLINE_BET_HISTORY_SORTING,
    SET_ONLINE_BET_HISTORY_FILTERS,
    SET_ONLINE_BET_HISTORY_LOADING,
    SET_ONLINE_BETS_TOTALS, SET_ONLINE_BET_HISTORY_TOTALS_LOADING
} from "../../../actionTypes";

const setOnlineBetHistoryLoading = loading => ({
    type: SET_ONLINE_BET_HISTORY_LOADING,
    payload: { loading },
});

const setOnlineBetHistoryTotalsLoading = loading => ({
    type: SET_ONLINE_BET_HISTORY_TOTALS_LOADING,
    payload: { loading },
})

const setOnlineBetHistory = (bets, add) => ({
    type: SET_ONLINE_BET_HISTORY,
    payload: { bets, add },
});

export const setOnlineBetHistorySorting = sorting => ({
    type: SET_ONLINE_BET_HISTORY_SORTING,
    payload: { sorting },
});

export const setOnlineBetHistoryFilters = filters => ({
    type: SET_ONLINE_BET_HISTORY_FILTERS,
    payload: { filters },
});


export const getOnlineBetHistory = nextPage => {
    return (dispatch, getState) => {
        const productT = "online"

        const betHistory = getState().betHistory[productT].sport;

        const filters = { ... betHistory.filters };
        const sorting = { ... betHistory.sorting };
        const total = betHistory.total;
        const page = nextPage === "first" ? 1 : nextPage === "next" ? sorting.page + 1 : sorting.page;

        if (filters.status === "cashed_out") {
            filters.isCashedout = true;

            delete filters.status;
        }

        let params = {
            ...sorting,
            ...filters,
            page: page
        };

        if(page > 1 && total <= (page - 1 ) * sorting.limit){
            return Promise.resolve();
        }

        dispatch(setBetHistoryActionBefore());
        dispatch(setOnlineBetHistoryLoading(true));

        return axios({
            url: ApiUrls.GET_ONLINE_BET_HISTORY,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: bets } }) => {
                dispatch(setOnlineBetHistory(bets, isMobile() && page !== 1));
                if (nextPage)
                    dispatch(setOnlineBetHistorySorting({ ...sorting, page: page }));
                dispatch(setBetHistoryActionFinished());
                dispatch(setOnlineBetHistoryLoading(false));
            })
            .catch((ex) => {
                dispatch(setBetHistoryActionFinished());
                dispatch(setOnlineBetHistoryLoading(false));
            });
    }
}

export const setOnlineBetsTotals = totals => ({
    type: SET_ONLINE_BETS_TOTALS,
    payload: { totals },
});

export const getOnlineBetsTotals = () => {
    return (dispatch, getState) => {

        const betHistory = getState().betHistory.online.sport;

        const filters = { ... betHistory.filters };

        let params = {
            ...filters
        };

        dispatch(setOnlineBetHistoryTotalsLoading(true));

        return axios({
            url: ApiUrls.ONLINE_BETS_TOTALS,
            method: Methods.GET,
            params: params,
        })
            .then(({ data: { value: totals } }) => {
                dispatch(setOnlineBetsTotals(totals));
                dispatch(setBetHistoryActionFinished());
            })
            .catch((ex) => {

            })
            .finally(() => {
                dispatch(setOnlineBetHistoryTotalsLoading(false));
            });
    }
}
