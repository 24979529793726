import React from "react";

import { useTranslation } from "react-i18next";

import { getPromotionStatus } from "pages/terminalCustomize/helpers";
import { PROMOTION_STATUS } from "constants/terminalCustomize.constants";

const PromotionStatus = ({
    promotion
}) => {

    const { t } = useTranslation()

    const status = getPromotionStatus(promotion)

    return (
        <div 
            className="rt--status"
            data-highlight={
                status === PROMOTION_STATUS.FINISHED ? "grey" :
                status === PROMOTION_STATUS.IN_PROGRESS ? "green" : "purple"
            }
        >
            <span className="rt--status-content rt--title rt--font-small">
                {
                    status === PROMOTION_STATUS.FINISHED ? t("backoffice.terminals.finished") :
                    status === PROMOTION_STATUS.IN_PROGRESS ? t("backoffice.terminals.inprogress") : t("backoffice.terminals.upcoming")
                }
            </span>
        </div>
    )
}

export default PromotionStatus;