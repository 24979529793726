//#region styles
import Colors from 'pages/terminalCustomize/editor/styles/colors';
import Fonts from 'pages/terminalCustomize/editor/styles/fonts';
import Logo from 'pages/terminalCustomize/editor/styles/logo';
//#endregion

//#region styles
import Header from 'pages/terminalCustomize/editor/sections/header';
import FeaturedProducts from 'pages/terminalCustomize/editor/sections/featuredProducts';
import Promotions from 'pages/terminalCustomize/editor/sections/promotions';
import TermsAndConditions from 'pages/terminalCustomize/editor/sections/ternsAndConditions';
import Footer from 'pages/terminalCustomize/editor/sections/footer';
import SecondScreen from 'pages/terminalCustomize/editor/sections/secondScreen';
import LanguageConfig from "pages/terminalCustomize/editor/sections/languageConfig";
//#endregion

//#region Language
import LanguagesMenuItem from 'pages/terminalCustomize/editor/languageMenuItem';
//#endregion

export const TERMINAL_SECTION_TYPE = {
    LOGO: 1 << 0,
    HEADER: 1 << 1,
    FEATURED_PRODUCTS: 1 << 2,
    PROMOTIONS: 1 << 3,
    TERMS_AND_CONDITIONS: 1 << 4,
    FOOTER_GENERAL: 1 << 5,
    FOOTER_CERTIFICATES: 1 << 6,
    LANGUAGE: 1 << 7,
    SECOND_SCREEN: 1 << 8,
    SECOND_SCREEN_CONFIG: 1 << 9
}

export const TERMINAL_UPDATE_TYPE = {
    COLORS: 1,
    FONT_FAMILY: 2,
    IMAGE: 3,
    DYNAMIC_DATA: 4,
    SCROLL_TO_SECTION: 5,
    LANGUAGE: 6,
    LANGUAGES_LIST: 7,
    DEFAULT_LANGUAGE_CODE: 8
}

export const SECTION_NAMES = {
    LANGUAGE_CONFIGS: "languageConfigs",
    HEADER: "header",
    FEATURED_PRODUCTS: "featuredProducts",
    PROMOTIONS: "promotions",
    TERMS_AND_CONDITIONS: "termsAndConditions",
    FOOTER : "footer",
    SECOND_SCREEN : "secondScreen",
    COLORS: "colors",
    FONTS: "fonts",
    LOGO: "logo",
}

export const TERMINAL_FILE_TYPE = {
    LOGO: 1
}

export const TERMINAL_FILE_NAMES = {
    [TERMINAL_FILE_TYPE.LOGO]: "logo",
}

export const SECTION_PREDEFINED_DATA_BY_SECTION_NAMES = {
    [SECTION_NAMES.LANGUAGE_CONFIGS]: {
        key: SECTION_NAMES.LANGUAGE_CONFIGS,
        text: "backoffice.terminals.languageConfig",
        content: LanguageConfig,
        menuItemComponent: LanguagesMenuItem,
    },
    [SECTION_NAMES.HEADER]: {
        key: SECTION_NAMES.HEADER,
        sectionType: TERMINAL_SECTION_TYPE.HEADER,
        text: "backoffice.terminals.header",
        content: Header,
    },
    [SECTION_NAMES.FEATURED_PRODUCTS]: {
        key: SECTION_NAMES.FEATURED_PRODUCTS,
        sectionType: TERMINAL_SECTION_TYPE.FEATURED_PRODUCTS,
        text: "backoffice.terminals.featuredProducts",
        content: FeaturedProducts,
        info: "backoffice.terminals.featuredProductsInfo",
    },
    [SECTION_NAMES.PROMOTIONS]: {
        key: SECTION_NAMES.PROMOTIONS,
        sectionType: TERMINAL_SECTION_TYPE.PROMOTIONS,
        text: "backoffice.terminals.promotions",
        content: Promotions,
        info: "backoffice.terminals.promotionsInfo",
    },
    [SECTION_NAMES.TERMS_AND_CONDITIONS]: {
        key: SECTION_NAMES.TERMS_AND_CONDITIONS,
        sectionType: TERMINAL_SECTION_TYPE.TERMS_AND_CONDITIONS,
        text: "backoffice.terminals.termsAndConditions",
        content: TermsAndConditions
    },
    [SECTION_NAMES.FOOTER]: {
        key: SECTION_NAMES.FOOTER,
        sectionType: TERMINAL_SECTION_TYPE.FOOTER_GENERAL,
        text: "backoffice.terminals.footer",
        content: Footer
    },
    [SECTION_NAMES.SECOND_SCREEN]: {
        key: SECTION_NAMES.SECOND_SCREEN,
        sectionType: TERMINAL_SECTION_TYPE.SECOND_SCREEN,
        text: "backoffice.terminals.secondScreen",
        content: SecondScreen,
        info: "backoffice.terminals.secondScreenInfo",
    },
}

export const SECTIONS_DEFAULT_SELECTED_KEY = SECTION_PREDEFINED_DATA_BY_SECTION_NAMES[SECTION_NAMES.HEADER].key;

export const STYLES_EDITOR_MENU_ITEMS = {
    [SECTION_NAMES.COLORS]: {
        key: SECTION_NAMES.COLORS,
        text: "backoffice.terminals.colors",
        content: Colors,
    },

    [SECTION_NAMES.FONTS]: {
        key: SECTION_NAMES.FONTS,
        text: "backoffice.terminals.fonts",
        content: Fonts,
    },

    [SECTION_NAMES.LOGO]: {
        key: SECTION_NAMES.LOGO,
        text: "backoffice.terminals.logo",
        content: Logo
    }
}

export const STYLES_DEFAULT_SELECTED_KEY = SECTION_NAMES.COLORS // Colors

export const TERMINAL_HEADER_NAVIGATION_ITEM = {
    HOME: 1,
    SPORT: 2,
    VIRTUAL_GAME: 3,
    PROMOTIONS: 4,
    RESULTS: 5
}

export const TERMINAL_FEATURED_PRODUCTS = {
    SPORT: 1,
    VIRTUAL_GAME: 2,
    PROMOTIONS: 3,
    RESULTS: 4
}

export const TERMINAL_SPORTBOOKS_ITEM = {
    LIVE: "1"
}

export const TERMINAL_SPORTBOOKS_ITEM_TRANSLATIONS = {
    [TERMINAL_SPORTBOOKS_ITEM.LIVE]: "Live"
}

export const TERMINAL_VIRTUAL_SPORT_ITEM = {
    FOOTBALL_SINGLE_MATCH : 1,
    FOOTBALL_ENGLAND_LEAGUE : 2,
    HORSE_RACE_FLAT : 4,
    GREYHOUND_RACE : 8,
    FOOTBALL_AFRICAN_CUP : 16,
    KENO : 32,
    FOOTBALL_WORLD_CUP : 64,
    FOOTBALL_EUROPEAN_CUP : 128,
    FOOTBALL_CHAMPIONS_LEAGUE : 256,
    FOOTBALL_COPA_LIBERTADORES : 512,
    HORSE_RACE_STEEPLECHASING : 1024,
    PENALTY : 2048,
    // FOOTBALL_TURKISH_LEAGUE : 4096,
    // LUCKY_SIX : 8192,
}
 
export const TERMINAL_VIRTUAL_SPORT_ITEM_TRANSLATIONS = {
    [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_SINGLE_MATCH] : "Football Single Match",
    [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_ENGLAND_LEAGUE] : "English League",
    [TERMINAL_VIRTUAL_SPORT_ITEM.HORSE_RACE_FLAT] : "Horse Racing",
    [TERMINAL_VIRTUAL_SPORT_ITEM.GREYHOUND_RACE] : "Greyhound Racing",
    [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_AFRICAN_CUP] : "African Cup",
    [TERMINAL_VIRTUAL_SPORT_ITEM.KENO] : "Keno",
    [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_WORLD_CUP] : "World Cup",
    [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_EUROPEAN_CUP] : "European Cup",
    [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_CHAMPIONS_LEAGUE] : "Champions League",
    [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_COPA_LIBERTADORES] : "Copa Libertadores",
    [TERMINAL_VIRTUAL_SPORT_ITEM.HORSE_RACE_STEEPLECHASING] : "Steeplechase",
    [TERMINAL_VIRTUAL_SPORT_ITEM.PENALTY] : "Penalty Shootout",
    // [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_TURKISH_LEAGUE] : "Turkish League",
    // [TERMINAL_VIRTUAL_SPORT_ITEM.LUCKY_SIX] : "Lucky Six",
}

export const TERMINAL_FEATURED_PRODUCTS_BANNERS = {
    [TERMINAL_FEATURED_PRODUCTS.SPORT]: {
        path: "system/static/terminal/products/sport.png",
        items: {
            [TERMINAL_SPORTBOOKS_ITEM.LIVE]: "system/static/terminal/products/sport_live.png"
        }
    },
    [TERMINAL_FEATURED_PRODUCTS.VIRTUAL_GAME]: {
        path: "system/static/terminal/products/virtual.png",
        items: {
            [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_AFRICAN_CUP] : "system/static/terminal/products/virtual_african_cup.png",
            [TERMINAL_VIRTUAL_SPORT_ITEM.HORSE_RACE_STEEPLECHASING] : "system/static/terminal/products/virtual_steeplechase.png",
            [TERMINAL_VIRTUAL_SPORT_ITEM.GREYHOUND_RACE] : "system/static/terminal/products/virtual_greyhounds.png",
            [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_WORLD_CUP] : "system/static/terminal/products/virtual_world_cup.png",
            [TERMINAL_VIRTUAL_SPORT_ITEM.KENO] : "system/static/terminal/products/virtual_keno.png",
            [TERMINAL_VIRTUAL_SPORT_ITEM.HORSE_RACE_FLAT] : "system/static/terminal/products/virtual_horse_racing.png",
            [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_ENGLAND_LEAGUE] : "system/static/terminal/products/virtual_english_league.png",
            [TERMINAL_VIRTUAL_SPORT_ITEM.PENALTY] : "system/static/terminal/products/virtual_penalty_shootout.png",
            [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_EUROPEAN_CUP] : "system/static/terminal/products/virtual_european_cup.png",
            [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_COPA_LIBERTADORES] : "system/static/terminal/products/virtual_copa_libertadores.png",
            [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_CHAMPIONS_LEAGUE] : "system/static/terminal/products/virtual_champions_league.png",
            [TERMINAL_VIRTUAL_SPORT_ITEM.FOOTBALL_SINGLE_MATCH] : "system/static/terminal/products/virtual_football.png"
        }
    },
    [TERMINAL_FEATURED_PRODUCTS.PROMOTIONS]: {
        path: "system/static/terminal/products/promotion.png"
    },
    [TERMINAL_FEATURED_PRODUCTS.RESULTS]: {
        path: "system/static/terminal/products/results.png"
    }
}

export const PROMOTION_STATUS = {
    UPCOMING: 1,
    IN_PROGRESS: 2,
    FINISHED: 3
}

export const TERMINAL_FOOTER_ITEM_TYPE = {
    DATE_TIME: 0,
    TERMS_AND_CONDITIONS: 1,
    LANGUAGE: 2,
    AGE_RESTRICTION: 3
}

export const TERMINAL_FOOTER_ITEM_TYPE_TRANSLATIONS = {
    [TERMINAL_FOOTER_ITEM_TYPE.DATE_TIME]: "backoffice.terminals.dateAndTime",
    [TERMINAL_FOOTER_ITEM_TYPE.TERMS_AND_CONDITIONS]: "backoffice.terminals.termsAndConditions",
    [TERMINAL_FOOTER_ITEM_TYPE.LANGUAGE]: "backoffice.terminals.language",
    [TERMINAL_FOOTER_ITEM_TYPE.AGE_RESTRICTION]: "backoffice.terminals.ageRestriction"
}

export const TERMINAL_SECOND_SCREEN_ANIMATION_TYPE = {
    VERTICAL: 0,
    HORIZONTAL: 1
}