import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Col, Form, message, Row, Spin, Switch} from "antd";
import useUnsavedForm from "hooks/useUnsavedForm";
import {IMAGE_TYPE, UNSAVED_FORM_PAGE_TYPE} from "constants/common.constants";
import {binaryToFlags, flagsToBinary, isMobile, printElement} from "utils/common";
import {TICKET_BET_DETAILS, TICKET_GENERAL_DETAILS} from "constants/ticket.constants";
import {isFormChanged} from "utils/form";
import {getUser} from "utils/auth";
import MainFormDashboardLayout from "components/layouts/main/form";
import {hasPermission} from "utils/permissions";
import {PERMISSION_ACTION, PERMISSION_RESOURCE} from "constants/permissions.constants";
import ImageUploader from "components/common/imageUploader";
import ApiUrls from "constants/api.constants";
import Selecter from "components/common/selecter";
import TextAreaInput from "components/common/textAreaInput";
import Modal from "components/common/modal";
import {connect} from "react-redux";
import {
    deleteVoucherLogo,
    getVoucher,
    saveVoucher,
    setCurrentVoucher, setVoucherLogo
} from "store/actions/dashboard/cms/voucher/voucher.actions";
import VoucherPreviewComponent from "pages/cms/voucher/preview.component";
import {VOUCHER_GENERAL_DETAILS, VOUCHER_INFO_DETAILS} from "constants/voucher.contants";
import {getVouchersConfigs} from "pages/projects/edit/sections/terminal/api";
import {isNull} from "utils/javaScriptTypes";

const VoucherComponent = ({
    getVoucher,
    saveVoucher,
    setCurrentVoucher,
    setVoucherLogo,
    deleteVoucherLogo,
    isLoading,
    isSaving,
    globalProjectId,
    voucher,
    currentVoucher
}) => {
    const [voucherConfigs, setVoucherConfigs] = useState({});

    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [ticketPreview, setTicketPreview] = useState(false);

    const generalDetails = Form.useWatch("generalDetails", formInstance) || 0;
    const infoDetails = Form.useWatch("infoDetails", formInstance) || 0;

    const hasExpirationDurantion = !isNull(voucherConfigs.expirationDuration);

    const {
        setToUnsavedForms,
        removeFromUnsavedForms,
    } = useUnsavedForm(UNSAVED_FORM_PAGE_TYPE.TAB); // TODO: add one more unsaved form page type <page>

    /** Load company payout ticket */
    useEffect(() => {
        if (globalProjectId) {
            getVoucher();
            getVouchersConfigs()
                .then((value) => {
                    setVoucherConfigs(value);
                });
        }
    }, [globalProjectId]);

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            ...voucher,
        });
    }, [voucher]);

    useEffect(() => {
        if (isFormTouched) {
            setToUnsavedForms({ key: "voucher" });
        } else {
            removeFromUnsavedForms({ key: "voucher" });
        }
    }, [isFormTouched]);

    /** Fires when form submitted
     * @function
     * @memberOf VoucherComponent
     */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveVoucher({
                    ...data,
                });
                setIsFormTouched(false);
            }).catch((err) => { console.log(err) });
    }


    /** Check is form changed
     * @function
     * @param {object} formValues - form current values
     * @returns {boolean}
     * @memberOf VoucherComponent
     */
    const formChanged = () => {
        return isFormChanged(formInstance.getFieldsValue(), { ...voucher })
    }


    /** Fires when any field value is changed in TicketConfigComponent component
     * @function
     * @param {string} field - field name
     * @param {string} value - field value
     * @memberOf VoucherComponent
     */
    const onTicketChange = (field, val) => {
        if (field === "selecter") {
            const generalDetails = val.filter(value => value[0] === "g").map(value => Number(value.slice(1)));
            const infoDetails = val.filter(value => value[0] === "i").map(value => Number(value.slice(1)));

            setFieldsValue({
                "generalDetails": flagsToBinary(generalDetails),
                "infoDetails": flagsToBinary(infoDetails)
            });

            setCurrentVoucher({
                ...currentVoucher,
                "generalDetails": flagsToBinary(generalDetails),
                "infoDetails": flagsToBinary(infoDetails)
            });

            formInstance.validateFields();
        } else {
            setCurrentVoucher({
                ...currentVoucher,
                [field]: val
            });
        }
    }

    /** Fires when logo uploaded
     * @function
     * @param {object} logo - the uploaded logo
     * @memberOf VoucherComponent
     */
    const onUpload = logo => {
        const msg = logo?.message ?? null;
        msg && message.success(msg);
        setVoucherLogo(logo?.value)
    };

    /** Function to build logo path
     * @function
     * @param {object} logoId - the uploaded logo id
     * @memberOf VoucherComponent
     */
    const buildLogoPath = logo => {
        if(!logo) return null;
        const companyLongId = getUser()?.companyLongId ?? null;
        const projectLongId = getUser()?.projectLongId ?? null;
        return `${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/projects/${projectLongId}/images/${logo}_ticket_logo.png`
    };

    return (
        <MainFormDashboardLayout
            breadcrumbs={
                {
                    items: [
                        { title: t('backoffice.menu.voucher') }
                    ]
                }
            }
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_VOUCHER_TICKET, action: PERMISSION_ACTION.MODIFY }),
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }

            header={
                {
                    button: {
                        icon: 'icon-eye',
                        type: "primary",
                        onClick: () => setTicketPreview(true),
                        text: t("backoffice.voucher.preview"),
                        enabled: isMobile()
                    }
                }
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        enableBarcodePrinting: voucher.enableBarcodePrinting,
                        printVouchers: voucher.printVouchers,
                        generalDetails: binaryToFlags(Object.values(TICKET_GENERAL_DETAILS), voucher.generalDetails),
                        betDetails: binaryToFlags(Object.values(TICKET_BET_DETAILS), voucher.betDetails),
                        customText: voucher.customText
                    }}
                    onFieldsChange={() => setIsFormTouched(formChanged())}
                >
                    <div className="rt--payout-ticket rt--flex">
                        <div className="rt--payout-ticket-form rt--flex-equal rt--mr-24">
                            <Row gutter={[16, 0]}>
                                <Col span={isMobile() ? 24 : 8}>
                                    {
                                        !isLoading && (
                                            <div className="rt--mb-24">
                                                <ImageUploader
                                                    uploadUrl={`${import.meta.env.SYSTEM_API_URL}${ApiUrls.UPLOAD_VOUCHER_TICKET_LOGO}`}
                                                    defaultFile={voucher.ticketLogoId ? {
                                                        url: buildLogoPath(voucher.ticketLogoId),
                                                        status: "done",
                                                        percent: 100
                                                    } : null}
                                                    remove={hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_VOUCHER_TICKET, action: PERMISSION_ACTION.DELETE }) ? {
                                                        handler: deleteVoucherLogo
                                                    } : null}
                                                    data={{
                                                        projectId: globalProjectId
                                                    }}
                                                    fileBuilder={value => ({
                                                        url: buildLogoPath(value)
                                                    })}
                                                    acceptedFormats={[IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG]}
                                                    size={1024 * 1024}
                                                    disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_VOUCHER_TICKET, action: PERMISSION_ACTION.CREATE })}
                                                    onSuccess={onUpload}
                                                    updateProps={[voucher.ticketLogoId]}
                                                    title={t('backoffice.voucher.header')}
                                                />
                                            </div>
                                        )
                                    }
                                </Col>
                            </Row>
                            <h4
                                className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-big'>
                                {
                                    t('backoffice.voucher.generalDetails')
                                }
                            </h4>
                            <Row gutter={[16, 0]}>
                                <Col span={24} >
                                    <Form.Item noStyle name="generalDetails"></Form.Item>
                                    <Form.Item noStyle name="infoDetails"></Form.Item>

                                    <Selecter
                                        value={[
                                            ...binaryToFlags(Object.values(VOUCHER_GENERAL_DETAILS), generalDetails).map(value => 'g' + value),
                                            ...binaryToFlags(Object.values(VOUCHER_INFO_DETAILS), infoDetails).map(value => 'i' + value),
                                        ]}
                                        items={[
                                            { title: t('backoffice.voucher.betshopName'), value: 'g' + VOUCHER_GENERAL_DETAILS.BETSHOP_NAME },
                                            { title: t('backoffice.voucher.cashierUserName'), value: 'g' + VOUCHER_GENERAL_DETAILS.CASHIER_USERNAME },
                                            { title: t('backoffice.voucher.terminalName'), value: 'g' + VOUCHER_GENERAL_DETAILS.TERMINAL_NAME },
                                            { title: t('backoffice.voucher.voucherId'), value: 'g' + VOUCHER_GENERAL_DETAILS.VOUCHER_ID },
                                            { title: t('backoffice.voucher.voucherCode'), value: 'i' + VOUCHER_INFO_DETAILS.VOUCHER_CODE},
                                            { title: t('backoffice.voucher.voucherAmount'), value: 'i' + VOUCHER_INFO_DETAILS.VOUCHER_AMOUNT },
                                            { title: t('backoffice.voucher.issueDate'), value: 'i' + VOUCHER_INFO_DETAILS.PAYOUT_DATE },
                                            { title: t('backoffice.voucher.expirationDate'), value: 'i' + VOUCHER_INFO_DETAILS.EXPIRATION_DATE, disabled: !hasExpirationDurantion },
                                        ]}
                                        onChange={val => onTicketChange("selecter", val)}
                                        disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_VOUCHER_TICKET, action: PERMISSION_ACTION.MODIFY })}
                                    />
                                </Col>
                            </Row>
                            <h4
                                className='rt--form-section-title rt--title rt--mb-16 rt--mt-0 rt--font-bold rt--font-big'>
                                {
                                    t('backoffice.voucher.barcode')
                                }
                            </h4>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-14">
                                        <Form.Item
                                            name="enableBarcodePrinting"
                                            valuePropName="checked"
                                            className='rt--form-item-without-margin'
                                        >
                                            <Switch
                                                onChange={val => onTicketChange("enableBarcodePrinting", val)}
                                                disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_VOUCHER_TICKET, action: PERMISSION_ACTION.MODIFY })}
                                            />
                                        </Form.Item>
                                        <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.voucher.enableBrcode')}</label>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 0]}>
                                <Col xs={24} sm={24} xl={12} xxl={8}>
                                    <Form.Item
                                        label={t('backoffice.voucher.customText')}
                                        name="customText"
                                        rules={[{ max: 300, message: t('backoffice.validation.fieldInvalid') }]}
                                        className={'rt--general-form-item' + (!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_VOUCHER_TICKET, action: PERMISSION_ACTION.MODIFY }) ? " rt--form-item-disabled" : "")}
                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.voucher.customText')}`}
                                    >
                                        <TextAreaInput
                                            rows={4}
                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.voucher.customText')}`}
                                            maxLength={300}
                                            onChange={e => { onTicketChange("customText", e.target.value) }}
                                            disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_VOUCHER_TICKET, action: PERMISSION_ACTION.MODIFY })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <div className="rt--flex-inline rt--align-center rt--form-item-checkbox rt--mt-8">
                                        <Form.Item
                                            className='rt--form-item-inline rt--form-item-without-margin'
                                            name="printVoucherTickets"
                                            valuePropName='checked'
                                        >
                                            <Checkbox
                                                disabled={!hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_VOUCHER_TICKET, action: PERMISSION_ACTION.MODIFY })}
                                            />
                                        </Form.Item>
                                        <span className='rt--title rt--font-normal rt--font-regular rt--pl-8'>{t('backoffice.voucher.printVouchers')}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {
                            !isMobile() && (
                                <div className="rt--payout-ticket-preview">
                                    <div className="rt--payout-ticket-preview-inner">
                                        <VoucherPreviewComponent />
                                    </div>
                                    {
                                        hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_VOUCHER_TICKET, action: PERMISSION_ACTION.MODIFY }) && (
                                            <Button
                                                type="primary"
                                                className="rt--button rt--button-main rt--mt-16"
                                                onClick={() => printElement("rt--payout-ticket-preview")}
                                            >
                                                <i className="icon-print rt--font-bigest"></i>
                                                {t("backoffice.common.print")}
                                            </Button>
                                        )
                                    }
                                </div>
                            )
                        }


                    </div>


                </Form>
            </Spin>
            {
                ticketPreview && (
                    <Modal
                        title={t("backoffice.voucher.preview")}
                        onCancel={() => setTicketPreview(false)}
                    >
                        <div className="rt--payout-ticket-preview-inner">
                            <VoucherPreviewComponent />
                        </div>
                    </Modal>
                )
            }
        </MainFormDashboardLayout>
    );
};

const mapDispatchToProps = dispatch => ({
    getVoucher: () => {
        dispatch(getVoucher());
    },
    saveVoucher: ticket => {
        dispatch(saveVoucher(ticket))
    },
    setCurrentVoucher: ticket => {
        dispatch(setCurrentVoucher(ticket))
    },
    deleteVoucherLogo: () => {
        dispatch(deleteVoucherLogo())
    },
    setVoucherLogo: logo => {
        dispatch(setVoucherLogo(logo))
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.voucher.isLoading,
        isSaving: state.voucher.isSaving,
        currentVoucher: state.voucher.currentVoucher,
        voucher: state.voucher.voucher,
        globalProjectId: state.common.globalProjectId
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VoucherComponent);
