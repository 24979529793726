import React from "react";

import {AGENT_COMMISSION_STATE} from "constants/agent.constants";

import CommissionDetailsBaseRow from "../comissionDetailsBaseRow";
import calcCategoryCommissionPercent
	from "pages/agentSystem/agents/edit/sections/commission/components/agentCommissionDetailsForm/helpers/calcCategoryCommissionPercent";

const CommissionDetailsCategoryRow = ({ formInstance, label, name, category, parentCategory }) => {
	const handleStateChange = (value) => {
		const state = value ? AGENT_COMMISSION_STATE.ACTIVE : AGENT_COMMISSION_STATE.INACTIVE;

		formInstance.setFieldsValue({
			[category.id]: {
				commissionPercent: !value && 0,
				providers: category.providers.reduce((acc, provider) => {
					const parentProvider = parentCategory?.providers.find(parentProvider => parentProvider.id === provider.id)

					if (parentProvider && parentProvider.state === AGENT_COMMISSION_STATE.INACTIVE) {
						return acc;
					}

					acc[provider.id] = {
						state
					};

					return acc;
				}, {})
			}
		});

		if (state === AGENT_COMMISSION_STATE.INACTIVE) {
			formInstance.validateFields(
				category.providers.map((provider) => [category.id, "providers", provider.id, "commissionPercent"])
			);
		}

		if (value) {
			calcCategoryCommissionPercent({ formInstance, category });
		}
	};

	const handlePercentChange = (value) => {
		formInstance.setFieldsValue({
			[category.id]: {
				providers: category.providers.reduce((acc, provider) => {
					acc[provider.id] = {
						commissionPercent: value
					};

					return acc;
				}, {})
			}
		});
	};

	return (
		<CommissionDetailsBaseRow
			name={name}
			label={label}
			stateDisabled={parentCategory && parentCategory.state === AGENT_COMMISSION_STATE.INACTIVE}
			formInstance={formInstance}
			onPercentChange={handlePercentChange}
			onStateChange={handleStateChange}
		/>
	);
};

export default CommissionDetailsCategoryRow;
