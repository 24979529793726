import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Col, Row, Form } from 'antd';

import Modal from 'components/common/modal';
import NumericInput from 'components/common/numericInput';
import Select from 'components/common/select';

import { getTerminalSectionItems } from 'store/actions/dashboard/terminalCustomize/sections.action';

import { isFunction } from 'utils/common';
import { POPUP_SIZE } from 'constants/common.constants';
import { TERMINAL_SECOND_SCREEN_ANIMATION_TYPE, TERMINAL_SECTION_TYPE } from 'constants/terminalCustomize.constants';

const FORM_FIELD_NAMES = {
    ANIMATION_TYPE : "animationType",
    ANIMATION_SPEED: "animationSpeed"
}

const SettingsPopupComponent = ({
    onClose,
    onOk,
    isSaving,
    getTerminalSectionItems,
    data
}) => {
    const { t } = useTranslation();
    
    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    // Get Second Screen Settings
    useEffect(() => {
        getTerminalSectionItems(TERMINAL_SECTION_TYPE.SECOND_SCREEN_CONFIG);
    }, []);


    useEffect(() => {
        console.log({data})
        setFieldsValue({
            [FORM_FIELD_NAMES.ANIMATION_SPEED] : data.animationSpeed,
            [FORM_FIELD_NAMES.ANIMATION_TYPE] : data.animationType
        })
    }, [data])

    const handleForm = () => {
        validateFields()
            .then(fieldsValue => {
                if (isFunction(onOk)) {
                    onOk(fieldsValue);
                    onClose();
                }

            }).catch(ex => {
                console.log(ex)
            })
    }

    const validateMinMaxOfSpeed = (value) => {
        if (value === '') {
            return Promise.resolve();
        }

        if (Number(value) >= 1 && Number(value) <= 15) {
            return Promise.resolve();
        }

        return Promise.reject(
            t("backoffice.validation.mustBeBetween")
                .replace("%X%", 1)
                .replace("%Y%", 15)
        );
    }

    return (
        <Modal
            title={ t('backoffice.terminals.settings')}
            cancelText={t('backoffice.common.cancel')}
            okText={ t('backoffice.common.save') }
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALLEST}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.terminals.sliderAnimationType')} *`}
                            name={FORM_FIELD_NAMES.ANIMATION_TYPE}
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <Select
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.terminals.sliderAnimationType')}`}
                                options={[
                                    { value: TERMINAL_SECOND_SCREEN_ANIMATION_TYPE.HORIZONTAL, text: t("backoffice.terminals.horizontal")},
                                    { value: TERMINAL_SECOND_SCREEN_ANIMATION_TYPE.VERTICAL, text: t("backoffice.terminals.vertical")}
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.terminals.animationSpeed')} *`}
                            name={FORM_FIELD_NAMES.ANIMATION_SPEED}
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                () => ({
                                    validator: (_, value) => validateMinMaxOfSpeed(value)
                                })
                            ]}
                            validateFirst
                            className='rt--general-form-item rt--form-item-without-margin'
                        >
                            <NumericInput
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.terminals.animationSpeed')}`}
                                disableFormatting={true}
                                maxLength={2}
                            />
                        </Form.Item>
                        <small><i className='rt--text-light'>{t('backoffice.terminals.animationSpeedInfo')}</i></small>
                    </Col>
                    
                </Row>
            </Form>
        </Modal>
    );
}

/** SettingsPopupComponent propTypes
    * PropTypes
*/
SettingsPopupComponent.propTypes = {
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Fires on popup add button clicked */
    onOk: PropTypes.func,
    /** true when saving data */
    isSaving: PropTypes.bool,
    /** Redux state property, Second Screen settings */
    data: PropTypes.object,
    /** Redux action to get terminal Second Screen settings */
    getTerminalSectionItems: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
    getTerminalSectionItems: sectionType => {
        dispatch(getTerminalSectionItems(sectionType))
    },
});

const mapStateToProps = (state) => {
    return {
        data: state.terminalCustomize.sections[TERMINAL_SECTION_TYPE.SECOND_SCREEN_CONFIG].data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPopupComponent);
