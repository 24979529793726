import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import {USER_TYPE} from "constants/user.constants";

export const getBetshops = async agentId => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_BETSHOPS,
        method: Methods.GET,
        params: {
            agentId
        }
    })

    return response?.data?.value;
};

export const getCashiers = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_CASHIERS,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getBetshopManagers = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_BETSHOP_MANAGERS,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getTerminals = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_TERMINALS,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getProjects = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_PROJECTS,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getCompanies = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_COMPANIES,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getUsers = async (params={}) => {
    const data = {
		sendDeleted: params.sendDeleted
	}
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_USERS,
        method: Methods.GET,
        params: data
    })

    return response?.data?.value;
};

export const getAccessManagers = async () => {

	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_ACCESS_MANAGERS,
        method: Methods.GET,
    })

    return response?.data?.value;
};

export const getAgents = async (params={}) => {
	const data = {
		id: params.id,
		state: params.state,
		userRole: params.userRole,
		currencyCode: params.currencyCode,
		parentId: params.parentId,
		excludeAgent: params.excludeAgent ?? true
	}

	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_AGENTS,
        method: Methods.GET,
        params: data
    })

    return response?.data?.value;
};

export const getAgentPlayers = async () => {
	const response = await axios({
		url: ApiUrls.AUTOSUGGESTION_GET_AGENTS,
		method: Methods.GET,
		params: {
			userRole: USER_TYPE.AGENT_PLAYER
		}
	})

    return response?.data?.value;
}

export const getPlayers = async (params = {}) => {
	const data = {
		filterType: params.filterType,
		state: params.state,
		userNameOrId: params.userNameOrId
	}

	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_PLAYERS,
        method: Methods.GET,
        params: data
    })

    return response?.data?.value;
};

export const getPermissionGroups = async type => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_PERMISSION_GROUPS,
        method: Methods.GET,
        params: { type }
    })

    return response?.data?.value;
};

export const getControllers = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_CONTROLLERS,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getBanks = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_BANKS,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getCommissionPlans = async () => {
	const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_COMMISSION_PLANS,
        method: Methods.GET
    })

    return response?.data?.value;
};

export const getPermissionGroupAvailableUsers = async groupId => {
    const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_PERMISSION_GROUP_AVAILABLE_USERS,
        method: Methods.GET,
        params: {
            groupId
        }
    })

    return response?.data?.value;
};

export const getTopPlayers = async (params = {}) => {
	const data = {
		filterType: params.filterType,
		state: params.state,
		userNameOrId: params.userNameOrId
	}

    const response = await axios({
        url: ApiUrls.AUTOSUGGESTION_GET_PLAYERS_TOP,
        method: Methods.GET,
        params: data
    })

    return response?.data?.value;
};
