import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { Menu } from "antd";

import { setTerminalCustomizeLanguage } from "store/actions/dashboard/terminalCustomize/common.action";
import { getTerminalLanguages } from 'store/actions/dashboard/terminalCustomize/sections.action';

import Dropdown from "components/common/dropdown";

import { classNames } from 'utils/common';
import { sendToTerminalIframe } from 'utils/terminal';

import { TERMINAL_UPDATE_TYPE } from 'constants/terminalCustomize.constants';

/** Language Menu Item Component */
const LanguageMenuItem = ({
    onItemClick,
    isActive,
    customizeLanguage,
    setTerminalCustomizeLanguage,
    getTerminalLanguages,
    languages,
    defaultLanguageCode,
}) => {
    const { t } = useTranslation();

    // Load terminal languages
    useEffect(() => {
        getTerminalLanguages();
    }, []);

    const handleLanguageDropdownChange = (lang) => {
        if (lang === customizeLanguage) {
            return;
        }

        setTerminalCustomizeLanguage(lang);

        sendToTerminalIframe(TERMINAL_UPDATE_TYPE.LANGUAGE, {
            language: lang,
        });
    };

    useEffect(() => {
        const isSelectedLanguageDisabled = languages.some((languageData) => {
            const isSelected =
                languageData.languageCode.toLowerCase() ===
                customizeLanguage.toLowerCase();

            if (!isSelected) {
                return false;
            }

            if (!languageData.enabled) {
                return true;
            }
        });

        if (isSelectedLanguageDisabled) {
            setTerminalCustomizeLanguage(defaultLanguageCode);
        }
    }, [languages]);

    const enabledLanguages = useMemo(() => {
        return languages.filter((lang) => lang.enabled);
    }, [languages]);

    return (
        <div className="rt--customize-widget-main-menu-item-language">
            {enabledLanguages.length > 1 ? (
                <Dropdown
                    placement="bottomRight"
                    dropdownRender={() => (
                        <Menu
                            onClick={(e) =>
                                handleLanguageDropdownChange(e ? e.key : null)
                            }
                            items={enabledLanguages.map((lang) => ({
                                key: lang.languageCode,
                                className:
                                    lang.languageCode.toUpperCase() ===
                                    customizeLanguage.toUpperCase()
                                        ? "rt--dropdown-item-active"
                                        : "",
                                label: (
                                    <div className="rt--flex rt--align-center">
                                        <span className="rt--title rt--font-normal rt--font-regular">
                                            {t(
                                                `backoffice.languages.${lang.languageCode?.toLowerCase()}`
                                            )}
                                        </span>
                                    </div>
                                ),
                            }))}
                        />
                    )}
                >
                    <div className="rt--customize-header-languages-content rt--flex rt--flex-equal rt--justify-between rt--align-center">
                        <span className="rt--title rt--font-normal rt--font-bold rt--pr-4">
                            {t(
                                `backoffice.languages.${customizeLanguage?.toLowerCase()}`
                            )}
                        </span>

                        <i className="icon-down-small rt--font-bigest" />
                    </div>
                </Dropdown>
            ) : (
                <div className="rt--customize-header-languages-content rt--flex rt--flex-equal rt--justify-between rt--align-center">
                    <span className="rt--title rt--font-normal rt--font-bold rt--pr-4">
                        {t(
                            `backoffice.languages.${customizeLanguage?.toLowerCase()}`
                        )}
                    </span>
                </div>
            )}

            <div className="rt--customize-widget-main-menu-item-language-separator" />

            <div
                onClick={onItemClick}
                className={classNames(
                    "rt--customize-widget-main-menu-item-language-settings",
                    isActive &&
                        "rt--customize-widget-main-menu-item-language-settings-active"
                )}
            >
                <i className="icon-settings rt--font-bigest rt--customize-widget-main-menu-item-language-settings-icon" />
            </div>
        </div>
    );
};

/** LanguageMenuItem propTypes
 * PropTypes
*/
LanguageMenuItem.propTypes = {
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Redux action to set terminal customization language */
    setTerminalCustomizeLanguage: PropTypes.func,
    /** Redux action to get terminal languages */
    getTerminalLanguages: PropTypes.func,
    /** Redux state property, represents the array of prom languages  */
    languages: PropTypes.arrayOf(PropTypes.shape({
        languageCode: PropTypes.string,
        isActive: PropTypes.bool,
    })),
    /** Fire when settings icon is clicked */
    onItemClick: PropTypes.func,
    /** Indicates whether the element is active or not */
    isActive: PropTypes.bool,
    /** Redux state property, represents default language code */
    defaultLanguageCode: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        customizeLanguage: state.terminalCustomize.customizeLanguage,
        defaultLanguageCode: state.terminalCustomize.configuration.defaultLanguageCode,
        languages: state.terminalCustomize.languages,
    }
}

const mapDispatchToProps = dispatch => (
    {
        setTerminalCustomizeLanguage: lang => {
            dispatch(setTerminalCustomizeLanguage(lang))
        },

        getTerminalLanguages: () => {
            dispatch(getTerminalLanguages());
        }
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(LanguageMenuItem)