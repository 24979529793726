import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { sendToTerminalIframe } from "utils/terminal";

import { TERMINAL_SECTION_TYPE, TERMINAL_UPDATE_TYPE } from "constants/terminalCustomize.constants";

const SCREEN_TYPE = {
    MAIN: 1,
    SECONDARY: 2
}

const ScreenChooser = () => {

    const { t } = useTranslation();

    const [active, setActive] = useState(SCREEN_TYPE.MAIN);

    const handleClick = type => {
        setActive(type);
        sendToTerminalIframe(TERMINAL_UPDATE_TYPE.SCROLL_TO_SECTION, {
            sectionType: type === SCREEN_TYPE.MAIN ? TERMINAL_SECTION_TYPE.FEATURED_PRODUCTS : TERMINAL_SECTION_TYPE.SECOND_SCREEN
        })
    }

    return (
        <div className="rt--customize-screen-chooser rt--flex rt--align-center rt--justify-center rt--pt-4 rt--pb-4 rt--pl-4 rt--pr-4">
            <div 
                className="rt--customize-screen-chooser-item rt--pt-12 rt--pb-12 rt--pl-16 rt--pr-16" 
                data-active={active === SCREEN_TYPE.MAIN} 
                onClick={() => handleClick(SCREEN_TYPE.MAIN)}
            >
                <span className="rt--font-big rt--font-regular">{t("backoffice.terminals.mainScreen")}</span>
            </div>
            <div
                className="rt--customize-screen-chooser-item rt--pt-12 rt--pb-12 rt--pl-16 rt--pr-16" 
                data-active={active === SCREEN_TYPE.SECONDARY} 
                onClick={() => handleClick(SCREEN_TYPE.SECONDARY)}
            >
                <span className="rt--font-big rt--font-regular">{t("backoffice.terminals.secondaryScreen")}</span>
            </div>
        </div>
    )
}

export default ScreenChooser;