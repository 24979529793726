//#region react
import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
//#endregion

//#region actions
import {
    getProductReport,
    setProductReportFilters
} from 'store/actions/dashboard/reports/productReport.action';
//#endregion

//#region components
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
import TotalsComponent from 'components/common/totals';
import Filters from './filters.component';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useFormat from 'hooks/useFormat';
import useProjectType from "hooks/useProjectType";
import {useNavigate} from "react-router";
//#endregion

//#region utils
import {isMobile} from "utils/common";
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import { REPORT_TOTAL_TYPE } from 'constants/reports.constants';
import {EXPAND_TABLE_TYPES, FILTER_TYPE} from 'constants/common.constants';
import { getExpandColumns, getTableColumns } from './columns';
import Paths from "constants/path.constants";
//#endregion

//#region types
import productReportType from "types/reports/productReport.type";
import SearchWithSelect from "pages/reports/productReport/components/searchWithSelect";
import {FILTER_USER_TYPE} from "constants/user.constants";
//#endregion

const ROW_UNIQUE_KEY_PROP = "product";
const EXPAND_TABLE_ROW_UNIQUE_KEY = "providerId";
const PRODUCT_REPORT = "productReport";

/** Product Report Page Component */
const ProductReport = ({
    isLoading,
    report,
    filters,
    getProductReport,
    setProductReportFilters,
    globalProjectId
}) => {
    const [userType, setUserType] = useState(filters.type);

    const { t } = useTranslation();

    const { formatAmount } = useFormat();

    const { hasLiteMode } = useProjectType();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PRODUCT_REPORT });

    const reportCurrency = report.sportReport?.currencyCode || report.casinoReport?.totalReport?.currencyCode;

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        expandTableColumns,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            expandColumns: getExpandColumns,
            mainColumns: getTableColumns,
            constructForInclude: true,
            additionalProps: {
                formatAmount,
                hasLiteMode,
            }
        })
    }, [includedColumns, formatAmount])

    //#endregion

    const handleSearchChange = (value) => {
        setProductReportFilters({ ...filters, user: value });
    }

    const handleUserTypeChange = (value) => {
        setUserType(value);
        setProductReportFilters({
            ...filters,
            user: [value, filters.user[1]],
            type: FILTER_TYPE.NETWORK
        });
    }

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters selectedUserType={userType} />,
        search: (
            !isMobile() ? <SearchWithSelect value={filters.user} onChange={handleSearchChange} onUserTypeChange={handleUserTypeChange} /> : null
        ),
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.productReport') }]
        }
    }

    //#endregion

    const reportData = useMemo(() => {
        return [
            {
                product: "sport",
                betAmount: report.sportReport?.betAmount ?? 0,
                winAmount: report.sportReport?.winAmount ?? 0,
                ggr: report.sportReport?.ggr ?? 0,
                commissionPercent: report.sportReport?.commissionPercent,
                earning: report.sportReport?.earning,
                toPay: report.sportReport?.toPay,
                currencyCode: report.sportReport?.currencyCode,
                providersReport: [],
            },
            {
                product: "casino",
                betAmount: report.casinoReport?.totalReport?.betAmount ?? 0,
                winAmount: report.casinoReport?.totalReport?.winAmount ?? 0,
                ggr: report.casinoReport?.totalReport?.ggr ?? 0,
                commissionPercent: report.casinoReport?.totalReport?.commissionPercent,
                earning: report.casinoReport?.totalReport?.earning,
                toPay: report.casinoReport?.totalReport?.toPay ,
                currencyCode: report.casinoReport?.totalReport?.currencyCode,
                providersReport: report.casinoReport?.providersReport ?? []
            }
        ]
    }, [report])

    const reportTotalData = useMemo(() => {
        const data = {
            betAmount: report.total?.betAmount ?? 0,
            winAmount: report.total?.winAmount ?? 0,
            ggr: report.total?.ggr ?? 0,
            earning: report.total?.earning,
            toPay: report.total?.toPay,
            currencyCode: report.total?.currencyCode
        }

        if (!isMobile()) {
            Object.keys(data).forEach(key => {
                data[key] = formatAmount(data[key], reportCurrency)
            });
        }

        if(!isMobile()){
            data.earning = report.total?.earning ? formatAmount(report.total?.earning, reportCurrency) : "-"
            data.toPay = report.total?.toPay ? formatAmount(report.total?.toPay, reportCurrency) : "-";
        }

        return data
    }, [report, reportCurrency])

    return (
        <MainDashboardLayout
            header={headerPartsData}
            isContentScrollable={isMobile()}
            extraContent={
                isMobile() ?{
                    content: (
                        <TotalsComponent
                            totals={[reportTotalData]}
                            dependencies={[globalProjectId, filters]}
                            isLoading={isLoading}
                            totalType={REPORT_TOTAL_TYPE.PRODUCT_REPORT}
                        />
                    )
                } : undefined
            }
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={reportData}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                loadFn={getProductReport}
                updateProps={[globalProjectId]}
                total={reportData.length}
                filters={filters}
                setFiltersFn={setProductReportFilters}
                noPagination={true}
                disableFullView={true}
                expandable={{
                    type: EXPAND_TABLE_TYPES.TABLE,
                    details: rowData => ({
                        columns: expandTableColumns,
                        data: rowData?.providersReport,
                        uniqueKey: EXPAND_TABLE_ROW_UNIQUE_KEY,
                    }),
                    disabled: ["sport"]
                }}
                totals={{
                    data: reportTotalData,
                    title: t("backoffice.common.total")
                }}
            />
        </MainDashboardLayout>
    );
}

/** ProductReport propTypes
 * PropTypes
*/
ProductReport.propTypes = {
    /** Redux action to get product report */
    getProductReport: PropTypes.func,
    /** Redux state property, is true when loading product report */
    isLoading: PropTypes.bool,
    /** Redux state property, represents the product report  */
    report: productReportType,
    /** Redux state property, product report filters */
    filters: PropTypes.object,
    /** Redux action to set product report filters */
    setProductReportFilters: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
    getProductReport: () => {
        dispatch(getProductReport());
    },
    setProductReportFilters: filters => {
        dispatch(setProductReportFilters(filters));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.productReport.isLoading,
        report: state.productReport.report,
        filters: state.productReport.filters,
        globalProjectId: state.common.globalProjectId,
    };
};

const withPermCheck = (WrappedComponent) => (props) => {
    const { hasLiteMode } = useProjectType();
    const navigate = useNavigate();

    useEffect(() => {
        if (!hasLiteMode) {
            navigate(Paths.NEW_REPORTS_PRODUCT_REPORT);
        }
    }, [hasLiteMode, navigate]);

    if (!hasLiteMode) {
        return null;
    }

    return (
        <WrappedComponent {...props} />
    )
}

export default withPermCheck(connect(mapStateToProps, mapDispatchToProps)(ProductReport));
