//#region react
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

//#endregion

//#region libraries
import { Row, Col, Form } from 'antd';
//#endregion

//#region actions
import {
    getProductReport,
    setProductReportFilters
} from 'store/actions/dashboard/reports/productReport.action';

//#endregion

//#region components
import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import DateRangePicker from "components/common/dateRangePicker";
//#endregion

//#region constants
import { TIME_PICKER_MODES } from 'components/common/dateRangePicker/constants';
import { FILTER_TYPE } from 'constants/common.constants';
import {FILTER_USER_TYPE} from "constants/user.constants";
import SearchWithSelect from "pages/reports/productReport/components/searchWithSelect";
import {isMobile} from "utils/common";
//#endregion

const FILTERS_FIELD_NAMES = {
    USER: "user",
    DATE: "date",
    TYPE: "type"
}

/**Product Report Page Filters Component */
const Filters = ({
    selectedUserType,
    globalProjectId,
    filters,
    getProductReport,
    setProductReportFilters
}) => {
    const [userType, setUserType] = useState(selectedUserType);

    const { t } = useTranslation();

    const hideNetwork = userType === FILTER_USER_TYPE.PLAYER;

    useEffect(() => {
        setUserType(selectedUserType);
    }, [selectedUserType]);

    const handleUserTypeChange = (value) => {
        setUserType(value);
        setProductReportFilters({
            ...filters,
            user: [value, filters.user[1]],
            type: FILTER_TYPE.NETWORK
        });
    }

    return (
        <FiltersWrapper
            loadFn={getProductReport}
            setFiltersFn={setProductReportFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            searchFieldName={!isMobile() ? ["user"] : []}
            datePickerFields={[
                { name: FILTERS_FIELD_NAMES.DATE, time: true },
            ]}
        >
            <Row gutter={[16, 0]}>
                {isMobile() && (
                    <Col span={24}>
                        <Form.Item name={FILTERS_FIELD_NAMES.USER} label={t("backoffice.reports.usernameOrId")}>
                            <SearchWithSelect onUserTypeChange={handleUserTypeChange} />
                        </Form.Item>
                    </Col>
                )}

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.date')}
                        name={FILTERS_FIELD_NAMES.DATE}
                    >
                        <DateRangePicker
                            showTime={true}
                            timePickerMode={TIME_PICKER_MODES.ONLY_HOURS}
                            allowClear={false}
                            enabledMountsCount={12}
                        />
                    </Form.Item>
                </Col>

                {!hideNetwork && (
                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.performancereport.type')}
                            name={FILTERS_FIELD_NAMES.TYPE}
                            className="rt--form-item-without-margin"
                        >
                            <Select
                                disabled={filters.userType === FILTER_USER_TYPE.PLAYER}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.performancereport.type')}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                options={[
                                    {value: FILTER_TYPE.DIRECT, text: t('backoffice.performancereport.direct')},
                                    {value: FILTER_TYPE.NETWORK, text: t('backoffice.performancereport.network')},
                                ]}
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
        </FiltersWrapper>
    );
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Prop, selected user type */
    selectedUserType: PropTypes.number,
    /** Redux state property, product report filters */
    filters: PropTypes.object,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux action to set product report filters */
    setProductReportFilters: PropTypes.func,
    /** Redux action to get product report */
    getProductReport: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getProductReport: () => {
            dispatch(getProductReport());
        },
        setProductReportFilters: filters => {
            dispatch(setProductReportFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.productReport.filters,
        globalProjectId: state.common.globalProjectId,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
