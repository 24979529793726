import {
    SET_TERMINAL_CUSTOMIZE_LANGUAGE,
    SET_TERMINAL_CUSTOMIZE_ACTION_BEFORE,
    SET_TERMINAL_CUSTOMIZE_ACTION_FINISH,
    SET_TERMINAL_CUSTOMIZE_SAVE_ACTION_BEFORE,
    SET_TERMINAL_CUSTOMIZE_SAVE_ACTION_FINISH,
    SET_TERMINAL_CUSTOMIZE_PUBLISH_ACTION_BEFORE,
    SET_TERMINAL_CUSTOMIZE_PUBLISH_ACTION_FINISH,
    SET_TERMINAL_CUSTOMIZE_CONFIGURATION,
    SET_TERMINAL_CUSTOMIZE_COLORS,
    SET_TERMINAL_CUSTOMIZE_FONT_FAMILY,
    SET_TERMINAL_CUSTOMIZE_IMAGE,
    SET_TERMINAL_CUSTOMIZE_LANGUAGES,
    SET_TERMINAL_CUSTOMIZE_DEFAULT_LANGUAGES,
    SET_TERMINAL_CUSTOMIZE_SECTION_TRANSLATIONS,
    SET_TERMINAL_CUSTOMIZE_SECTION_DATA,
    UPDATE_TERMINAL_CUSTOMIZE_SECTION_TRANSLATIONS,
    SET_TERMINAL_CUSTOMIZE_SECTION_TRANSLATION_GROUPS
} from "../../actionTypes";
import { TERMINAL_FILE_NAMES } from "constants/terminalCustomize.constants";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_TERMINAL_CUSTOMIZE_LANGUAGE:
            return {
                ...state,
                customizeLanguage: payload.language
            }
        case SET_TERMINAL_CUSTOMIZE_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true
            }
        case SET_TERMINAL_CUSTOMIZE_ACTION_FINISH:
            return {
                ...state,
                isLoading: false
            }
        case SET_TERMINAL_CUSTOMIZE_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true
            }
        case SET_TERMINAL_CUSTOMIZE_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false
            }
        case SET_TERMINAL_CUSTOMIZE_PUBLISH_ACTION_BEFORE:
            return {
                ...state,
                isPublishing: true
            }
        case SET_TERMINAL_CUSTOMIZE_PUBLISH_ACTION_FINISH:
            return {
                ...state,
                isPublishing: false
            }
        case SET_TERMINAL_CUSTOMIZE_COLORS:
            return {
                ...state,
                colors: payload.colors
            }
        case SET_TERMINAL_CUSTOMIZE_CONFIGURATION:
            return {
                ...state,
                configuration: payload.configuration
            }
        case SET_TERMINAL_CUSTOMIZE_DEFAULT_LANGUAGES:
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    defaultLanguageCode: payload
                }
            }
        case SET_TERMINAL_CUSTOMIZE_FONT_FAMILY:
            return {
                ...state,
                fontFamily: payload.fontFamily
            }
        case SET_TERMINAL_CUSTOMIZE_IMAGE:
            if (!payload.sectionName) {
                return {
                    ...state,
                    configuration: {
                        ...state.configuration,
                        logoSection: {
                            ...state.configuration.logoSection,
                            files: {
                                ...state.configuration.logoSection.files,
                                [TERMINAL_FILE_NAMES[payload.fileType]]: payload.path
                            }
                        }
                    }
                }
            }
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    sections: {
                        ...state.configuration.sections,
                        [payload.sectionName]: {
                            ...state.configuration.sections[payload.sectionName],
                            files: {
                               ...state.configuration.sections[payload.sectionName].files,
                               [TERMINAL_FILE_NAMES[payload.fileType]]: payload.path
                            }
                        }
                    }
                }
            }
        case SET_TERMINAL_CUSTOMIZE_LANGUAGES:
            return {
                ...state,
                languages: payload
            }
        case SET_TERMINAL_CUSTOMIZE_SECTION_DATA:
            return {
                ...state,
                sections: {
                    ...state.sections,
                    [payload.sectionType]: {
                        ...state.sections[payload.sectionType],
                        data: payload.data,
                    }
                }
            }
        case SET_TERMINAL_CUSTOMIZE_SECTION_TRANSLATIONS:
            return {
                ...state,
                sections: {
                    ...state.sections,
                    [payload.sectionType]: {
                        ...state.sections[payload.sectionType],
                        translations: payload.translations,
                    }
                }
            }
        case UPDATE_TERMINAL_CUSTOMIZE_SECTION_TRANSLATIONS:
            return {
                ...state,
                sections: {
                    ...state.sections,
                    [payload.sectionType]: {
                        ...state.sections[payload.sectionType],
                        translations: {
                            ...state.sections[payload.sectionType].translations,
                            [payload.key]: payload.data
                        }
                    }
                }
            }
        case SET_TERMINAL_CUSTOMIZE_SECTION_TRANSLATION_GROUPS:
            return {
                ...state,
                translationGroups: payload.groups
            }
        default:
            return state;
    }
};
