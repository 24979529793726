import { useEffect, useRef } from "react";

const useInfiniteScroll = ({
    dataLoadFn,
    loadInitially = true,
    dependencies = [],
    preventFunctionality = false
}) => {
    const elementRef = useRef(null);

    const handleScroll = () => {
        if (!Boolean(elementRef.current)) {
            return;
        }

        const element = elementRef.current.closest(".rt--main-scrollable");
        if(element){
            if (Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 1) {
                dataLoadFn?.("next");
            }
        }

    }

    /** Load data */
    useEffect(() => {
        if(!loadInitially) {
            return;
        }

        // We need a delay in case of asynchronous update of some data in redux (filters, sorting...)
        setTimeout(() => dataLoadFn?.("first"), 20);
    }, dependencies);

    useEffect(() => {
        if(preventFunctionality || !elementRef.current) {
            return;
        }

        const element = elementRef.current.closest(".rt--main-scrollable");

        if(element){
            element.addEventListener('scroll', handleScroll);
        }

        return () => {
            if(element){
                element.removeEventListener('scroll', handleScroll);
            }
        }
    }, [elementRef.current, preventFunctionality])

    return elementRef;
}

export default useInfiniteScroll;
