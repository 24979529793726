import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    SET_TERMINAL_CUSTOMIZE_LANGUAGE,
    SET_TERMINAL_CUSTOMIZE_ACTION_BEFORE,
    SET_TERMINAL_CUSTOMIZE_ACTION_FINISH,
    SET_TERMINAL_CUSTOMIZE_SAVE_ACTION_BEFORE,
    SET_TERMINAL_CUSTOMIZE_SAVE_ACTION_FINISH,
    SET_TERMINAL_CUSTOMIZE_PUBLISH_ACTION_BEFORE,
    SET_TERMINAL_CUSTOMIZE_PUBLISH_ACTION_FINISH
} from "../../../actionTypes";

export const setTerminalCustomizeLanguage = language => ({
    type: SET_TERMINAL_CUSTOMIZE_LANGUAGE,
    payload: { language }
});

export const setTerminalCustomizeActionBefore = () => ({
    type: SET_TERMINAL_CUSTOMIZE_ACTION_BEFORE,
});

export const setTerminalCustomizeActionFinished = () => ({
    type: SET_TERMINAL_CUSTOMIZE_ACTION_FINISH,
});

export const setTerminalCustomizeSaveActionBefore = () => ({
    type: SET_TERMINAL_CUSTOMIZE_SAVE_ACTION_BEFORE,
});

export const setTerminalCustomizeSaveActionFinished = () => ({
    type: SET_TERMINAL_CUSTOMIZE_SAVE_ACTION_FINISH,
});

export const setTerminalCustomizePublishActionBefore = () => ({
    type: SET_TERMINAL_CUSTOMIZE_PUBLISH_ACTION_BEFORE,
});

export const setTerminalCustomizePublishActionFinished = () => ({
    type: SET_TERMINAL_CUSTOMIZE_PUBLISH_ACTION_FINISH,
});

export const publishTerminal = () => {
    return dispatch => {
        dispatch(setTerminalCustomizePublishActionBefore());
        return axios({
            url: ApiUrls.PUBLISH_TERMINAL,
            method: Methods.POST,
            data: {}
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
            } 
            dispatch(setTerminalCustomizePublishActionFinished());
        })
        .catch((ex) => {
            dispatch(setTerminalCustomizePublishActionFinished());
        })
    }
}
