import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import { Button, Spin } from 'antd';
import Table from 'components/common/table';

import BannerAddEditComponent from './banner-add-edit.component';
import SettingsPopupComponent from './settings-popup.component';

import Empty from '../empty';

import { TERMINAL_SECTION_TYPE } from 'constants/terminalCustomize.constants';

import {
    getTerminalSectionItems,
    addTerminalSecondScreenBanner,
    saveTerminalSecondScreenBanner,
    saveTerminalSecondScreenSettings,
    removeTerminalSectionItem,
    changeTerminalSectionItemState,
    reorderTerminalSectionItems
} from 'store/actions/dashboard/terminalCustomize/sections.action';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { classNames } from 'utils/common';

import { rowSortingHandler, sortByOrder } from '../../../helpers';

import { tableColumns } from './columns';


const SecondScreen = ({
    data,
    isLoading,
    isSaving,
    getTerminalSectionItems,
    addTerminalSecondScreenBanner,
    saveTerminalSecondScreenBanner,
    saveTerminalSecondScreenSettings,
    removeTerminalSectionItem,
    changeTerminalSectionItemState,
    reorderTerminalSectionItems,
    customizeLanguage,
}) => {
    const { t } = useTranslation();

    const [addEditPopupData, setAddEditPopupData] = useState({
        isVisible: false,
        data: null
    });

    const [settingsPopup, setSettingsPopup] = useState(false);

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showAddEditPopup = data => {
        setAddEditPopupData({
            isVisible: true,
            data
        })
    }

    const hideAddEditPopup = () => {
        setAddEditPopupData({
            isVisible: false,
            data: null
        })
    }

    const showSettingsPopup = () => {
        setSettingsPopup(true)
    }

    const hideSettingsPopup = () => {
        setSettingsPopup(false)
    }

    const handleSecondScreenBannerAddEdit = data => {
        if(addEditPopupData.data?.mode === "edit"){
            handleSecondScreenBannerEdit(data)
        } else {
            handleSecondScreenBannerAdd(data)
        }
    }

    const handleSecondScreenBannerAdd = data => {
        addTerminalSecondScreenBanner(data);
    }

    const handleSecondScreenBannerEdit = data => {
        saveTerminalSecondScreenBanner(data);
    }

    const handleSecondScreenSettingsSave = data => {
        saveTerminalSecondScreenSettings(data);
    }

    const handleSecondScreenBannerDelete = record => {
        removeTerminalSectionItem(TERMINAL_SECTION_TYPE.SECOND_SCREEN, record.rowId)
    }

    const handleTableSwitcherChange = (isChecked, record) => {
        changeTerminalSectionItemState(TERMINAL_SECTION_TYPE.SECOND_SCREEN, record.rowId, isChecked)
    }

    const handleRowSort = (from, to) => {
        const orders = rowSortingHandler(from, to, tableData);
        reorderTerminalSectionItems(TERMINAL_SECTION_TYPE.SECOND_SCREEN, orders)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ 
            mainColumns: tableColumns,
        });
    }, [])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [
        {
            title: t("backoffice.common.edit"),
            icon: "icon-edit",
            onClick: record => {
                showAddEditPopup({
                    ...record,
                    mode: "edit"
                })
            }
        },
        {
            title: t("backoffice.common.delete"),
            icon: "icon-trash",
            onClick: handleSecondScreenBannerDelete,
            disabled: record => !record.deletable
        },
    ]

    //#endregion

    // Get Second Screen Banners
    useEffect(() => {
        getTerminalSectionItems(TERMINAL_SECTION_TYPE.SECOND_SCREEN);
    }, [customizeLanguage]);

    const tableData = useMemo(() => sortByOrder(data), [data])

    return (
        <Spin spinning={isLoading || isSaving}>
             <div className='rt--flex rt--justify-end rt--mb-16'>
                <Button
                    type="primary"
                    className="rt--button rt--button-main"
                    onClick={() => showAddEditPopup({
                        mode: "add"
                    })}
                    disabled={data.length >= 20}
                    icon={<i className='icon-plus rt--font-bigest' />}
                >
                    <span className='rt--font-normal'>
                        {t('backoffice.terminals.addBanner')}
                    </span>
                </Button>

                <div
                    className={
                        classNames(
                            "rt--ml-16 rt--customize-second-screen-settings-button",
                            settingsPopup && "rt--customize-second-screen-settings-button-active"
                        )
                    }
                    onClick={showSettingsPopup}
                >
                    <i className='icon-settings rt--font-bigest' />
                </div>
            </div>

            <div className="rt--customize-table">
                <Table
                    data={tableData}
                    renderEmpty={() => (
                        <Empty 
                            text={t('backoffice.terminals.noBanners')}
                            description={t('backoffice.terminals.addBanner')}
                        />
                    )}
                    columns={mainTableColumns}
                    noPagination={true}
                    actions={tableRowActions}
                    switcher={{
                        valueIndex: "enabled",
                        dataIndex: "enabled",
                        disabled: record => !record.editable,
                        onChange: handleTableSwitcherChange,
                    }}
                    uniqueKey="rowId"
                    sortable={true}
                    sortableData={tableData}
                    onRowSort={handleRowSort}
                />

                {addEditPopupData.isVisible && (
                    <BannerAddEditComponent
                        onClose={hideAddEditPopup}
                        onOk={handleSecondScreenBannerAddEdit}
                        data={addEditPopupData.data}
                        isSaving={isSaving}
                    />
                )}

                {settingsPopup && (
                    <SettingsPopupComponent
                        onClose={hideSettingsPopup}
                        onOk={handleSecondScreenSettingsSave}
                        isSaving={isSaving}
                    />
                )}
            </div>
        </Spin>
    )
}

/** SecondScreen propTypes
 * PropTypes
*/
SecondScreen.propTypes = {
    data: PropTypes.array,
    /** Redux state property, is true when loading terminal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when terminal saving */
    isSaving: PropTypes.bool,
    /** Redux action to get terminal Second Screen banners */
    getTerminalSectionItems: PropTypes.func,
    /** Redux action to add terminal Second Screen banner */
    addTerminalSecondScreenBanner: PropTypes.func,
    /** Redux action to save terminal Second Screen banner */
    saveTerminalSecondScreenBanner: PropTypes.func,
    /** Redux action to save terminal Second Screen settings */
    saveTerminalSecondScreenSettings: PropTypes.func,
    /** Redux action to remove terminal section item */
    removeTerminalSectionItem: PropTypes.func,
    /** Redux action to change terminal section item state */
    changeTerminalSectionItemState: PropTypes.func,
    /** Redux action to reorder terminal section items */
    reorderTerminalSectionItems: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
    getTerminalSectionItems: sectionType => {
        dispatch(getTerminalSectionItems(sectionType))
    },
    addTerminalSecondScreenBanner: requestBody => {
        dispatch(addTerminalSecondScreenBanner(requestBody))
    },
    saveTerminalSecondScreenBanner: requestBody => {
        dispatch(saveTerminalSecondScreenBanner(requestBody))
    },
    saveTerminalSecondScreenSettings: requestBody => {
        dispatch(saveTerminalSecondScreenSettings(requestBody))
    },
    removeTerminalSectionItem: (sectionType, rowId) => {
        dispatch(removeTerminalSectionItem(sectionType, rowId))
    },
    changeTerminalSectionItemState: (sectionType, rowId, enabled) => {
        dispatch(changeTerminalSectionItemState(sectionType, rowId, enabled))
    },
    reorderTerminalSectionItems: (sectionType, rowOrders) => {
        dispatch(reorderTerminalSectionItems(sectionType, rowOrders))
    }
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.terminalCustomize.customizeLanguage,
        isLoading: state.terminalCustomize.isLoading,
        isSaving: state.terminalCustomize.isSaving,
        data: state.terminalCustomize.sections[TERMINAL_SECTION_TYPE.SECOND_SCREEN].data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondScreen);