import React, { useMemo, useRef } from 'react';

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import useFormat from "hooks/useFormat";
import useFirstRender from "hooks/useFirstRender";
import useAutosuggestion from "hooks/useAutosuggestion";
import useIncludedColumns from "hooks/useIncludedColumns";
import useDate from 'hooks/useDate';

import { getUser } from "utils/auth";
import { hasPermission } from "utils/permissions";
import { getGlobalProjectData, isMobile } from "utils/common";
import { tableColumnsCreator } from "utils/tableColumnsCreator";

import { getTableColumns } from "./columns";

import Filters from "./filters.component";
import Table from "components/common/table";
import TotalsComponent from "components/common/totals";
import TabTableDashboardLayout from "components/layouts/tab/table";

import ApiUrls from "constants/api.constants";
import { USER_ROLE } from "constants/user.constants";
import { REPORT_TOTAL_TYPE } from "constants/reports.constants";
import { REAL_TIME_SPORT_BETS } from "constants/pageName.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

import sortingType from "types/common/sorting.type";
import betsTotalsType from "types/bet/betsTotals.type";
import userInfoType from "types/profile/userInfo.type";
import realTimeCasinoBetType from "types/bet/realTimeCasinoBet.type";

import {
    getRealTimeCasinoBets,
    setRealTimeCasinoBetsFilters,
    setRealTimeCasinoBetsSorting
} from "store/actions/dashboard/betHistory/realTimeSportBets.action";
import useProjectType from "hooks/useProjectType";

const CasinoBets = ({
    bets,
    totals,
    globalProjectId,
    getRealTimeCasinoBets,
    setRealTimeCasinoBetsSorting,
    setRealTimeCasinoBetsFilters,
    sorting,
    filters,
    isLoading,
    total,
    userInfo,
}) => {
    const { t } = useTranslation();

    const { role } = getUser();
    const isAdmin = role === USER_ROLE.ADMIN;

    const { formatAmount } = useFormat();
    const { hasLiteMode } = useProjectType();

    const { dateService } = useDate();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: REAL_TIME_SPORT_BETS });

    const [agentNames] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        dependencies: [globalProjectId],
        actionParams: { excludeAgent: role === USER_ROLE.ADMIN }
    });

    const { name: globalProjectName } = getGlobalProjectData(globalProjectId)

    const possibleParents = [...agentNames];

    if (isAdmin) {
        possibleParents.unshift({
            name: globalProjectName,
            id: globalProjectId
        })
    }

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETS_ONLINE,
        action: PERMISSION_ACTION.EXPORT
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (value, item, eventType) => {
        let playerValue = value;

        if (item && eventType === "select") {
            playerValue = item.longId;
        }

        setRealTimeCasinoBetsFilters({
            ...filters,
            playerUserNameOrId: playerValue
        });
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                hasLiteMode,
                formatAmount,
            }
        })
    }, [includedColumns, formatAmount])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters
            getRealTimeCasinoBets={() => getRealTimeCasinoBets("first")}
        />,
        search: {
            placeholder: t("backoffice.bets.playerUsernameOrId"),
            loadFn: getRealTimeCasinoBets,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.PLAYER,
                dependencies: [globalProjectId],
                autoGet: false,
                searchFieldName: "userNameOrId"
            },
            onSearch: handleSearchChange,
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        }
    }

    const generateExportFilters = () => {
        const exportFilters = { ...filters };
        delete exportFilters.day;
        delete exportFilters.time;

        let from = dateService.mergeDateAndTime(filters.day, filters.time[0]);
        let to = dateService.mergeDateAndTime(filters.day, filters.time[1]);

        from = dateService.toISOString(from);
        to = dateService.toISOString(to);

        exportFilters.from = from;
        exportFilters.to = to;

        return exportFilters;
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.realTimeCasinoBets"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_REAL_TIME_CASINO_BETS,
            filters: generateExportFilters()
        }
    }

    //#endregion

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            isContentScrollable={isMobile()}
            extraContent={(
                <TotalsComponent
                    totals={totals}
                    isLoading={isLoading}
                    currencyCode={filters.currencyCode}
                    totalType={REPORT_TOTAL_TYPE.PLAYER_BETS}
                />
            )}
        >
            <Table
                enableReload
                loading={isLoading}
                columns={mainTableColumns}
                data={bets}
                loadFn={getRealTimeCasinoBets}
                sorting={sorting}
                filters={filters}
                setFiltersFn={setRealTimeCasinoBetsFilters}
                setSortingFn={setRealTimeCasinoBetsSorting}
                total={total}
                updateProps={[globalProjectId]}
            />
        </TabTableDashboardLayout>
    );
}

/** CasinoBets propTypes
 * PropTypes
 */
CasinoBets.propTypes = {
    /** Redux state property, represents the array of real time sport bets */
    bets: PropTypes.arrayOf(realTimeCasinoBetType),
    /** Redux state property, represents the array of real time sport bets totals */
    totals: betsTotalsType,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, real time sport bets sorting details */
    sorting: sortingType,
    /** Redux state property, real time sport bets filters */
    filters: PropTypes.object,
    /** Redux state property, real time sport bets total count */
    total: PropTypes.number,
    /** Redux action to get real time sport bets */
    getRealTimeCasinoBets: PropTypes.func,
    /** Redux action to set real time sport bets sorting details */
    setRealTimeCasinoBetsSorting: PropTypes.func,
    /** Redux action to set real time sport bets filters */
    setRealTimeCasinoBetsFilters: PropTypes.func,
    /** Redux state property, is true when loading bet history */
    isLoading: PropTypes.bool,
    /** Redux state property, the user info */
    userInfo: userInfoType
}

const mapDispatchToProps = dispatch => (
    {
        getRealTimeCasinoBets: (nextPage, agentId) => {
            dispatch(getRealTimeCasinoBets(nextPage, agentId));
        },
        setRealTimeCasinoBetsSorting: sorting => {
            dispatch(setRealTimeCasinoBetsSorting(sorting));
        },
        setRealTimeCasinoBetsFilters: filters => {
            dispatch(setRealTimeCasinoBetsFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        globalProjectId: state.common.globalProjectId,
        isLoading: state.betHistory.isLoading,
        total: state.betHistory.realTimeBets.casinoBets.total,
        bets: state.betHistory.realTimeBets.casinoBets.bets,
        totals: state.betHistory.realTimeBets.casinoBets.totals,
        sorting: state.betHistory.realTimeBets.casinoBets.sorting,
        filters: state.betHistory.realTimeBets.casinoBets.filters,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CasinoBets);
