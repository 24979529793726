import i18n from 'translations/config';
import dateService from "utils/date";

import { 
    PROMOTION_STATUS,
    TERMINAL_FEATURED_PRODUCTS,
    TERMINAL_FEATURED_PRODUCTS_BANNERS,
    TERMINAL_HEADER_NAVIGATION_ITEM, 
    TERMINAL_SECTION_TYPE,
    TERMINAL_SPORTBOOKS_ITEM_TRANSLATIONS, 
    TERMINAL_VIRTUAL_SPORT_ITEM_TRANSLATIONS
} from "constants/terminalCustomize.constants"

export const makeImagePath = path => {
    if(!path) return;

    return `${import.meta.env.SYSTEM_CDN_URL}/${path}`.toLowerCase();
}

export const makeProductName = (item, sectionType) => {
    if(sectionType === TERMINAL_SECTION_TYPE.HEADER){
        switch(item.type){
            case TERMINAL_HEADER_NAVIGATION_ITEM.HOME:
                return i18n.t("backoffice.terminals.home");
            case TERMINAL_HEADER_NAVIGATION_ITEM.PROMOTIONS:
                return i18n.t("backoffice.terminals.promotions");
            case TERMINAL_HEADER_NAVIGATION_ITEM.RESULTS:
                return i18n.t("backoffice.terminals.sportResults");
            case TERMINAL_HEADER_NAVIGATION_ITEM.SPORT:
                if(item.product){
                    return TERMINAL_SPORTBOOKS_ITEM_TRANSLATIONS[item.product]
                }
                return i18n.t("backoffice.terminals.sport")
            case TERMINAL_HEADER_NAVIGATION_ITEM.VIRTUAL_GAME:
                if(item.product){
                    return TERMINAL_VIRTUAL_SPORT_ITEM_TRANSLATIONS[item.product]
                }
                return i18n.t("backoffice.terminals.virtualSport");
            default:
                return null; 
        }
    } else if(sectionType === TERMINAL_SECTION_TYPE.FEATURED_PRODUCTS){
        switch(item.type){
            case TERMINAL_FEATURED_PRODUCTS.PROMOTIONS:
                return i18n.t("backoffice.terminals.promotions");
            case TERMINAL_FEATURED_PRODUCTS.RESULTS:
                return i18n.t("backoffice.terminals.sportResults");
            case TERMINAL_FEATURED_PRODUCTS.SPORT:
                if(item.product){
                    return TERMINAL_SPORTBOOKS_ITEM_TRANSLATIONS[item.product]
                }
                return i18n.t("backoffice.terminals.sport")
            case TERMINAL_FEATURED_PRODUCTS.VIRTUAL_GAME:
                if(item.product){
                    return TERMINAL_VIRTUAL_SPORT_ITEM_TRANSLATIONS[item.product]
                }
                return i18n.t("backoffice.terminals.virtualSport");
            default:
                return null; 
        }
    }
}

export const makeProviderName = (item, sectionType) => {
    if(sectionType === TERMINAL_SECTION_TYPE.HEADER){
        switch(item.type){
            case TERMINAL_HEADER_NAVIGATION_ITEM.SPORT:
                return i18n.t("backoffice.terminals.sport");
            case TERMINAL_HEADER_NAVIGATION_ITEM.VIRTUAL_GAME:
                return i18n.t("backoffice.terminals.virtualSport");
            default:
                return null; 
        }
    } else if(sectionType === TERMINAL_SECTION_TYPE.FEATURED_PRODUCTS){
        switch(item.type){
            case TERMINAL_FEATURED_PRODUCTS.SPORT:
                return i18n.t("backoffice.terminals.sport");
            case TERMINAL_FEATURED_PRODUCTS.VIRTUAL_GAME:
                return i18n.t("backoffice.terminals.virtualSport");
            default:
                return null; 
        }
    }
}

export const makeProductBanner = product => {
    let path = null;
    if(product.file){
        path = product.file;
    } else {
        switch (product.type) {
            case TERMINAL_FEATURED_PRODUCTS.PROMOTIONS:
                path = TERMINAL_FEATURED_PRODUCTS_BANNERS[TERMINAL_FEATURED_PRODUCTS.PROMOTIONS].path;
                break;
            case TERMINAL_FEATURED_PRODUCTS.RESULTS:
                path = TERMINAL_FEATURED_PRODUCTS_BANNERS[TERMINAL_FEATURED_PRODUCTS.RESULTS].path;
                break;
            case TERMINAL_FEATURED_PRODUCTS.SPORT:
                if(product.product === null){
                    path = TERMINAL_FEATURED_PRODUCTS_BANNERS[TERMINAL_FEATURED_PRODUCTS.SPORT].path;
                } else {
                    path = TERMINAL_FEATURED_PRODUCTS_BANNERS[TERMINAL_FEATURED_PRODUCTS.SPORT].items[product.product]
                }
                break;
            case TERMINAL_FEATURED_PRODUCTS.VIRTUAL_GAME:
                if(product.product === null){
                    path = TERMINAL_FEATURED_PRODUCTS_BANNERS[TERMINAL_FEATURED_PRODUCTS.VIRTUAL_GAME].path;
                } else {
                    path = TERMINAL_FEATURED_PRODUCTS_BANNERS[TERMINAL_FEATURED_PRODUCTS.VIRTUAL_GAME].items[product.product]
                }
                break;
            default:
                break;
        }
    }
    
    return makeImagePath(path)
}

export const makeHeaderMenuItemIcon = item => {
    switch (item.type){
        case TERMINAL_HEADER_NAVIGATION_ITEM.PROMOTIONS:
            return "icon-promotions";
        case TERMINAL_HEADER_NAVIGATION_ITEM.HOME:
            return "icon-terminalHome";
        case TERMINAL_HEADER_NAVIGATION_ITEM.RESULTS:
            return "icon-sport";
        case TERMINAL_HEADER_NAVIGATION_ITEM.SPORT:
            return "icon-sport";
        case TERMINAL_HEADER_NAVIGATION_ITEM.VIRTUAL_GAME:
            return "icon-virtualSport";
        default:
            return null;
    }
}

export const getPromotionStatus = promotion => {
    const {startDate, endDate} = promotion;
    if(dateService.isAfter(dateService.makeUtcDateToLocal(startDate), dateService.getNow()) ){
        return PROMOTION_STATUS.UPCOMING;
    } else if(dateService.isBefore(dateService.makeUtcDateToLocal(endDate), dateService.getNow()) ){
        return PROMOTION_STATUS.FINISHED;
    } else {
        return PROMOTION_STATUS.IN_PROGRESS;
    }
}

export const sortByOrder = data => {
    const result = [...data];
    result.sort((item1, item2) => item1.order < item2.order ? -1 : 1)
    return result;
}

export const rowSortingHandler = (from, to, data, disableFn) => {
    const orders = data.reduce((acc, element) => {
        if(!disableFn || !disableFn(element)){
            acc[element.rowId] = Number(element.order);
        }

        return acc;
    }, {})
    const toPrevIndex = orders[to];
    Object.keys(orders).map(key => {
        if(key === from){
            orders[key] = toPrevIndex;
        } else if(orders[key] >= toPrevIndex){
            orders[key]++
        }
    })
    return orders;
}