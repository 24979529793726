import React from "react";
import { Outlet } from "react-router-dom";

import Header from "./header";
import LogoutModal from "components/common/logoutModal";

const CustomizeLayout = () => {

    return (
        <div className="rt--dashboard-layout">
            <div className='rt--customize-layout rt--flex rt--flex-col'>
                <Header />
                <div className='rt--customize-layout-content rt--flex-equal rt--pb-24 rt--pt-24 rt--pl-16 rt--pr-16'>
                    <Outlet />
                </div>
            </div>
            <LogoutModal />
        </div>

    )
}

export default CustomizeLayout;