export const FILTER_STATUS = {
    ALL : null,
    ACTIVE: "true",
    INACTIVE: "false"
}

export const FILTER_TEST_STATE = {
    ALL : null,
    TESTING : "true",
    NOTTESTING: "false"
}

export const FILTER_PLAYER_STATE = {
    ALL: "",
    ACTIVE: "1",
    BLOCKED: "2",
    PARTIALY_BLOCKED: "4",
    UNKNOWN: "8"
}

export const FILTER_USER_STATE = {
    ALL: "",
    IN_PROGRESS: "1",
    ACTIVE: "2",
    BLOCKED: "4",
    EXPIRED: "8",
    DELETED: "16"
}

export const FILTER_USER_ONLINE_STATE = {
    ALL: "",
    ONLINE: "1",
    OFFLINE: "2"
}

export const FILTER_WALLET_TRANSACTION_TYPE = {
    ALL: "",
    BET: "1",
    CANCEL_BET : "2",
    PAIDOUT: "3"
}

export const FILTER_BET_STATE = {
    ALL : "",
    WON: "4",
    LOST: "32",
    CANCELLED: "64",
    REJECTED_BY_PROVIDER: "128"
}

export const FILTER_PROVIDER = {
    ALL : "",
    SPORTBOOK: "1",
    VIRTUAL_SPORTS: "2",
    GOLDEN_RACE: "4"
}

export const FILTER_SHOW_IN_CURRENCY = {
    ORIGINAL: 1,
    CONVERTED: 2
}

export const FILTER_COUNTRY = {
    ALL: [],
    NONE: "none"
}

export const FILTER_REAL_TIME_SPORT_BET_DATA_TYPE = {
    BET_DATE_TIME: 1,
    CALCULATED_DATE_TIME: 2
}

export const BETSLIP_FILTER_TYPE = {
    BET_TIME: 1,
    CALCULATION_TIME: 2,
    PAYOUT_TIME: 3
}
