export const tableColumns = [
    {
        title: "backoffice.terminals.name",
        dataIndex: "name"
    },
    {
        title: "backoffice.terminals.image",
        dataIndex: "file",
        render: value => {
            return value ? (
                <div className="rt--table-col-img rt--mt-6 rt--mb-6">
                    <img src={`${import.meta.env.SYSTEM_CDN_URL}/${value}`.toLowerCase()} alt="attachment" />
                </div>
            ) : "-"
        },
    },
    {
        title: "backoffice.terminals.url",
        dataIndex: "url",
        render: value => value ?? "-"
    },
]