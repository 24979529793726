import React from "react";

import VoucherComponent from "pages/cms/voucher/voucher.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const VoucherRoute = () => {
    return <VoucherComponent />;
};

export default withPermission(
    withAuth(VoucherRoute),
    {
        resource: PERMISSION_RESOURCE.PROJECT_VOUCHER_TICKET,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DEFAULT
);
