import React from 'react';
import PropTypes from 'prop-types';

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Tabs, Spin } from 'antd';

import FooterCertificates from './certificates';
import FooterGeneral from './general';

import { sendToTerminalIframe } from 'utils/terminal';

import { TERMINAL_SECTION_TYPE, TERMINAL_UPDATE_TYPE } from 'constants/terminalCustomize.constants';

const Footer = ({
    isLoading,
    isSaving
}) => {
    const { t } = useTranslation();

    return (
        <Spin spinning={isLoading || isSaving}>
            <Tabs 
                animated={false} 
                className='rt--tabs'
                onTabClick={sectionType => {
                    sendToTerminalIframe(TERMINAL_UPDATE_TYPE.SCROLL_TO_SECTION, {
                        sectionType: sectionType
                    })
                }}
                items={[
                    { 
                        key: TERMINAL_SECTION_TYPE.FOOTER_GENERAL, 
                        label: t("backoffice.terminals.general"),
                        children: <FooterGeneral />
                    },
                    { 
                        key: TERMINAL_SECTION_TYPE.FOOTER_CERTIFICATES, 
                        label: t("backoffice.terminals.certificates"),
                        children: <FooterCertificates />
                    }
                ]}
            />
        </Spin>
    );
}


/** Footer propTypes
 * PropTypes
*/
Footer.propTypes = {
    /** Redux state property, is true when loading terminal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when terminal saving */
    isSaving: PropTypes.bool
};


const mapStateToProps = (state) => {
    return {
        isLoading: state.terminalCustomize.isLoading,
        isSaving: state.terminalCustomize.isSaving,
    };
};

export default connect(mapStateToProps, null)(Footer);