
import axios from "axios";

import {
    setTerminalCustomizeActionBefore,
    setTerminalCustomizeActionFinished,
    setTerminalCustomizeLanguage
} from "./common.action";

import {
    SET_TERMINAL_CUSTOMIZE_CONFIGURATION,
} from "store/actionTypes";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

const setTerminalConfiguration = configuration => ({
    type: SET_TERMINAL_CUSTOMIZE_CONFIGURATION,
    payload: { configuration }
})

export const getTerminalConfiguration = (projectId, customizeLanguage, onSuccess) => {
    return dispatch => {
        dispatch(setTerminalCustomizeActionBefore());

        const params = {
            languageCode: customizeLanguage?.toUpperCase(),
            projectId
        };

        return axios({
            url: ApiUrls.GET_TERMINAL_CONFIGURATION,
            method: Methods.GET,
            params: params
        })
            .then(({ data: { value: configuration } }) => {
                dispatch(setTerminalConfiguration(configuration));
                dispatch(setTerminalCustomizeLanguage(customizeLanguage ? customizeLanguage : configuration.defaultLanguageCode))
                dispatch(setTerminalCustomizeActionFinished());
            })
            .catch((ex) => {
                dispatch(setTerminalCustomizeActionFinished());
            })
    }
}