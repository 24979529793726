import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Customize from "components/common/customize";
import ScreenChooser from "./screenChooser";

import { publishTerminal } from "store/actions/dashboard/terminalCustomize/common.action";
import { getTerminalConfiguration } from "store/actions/dashboard/terminalCustomize/configuration.action";

import {
    TERMINAL_UPDATE_TYPE,
    SECTIONS_DEFAULT_SELECTED_KEY,
    SECTION_PREDEFINED_DATA_BY_SECTION_NAMES,
    STYLES_DEFAULT_SELECTED_KEY,
    STYLES_EDITOR_MENU_ITEMS,
} from "constants/terminalCustomize.constants";
import PATHS from "constants/path.constants";

import { sendToTerminalIframe } from "utils/terminal";

import useCustomizeItems from "hooks/useCustomizeItems";

import terminalConfigurationType from "types/terminal/configuration.type";


/** Terminal Customize Page Component */
const TerminalCustomizeComponent = ({
    customizeLanguage,
    configuration,
    getTerminalConfiguration,
    publishTerminal,
    isPublishing
}) => {

    const { t, locale } = useTranslation();

    const searchParams = useParams();
    const navigate = useNavigate();

    const { sectionList, stylesList, unsavedTabs } = useCustomizeItems({
        sections: configuration?.sections,
        stylesData: STYLES_EDITOR_MENU_ITEMS,
        sectionsData: SECTION_PREDEFINED_DATA_BY_SECTION_NAMES
    })

    /** Get Configuration */
    useEffect(() => {
        getTerminalConfiguration(searchParams.id, customizeLanguage ?? locale);
    }, [customizeLanguage])

    const previewURL = `${import.meta.env.SYSTEM_SSBT_URL}?isPreview=true&projectId=${searchParams.id}&language=${customizeLanguage}`

    return (
        <Customize
            sidebarConfigs={{
                items: [
                    {
                        content: (
                            <Customize.Editor
                                items={stylesList}
                                defaultActiveSectionKey={STYLES_DEFAULT_SELECTED_KEY}
                                unsavedTabs={unsavedTabs.customizeStyles}
                                dependencies={[customizeLanguage]}
                                onSectionChange={sectionType => {
                                    sendToTerminalIframe(TERMINAL_UPDATE_TYPE.SCROLL_TO_SECTION, {
                                        sectionType: sectionType
                                    })
                                }}
                            />
                        ),
                        icon: "icon-pallet",
                        unsaved: unsavedTabs.customizeStyles.length > 0,
                        popupClassName: "rt--customize-sidebar-item-popup-small"
                    },
                    {
                        content: (
                            <Customize.Editor
                                items={sectionList}
                                defaultActiveSectionKey={SECTIONS_DEFAULT_SELECTED_KEY}
                                unsavedTabs={unsavedTabs.customizeSections}
                                onSectionChange={sectionType => {
                                    sendToTerminalIframe(TERMINAL_UPDATE_TYPE.SCROLL_TO_SECTION, {
                                        sectionType: sectionType
                                    })
                                }}
                            />
                        ),
                        icon: "icon-grid",
                        unsaved: unsavedTabs.customizeSections.length > 0,
                        popupClassName: "rt--customize-sidebar-item-popup-big"
                    },
                ]
            }}
            preview={{
                url: previewURL
            }}
            toolbarConfigs={{
                title: configuration?.name,
                buttons: [
                    {
                        className: "rt--button-main rt--button-main-without-icon",
                        loading: false,
                        text: t("backoffice.terminals.preview"),
                        onClick: () => window.open(previewURL, "_blank")
                    },
                    {
                        className: "rt--button-primary",
                        loading: isPublishing,
                        text: t("backoffice.terminals.publish"),
                        onClick: publishTerminal
                    }
                ],
                backButton: {
                    onClick: () => navigate(`${PATHS.PROJECTS_EDIT}/${searchParams.id}#tab=3&subtab=2`),
                    text: t("backoffice.terminals.backToProject")
                },
                toolbarContent: (
                    <ScreenChooser />
                )
            }}
        />
    );
};

/** TerminalCustomizeComponent propTypes
    * PropTypes
*/
TerminalCustomizeComponent.propTypes = {
    /** Redux action to load terminal configuration */
    getTerminalConfiguration: PropTypes.func,
    /** Redux state property, terminal configuration */
    configuration: terminalConfigurationType,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
    /** Redux action to set preview device mode */
    publishTerminal: PropTypes.func,
    /** Redux state property, preview device mode */
    isPublishing: PropTypes.bool,
}

const mapStateToProps = state => {
    return {
        configuration: state.terminalCustomize.configuration,
        customizeLanguage: state.terminalCustomize.customizeLanguage,
        isPublishing: state.terminalCustomize.isPublishing,
    }
}

const mapDispatchToProps = dispatch => (
    {
        getTerminalConfiguration: (id, customizeLanguage) => {
            dispatch(getTerminalConfiguration(id, customizeLanguage))
        },
        publishTerminal: () => {
            dispatch(publishTerminal());
        },
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(TerminalCustomizeComponent);
