import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    setTerminalCustomizeActionBefore,
    setTerminalCustomizeActionFinished,
    setTerminalCustomizeSaveActionBefore,
    setTerminalCustomizeSaveActionFinished
} from "./common.action";

import { 
    SET_TERMINAL_CUSTOMIZE_COLORS,
    SET_TERMINAL_CUSTOMIZE_FONT_FAMILY 
} from "../../../actionTypes";

const setTerminalColors = colors => ({
    type: SET_TERMINAL_CUSTOMIZE_COLORS,
    payload: { colors }
})

export const getTerminalColors = () => {
    return dispatch => {
        dispatch(setTerminalCustomizeActionBefore());

        return axios({
            url: ApiUrls.GET_TERMINAL_COLORS,
            method: Methods.GET
        })
        .then(({data : {value: colors}}) => {
            dispatch(setTerminalColors(colors))
            dispatch(setTerminalCustomizeActionFinished());
        })
        .catch((ex) => {
            dispatch(setTerminalCustomizeActionFinished());
        })
    }
}

export const saveTerminalColors = colors => {
    return dispatch => {
        dispatch(setTerminalCustomizeSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_TERMINAL_COLORS,
            method: Methods.POST,
            data: {
                ...colors
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                dispatch(setTerminalColors(data.value));
            } 
            dispatch(setTerminalCustomizeSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setTerminalCustomizeSaveActionFinished());
        })
    }
}

const setTerminalFontFamily = fontFamily => ({
    type: SET_TERMINAL_CUSTOMIZE_FONT_FAMILY,
    payload: { fontFamily }
})

export const getTerminalFontFamily = () => {
    return dispatch => {
        dispatch(setTerminalCustomizeActionBefore());

        return axios({
            url: ApiUrls.GET_TERMINAL_FONT_FAMILY,
            method: Methods.GET
        })
        .then(({data : {value: fontFamily}}) => {
            dispatch(setTerminalFontFamily(fontFamily))
            dispatch(setTerminalCustomizeActionFinished());
        })
        .catch((ex) => {
            dispatch(setTerminalCustomizeActionFinished());
        })
    }
}

export const saveTerminalFontFamily = fontFamily => {
    return dispatch => {
        dispatch(setTerminalCustomizeSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_TERMINAL_FONT_FAMILY,
            method: Methods.POST,
            data: {
                name: fontFamily
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                dispatch(setTerminalFontFamily(fontFamily));
            } 
            dispatch(setTerminalCustomizeSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setTerminalCustomizeSaveActionFinished());
        })
    }
}