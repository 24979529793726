import React from "react";

import CommissionDetailsCategoryRow from "../commissionDetailsCategoryRow";
import CommissionDetailsProviderRow from "../commissionDetailsProviderRow";

const CommissionDetailsRow = ({ formInstance, category, parentCategory, provider, parentProvider, label }) => {
	const isOnlyCategory = !provider;
	const namePrefix = provider ? [category.id, "providers", provider.id] : [category.id];

	if (isOnlyCategory) {
		return (
			<CommissionDetailsCategoryRow
				name={namePrefix}
				label={label}
				formInstance={formInstance}
				category={category}
				parentCategory={parentCategory}
			/>
		);
	}

	return (
		<CommissionDetailsProviderRow
			name={namePrefix}
			label={label}
			formInstance={formInstance}
			category={category}
			parentCategory={parentCategory}
			provider={provider}
			parentProvider={parentProvider}
		/>
	);
};

export default CommissionDetailsRow;
