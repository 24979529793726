import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";

import { Layout as AntLayout, Spin } from 'antd';

const { Content: AntContent, Header: AntHeader, Sider: AntSider } = AntLayout;

import { TransferProvider, Transfer, TRANSFER_SUCCESS_EVENT_NAME } from 'components/common/transfer';


import Sidebar from 'components/common/sidebar';
import Header from 'components/common/header';
import PlayerDepositSearch from 'components/common/playerDepositSearch';
import LogoutModal from 'components/common/logoutModal';

import { isMobile } from 'utils/common';
import { getUser, isAuthenticated } from 'utils/auth';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';
import { USER_ROLE } from 'constants/user.constants';

const MainLayout = ({
    isProjectChanging,
    isLogging,
}) => {

    const [collapsed, setCollapsed] = useState(false);

    const isMobileDevice = isMobile();

    useEffect(() => {
        const handler = event => {
            const data = event?.detail;
            if(data){
                if(
                    getUser()?.role === USER_ROLE.ADMIN && hasPermission({ resource: PERMISSION_RESOURCE.PROJECT_WALLETS, action: PERMISSION_ACTION.VIEW })
                ){
                    getCurrencies()
                }
            }
        }

        document.addEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_SUCCESS_EVENT_NAME, handler);
        }
    }, [])

    if(!isAuthenticated()){
        return <Outlet />;
    }

    return (
        <AntLayout className='rt--dashboard-layout'>
            <AntSider
                className='rt--sidebar'
                width={isMobileDevice ? "100vw" : 256}
                collapsedWidth={56}
                collapsed={isMobileDevice ? false : collapsed}
            >
                <Sidebar
                    onCollapse={setCollapsed}
                    collapsed={isMobileDevice ? false : collapsed}
                />
            </AntSider>
            <TransferProvider>
                <AntLayout className="rt--flex rt--flex-col">
                    <Spin spinning={isProjectChanging || isLogging}>
                        <AntHeader className={'rt--header-wrapper' + (getUser()?.hasPrevious ? " rt--header-wrapper-asAgent" : "")}>
                            <Header />
                        </AntHeader>
                        <AntContent className='rt--flex-equal'>
                            <Outlet />
                            
                            <LogoutModal />
                            <Transfer />
                            {
                                isMobile() && <PlayerDepositSearch />
                            }

                        </AntContent>
                    </Spin>
                </AntLayout>
            </TransferProvider>   
        </AntLayout>
    )
}

const mapStateToProps = state => {
    return {
        isProjectChanging: state.profile.isProjectChanging,
        isLogging: state.auth.isLoading
    }
}

export default connect(mapStateToProps, null)(MainLayout);