import React from "react";

import {AGENT_COMMISSION_STATE} from "constants/agent.constants";

import CommissionDetailsBaseRow from "../comissionDetailsBaseRow";
import calcCategoryCommissionPercent
	from "pages/agentSystem/agents/edit/sections/commission/components/agentCommissionDetailsForm/helpers/calcCategoryCommissionPercent";

const CommissionDetailsProviderRow = ({ formInstance, name, label, category, provider, parentProvider }) => {

	const handlePercentChange = () => {
		calcCategoryCommissionPercent({ formInstance, category });
	};

	const handleStateChange = (checked) => {
		if (checked) {
			formInstance.setFieldsValue({ [category.id]: {state: AGENT_COMMISSION_STATE.ACTIVE} });
		} else {
			const hasAnyEnabledProvider = Object.entries(formInstance.getFieldValue([category.id, "providers"])).some(
				([_, provider]) => provider.state === AGENT_COMMISSION_STATE.ACTIVE
			);

			if (!hasAnyEnabledProvider) {
				formInstance.setFieldsValue({
					[category.id]: { state: AGENT_COMMISSION_STATE.INACTIVE, commissionPercent: 0 }
				});
				return;
			}
		}

		calcCategoryCommissionPercent({ formInstance, category });
	};

	return (
		<CommissionDetailsBaseRow
			name={name}
			label={label}
			stateDisabled={parentProvider && parentProvider.state === AGENT_COMMISSION_STATE.INACTIVE}
			maxPercent={parentProvider && parentProvider.commissionPercent}
			formInstance={formInstance}
			onPercentChange={handlePercentChange}
			onStateChange={handleStateChange}
		/>
	);
};

export default CommissionDetailsProviderRow;
