import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import {
    getTerminalLanguages,
    saveTerminalLanguages,
    setTerminalDefaultLanguage,
} from 'store/actions/dashboard/terminalCustomize/sections.action';

import Table from 'components/common/table';
import Empty from '../empty';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { getTableColumns } from './columns';

const ROW_UNIQUE_KEY_PROP = "languageCode";

const LanguageConfig = ({
    languages,
    isLoading,
    isSaving,
    getTerminalLanguages,
    saveTerminalLanguages,
    setTerminalDefaultLanguage,
    defaultLanguageCode,
}) => {

    const { t } = useTranslation();

    const handleTableSwitcherChange = (isChecked, record) => {
        const requestBody = {
            languageCode: record.languageCode,
            enabled: isChecked
        }

        saveTerminalLanguages(requestBody);
    }

    const handleDefaultLanguageChange = useCallback((e, record) => {
        const isChecked = e.target.checked;

        if (!isChecked) {
            return;
        }

        const requestBody = {
            languageCode: record.languageCode
        }

        setTerminalDefaultLanguage(requestBody)
    }, [])

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                defaultLanguageCode,
                handleDefaultLanguageChange,
            }
        });
    }, [defaultLanguageCode, handleDefaultLanguageChange, t])

    useEffect(() => {
        getTerminalLanguages();
    }, []);

    return (
        <Table
            loading={isLoading || isSaving}
            renderEmpty={() => <Empty />}
            data={languages}
            columns={mainTableColumns}
            noPagination={true}
            uniqueKey={ROW_UNIQUE_KEY_PROP}
            switcher={{
                valueIndex: "enabled",
                dataIndex: "enabled",
                onChange: handleTableSwitcherChange,
                disabled: record => (record.languageCode.toLowerCase() === defaultLanguageCode.toLowerCase())
            }}
        />
    );
}

/** LanguageConfig propTypes
 * PropTypes
*/
LanguageConfig.propTypes = {
    languages: PropTypes.arrayOf(PropTypes.shape({
        languageCode: PropTypes.string,
        isActive: PropTypes.bool,
    })),
    /** Redux action to get terminal languages */
    getTerminalLanguages: PropTypes.func,
    /** Redux action to save terminal language */
    saveTerminalLanguages: PropTypes.func,
    /** Redux action to set terminal default language */
    setTerminalDefaultLanguage: PropTypes.func,
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when terminal saving */
    isSaving: PropTypes.bool,
    /** Redux state property, represents default language code */
    defaultLanguageCode: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
    getTerminalLanguages: () => {
        dispatch(getTerminalLanguages())
    },
    saveTerminalLanguages: (requestBody) => {
        dispatch(saveTerminalLanguages(requestBody))
    },
    setTerminalDefaultLanguage: (requestBody) => {
        dispatch(setTerminalDefaultLanguage(requestBody))
    },
});

const mapStateToProps = (state) => {
    return {
        languages: state.terminalCustomize.languages,
        isLoading: state.terminalCustomize.isLoading,
        defaultLanguageCode: state.terminalCustomize.configuration.defaultLanguageCode,
        isSaving: state.terminalCustomize.isSaving,
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(LanguageConfig);