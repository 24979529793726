import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import Select from "components/common/select";
import DateRangePicker from "components/common/dateRangePicker";

import { setAggregatedBetHistoryFilters, getAggregatedBetHistory } from "store/actions/dashboard/betHistory/aggregated.action";
import { getGameProviders } from "store/actions/dashboard/common/common.action";

import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { DATE_PICKER_RANGES, FILTER_TYPE} from 'constants/common.constants';
import { USER_ROLE } from 'constants/user.constants';

import { getCurrencyFieldOptions } from 'utils/currency';
import { getUser } from 'utils/auth';
import { getPossibleParentsName } from 'utils/common';

import useAutosuggestion from 'hooks/useAutosuggestion';
import useUserCurrencies from 'hooks/useUserCurrencies';
import useProjectType from 'hooks/useProjectType';

import gameProviderType from "types/project/gameProvider.type";
import userInfoType from 'types/profile/userInfo.type';

/** Aggregated Bets Page Filters Component */

const Filters = ({
    setAggregatedBetHistoryFilters,
    getAggregatedBetHistory,
    filters,
    globalProjectId,
    gameProviders,
    getGameProviders,
    userInfo
}) => {
    const { t } = useTranslation();

    const currentUserId = getUser()?.role === USER_ROLE.ADMIN ? globalProjectId : userInfo.id;

    const [ agentNames, getAgentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        autoGet: false
    });

    const [ userCurrencies, getUserCurrencies ] = useUserCurrencies(false);

    const { hasLiteMode } = useProjectType();

    const currencyFieldOptions = getCurrencyFieldOptions(userCurrencies);

    const belongsToFieldOptions = useMemo(() => {
        const options = getPossibleParentsName({
            globalProjectId,
            agentNames,
        })

        return options.map(o => ({
            value: o.id === currentUserId ? null : o.id, // for current user send null
            text: o.name
        }))
    }, [globalProjectId, agentNames, currentUserId])

    return (
        <FiltersWrapper
            loadFn={getAggregatedBetHistory}
            setFiltersFn={setAggregatedBetHistoryFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true }
            ]}
            searchFieldName="playerUserNameOrId"
            onInit={() => {
                getAgentNames({
                    excludeAgent: false
                });
                if(!hasLiteMode){
                    getUserCurrencies();
                }
                getGameProviders();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.common.dateAndTime')}
                        name="date"
                    >
                        <DateRangePicker
                            showTime={true}
                            enabledMountsCount={3}
                            allowClear={false}
                            disabledRanges={[DATE_PICKER_RANGES.YTD]}
                        />
                    </Form.Item>
                </Col>
                {
                    !hasLiteMode && (
                        <Col span={24} >
                            <Form.Item
                                label={t('backoffice.bets.currency')}
                                name="currencyCode"
                            >
                                <Select
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.currency')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    options={[
                                        { value: "", text: t("backoffice.common.all") },
                                        ...currencyFieldOptions
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }
                

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.bets.provider')}
                        name="provider"
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t("backoffice.common.all") },
                                    ...gameProviders
                                        .map(provider => (
                                            { value: provider.id, text: provider.name }
                                        ))
                                ]

                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.provider')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                        />
                    </Form.Item>
                </Col>
                
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.performancereport.belongsTo')}
                        name="belongsTo"
                    >
                        <Select
                            options={belongsToFieldOptions}
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.performancereport.belongsTo')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.bets.type')}
                        name="filterType"
                    >
                        <Select
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.bets.type')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            options={
                                [
                                    { value: FILTER_TYPE.NETWORK, text: t('backoffice.bets.network') },
                                    { value: FILTER_TYPE.DIRECT, text: t('backoffice.bets.direct') }
                                ]
                            }
                        />
                    </Form.Item>
                </Col>

                
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get aggregated bet histpry */
    getAggregatedBetHistory: PropTypes.func,
    /** Redux action to set aggregated bet history filters */
    setAggregatedBetHistoryFilters: PropTypes.func,
    /** Redux state property, aggregated bet history filters */
    filters: PropTypes.object,
    /** Redux state property, game providers */
    gameProviders: PropTypes.arrayOf(gameProviderType),
    /** Redux action to get game providers */
    getGameProviders: PropTypes.func,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, the user info */
    userInfo: userInfoType
}

const mapDispatchToProps = dispatch => (
    {
        getAggregatedBetHistory: () => {
            dispatch(getAggregatedBetHistory())
        },

        setAggregatedBetHistoryFilters: filters => {
            dispatch(setAggregatedBetHistoryFilters(filters));
        },

        getGameProviders: () => {
            dispatch(getGameProviders());
        }
    }
)

const mapStateToProps = state => {
    return {
        globalProjectId: state.common.globalProjectId,
        filters: state.betHistory.online.aggregated.filters,
        gameProviders: state.common.gameProviders,
        userInfo: state.profile.userInfo,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);
