import React from "react";

import TerminalCustomizeComponent from "pages/terminalCustomize/terminalCustomize.component";
import withPermission from "hocs/withPermission";
import withAuth from "hocs/withAuth";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const TerminalCustomizeRoute = () => {
    return <TerminalCustomizeComponent />;
};

export default withPermission(
    withAuth(TerminalCustomizeRoute),
    {
        resource: PERMISSION_RESOURCE.PROJECT_TERMINALS,
        action: PERMISSION_ACTION.VIEW,
    },
    Paths.DEFAULT
);
