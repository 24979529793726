import axios from "axios";
import { message } from 'antd';

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    SET_VOUCHER_ACTION_BEFORE,
    SET_VOUCHER_ACTION_FINISH,
    SET_VOUCHER,
    SET_CURRENT_VOUCHER,
    SET_VOUCHER_SAVE_ACTION_BEFORE,
    SET_VOUCHER_SAVE_ACTION_FINISH,
    SET_VOUCHER_LOGO
} from "../../../../actionTypes";

const setVoucherActionBefore = () => ({
    type: SET_VOUCHER_ACTION_BEFORE,
});

const setVoucherActionFinished = () => ({
    type: SET_VOUCHER_ACTION_FINISH,
});

const setVoucherSaveActionBefore = () => ({
    type: SET_VOUCHER_SAVE_ACTION_BEFORE,
});

const setVoucherSaveActionFinished = () => ({
    type: SET_VOUCHER_SAVE_ACTION_FINISH,
});

const setVoucher = ticket => ({
    type: SET_VOUCHER,
    payload: { ticket }
})

export const setCurrentVoucher = ticket => ({
    type: SET_CURRENT_VOUCHER,
    payload: { ticket }
})

export const getVoucher = () => {
    return dispatch => {

        dispatch(setVoucherActionBefore());

        return axios({
            url: ApiUrls.GET_VOUCHER_TICKET,
            method: Methods.GET
        })
            .then(({ data: { value: ticket } }) => {
                dispatch(setVoucher(ticket));
                dispatch(setCurrentVoucher({
                    generalDetails: ticket.generalDetails,
                    infoDetails: ticket.infoDetails,
                    customText: ticket.customText,
                    enableBarcodePrinting: ticket.enableBarcodePrinting
                }));
                dispatch(setVoucherActionFinished());
            })
            .catch((ex) => {
                dispatch(setVoucherActionFinished());
            });
    }
}

export const saveVoucher = ticket => {
    return (dispatch) => {
        dispatch(setVoucherSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_VOUCHER_TICKET,
            method: Methods.POST,
            data: ticket
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(setVoucher(data.value));
                }
                dispatch(setVoucherSaveActionFinished());
            })
            .catch(() => {
                dispatch(setVoucherSaveActionFinished());
            });
    };
};

export const setVoucherLogo = ticketLogoId => ({
    type: SET_VOUCHER_LOGO,
    payload: { ticketLogoId }
})

export const deleteVoucherLogo = () => {
    return dispatch => {

        return axios({
            url: ApiUrls.DELETE_VOUCHER_LOGO,
            method: Methods.DELETE
        })
            .then(({ data }) => {
                if (isResponseSuccess(data)) {
                    message.success(data.message);
                    dispatch(setVoucherLogo(null));
                }
            })
            .catch((ex) => {})
    }
}
