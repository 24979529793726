import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { REPARENT_ENTITY_TYPE } from "constants/agent.constants";
import { RESPONSE_CODES } from "constants/errorCode.constants";

/** Reparent Popup Result Component */
const ReparentResultComponent = ({
    type,
    result
}) => {
    const { t } = useTranslation();

    const totalCount = result?.ids?.length ?? 0;
    const response = result?.response ?? {}

    const reparentedUsers = Object.keys(response);
    const reparentedUsersSuccess = reparentedUsers.filter(u => response[u] === RESPONSE_CODES.Success);
    const reparentedUsersFailed = reparentedUsers.filter(u => response[u] !== RESPONSE_CODES.Success)

    const successedCount = reparentedUsersSuccess.length;
    const failedCount = reparentedUsersFailed.length;

    return (
        <div className='rt--reparent-result'>
            {
                successedCount > 0 && (
                    <Fragment>
                        <h4 className='rt--title rt--font-bold rt--font-normal rt--mb-16'>
                            {
                                successedCount === 1
                                    ? t("backoffice.players.playerReparentDoneOnePlayer")
                                    : t("backoffice.players.playerReparentDone")
                                        .replace("%%X%%", successedCount)
                            }
                        </h4>
                        <div className='rt--reparent-result-item rt--reparent-result-success rt--pl-16 rt--pr-16 rt--flex rt--align-center rt--justify-between'>
                            <div className='rt--flex rt--align-center rt--flex-equal'>
                                <i className='icon-success rt--font-bigest' />
                                <div className='rt--reparent-result-success-count rt--flex rt--align-center rt--justify-between rt--pl-8 rt--pr-8 rt--ml-8 rt--mr-8'>
                                    <span className='rt--title rt--font-regular rt--font-normal'>{`${successedCount} ${successedCount === 1 ? t("backoffice.menu.player") : t("backoffice.menu.players")}`}</span>
                                </div>
                            </div>
                            <b className='rt--title rt--font-regular rt--font-normal'>{t("backoffice.common.success")}</b>
                        </div>
                    </Fragment>
                )
            }

            {
                failedCount > 0 && (
                    <div className='rt--mt-24 rt--reparent-result-error-wrapper'>
                        <h4 className='rt--title rt--font-bold rt--font-normal rt--mb-16'>
                            {
                                t("backoffice.players.playerReparentFailed")
                                    .replace("%%X%%", failedCount)
                                    .replace("%%Y%%", totalCount)
                            }
                        </h4>
                        {
                            reparentedUsersFailed.map(user => (
                                <div
                                    className='rt--reparent-result-item rt--reparent-result-error rt--pl-16 rt--pr-16 rt--flex rt--align-center rt--justify-between rt--mb-8'
                                    key={user}
                                >
                                    <div className='rt--flex rt--align-center rt--flex-equal'>
                                        <i className='icon-warning rt--font-bigest' />
                                        <span className='rt--title rt--font-bold rt--font-normal rt--text-secondary rt--pl-8 rt--pr-8'>{user}</span>
                                    </div>
                                    <b className='rt--title rt--font-regular rt--font-normal'>{t("backoffice.common.failed")}</b>
                                </div>
                            ))
                        }

                    </div>
                )
            }
        </div>
    )
}

/** ReparentResultComponent propTypes
    * PropTypes
*/
ReparentResultComponent.propTypes = {
    /** Entity type */
    type: PropTypes.oneOf(Object.values(REPARENT_ENTITY_TYPE)),
    /** Reparent result */
    result: PropTypes.shape({
        response: PropTypes.object,
        ids: PropTypes.arrayOf(PropTypes.string)
    })
}



export default ReparentResultComponent;
