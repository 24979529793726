import React, {Component} from 'react';

import {Navigate, useLocation} from 'react-router-dom';

import {hasOneOfPermissions, hasPermission} from 'utils/permissions';
import useProjectType from "hooks/useProjectType.js";
import getMenuItems from "components/common/sidebar/items.jsx";
import {getUser} from "utils/auth.js";
import {useTranslation} from "react-i18next";
import PATHS from "constants/path.constants";

const groupRoutesConfigsByUrl = (routes, res = {}) => {
    for (const route of routes) {
        if (route.url) {
            res[route.url] = {
                permission: route.permission,
                projectType: route.projectType,
                paymentType: route.paymentType,
                projectMode: route.projectMode,
                userRole: route.userRole,
                disabled: route.disabled !== undefined ? route.disabled : false,
                isProjectRequired: route.isProjectRequired,
            };
        } else if (route.items) {
            groupRoutesConfigsByUrl(route.items, res);
        }
    }

    return res;
}

/** Hocs which allows access to page only if user
       * @function
       * @param {Component} WrappedComponent - Component to add functionality
       * @param {string/Array} permission - permission to check
       * @param {string} redirectURL - url to redirect when don't have permission
   */
const WithPermission = (WrappedComponent, permission, redirectURL) => {
    const canOpenPage = (permission) =>
      !permission ||
      (
        !Array.isArray(permission) &&
        hasPermission(permission)
      ) ||
      (
        Array.isArray(permission) && hasOneOfPermissions(permission)
      );

    const isRouteAccessible = (route, { globalProjectType, globalProjectMode, globalPaymentType, userRole }) => {
        const haveProject = !!globalProjectType;

        if (route.isProjectRequired && !haveProject) {
            return false;
        }

        const havePermission = canOpenPage(route.permission);
        const haveRole = route.userRole ? route.userRole.some(role => role === userRole) : true;
        const haveProjectType = route.projectType && globalProjectType ? route.projectType.some(projectType => (projectType & globalProjectType) > 0) : true;
        const haveProjectMode = route.projectMode ? route.projectMode.some(projectMode => (projectMode & globalProjectMode) > 0) : true;
        const havePaymentType = route.paymentType ? route.paymentType.some(paymentType => (paymentType & globalPaymentType) > 0) : true;
        const isNotDisabled = !route.disabled;

        return (
          havePermission &&
          haveRole &&
          haveProjectType &&
          haveProjectMode &&
          havePaymentType &&
          isNotDisabled
        );
    }

    const getFirstAccessibleRoute = (routes, globals) => {
        for (const route of routes) {
            if (route.url) {
                if (isRouteAccessible(route, globals)) {
                    return route.url;
                }
            } else if (routes.items) {
                const childAccessibleRouteURL = getFirstAccessibleRoute(route.items, globals);

                if (childAccessibleRouteURL) {
                    return childAccessibleRouteURL;
                }
            }
        }
    }

    return (props) => {
        const { t } = useTranslation();
        const { globalProjectType, globalPaymentType, globalProjectMode } = useProjectType();
        const { pathname } = useLocation();

        const routesConfigs = groupRoutesConfigsByUrl(getMenuItems());
        const routeConfigs = { ...routesConfigs[pathname], permission };
        const globals = { globalProjectType, globalPaymentType, globalProjectMode, userRole: getUser()?.role };

        if (!getUser()) {
            return <Navigate to={PATHS.LOGIN}/>
        }

        if (!isRouteAccessible(routeConfigs, globals)) {
            const firstAccessibleRoute = getFirstAccessibleRoute(getMenuItems(), globals);

            if (!firstAccessibleRoute) {
                return <span
                    className='rt--title rt--font-bold rt--text-center rt--font-bigest rt--no-perm'>{t("backoffice.common.noPermission")}</span>
            }

            return <Navigate to={firstAccessibleRoute}/>
        }

        return <WrappedComponent {...props} />;
    }
}

export default WithPermission;
