import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Menu from './menu';
import ContentBlock from './contentBlock';

import useFirstRender from 'hooks/useFirstRender';

import menuItemsType from 'types/customize/menuItems.type';

const Editor = ({
    items,
    defaultActiveSectionKey,
    unsavedTabs,
    dependencies=[],
    onSectionChange
}) => {
    const [activeSectionKey, setActiveSectionKey] = useState(defaultActiveSectionKey);

    const [alreadyRenderedSectionKeys, setAlreadyRenderedSectionKeys] = useState([
        defaultActiveSectionKey
    ]);

    // On language change reset alreadyRenderedSectionKeys
    useFirstRender({
        afterFirstRenderCB: () => {
            setAlreadyRenderedSectionKeys([activeSectionKey])
        },
        dependencies: dependencies
    })

    const handleMenuItemClick = (key) => {
        setActiveSectionKey(key);

        if (!alreadyRenderedSectionKeys.includes(key)) {
            setAlreadyRenderedSectionKeys(prevState => ([
                ...prevState,
                key,
            ]))
        }
    }

    useEffect(() => {
        const activeSectionData = items.find(item => item.key === activeSectionKey);

        if(activeSectionData?.sectionType && onSectionChange){
            onSectionChange(activeSectionData?.sectionType);
        }

    }, [activeSectionKey])

    return (
        <div className="rt--customize-widget-main">
            <div className="rt--customize-widget-main rt--flex">
                <Menu
                    items={items}
                    onItemClick={handleMenuItemClick}
                    activeItemKey={activeSectionKey}
                    unsavedTabs={unsavedTabs}
                />
                <ContentBlock
                    items={items}
                    alreadyRenderedSectionKeys={alreadyRenderedSectionKeys}
                    activeItemKey={activeSectionKey}
                />
            </div>
        </div>
    );
}

/** Editor propTypes
 * PropTypes
*/
Editor.propTypes = {
    defaultActiveSectionKey: PropTypes.string,
    items: PropTypes.arrayOf(menuItemsType),
    unsavedTabs: PropTypes.arrayOf(PropTypes.string),
    onSectionChange: PropTypes.func,
    dependencies: PropTypes.array
};

export default Editor;