import { WALLET_TYPE } from "constants/wallet.constants";

import {
    SET_TERMINALS_ACTION_BEFORE,
    SET_TERMINALS_ACTION_FINISH,
    SET_TERMINALS,
    SET_TERMINALS_SORTING,
    SET_TERMINALS_FILTERS,
    SET_TERMINALS_SAVE_ACTION_BEFORE,
    SET_TERMINALS_SAVE_ACTION_FINISH
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_TERMINALS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_TERMINALS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_TERMINALS:
            return {
                ...state,
                terminals: !payload.add ? payload.terminals.item2 : state.terminals.concat(payload.terminals.item2),
                total: payload.terminals.item1,
            };

        case SET_TERMINALS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_TERMINALS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_TERMINALS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_TERMINALS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        default:
            return state;
    }
};
