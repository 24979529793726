import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Form, Col, Row, Radio, Switch } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import NumericInput from "components/common/numericInput";
import Input from 'components/common/input';
import Tooltip from 'components/common/tooltip';
import DatePicker from 'components/common/datePicker';

import { createCommissionPlan } from "store/actions/dashboard/agentSystem/commissionPlans/commissionPlans.action";
import { getRetailProviders } from "store/actions/dashboard/projects/projects.action";
import { getGameProviders } from "store/actions/dashboard/common/common.action";
import { getProjectCurrencies } from "store/actions/dashboard/projects/currencies.action";

import useDate from 'hooks/useDate';

import { numberTransform, flagsToBinary, isFranchisingMode } from "utils/common";

import { isMobile } from 'utils/common';

import { POPUP_SIZE } from "constants/common.constants";
import { NAME_REGEX } from "constants/regex.constants";
import {
    COMMISSION_PLAN_TYPE,
    COMMISSION_PLAN_SOURCE_DIRECTION,
    COMMISSION_PLAN_SOURCE,
    COMMISSION_PLAN_CALCULATION_PERIOD,
    COMMISSION_PLAN_STRUCTURE,
    COMMISSION_PLAN_SOURCE_TYPE,
    COMMISSION_INCOME_TYPE,
    COMMISSION_SETTINGS_TYPE,
    COMMISSION_PLAN_TURNOVER_MAX_PERCENT
} from "constants/commission.constants";

import { PROJECT_PROVIDER_TYPE } from "constants/project.constants"

import currencyType from 'types/currency/currency.type';
import gameProviderType from "types/project/gameProvider.type";

/** Project Edit Page Commission plan Create popup Component */
const CommissionPlanCreateComponent = ({
    isSaving,
    createCommissionPlan,
    getProjectCurrencies,
    currencies,
    getGameProviders,
    getRetailProviders,
    gameProviders,
    retailProviders,
    onClose
}) => {
    const { t } = useTranslation();

    const searchParams = useParams();

    const { dateService } = useDate();

    const [selectedType, setSelectedType] = useState(isFranchisingMode() ? COMMISSION_PLAN_TYPE.DIRECT : null)
    const [selectedStructure, setSelectedStructure] = useState({
        [COMMISSION_INCOME_TYPE.NETWORK]: COMMISSION_PLAN_STRUCTURE.FLAT,
        [COMMISSION_INCOME_TYPE.DIRECT]: COMMISSION_PLAN_STRUCTURE.FLAT,
    })

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue, getFieldValue } = formInstance;

    /** Available Settings Types */
    const incomeTypes = selectedType === COMMISSION_PLAN_TYPE.MIX ?
        [COMMISSION_INCOME_TYPE.NETWORK, COMMISSION_INCOME_TYPE.DIRECT] :
        selectedType === COMMISSION_PLAN_TYPE.DIRECT ? [COMMISSION_INCOME_TYPE.DIRECT] :
            selectedType === COMMISSION_PLAN_TYPE.NETWORK ? [COMMISSION_INCOME_TYPE.NETWORK] : []

    /** Selected source */
    const [selectedSource, setSelectedSource] = useState(null);

    /** Selected Source Direction */
    const [selectedSourceDirection, setSelectedSourceDirection] = useState(isFranchisingMode() ? [COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS] : [])

    const handlePeriodChange = () => {
        const startDate = getFieldValue("startDate");

        if (startDate) {
            return;
        }

        let newStartDate = dateService.getNow();
        newStartDate = dateService.addUnit(newStartDate, 1, "hour");
        newStartDate = dateService.setUnit(newStartDate, "minute", 0);

        setFieldsValue({ startDate: newStartDate });
    }

    /** Load project currencies */
    useEffect(() => {
        getProjectCurrencies();
    }, [])

    /** Load available providers */
    useEffect(() => {
        if (!isFranchisingMode()) {
            getGameProviders();
        }

        getRetailProviders()
    }, [])

    useEffect(() => {
        if (retailProviders.length === 0) {
            setFieldsValue({ sourceDirection: isFranchisingMode() ? [COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS] : [COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS] })
            setSelectedSourceDirection(isFranchisingMode() ? [COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS] : [COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS])
        } else {
            setFieldsValue({ sourceDirection: isFranchisingMode() ? [COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS] : [] });
            setSelectedSourceDirection(isFranchisingMode() ? [COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS] : [])
        }
    }, [retailProviders.length])

    /** Fires when form submitted
       * @function
       * @memberOf CommissionPlanCreateComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const d = {
                    name: data.name,
                    currencyCode: data.currencyCode,
                    source: data.source,
                    onlineProviders: !isFranchisingMode() && selectedSourceDirection.includes(COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS) ? data.onlineProviders : null,
                    retailProviders: selectedSourceDirection.includes(COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS) ? flagsToBinary(data.retailProviders) : null,
                    period: data.period,
                    includeOtherCosts: selectedSourceDirection.includes(COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS) && selectedSource === COMMISSION_PLAN_SOURCE.NGR ? data.includeOtherCosts : false,
                    settings: [],
                    startDate: dateService.toISOString(data.startDate),
                }

                if (!isFranchisingMode()) {
                    d.type = data.type;
                    d.sourceDirection = flagsToBinary(data.sourceDirection);
                } else {
                    d.type = COMMISSION_PLAN_TYPE.DIRECT;
                    d.sourceDirection = COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS;
                }

                incomeTypes.forEach(incomeType => {
                    let item = {
                        structure: data["settings"]["s_" + incomeType].structure,
                        percent: data["settings"]["s_" + incomeType].percent,
                        sourceType: data["settings"]["s_" + incomeType].structure === COMMISSION_PLAN_STRUCTURE.FLAT ? COMMISSION_PLAN_SOURCE_TYPE.PERCENT : data["settings"]["s_" + incomeType].sourceType,
                        settingType: COMMISSION_SETTINGS_TYPE.GENERAL,
                        incomeType: incomeType
                    }
                    d.settings.push(item)
                })

                createCommissionPlan(d, searchParams.id, onClose)
            }).catch(ex => {
                console.log(ex)
            })
    }

    /** Function to reset values on sturcture change
       * @function
       * @param {number} type
       * @memberOf CommissionPlanCreateComponent
    */
    const handleStructureChange = type => {
        if (selectedStructure[type] === COMMISSION_PLAN_STRUCTURE.FLAT) {
            setFieldsValue({
                settings: {
                    ["s_" + type]: {
                        percent: ""
                    }
                }

            })
        } else {
            setFieldsValue({
                settings: {
                    ["s_" + type]: {
                        sourceType: undefined
                    }
                }
            })
        }
    }

    useEffect(() => {
        if (selectedSource !== COMMISSION_PLAN_SOURCE.NGR || !selectedSourceDirection.includes(COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS)) {
            setFieldsValue({ includeOtherCosts: false })
        }
    }, [selectedSource, selectedSourceDirection])

    useEffect(() => {
        if (selectedSource === COMMISSION_PLAN_SOURCE.DEPOSIT || selectedSource === COMMISSION_PLAN_SOURCE.WITHDRAWAL) {
            setFieldsValue({ sourceDirection: [COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS] });
            setSelectedSourceDirection([COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS])
        }
    }, [selectedSource])

    return (
        <Modal
            title={t('backoffice.commissionplans.createCommissionPlan')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALL}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    settings: {
                        ["s_" + COMMISSION_INCOME_TYPE.NETWORK]: {
                            structure: COMMISSION_PLAN_STRUCTURE.FLAT,
                            percent: ""
                        },
                        ["s_" + COMMISSION_INCOME_TYPE.DIRECT]: {
                            structure: COMMISSION_PLAN_STRUCTURE.FLAT,
                            percent: ""
                        }
                    },
                    sourceDirection: isFranchisingMode() ? [COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS] : [],
                    includeOtherCosts: false
                }}
            >
                <Row gutter={[8, 0]}>

                    <Col xs={24} >
                        <Form.Item
                            label={`${t('backoffice.commissionplans.name')} *`}
                            name="name"
                            className='rt--general-form-item'
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                            ]}
                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.name')}`}
                            validateFirst
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.name')}`}
                                maxLength={30}
                                autoFocus={(isMobile() ? false : true)}
                                autoComplete="off"
                            />
                        </Form.Item>
                    </Col>
                    {
                        !isFranchisingMode() && (
                            <Col xs={12} sm={8}>
                                <Form.Item
                                    label={`${t('backoffice.commissionplans.incomeType')} *`}
                                    name="type"
                                    rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                >
                                    <Select
                                        options={[
                                            { value: COMMISSION_PLAN_TYPE.DIRECT, text: t('backoffice.commissionplans.direct') },
                                            { value: COMMISSION_PLAN_TYPE.NETWORK, text: t('backoffice.commissionplans.network') },
                                            // { value: COMMISSION_PLAN_TYPE.MIX, text: t('backoffice.commissionplans.mix') }
                                        ]}
                                        placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.incomeType")}`}
                                        onChange={v => setSelectedType(v)}
                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }

                    <Col xs={12} sm={8}>
                        <Form.Item
                            label={`${t('backoffice.commissionplans.currency')} *`}
                            name="currencyCode"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                        >
                            <Select
                                options={
                                    currencies.filter(item => item.enabled).map(item => (
                                        { value: item.code, text: item.code }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.currency')}`}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={8}>
                        <Form.Item
                            label={`${t('backoffice.commissionplans.source')} *`}
                            name="source"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                        >
                            <Select
                                options={[
                                    { value: COMMISSION_PLAN_SOURCE.TURNOVER, text: t('backoffice.commissionplans.turnover') },
                                    { value: COMMISSION_PLAN_SOURCE.GGR, text: t('backoffice.commissionplans.ggr') },
                                    { value: COMMISSION_PLAN_SOURCE.NGR, text: t('backoffice.commissionplans.ngr') },
                                    ...(!isFranchisingMode() ? [
                                        { value: COMMISSION_PLAN_SOURCE.DEPOSIT, text: t('backoffice.commissionplans.deposit') },
                                        { value: COMMISSION_PLAN_SOURCE.WITHDRAWAL, text: t('backoffice.commissionplans.withdrawal') }
                                    ] : [])
                                ]}
                                placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.source")}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                onChange={value => {
                                    setSelectedSource(value);
                                    setTimeout(() => {
                                        incomeTypes.forEach(incomeType => {
                                            if (getFieldValue(["settings", "s_" + incomeType, "percent"])) {
                                                validateFields([["settings", "s_" + incomeType, "percent"]])
                                            }
                                        })
                                    }, 0)
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24}>
                        <Form.Item
                            label={`${t("backoffice.commissionplans.sourceDirection")} *`}
                            name="sourceDirection"
                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                            className={(
                                isFranchisingMode() || retailProviders.length === 0 || selectedSource === COMMISSION_PLAN_SOURCE.DEPOSIT || selectedSource === COMMISSION_PLAN_SOURCE.WITHDRAWAL
                            ) ? "rt--form-item-disabled" : ""}
                        >
                            <Select
                                options={[
                                    { value: COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS, text: t("backoffice.commissionplans.players") },
                                    ...(
                                        (
                                            (retailProviders.length > 0 || isFranchisingMode()) &&
                                            selectedSource !== COMMISSION_PLAN_SOURCE.DEPOSIT &&
                                            selectedSource !== COMMISSION_PLAN_SOURCE.WITHDRAWAL
                                        )
                                    ) ? [
                                        { value: COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS, text: t("backoffice.commissionplans.betshops") }
                                    ] : []
                                ]}
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.sourceDirection')}`}
                                isMultiple={true}
                                onChange={e => setSelectedSourceDirection(e)}
                                disabled={isFranchisingMode() || retailProviders.length === 0 || selectedSource === COMMISSION_PLAN_SOURCE.DEPOSIT || selectedSource === COMMISSION_PLAN_SOURCE.WITHDRAWAL}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                            />
                        </Form.Item>
                    </Col>

                    {
                        (
                            !isFranchisingMode() &&
                            selectedSourceDirection.includes(COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS) &&
                            selectedSource !== COMMISSION_PLAN_SOURCE.DEPOSIT &&
                            selectedSource !== COMMISSION_PLAN_SOURCE.WITHDRAWAL
                        ) && (
                            <Col xs={24} >
                                <Form.Item
                                    label={`${t("backoffice.commissionplans.onlineProviders")} *`}
                                    name="onlineProviders"
                                    rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                >
                                    <Select
                                        options={
                                            gameProviders
                                                .map(provider => (
                                                    { value: provider.id, text: provider.name }
                                                )
                                                )
                                        }
                                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.onlineProviders')}`}
                                        isMultiple={true}
                                        search={true}
                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }

                    {
                        (
                            selectedSourceDirection.includes(COMMISSION_PLAN_SOURCE_DIRECTION.BETSHOPS) &&
                            selectedSource !== COMMISSION_PLAN_SOURCE.DEPOSIT &&
                            selectedSource !== COMMISSION_PLAN_SOURCE.WITHDRAWAL
                        ) && (
                            <Col xs={24} >
                                <Form.Item
                                    label={`${t("backoffice.commissionplans.retailProviders")} *`}
                                    name="retailProviders"
                                    rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                >
                                    <Select
                                        options={
                                            retailProviders.map(pr => ({
                                                value: pr, 
                                                text: pr === PROJECT_PROVIDER_TYPE.SPORTBOOK ? 
                                                    t("backoffice.common.Sportsbook") : 
                                                    pr === PROJECT_PROVIDER_TYPE.VIRTUAL_SPORTS ? 
                                                    t("backoffice.common.DGVirtuals") : 
                                                    pr === PROJECT_PROVIDER_TYPE.GOLDEN_RACE ? 
                                                    t("backoffice.common.GoldenRace") : ""
                                            }))
                                        }
                                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.retailProviders')}`}
                                        isMultiple={true}
                                        search={true}
                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    }

                </Row>

                <Row gutter={[16, 0]}>
                    {
                        incomeTypes.map(incomeType => (
                            <Col xs={24} key={incomeType}>
                                <Row gutter={[16, 0]}>
                                    <Col span={24}>
                                        <Form.Item
                                            label={`${isFranchisingMode() ? t('backoffice.commissionplans.structure') : incomeType === COMMISSION_INCOME_TYPE.NETWORK ? t('backoffice.commissionplans.networkPlanStructure') : t('backoffice.commissionplans.directPlanStructure')} *`}
                                            name={["settings", "s_" + incomeType, "structure"]}
                                            rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                            className="rt--form-item-radio"
                                        >
                                            <Radio.Group
                                                options={[
                                                    { label: t('backoffice.commissionplans.flat'), value: COMMISSION_PLAN_STRUCTURE.FLAT },
                                                    { label: t('backoffice.commissionplans.structured'), value: COMMISSION_PLAN_STRUCTURE.STRUCTURED }
                                                ]}
                                                onChange={e => {
                                                    setSelectedStructure({
                                                        ...selectedStructure,
                                                        [incomeType]: e.target.value
                                                    })
                                                    setTimeout(() => {
                                                        handleStructureChange(incomeType)
                                                    }, 0)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        {
                                            selectedStructure[incomeType] === COMMISSION_PLAN_STRUCTURE.STRUCTURED ?
                                                (
                                                    <Form.Item
                                                        label={`${isFranchisingMode() ? t('backoffice.commissionplans.sourceType') : incomeType === COMMISSION_INCOME_TYPE.NETWORK ? t('backoffice.commissionplans.networkPlanSourceType') : t('backoffice.commissionplans.directPlanSourceType')} *`}
                                                        name={["settings", "s_" + incomeType, "sourceType"]}
                                                        rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                                    >
                                                        <Select
                                                            options={[
                                                                { value: COMMISSION_PLAN_SOURCE_TYPE.FIXED_AMOUNT, text: t('backoffice.commissionplans.fixedAmount') },
                                                                { value: COMMISSION_PLAN_SOURCE_TYPE.PERCENT, text: t('backoffice.commissionplans.percent') }
                                                            ]}
                                                            placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.sourceType")}`}
                                                            getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                        />
                                                    </Form.Item>
                                                ) :
                                                (
                                                    <Form.Item
                                                        label={`${isFranchisingMode() ? t('backoffice.commissionplans.commissionPercent') : incomeType === COMMISSION_INCOME_TYPE.NETWORK ? t('backoffice.commissionplans.networkPlanCommissionPercent') : t('backoffice.commissionplans.directPlanCommissionPercent')} *`}
                                                        name={["settings", "s_" + incomeType, "percent"]}
                                                        rules={[
                                                            { required: true, message: t('backoffice.validation.fieldRequired') },
                                                            { type: "number", min: 0, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },

                                                            () => ({
                                                                validator(rule, value) {
                                                                    const max = selectedSource === COMMISSION_PLAN_SOURCE.TURNOVER ? COMMISSION_PLAN_TURNOVER_MAX_PERCENT : 100;

                                                                    if (Number(value) < 0 || Number(value) > max) {
                                                                        return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", max))
                                                                    }
                                                                    return Promise.resolve();

                                                                }
                                                            })
                                                        ]}
                                                        className='rt--general-form-item'
                                                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.commissionPercent')}`}
                                                        validateFirst
                                                    >
                                                        <NumericInput
                                                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.commissionplans.commissionPercent')}`}
                                                        />
                                                    </Form.Item>
                                                )
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        ))
                    }
                </Row>

                <Row gutter={[16, 0]} >
                    <Col xs={24} >
                        <Form.Item
                            label={`${t("backoffice.commissionplans.calculationPeriod")} *`}
                            name="period"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                        >
                            <Select
                                options={[
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.HOUR, text: t('backoffice.commissionplans.hour') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.DAY, text: t('backoffice.commissionplans.day') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.WEEK, text: t('backoffice.commissionplans.week') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.TWO_WEEK, text: t('backoffice.commissionplans.2week') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.THREE_WEEK, text: t('backoffice.commissionplans.3week') },
                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.MONTH, text: t('backoffice.commissionplans.month') }
                                ]}
                                placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.calculationPeriod")}`}
                                getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                onChange={handlePeriodChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} >
                        <Form.Item
                            label={`${t("backoffice.commissionplans.startDate")} *`}
                            name="startDate"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') }
                            ]}
                        >
                            <DatePicker
                                className="rt--datepicker"
                                popupClassName='rt--datepicker-popup rt--datepicker-withtime-popup rt--datepicker-only-hours'
                                format={`${dateService.getFormat(false)} HH:00`}
                                showTime={ {
                                    hideDisabledOptions: true,
                                    format: "HH"
                                } }
                                disabledDate={d => dateService.isBefore(d, dateService.getNow(true))}
                                showToday={false}
                                showNow={false}
                                allowClear={true}
                                placeholder={t('backoffice.common.selectDate')}
                                suffixIcon={<i className='icon-date rt--font-bigest' />}
                                getPopupContainer={isMobile() ? '' : () => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                inputReadOnly={true}
                            />
                        </Form.Item>
                    </Col>
                    {
                        !isFranchisingMode() && (
                            <Col span={24}>
                                <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between">
                                    <Form.Item
                                        name="includeOtherCosts"
                                        valuePropName="checked"
                                        className='rt--form-item-without-margin'
                                    >
                                        <Switch
                                            disabled={selectedSource !== COMMISSION_PLAN_SOURCE.NGR || !selectedSourceDirection.includes(COMMISSION_PLAN_SOURCE_DIRECTION.PLAYERS)}
                                        />
                                    </Form.Item>
                                    <div className='rt--flex rt--align-center'>
                                        <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.commissionplans.includeOtherCosts')}</label>
                                        <Tooltip
                                            title={t(`backoffice.commissionplans.otherCostsInfoText`)}
                                            trigger={["hover", "click"]}
                                            placement="bottomLeft"
                                            enableMobile={true}
                                        >
                                            <i className='icon-info rt--font-bigest rt--ml-8' />
                                        </Tooltip>
                                    </div>
                                </div>
                            </Col>
                        )
                    }

                </Row>


            </Form>

        </Modal>
    )
}

/** CommissionPlanCreateComponent propTypes
    * PropTypes
*/
CommissionPlanCreateComponent.propTypes = {
    /** Redux state property, is true when creating commission plan request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to create commission plan for agent */
    createCommissionPlan: PropTypes.func,
    /** Redux action to get project currencies */
    getProjectCurrencies: PropTypes.func,
    /** Redux state, represents the currencies of current editing project  */
    currencies: PropTypes.arrayOf(currencyType),
    /** Redux state property, game providers */
    gameProviders: PropTypes.arrayOf(gameProviderType),
    /** Redux state property, retail providers */
    retailProviders: PropTypes.arrayOf(PropTypes.number),
    /** Redux action to get game providers */
    getGameProviders: PropTypes.func,
    /** Redux action to get retail providers */
    getRetailProviders: PropTypes.func,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        createCommissionPlan: (data, agentId, onSuccess) => {
            dispatch(createCommissionPlan(data, agentId, onSuccess));
        },
        getProjectCurrencies: () => {
            dispatch(getProjectCurrencies());
        },
        getGameProviders: () => {
            dispatch(getGameProviders());
        },
        getRetailProviders: () => {
            dispatch(getRetailProviders());
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.commissionPlans.isSaving,
        currencies: state.projects.edit.currencies,
        gameProviders: state.common.gameProviders,
        retailProviders: state.projects.retailProviders
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanCreateComponent)
