import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

/** Selecter Component */
const Selecter = ({
    onChange,
    value,
    items,
    disabled
}) => {

    const { t } = useTranslation();

    /** Fires om item click
         * @function
         * @param {string} item - selected item
         * @memberOf Selecter
     */
    const handleItemClick = item => {
        if(disabled || item.disabled) return;
        if(value.includes(item.value)){
            onChange(value.filter(v => v !== item.value))
        } else {
            onChange([...value, item.value])
        }
    }

    return (
        <div className="selecter">
            {
                items.map(item => (
                    <div
                        className={
                            "rt--tag rt--tag-info rt--cursor-pointer rt--flex rt--justify-between rt--align-center rt--mb-8 rt--mr-8" +
                            (value.includes(item.value) ? " rt--tag-info-selected" : "") +
                            (disabled || item.disabled ? " rt--tag-info-disabled" : "")
                        }
                        key={item.value}
                        onClick={() => handleItemClick(item)}
                    >
                        <span className='rt--title rt--font-normal rt--font-bold'>{item.title}</span>
                        { value.includes(item.value) && <i className="icon-ok rt--font-bigest" />}
                    </div>
                ))
            }
        </div>
    )
}

/** Selecter propTypes
    * PropTypes
*/
Selecter.propTypes = {
    /** Handler of selecter value change */
    onChange: PropTypes.func,
    /** Current value of selecter */
    value: PropTypes.array,
    /** Should selecter be disabled */
    disabled: PropTypes.bool,
    /** Array of items */
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }))
}


export default Selecter;
