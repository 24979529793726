import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import { Col, Form, Row } from 'antd';

import Modal from 'components/common/modal';
import Input from 'components/common/input';
import ImageUploader from 'components/common/imageUploader';

import { isFunction } from 'utils/common';
import { IMAGE_TYPE, POPUP_SIZE } from 'constants/common.constants';

const FORM_FIELD_NAMES = {
    NAME: "name",
    URL: "URL",
    FILE: "file"
}

const EditCertificatePopupComponent = ({
    onClose,
    onOk,
    isSaving,
    data
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const handleForm = () => {
        validateFields()
            .then(fieldsValue => {
                if (isFunction(onOk)) {
                    onOk({
                        ...fieldsValue,
                        rowId: data.rowId
                    });
                    onClose();
                }

            }).catch(ex => {
                console.log(ex)
            })
    }


    return (
        <Modal
            title={t('backoffice.common.edit')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.save')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALLEST}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
                initialValues={{
                    [FORM_FIELD_NAMES.NAME]: data.name,
                    [FORM_FIELD_NAMES.URL]: data.url ?? "",
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.terminals.certificateName')} *`}
                            name={FORM_FIELD_NAMES.NAME}
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.terminals.certificateName')}`}
                                maxLength={25}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label={t('backoffice.terminals.url')}
                            name={FORM_FIELD_NAMES.URL}
                        >
                            <Input.Dropdown
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.terminals.url')}`}
                                items={[{
                                    label: "https://",
                                    value: "https://",
                                }]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.terminals.image')} *`}                            
                            name={FORM_FIELD_NAMES.FILE}
                            className='rt--general-form-item rt--form-item-without-margin rt--mt-4'
                            rules={[
                                { required: !Boolean(data.file), message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <ImageUploader
                                size={1 * 1024 * 1024}
                                acceptedFormats={ [ IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG, IMAGE_TYPE.WEBP, IMAGE_TYPE.SVG ]}
                                defaultFile={
                                    data.file ? {
                                        url: `${import.meta.env.SYSTEM_CDN_URL}/${data.file.toLowerCase()}`,
                                        status: "done",
                                        percent: 100
                                    } : null
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

/** EditCertificatePopupComponent propTypes
    * PropTypes
*/
EditCertificatePopupComponent.propTypes = {
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Fires on popup add button clicked */
    onOk: PropTypes.func,
    /** true when saving data */
    isSaving: PropTypes.bool,
    /** Default data value */
    data: PropTypes.object
}

export default EditCertificatePopupComponent;