import React from 'react';

import { TERMINAL_SECTION_TYPE } from 'constants/terminalCustomize.constants';

import { makeProductBanner, makeProductName, makeProviderName } from 'pages/terminalCustomize/helpers';

export const tableColumns = [
    {
        title: "backoffice.terminals.product",
        dataIndex: "navigationItem",
        render: (_, record) => makeProductName(record, TERMINAL_SECTION_TYPE.FEATURED_PRODUCTS)
    },
    {
        title: "backoffice.terminals.provider",
        dataIndex: "provider",
        render: (_, record) => makeProviderName(record, TERMINAL_SECTION_TYPE.FEATURED_PRODUCTS) ? (
            <div className='rt--tag'>
                { makeProviderName(record, TERMINAL_SECTION_TYPE.FEATURED_PRODUCTS) }
            </div>
        ) : "-",
    },
    {
        title: "backoffice.terminals.thumbnail",
        dataIndex: "file",
        render: (_, record) => {
            return (
                <div className="rt--table-col-img rt--mt-6 rt--mb-6">
                    <img src={makeProductBanner(record)} alt="attachment" />
                </div>
            )
        },
    },
]