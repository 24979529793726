import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import { Button, Spin } from 'antd';
import Table from 'components/common/table';

import HeaderItemAddComponent from './headerItem-add-component';
import Empty from '../empty';

import { TERMINAL_HEADER_NAVIGATION_ITEM, TERMINAL_SECTION_TYPE } from 'constants/terminalCustomize.constants';

import {
    getTerminalSectionItems,
    addTerminalHeaderItem,
    removeTerminalSectionItem,
    changeTerminalSectionItemState,
    reorderTerminalSectionItems
} from 'store/actions/dashboard/terminalCustomize/sections.action';

import { rowSortingHandler, sortByOrder } from '../../../helpers';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { tableColumns } from './columns';


const Header = ({
    data,
    isLoading,
    isSaving,
    getTerminalSectionItems,
    addTerminalHeaderItem,
    removeTerminalSectionItem,
    changeTerminalSectionItemState,
    reorderTerminalSectionItems,
    customizeLanguage,
}) => {
    const { t } = useTranslation();

    const [addPopupData, setAddPopupData] = useState(false);

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showAddPopup = () => {
        setAddPopupData(true)
    }

    const hideAddPopup = () => {
        setAddPopupData(false)
    }

    const handleHeaderItemAdd = data => {
        addTerminalHeaderItem(data);
    }

    const handleHeaderItemDelete = record => {
        removeTerminalSectionItem(TERMINAL_SECTION_TYPE.HEADER, record.rowId)
    }

    const handleTableSwitcherChange = (isChecked, record) => {
        changeTerminalSectionItemState(TERMINAL_SECTION_TYPE.HEADER, record.rowId, isChecked)
    }

    const handleRowSort = (from, to) => {
        const orders = rowSortingHandler(from, to, tableData, element => element.navigatinItem === TERMINAL_HEADER_NAVIGATION_ITEM.HOME);
        reorderTerminalSectionItems(TERMINAL_SECTION_TYPE.HEADER, orders)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({ 
            mainColumns: tableColumns,
        });
    }, [])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [
        {
            title: t("backoffice.common.delete"),
            icon: "icon-trash",
            onClick: handleHeaderItemDelete,
            disabled: record => !record.deletable
        },
    ]

    //#endregion

    // Get Header Items
    useEffect(() => {
        getTerminalSectionItems(TERMINAL_SECTION_TYPE.HEADER);
    }, [customizeLanguage]);

    const tableData = useMemo(() => sortByOrder(data), [data])

    return (
        <Spin spinning={isLoading || isSaving}>
             <div className='rt--flex rt--justify-end rt--mb-16'>
                <Button
                    type="primary"
                    className="rt--button rt--button-main"
                    onClick={() => showAddPopup()}
                    icon={<i className='icon-plus rt--font-bigest' />}
                >
                    <span className='rt--font-normal'>
                        {t('backoffice.terminals.addItem')}
                    </span>
                </Button>
            </div>

            <div className="rt--customize-table">
                <Table
                    data={tableData}
                    renderEmpty={() => <Empty />}
                    columns={mainTableColumns}
                    noPagination={true}
                    actions={tableRowActions}
                    switcher={{
                        valueIndex: "enabled",
                        dataIndex: "enabled",
                        disabled: record => !record.editable,
                        onChange: handleTableSwitcherChange,
                    }}
                    uniqueKey="rowId"
                    sortable={true}
                    sortableData={tableData.filter(d => d.type !== TERMINAL_HEADER_NAVIGATION_ITEM.HOME)}
                    onRowSort={handleRowSort}
                />

                {addPopupData && (
                    <HeaderItemAddComponent
                        onClose={hideAddPopup}
                        onOk={handleHeaderItemAdd}
                        data={tableData}
                        isSaving={isSaving}
                    />
                )}

            </div>
        </Spin>
    )
}

/** Header propTypes
 * PropTypes
*/
Header.propTypes = {
    data: PropTypes.array,
    /** Redux state property, is true when loading terminal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when terminal saving */
    isSaving: PropTypes.bool,
    /** Redux action to get terminal header items */
    getTerminalSectionItems: PropTypes.func,
    /** Redux action to add terminal header item */
    addTerminalHeaderItem: PropTypes.func,
    /** Redux action to remove terminal section item */
    removeTerminalSectionItem: PropTypes.func,
    /** Redux action to change terminal section item state */
    changeTerminalSectionItemState: PropTypes.func,
    /** Redux action to reorder terminal section items */
    reorderTerminalSectionItems: PropTypes.func,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
    getTerminalSectionItems: sectionType => {
        dispatch(getTerminalSectionItems(sectionType))
    },
    addTerminalHeaderItem: requestBody => {
        dispatch(addTerminalHeaderItem(requestBody))
    },
    removeTerminalSectionItem: (sectionType, rowId) => {
        dispatch(removeTerminalSectionItem(sectionType, rowId))
    },
    changeTerminalSectionItemState: (sectionType, rowId, enabled) => {
        dispatch(changeTerminalSectionItemState(sectionType, rowId, enabled))
    },
    reorderTerminalSectionItems: (sectionType, rowOrders) => {
        dispatch(reorderTerminalSectionItems(sectionType, rowOrders))
    }
});

const mapStateToProps = (state) => {
    return {
        customizeLanguage: state.terminalCustomize.customizeLanguage,
        isLoading: state.terminalCustomize.isLoading,
        isSaving: state.terminalCustomize.isSaving,
        data: state.terminalCustomize.sections[TERMINAL_SECTION_TYPE.HEADER].data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);