import React, {useEffect, useMemo, useState} from 'react';

import {Col, Form, message, Row, Spin} from "antd";

import {useTranslation} from "react-i18next";

import AgentCommissionDetailsForm from "pages/agentSystem/agents/edit/sections/commission/components/agentCommissionDetailsForm";
import {getCommissionDetails, saveCommissionDetails} from "pages/agentSystem/agents/edit/sections/commission/api";
import {AGENT_COMMISSION_STATE} from "constants/agent.constants";
import {useParams} from "react-router-dom";

import "./styles.scss";
import TabFormDashboardLayout from "components/layouts/tab/form";
import {isFormChanged} from "utils/form";
import mapSettingsToForm from "pages/agentSystem/agents/edit/sections/commission/helpers/mapSettingsToForm";
import {hasPermission} from "utils/permissions";
import {PERMISSION_ACTION, PERMISSION_RESOURCE} from "constants/permissions.constants";

const CommissionComponent = () => {
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [commissionDetails, setCommissionDetails] = useState({});
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();

    const { id } = useParams();

    const { t } = useTranslation();

    const canEdit = () => hasPermission({ resource: PERMISSION_RESOURCE.AGENT_COMMISSION_PLAN, action: PERMISSION_ACTION.MODIFY })

    const initialValues = useMemo(() => {
        if (!Object.keys(commissionDetails).length) {
            return {};
        }

        const parentSettings = commissionDetails.parentCommissionSettings;
        const agentSettings = commissionDetails.agentCommissionSettings;

        const mappedSettings = mapSettingsToForm({ agentSettings, parentSettings });

        formInstance.setFieldsValue(mappedSettings)

        return mapSettingsToForm({ agentSettings, parentSettings });
    }, [commissionDetails]);

    useEffect(() => {
        setDetailsLoading(true);

        getCommissionDetails(id)
            .then(({ data: { value } }) => {
                setCommissionDetails(value);

                const parentSettings = value.parentCommissionSettings;
                const agentSettings = value.agentCommissionSettings;

                const mappedSettings = mapSettingsToForm({ agentSettings, parentSettings });

                formInstance.setFieldsValue(mappedSettings);
            })
            .finally(() => {
                setDetailsLoading(false);
            });
    }, []);

    const handleFieldsChange = () => {
        setIsFormTouched(
            isFormChanged(
                {...formInstance.getFieldsValue()},
                initialValues
            ));
    }

    const handleFinish = (values) => {
        const data = Object.entries(values).map(([key, category]) => {
            return {
                ...category,
                id: key,
                providers: Object.entries(category.providers).map(([key, provider]) => ({
                    ...provider,
                    id: key
                }))
            };
        });

        setSaveLoading(true);

        saveCommissionDetails({
            id,
            state: 1,
            categories: data
        })
            .then(({ data: { value } }) => {
                message.success(t("backoffice.agents.commissionDetailsSaved"));

                setIsFormTouched(false);
                setCommissionDetails(value);
            })
            .finally(() => {
                setSaveLoading(false);
            });
    };

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: formInstance.submit,
                        text: t("backoffice.common.save"),
                        enabled: true,
                        disabled: !isFormTouched,
                        loading: saveLoading,
                    }
                ]
            }
        >
            <Spin className="rt--commission-details-modal-content-loading" spinning={detailsLoading}>
                <Row>
                    <Col xs={24} md={18} lg={12}>
                            <div className={"rt--commission-details-modal-content"}>
                                <AgentCommissionDetailsForm
                                    canEdit={canEdit()}
                                    formInstance={formInstance}
                                    commissionDetails={commissionDetails}
                                    onFinish={handleFinish}
                                    onFieldsChange={handleFieldsChange}
                                />
                            </div>
                    </Col>
                </Row>
            </Spin>
        </TabFormDashboardLayout>
    );
};

export default CommissionComponent;
