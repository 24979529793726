//#region react
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Spin } from "antd";

import { getTerminalColors, saveTerminalColors } from "store/actions/dashboard/terminalCustomize/styles.action";

import ColorPicker from "components/common/colorPicker";

import { isFormChanged } from "utils/form";
import { sendToTerminalIframe } from "utils/terminal";

import { TERMINAL_UPDATE_TYPE } from "constants/terminalCustomize.constants";

import terminalColorsType from "types/terminal/colors.type";

/** Terminal Customize Page Sections Colors Editor Component */
const ColorsEditor = ({
    colors,
    getTerminalColors,
    saveTerminalColors,
    isSaving,
    isLoading
}) => {
    const { t } = useTranslation();

    const [values, setValues] = useState({});
    const [isFormTouched, setIsFormTouched] = useState(false);

    /** Load colors */
    useEffect(() => {
        getTerminalColors();
    }, [])

    useEffect(() => {
        setValues(colors);
    }, [colors])

    const onChange = (key, value) => {
        const newValues = { ...values };
        newValues[key] = value;
        setValues(newValues);

        sendToTerminalIframe(TERMINAL_UPDATE_TYPE.COLORS, { colors: newValues });
        setIsFormTouched(isFormChanged({ ...newValues }, { ...colors }))
    }

    const handleForm = () => {
        saveTerminalColors(values);
        setIsFormTouched(false);
    }

    return (
        <Spin spinning={isLoading}>
            <div className="rt--customize-widget-main-content-form">
                <div className="rt--flex rt--align-center rt--justify-between rt--pt-10 rt--pb-10 rt--mb-4 rt--customize-widget-main-content-form-item">
                    <span className="rt--title rt--font-normal rt--font-regular">{t("backoffice.terminals.brandColor")}</span>
                    <ColorPicker
                        color={values.brandColor}
                        onChange={value => onChange("brandColor", value)}
                    />
                </div>
                <div className="rt--flex rt--align-center rt--justify-between rt--pt-10 rt--pb-10 rt--customize-widget-main-content-form-item">
                    <span className="rt--title rt--font-normal rt--font-regular">{t("backoffice.terminals.backgroundColor")}</span>
                    <ColorPicker
                        color={values.backgroundColor}
                        onChange={value => onChange("backgroundColor", value)}
                    />
                </div>
                <div className="rt--flex rt--align-center rt--justify-between rt--pt-10 rt--pb-10 rt--customize-widget-main-content-form-item">
                    <span className="rt--title rt--font-normal rt--font-regular">{t("backoffice.terminals.textAndIconColor")}</span>
                    <ColorPicker
                        color={values.textAndIconColor}
                        onChange={value => onChange("textAndIconColor", value)}
                    />
                </div>
                <div className="rt--flex rt--justify-end rt--align-center rt--pt-24 rt--mt-16">
                    <Button
                        type="primary"
                        className="rt--button rt--button-primary"
                        onClick={handleForm}
                        loading={isSaving}
                        disabled={!isFormTouched}
                    >
                        <span>{t("backoffice.common.save")}</span>
                    </Button>
                </div>
            </div>
        </Spin>
    );
};

/** ColorsEditor propTypes
 * PropTypes
*/
ColorsEditor.propTypes = {
    /** Redux state promerty, terminal colors */
    colors: terminalColorsType,
    /** Redux action to get terminal colors */
    getTerminalColors: PropTypes.func,
    /** Redux action to save terminal colors */
    saveTerminalColors: PropTypes.func,
    /** Redux state promerty, is true when terminal saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    getTerminalColors: () => {
        dispatch(getTerminalColors())
    },
    saveTerminalColors: colors => {
        dispatch(saveTerminalColors(colors))
    }
});

const mapStateToProps = (state) => {
    return {
        isSaving: state.terminalCustomize.isSaving,
        colors: state.terminalCustomize.colors,
        isLoading: state.terminalCustomize.isLoading
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ColorsEditor);
