import axios from "axios";
import PATHS from "constants/api.constants";

export const savePlayerGeneralInfo = (data) => {
    return axios({
        url: PATHS.SAVE_PLAYER_GENERAL_INFO,
        method: "POST",
        data,
    })
}
