import {AGENT_COMMISSION_STATE} from "constants/agent.constants";

const calcCategoryCommissionPercent = ({ formInstance, category }) => {
    const providersCommissionPercent = Object.entries(formInstance.getFieldValue([category.id, "providers"]))
        .map(([_, provider]) => provider)
        .filter((provider) => provider.state === AGENT_COMMISSION_STATE.ACTIVE)
        .map((provider) => Number(provider.commissionPercent || 0));

    const count = providersCommissionPercent.length;
    const sum = providersCommissionPercent.reduce((acc, commissionPercent) => acc + commissionPercent, 0);

    formInstance.setFieldValue([category.id, "commissionPercent"], count ? Number((sum / count).toFixed(2)) : 0);
};

export default calcCategoryCommissionPercent;
