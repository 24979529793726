import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import { Form } from 'antd';

import Modal from 'components/common/modal';
import ImageUploaderComponent from 'components/common/imageUploader';

import { isFunction } from 'utils/common';
import { IMAGE_TYPE } from 'constants/common.constants';

const FORM_FIELD_NAMES = {
    FILE: "file",
}

const ProductEditComponent = ({
    onClose,
    onOk,
    data,
    isSaving
}) => {
    const { t } = useTranslation();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const handleForm = () => {
        validateFields()
            .then(formData => {
                if (isFunction(onOk)) {
                    onOk({
                        [FORM_FIELD_NAMES.FILE]: formData[FORM_FIELD_NAMES.FILE],
                        rowId: data.rowId
                    });
                    onClose();
                }

            }).catch(ex => {
                console.log(ex)
            })
    }

    return (
        <Modal
            title={t('backoffice.terminals.editProduct')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.save')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Form.Item
                    label=""
                    name={FORM_FIELD_NAMES.FILE}
                    className='rt--general-form-item rt--form-item-without-margin rt--mt-4'
                    validateFirst
                >
                    <ImageUploaderComponent
                        size={3 * 1024 * 1024}
                        acceptedFormats={ [ IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG, IMAGE_TYPE.WEBP, IMAGE_TYPE.SVG ]}
                        defaultFile={
                            data.file ? {
                                url: `${import.meta.env.SYSTEM_CDN_URL}/${data.file.toLowerCase()}`,
                                status: "done",
                                percent: 100
                            } : null
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}

/** ProductEditComponent propTypes
    * PropTypes
*/
ProductEditComponent.propTypes = {
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Fires on popup add button clicked */
    onOk: PropTypes.func,
    /** true when saving data */
    isSaving: PropTypes.bool,

}

export default ProductEditComponent;