import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { binaryToFlags } from 'utils/common';
import { getUser } from 'utils/auth';

import barCodeImage from 'assets/images/barcode.svg';
import retailLogoImage from 'assets/images/logo-retail-blue.svg';
import {VOUCHER_GENERAL_DETAILS, VOUCHER_INFO_DETAILS, VOUCHER_PREVIEW_VALUES} from "constants/voucher.contants";

/** Voucher Ticket Preview Component */
const VoucherPreviewComponent = ({
    voucher,
    currentVoucher
}) => {
    const { t } = useTranslation();

    const companyLongId = getUser()?.companyLongId ?? null;
    const projectLongId = getUser()?.projectLongId ?? null;

    const generalDetails = binaryToFlags(Object.values(VOUCHER_GENERAL_DETAILS), currentVoucher.generalDetails);
    const infoDetails = binaryToFlags(Object.values(VOUCHER_INFO_DETAILS), currentVoucher.infoDetails);

    const logo = voucher.ticketLogoId ? `${import.meta.env.SYSTEM_CDN_URL}/companies/${companyLongId}/projects/${projectLongId}/images/${voucher.ticketLogoId}_ticket_logo.png` : null

    return (
        <div id="rt--payout-ticket-preview" className="rt--print-wrapper rt--pt-20 rt--pb-20 rt--pl-16 rt--pr-16">
            <div className="rt--print">
                <div className="rt--print-header rt--justify-center rt--flex rt--mb-16">
                    {
                        logo ?
                            <img src={logo} alt="logo" /> :
                            <img src={retailLogoImage} alt="logo" />
                    }
                </div>
                {
                    (
                        generalDetails.includes(VOUCHER_GENERAL_DETAILS.BETSHOP_NAME) ||
                        generalDetails.includes(VOUCHER_GENERAL_DETAILS.CASHIER_USERNAME) ||
                        generalDetails.includes(VOUCHER_GENERAL_DETAILS.TERMINAL_NAME)
                    ) && (
                        <div className="rt--print-info rt--print-info-dashed rt--pb-16 rt--mb-16">
                            {
                                generalDetails.includes(VOUCHER_GENERAL_DETAILS.BETSHOP_NAME) && (
                                    <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                        <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.voucher.betshopName')}</span>
                                        <span className='rt--title rt--font-normal rt--font-regular'>{VOUCHER_PREVIEW_VALUES.BETSHOP_NAME}</span>
                                    </div>
                                )
                            }
                            {
                                generalDetails.includes(VOUCHER_GENERAL_DETAILS.CASHIER_USERNAME) && (
                                    <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                        <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.voucher.cashierUserName')}</span>
                                        <span className='rt--title rt--font-normal rt--font-regular'>{VOUCHER_PREVIEW_VALUES.CASHIER_USERNAME}</span>
                                    </div>
                                )
                            }
                            {
                                generalDetails.includes(VOUCHER_GENERAL_DETAILS.TERMINAL_NAME) && (
                                    <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                        <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.voucher.terminalName')}</span>
                                        <span className='rt--title rt--font-normal rt--font-regular'>{VOUCHER_PREVIEW_VALUES.TERMINAL_NAME}</span>
                                    </div>
                                )
                            }
                        </div>
                    )
                }

                {
                    (
                        generalDetails.includes(VOUCHER_GENERAL_DETAILS.VOUCHER_ID) ||
                        infoDetails.includes(VOUCHER_INFO_DETAILS.VOUCHER_CODE) ||
                        infoDetails.includes(VOUCHER_INFO_DETAILS.PAYOUT_DATE) ||
                        infoDetails.includes(VOUCHER_INFO_DETAILS.EXPIRATION_DATE)
                    ) && (
                        <div className="rt--print-info rt--print-info-dashed rt--pb-16 rt--mb-16">
                            <div className="rt--print-info-bet">
                                {
                                    generalDetails.includes(VOUCHER_GENERAL_DETAILS.VOUCHER_ID) && (
                                        <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.voucher.voucherId')}</span>
                                            <span className='rt--title rt--font-normal rt--font-regular'>{VOUCHER_PREVIEW_VALUES.VOUCHER_ID}</span>
                                        </div>
                                    )
                                }
                                {
                                    infoDetails.includes(VOUCHER_INFO_DETAILS.VOUCHER_CODE) && (
                                        <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.voucher.voucherCode')}</span>
                                            <span className='rt--title rt--font-normal rt--font-regular'>{VOUCHER_PREVIEW_VALUES.VOUCHER_CODE}</span>
                                        </div>
                                    )
                                }
                                {
                                    infoDetails.includes(VOUCHER_INFO_DETAILS.PAYOUT_DATE) && (
                                        <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.voucher.issueDate')}</span>
                                            <span className='rt--title rt--font-normal rt--font-regular'>{VOUCHER_PREVIEW_VALUES.CREATED_AT}</span>
                                        </div>
                                    )
                                }

                                {
                                    infoDetails.includes(VOUCHER_INFO_DETAILS.EXPIRATION_DATE) && (
                                        <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                                            <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.voucher.expirationDate')}</span>
                                            <span className='rt--title rt--font-normal rt--font-regular'>{VOUCHER_PREVIEW_VALUES.EXPIRATION_DATE}</span>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    )
                }

                {infoDetails.includes(VOUCHER_INFO_DETAILS.VOUCHER_AMOUNT) && (
                    <div className="rt--print-info rt--print-info-dashed rt--pb-16 rt--mb-16">
                        <div className="rt--print-info-bet">
                            <div className="rt--print-info-row rt--flex rt--align-center rt--justify-between rt--mb-4">
                            <span className='rt--title rt--font-normal rt--font-regular rt--font-bold'>{t('backoffice.voucher.voucherAmount')}</span>
                            <span className='rt--title rt--font-normal rt--font-regular rt--font-bold'>{VOUCHER_PREVIEW_VALUES.VOUCHER_AMOUNT}</span>
                            </div>
                        </div>
                    </div>
                )}

                <div className="rt--print-footer">
                    <div className="rt--print-footer-inner">
                        <div className="rt--print-footer-barcode rt--mb-16">
                            {
                                currentVoucher.enableBarcodePrinting && (
                                    <Fragment>
                                        <img src={barCodeImage} alt="barcode"/>
                                    </Fragment>
                                )
                            }
                        </div>
                        {
                            currentVoucher.customText && (
                                <span className="rt--title rt--font-normal rt--font-regular rt--print-footer-custom-text">{currentVoucher.customText}</span>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        currentVoucher: state.voucher.currentVoucher,
        voucher: state.voucher.voucher
    }
}

export default connect(
    mapStateToProps,
    null
)(
    VoucherPreviewComponent
);
