import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { Spin, Form, Row, Col, Button } from 'antd';

import {
    calculatePlayersPerformance,
    calculateAgentsPerformance,
    calculateBetshopsPerformance,
    calculateDashboardOperations,
    calculateCategoriesPerformance
} from "store/actions/dashboard/developer/calculateReports/calculateReports.action";

import MainDashboardLayout from "components/layouts/main";
import DatePicker from 'components/common/datePicker';

import useDate from 'hooks/useDate';
import useProjectType from "hooks/useProjectType";

import { isMobile } from "utils/common";
import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const REPORTS = {
    PLAYERS_PERFORMANCE: 1,
    AGENTS_PERFORMANCE: 2,
    BETSHOPS_PERFORMANCE: 3,
    DASHBOARD_OPERATIONS: 4,
    CATEGORIES: 5
}

/** Calculate Reports Page Component */
const CalculateReportsComponent = ({
    isLoading,
    calculatePlayersPerformance,
    calculateAgentsPerformance,
    calculateBetshopsPerformance,
    calculateDashboardOperations,
    calculateCategoriesPerformance
}) => {

    const { t } = useTranslation();

    const { dateService } = useDate();

    const [formInstance] = Form.useForm();

    const { getFieldsValue } = formInstance;

    const { hasLatamMode } = useProjectType();

    const hasModifyPermission = hasPermission({ resource: PERMISSION_RESOURCE.DEVELOPER, action: PERMISSION_ACTION.VIEW })

    const calculateReport = report => {
        const values = getFieldsValue();
        let dateTime = values["report_" + report];
        dateTime = dateTime ? dateService.format(dateTime, false, true, true, undefined, true) : null;

        if (dateTime) {
            switch (report) {
                case REPORTS.PLAYERS_PERFORMANCE:
                    calculatePlayersPerformance(dateTime);
                    break;
                case REPORTS.AGENTS_PERFORMANCE:
                    calculateAgentsPerformance(dateTime);
                    break;
                case REPORTS.BETSHOPS_PERFORMANCE:
                    calculateBetshopsPerformance(dateTime);
                    break;
                case REPORTS.DASHBOARD_OPERATIONS:
                    calculateDashboardOperations(dateTime);
                    break;
                case REPORTS.CATEGORIES:
                    calculateCategoriesPerformance(dateTime);
                    break;
                default:
                    break;
            }
        }
    }

    const availableReports = [ REPORTS.PLAYERS_PERFORMANCE, REPORTS.AGENTS_PERFORMANCE, REPORTS.BETSHOPS_PERFORMANCE, REPORTS.DASHBOARD_OPERATIONS];
    if(hasLatamMode){
        availableReports.push(REPORTS.CATEGORIES)
    }

    return (
        <MainDashboardLayout
            header={
                {
                    breadcrumbs: {
                        items: [
                            { title: t('backoffice.menu.calculateReports') }
                        ]
                    }
                }
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        ["report_" + REPORTS.PLAYERS_PERFORMANCE]: dateService.getNow(),
                        ["report_" + REPORTS.AGENTS_PERFORMANCE]: dateService.getNow(),
                        ["report_" + REPORTS.BETSHOPS_PERFORMANCE]: dateService.getNow(),
                        ["report_" + REPORTS.DASHBOARD_OPERATIONS]: dateService.getNow(),
                        ["report_" + REPORTS.CATEGORIES]: dateService.getNow()
                    }}
                >
                    <h4
                        className='rt--form-section-title rt--title rt--mb-16 rt--mt-8 rt--font-bold rt--font-big'>
                        {t("backoffice.menu.calculateReports")}
                    </h4>
                    {
                        availableReports.map(report => (
                            <Row gutter={[16, 0]} key={report}>
                                <Col xs={24} sm={18} lg={12} xl={8} xxl={6}>
                                    <div className="rt--flex rt--width-full rt--align-center">
                                        <div className="rt--mr-8 rt--flex-equal">
                                            <Form.Item
                                                className={!hasModifyPermission ? "rt--form-item-disabled" : ""}
                                                label={
                                                    report === REPORTS.PLAYERS_PERFORMANCE ? t("backoffice.menu.playerPerformanceReport") :
                                                        report === REPORTS.AGENTS_PERFORMANCE ? t("backoffice.menu.agentPerformanceReport") :
                                                            report === REPORTS.BETSHOPS_PERFORMANCE ? t("backoffice.menu.betshopPerformanceReport") :
                                                                report === REPORTS.DASHBOARD_OPERATIONS ? t("backoffice.menu.operational") :
                                                                    report === REPORTS.CATEGORIES ? t("backoffice.common.categories") : ""
                                                }
                                                name={"report_" + report}
                                                rules={[
                                                    { required: true, message: t('backoffice.validation.fieldRequired') }
                                                ]}
                                            >
                                                <DatePicker
                                                    className="rt--datepicker"
                                                    popupClassName='rt--datepicker-popup'
                                                    format={dateService.getFormat(false)}
                                                    disabledDate={d => !dateService.isInRange(d, dateService.getDate("2023 06 01"), dateService.endOfDay())}
                                                    showToday={false}
                                                    showNow={false}
                                                    allowClear={false}
                                                    placeholder={t('backoffice.common.selectDate')}
                                                    suffixIcon={<i className='icon-date rt--font-bigest' />}
                                                    getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                    inputReadOnly={isMobile()}
                                                    disabled={!hasModifyPermission}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div>
                                            <Form.Item
                                                label=" "
                                                className='rt--general-form-item'
                                            >
                                                <Button
                                                    type="primary"
                                                    htmlType="button"
                                                    className="rt--button rt--button-main rt--button-main-without-icon"
                                                    onClick={() => {
                                                        calculateReport(report)
                                                    }}
                                                    disabled={!hasModifyPermission}
                                                >
                                                    <span>{t('backoffice.common.calculate')}</span>
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        ))
                    }

                </Form>
            </Spin>
        </MainDashboardLayout>

    );
};

/** CalculateReportsComponent propTypes
 * PropTypes
*/
CalculateReportsComponent.propTypes = {
    /** Redux state property, is true when loading generations data */
    isLoading: PropTypes.bool,
    /** Redux action to calculate players performance */
    calculatePlayersPerformance: PropTypes.func,
    /** Redux action to calculate agents performance */
    calculateAgentsPerformance: PropTypes.func,
    /** Redux action to calculate betshops performance */
    calculateBetshopsPerformance: PropTypes.func,
    /** Redux action to calculate dashboard operations */
    calculateDashboardOperations: PropTypes.func,
    /** Redux action to calculate categories */
    calculateCategoriesPerformance: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
    calculatePlayersPerformance: dateTime => {
        dispatch(calculatePlayersPerformance(dateTime))
    },

    calculateAgentsPerformance: dateTime => {
        dispatch(calculateAgentsPerformance(dateTime))
    },

    calculateBetshopsPerformance: dateTime => {
        dispatch(calculateBetshopsPerformance(dateTime))
    },

    calculateDashboardOperations: dateTime => {
        dispatch(calculateDashboardOperations(dateTime))
    },

    calculateCategoriesPerformance: dateTime => {
        dispatch(calculateCategoriesPerformance(dateTime))
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.calculateReports.isLoading
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CalculateReportsComponent);
