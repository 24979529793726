import React, { useMemo, useRef } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import useFormat from "hooks/useFormat";
import useFirstRender from "hooks/useFirstRender";
import useAutosuggestion from "hooks/useAutosuggestion";
import useIncludedColumns from "hooks/useIncludedColumns";
import useDate from "hooks/useDate";


import { getUser } from "utils/auth";
import { hasPermission } from "utils/permissions";
import { getGlobalProjectData, isMobile}  from "utils/common";
import { tableColumnsCreator } from "utils/tableColumnsCreator";

import { getTableColumns } from "./columns";

import Table from "components/common/table";
import TotalsComponent from "components/common/totals";
import Select from "components/common/select";
import BetDetailsComponent from "../../betDetails.component";
import Filters from "./filters.component";
import SportBetsLayout from "./sportBetsLayout";

import ApiUrls from "constants/api.constants";
import { USER_ROLE } from "constants/user.constants";
import { REPORT_TOTAL_TYPE } from "constants/reports.constants";
import { REAL_TIME_SPORT_BETS } from "constants/pageName.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";

import { EXPAND_TABLE_TYPES } from "constants/common.constants";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

import sortingType from "types/common/sorting.type";
import userInfoType from "types/profile/userInfo.type";
import betsTotalsType from "types/bet/betsTotals.type";
import realTimeSportBetType from "types/bet/realTimeSportBet.type";

import {
    getRealTimeSportBetDetails,
    getRealTimeSportBets, setRealTimeCasinoBetsFilters,
    setRealTimeSportBetsFilters,
    setRealTimeSportBetsSorting
} from "store/actions/dashboard/betHistory/realTimeSportBets.action";
import useProjectType from "hooks/useProjectType";

const SportBets = ({
   isInTab,
   bets,
   totals,
   globalProjectId,
   getRealTimeSportBets,
   setRealTimeSportBetsSorting,
   setRealTimeSportBetsFilters,
   getRealTimeSportBetDetails,
   sorting,
   filters,
   isLoading,
   total,
   userInfo,
   details
}) => {
    const { t } = useTranslation();

    const { role } = getUser();
    const isAdmin = role === USER_ROLE.ADMIN;

    const { formatAmount } = useFormat();
    const { hasLiteMode } = useProjectType();

    const { dateService } = useDate();

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: REAL_TIME_SPORT_BETS });

    const [ agentNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AGENT,
        dependencies: [globalProjectId],
        actionParams: { excludeAgent: role === USER_ROLE.ADMIN }
    });

    const { name: globalProjectName } = getGlobalProjectData(globalProjectId)

    const possibleParents = [...agentNames];

    if (isAdmin) {
        possibleParents.unshift({
            name: globalProjectName,
            id: globalProjectId
        })
    }

    const isMobileDevice = isMobile();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = hasPermission({
        resource: PERMISSION_RESOURCE.BETS_ONLINE,
        action: PERMISSION_ACTION.EXPORT
    })

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//
    const handleSearchChange = (value, item, eventType) => {
        let playerValue = value;

        if (item && eventType === "select") {
            playerValue = item.longId;
        }

        setRealTimeSportBetsFilters({
            ...filters,
            playerUserNameOrId: playerValue
        });
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                hasLiteMode,
                formatAmount,
            }
        })
    }, [includedColumns, formatAmount])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters
            getRealTimeSportBets={() => getRealTimeSportBets("first")}
        />,
        search: {
            placeholder: t("backoffice.bets.playerUsernameOrId"),
            loadFn: getRealTimeSportBets,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.PLAYER,
                dependencies: [globalProjectId],
                autoGet: false,
                searchFieldName: "userNameOrId"
            },
            onSearch: handleSearchChange,
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        breadcrumbs: !isInTab ? {
            items: [
                { title: t('backoffice.menu.betHistory') },
                { title: t('backoffice.menu.realTimeBets') }
            ]
        } : undefined
    }

    const generateExportFilters = () => {
        const f = { ...filters }

        let from = dateService.mergeDateAndTime(filters.day, filters.time[0]);
        let to = dateService.mergeDateAndTime(filters.day, filters.time[1]);

        from = dateService.toISOString(from);
        to = dateService.toISOString(to);

        delete f.day;
        delete f.time;

        f.from = from;
        f.to = to;

        return f;
    }

    const getBetslipDetails = record => {
        if (details[record.id] === undefined) {
            return []
        }

        return details[record.id].map(bet => ({ ...bet, betType: record.betSlipType }))
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.realTimeSportBets"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_REAL_TIME_SPORT_BETS,
            filters: generateExportFilters()
        }
    }

    //#endregion

    return (
        <SportBetsLayout
            isInTab={isInTab}
            header={headerPartsData}
            extraContent={{
                content: (
                    <TotalsComponent
                        totals={totals}
                        isLoading={isLoading}
                        currencyCode={filters.currencyCode}
                        totalType={REPORT_TOTAL_TYPE.REAL_TIME_BETS}
                    />
                )
            }}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={bets}
                loadFn={getRealTimeSportBets}
                sorting={sorting}
                filters={filters}
                setFiltersFn={setRealTimeSportBetsFilters}
                setSortingFn={setRealTimeSportBetsSorting}
                total={total}
                updateProps={[globalProjectId]}
                expandable={{
                    title: t("backoffice.bets.bets"),
                    type: EXPAND_TABLE_TYPES.CONTENT,
                    detailsLoadFn: record => (
                        !Boolean(details[record.id]) && getRealTimeSportBetDetails(record.id)
                    ),
                    details: record => ({
                        data: getBetslipDetails(record),
                        content: (
                            <BetDetailsComponent
                                bets={getBetslipDetails(record)}
                                currencyCode={record.currencyCode}
                                isRealTimeBet={true}
                            />
                        ),
                    }),
                }}
            />
        </SportBetsLayout>
    );
}

/** RealTimeSportBets propTypes
 * PropTypes
 */
SportBets.propTypes = {
    isInTab: PropTypes.bool,
    /** Redux state property, represents the array of real time sport bets */
    bets: PropTypes.arrayOf(realTimeSportBetType),
    /** Redux state property, represents the array of real time sport bets totals */
    totals: betsTotalsType,
    /** Redux state property, represents global project id */
    globalProjectId: PropTypes.string,
    /** Redux state property, real time sport bets sorting details */
    sorting: sortingType,
    /** Redux state property, real time sport bets filters */
    filters: PropTypes.object,
    /** Redux state property, real time sport bet details */
    details: PropTypes.object,
    /** Redux state property, real time sport bets total count */
    total: PropTypes.number,
    /** Redux action to get real time sport bets */
    getRealTimeSportBets: PropTypes.func,
    /** Redux action to get real time sport bet details */
    getRealTimeSportBetDetails: PropTypes.func,
    /** Redux action to set real time sport bets sorting details */
    setRealTimeSportBetsSorting: PropTypes.func,
    /** Redux action to set real time sport bets filters */
    setRealTimeSportBetsFilters: PropTypes.func,
    /** Redux state property, is true when loading bet history */
    isLoading: PropTypes.bool,
    /** Redux state property, the user info */
    userInfo: userInfoType
}

const mapDispatchToProps = dispatch => (
    {
        getRealTimeSportBets: (nextPage, agentId) => {
            dispatch(getRealTimeSportBets(nextPage, agentId))
        },
        setRealTimeSportBetsSorting: sorting => {
            dispatch(setRealTimeSportBetsSorting(sorting));
        },
        setRealTimeSportBetsFilters: filters => {
            dispatch(setRealTimeSportBetsFilters(filters));
        },

        getRealTimeSportBetDetails: id => {
            dispatch(getRealTimeSportBetDetails(id));
        }
    }
)

const mapStateToProps = state => {
    return {
        globalProjectId: state.common.globalProjectId,
        isLoading: state.betHistory.isLoading,
        total: state.betHistory.realTimeBets.sportBets.total,
        bets: state.betHistory.realTimeBets.sportBets.bets,
        totals: state.betHistory.realTimeBets.sportBets.totals,
        sorting: state.betHistory.realTimeBets.sportBets.sorting,
        filters: state.betHistory.realTimeBets.sportBets.filters,
        details: state.betHistory.realTimeBets.sportBets.details,
        userInfo: state.profile.userInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SportBets);
