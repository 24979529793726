import React, {useState, useEffect, useCallback, useRef, Fragment, forwardRef, useImperativeHandle} from "react";

import { useTranslation } from "react-i18next";

import { Input, Form } from 'antd';

import AutoComplete from "components/common/autoComplete";
import Modal from "components/common/modal";

import useAutosuggestion from "hooks/useAutosuggestion";

import { debounce, isMobile } from "utils/common";

import searchType from 'types/components/search.type';

/** Autocomplete Search Component */
const AutocompleteSearch = forwardRef(({
    value: controlledValue,
    autoSuggestion,
    loadFn,
    placeholder,
    onSearch,
    onChange,
    isInForm,
    openWithModalInMobile,
    className = ''
}, ref) => {

    const { t } = useTranslation();

    const isSearched = useRef("");

    const inputRef = useRef(null);
    const modalInputRef = useRef(null);

    const [value, setValue] = useState("");
    const [loading, setLoading] = useState(false);

    const [showAutosuggestionModal, setShowAutosuggestionModal] = useState(false);

    const shouldOpenAutoSuggestionWithModal = isMobile() && openWithModalInMobile;

    const [autoSuggestionData, getAutoSuggestionData, resetAutoSuggestionData, isAutoSuggestionLoading] = useAutosuggestion({
        type: autoSuggestion?.type,
        dependencies: autoSuggestion?.dependencies,
        actionParams: autoSuggestion?.actionParams,
        autoGet: autoSuggestion?.autoGet
    });

    const autoSuggestionItems = autoSuggestion?.items ?? autoSuggestionData;

    useEffect(() => {
        setValue(controlledValue);
    }, [controlledValue]);

    const doSearch = (v, eventType) => {
        isSearched.current = v;

        const searchedItem = v !== "" ? autoSuggestionItems.find(item => (
            item.longId?.toString() === v ||
            item.name?.toLowerCase() === v.toLowerCase()
        )) || null : null

        onSearch?.(v, searchedItem, eventType);
        setTimeout(() => {
            loadFn?.(true);
        }, 20)

    }

    const handleChange = v => {
        if (v) {
            setValue(v);
        } else {
            clear();
        }
        onChange?.(v);
    }

    const handleSearch = (v, eventType) => {
        setValue(v);

        if (eventType === 'select' || isSearched.current !== v) {
            doSearch(v, eventType);
        }
    }

    const clear = e => {
        e?.stopPropagation();
        handleSearch("", "clear")
    };

    const handleDebounceFn = v => {
        if (autoSuggestion?.autoGet === false) {
            if (v?.length >= 3) {
                getAutoSuggestionData({ [autoSuggestion?.searchFieldName]: v })
            }
        }
    }

    const debounceSearch = useCallback(
        debounce(
            handleDebounceFn,
            1000,
            {
                before: v => {
                    if (v?.length >= 3) {
                        setLoading(true);
                    }
                },
                after: () => setLoading(false)
            }
        ), []);

    useImperativeHandle(ref, () => ({
        clearValue() {
            handleSearch("", "clear")
        }
    }), [handleSearch]);

    /** Reset autoSuggestion data for autoGet=false */
    useEffect(() => {
        if (value === "" && autoSuggestion?.autoGet === false) {
            resetAutoSuggestionData()
        }
    }, [value])


    /** Open modal if shouldOpenAutoSuggestionWithModal */
    const handleAutoSuggestionClick = () => {
        setShowAutosuggestionModal(true);
        setTimeout(() => {
            modalInputRef.current?.focus();
        }, 400)
    }

    const handleModalAutoCompleteSelect = v => {
        handleSearch(v, "select");
        setShowAutosuggestionModal(false)
    }

    return (
        <Fragment>
            <div
                className={
                    (isInForm ? "rt--form-item-search" : "rt--table-search") +
                    (shouldOpenAutoSuggestionWithModal ? " rt--form-item-search-with-modal" : "") +
                    (className ? ` ${className}` : "")
                }
                onClick={shouldOpenAutoSuggestionWithModal ? handleAutoSuggestionClick : undefined}
            >

                <AutoComplete
                    items={autoSuggestionItems}
                    getPopupContainer={
                        isMobile() ? trigger => trigger.parentNode : () => document.getElementsByClassName("rt--dashboard-layout")[0]
                    }
                    disableFiltering={autoSuggestion?.autoGet === false} // if autoGet is false, then data is filtered from backend
                    onChange={handleChange}
                    onSelect={v => handleSearch(v, "select")}
                    value={value}
                    loading={loading || isAutoSuggestionLoading}
                    disabled={shouldOpenAutoSuggestionWithModal}
                >
                    <Input
                        type='search'
                        placeholder={placeholder}
                        prefix={(<i className="icon-search rt--font-bigest" />)}
                        suffix={value ? (<i className="icon-close rt--font-bigest" onClick={clear} />) : undefined}
                        value={value}
                        onPressEnter={e => {
                            if (loading) {
                                e.stopPropagation();
                                return;
                            }

                            const inputValue = e?.target?.value ?? "";
                            if (inputValue && inputValue.length > 1) {
                                handleSearch(inputValue, "enter");
                            }
                        }}
                        onChange={autoSuggestion?.autoGet === false ? e => debounceSearch(e.target.value) : undefined}
                        ref={inputRef}
                    />
                </AutoComplete>
            </div>

            {
                showAutosuggestionModal && (
                    <Modal
                        title={`${t("backoffice.common.select")} ${t("backoffice.common.player")}`}
                        onCancel={() => setShowAutosuggestionModal(false)}
                        closeIcon="icon-left"
                    >
                        <div className="rt--form-item-search-modal">
                            <div
                                className="rt--form-item-search"
                            >
                                <AutoComplete
                                    items={autoSuggestionItems}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    disableFiltering={autoSuggestion?.autoGet === false} // if autoGet is false, then data is filtered from backend
                                    onChange={handleChange}
                                    onSelect={handleModalAutoCompleteSelect}
                                    value={value}
                                    loading={loading || isAutoSuggestionLoading}
                                >
                                    <Input
                                        type='search'
                                        placeholder={placeholder}
                                        prefix={(<i className="icon-search rt--font-bigest" />)}
                                        suffix={value ? (<i className="icon-close rt--font-bigest" onClick={clear} />) : undefined}
                                        value={value}
                                        onChange={autoSuggestion?.autoGet === false ? e => debounceSearch(e.target.value) : undefined}
                                        ref={modalInputRef}
                                    />
                                </AutoComplete>
                            </div>
                        </div>
                    </Modal>
                )
            }

        </Fragment>

    )
});

/** Search propTypes
    * PropTypes
*/
AutocompleteSearch.propTypes = searchType;

export default AutocompleteSearch;

