import React from "react";

import TerminalsComponent from "pages/retail/terminals/terminals.component";

import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

import Paths from "constants/path.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";

const TerminalsRoute = () => {
    return <TerminalsComponent />;
};

export default withPermission(
    withAuth(TerminalsRoute),
    { resource: PERMISSION_RESOURCE.TERMINAL, action: PERMISSION_ACTION.VIEW },
    Paths.DEFAULT
);
