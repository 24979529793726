import { BET_STATE } from "constants/bet.constants";
import { COMMISSION_PLAN_STATUS } from "constants/commission.constants";
import { DB_CONNECTION_STATE } from "constants/common.constants";
import { COMPANY_STATE } from "constants/company.constants";
import { JOB_STATE } from "constants/job.constants";
import { PLAYER_STATE } from "constants/player.constants";
import { TERMINAL_STATE } from "constants/terminal.constants";
import { BANK_STATUS } from "constants/project.constants";
import { STATUS_TYPES } from "constants/status.constants";
import { USER_STATE } from "constants/user.constants";

export const STATUS_RESOURCES_BY_TYPE = {
    //#region - BANK
    [STATUS_TYPES.BANK]: {
        color: {
            [BANK_STATUS.ACTIVE]: "green",
            [BANK_STATUS.DELETED]: "red",
            [BANK_STATUS.DEACTIVATED]: "red",
        },
        translationSource: {
            [BANK_STATUS.ACTIVE]: "backoffice.common.active",
            [BANK_STATUS.DELETED]: "backoffice.common.deleted",
            [BANK_STATUS.DEACTIVATED]: "backoffice.common.deactivated",
        }
    },
    //#endregion

    //#region - BET
    [STATUS_TYPES.BET]: {
        color: {
            [BET_STATE.PENDING]: "orange",
            [BET_STATE.RETURN]: "red",
            [BET_STATE.CANCELLED]: "red",
            [BET_STATE.WON]: "green",
            [BET_STATE.SEMIWON]: "green",
            [BET_STATE.SEMILOST]: "red",
            [BET_STATE.LOST]: "red",
            [BET_STATE.PAID_OUT]: "green",
            [BET_STATE.CASHED_OUT]: "orange"
        },
        translationSource: {
            [BET_STATE.PENDING]: "backoffice.bets.pending",
            [BET_STATE.RETURN]: "backoffice.bets.return",
            [BET_STATE.WON]: ({cashedOut}) => cashedOut ? "backoffice.bets.cashOutWon" : "backoffice.bets.won",
            [BET_STATE.SEMIWON]: "backoffice.bets.semiWon",
            [BET_STATE.SEMILOST]: "backoffice.bets.semiLost",
            [BET_STATE.LOST]: "backoffice.bets.lost",
            [BET_STATE.CANCELLED]: "backoffice.bets.cancelled",
            [BET_STATE.REJECTED_BY_PROVIDER]: "backoffice.bets.rejectedByProvider",
            [BET_STATE.PAID_OUT]: "backoffice.bets.paidOut",
            [BET_STATE.CASHED_OUT]: "backoffice.bets.cashedOut",
        }
    },
    //#endregion

    //#region - COMPANY
    [STATUS_TYPES.COMPANY]: {
        color: {
            [COMPANY_STATE.ACTIVE]: "green",
            [COMPANY_STATE.INACTIVE]: "red",
        },
        translationSource: {
            [COMPANY_STATE.ACTIVE]: "backoffice.common.active",
            [COMPANY_STATE.INACTIVE]: "backoffice.common.inactive",
        }
    },
    //#endregion

    //#region - JOB
    [STATUS_TYPES.JOB]: {
        color: {
            [JOB_STATE.RUNNING]: "orange",
            [JOB_STATE.SUCCESS]: "green",
            [JOB_STATE.FAILED]: "red"
        },
        translationSource: {
            [JOB_STATE.RUNNING]: "backoffice.jobs.state0",
            [JOB_STATE.SUCCESS]: "backoffice.jobs.state1",
            [JOB_STATE.FAILED]: "backoffice.jobs.state2"
        }
    },
    //#endregion

    //#region - PLAYER
    [STATUS_TYPES.PLAYER]: {
        color: {
            [PLAYER_STATE.ACTIVE]: "green",
            [PLAYER_STATE.PARTIALY_BLOCKED]: "red",
            [PLAYER_STATE.BLOCKED]: "red",
            [PLAYER_STATE.UNKNOWN]: "orange"
        },
        translationSource: {
            [PLAYER_STATE.ACTIVE]: "backoffice.common.active",
            [PLAYER_STATE.PARTIALY_BLOCKED]: "backoffice.players.partiallyBlocked",
            [PLAYER_STATE.BLOCKED]: "backoffice.common.blocked",
            [PLAYER_STATE.UNKNOWN]: "backoffice.players.unknown"
        }
    },
    //#endregion

    //#region - ONLINE/OFFLINE
    [STATUS_TYPES.ONLINE_OFFLINE]: {
        color: {
            online: "green",
            offline: "grey",
        }
    },
    //#endregion

    //#region - COMMISSION
    [STATUS_TYPES.COMMISSION]: {
        color: {
            [COMMISSION_PLAN_STATUS.ACTIVE]: "green",
            [COMMISSION_PLAN_STATUS.DEACTIVE]: "red",
        },
        translationSource: {
            [COMMISSION_PLAN_STATUS.ACTIVE]: "backoffice.common.active",
            [COMMISSION_PLAN_STATUS.DEACTIVE]: "backoffice.common.deactivated"
        }
    },
    //#endregion

    //#region - DB Connection
    [STATUS_TYPES.DBCONNECTION]: {
        color: {
            [DB_CONNECTION_STATE.ACTIVE]: "green",
            [DB_CONNECTION_STATE.IDLE]: "orange"
        },
        translationSource: {
            [DB_CONNECTION_STATE.ACTIVE]: "backoffice.common.active",
            [DB_CONNECTION_STATE.IDLE]: "backoffice.common.idle"
        }
    },
    //#endregion

    //#region - USER
    [STATUS_TYPES.USER]: {
        color: {
            [USER_STATE.ACTIVE]: "green",
            [USER_STATE.IN_PROGRESS]: "grey",
            [USER_STATE.LOCKED2FA]: "orange",
            [USER_STATE.LOCKEDLOGIN]: "orange",
            [USER_STATE.BLOCKED]: "red",
            [USER_STATE.EXPIRED]: "red",
            [USER_STATE.DELETED]: "red",
        },
        translationSource: {
            [USER_STATE.ACTIVE]: "backoffice.common.active",
            [USER_STATE.IN_PROGRESS]: "backoffice.common.inactive",
            [USER_STATE.BLOCKED]: "backoffice.common.blocked",
            [USER_STATE.EXPIRED]: "backoffice.common.expired",
            [USER_STATE.DELETED]: "backoffice.common.deleted",
            [USER_STATE.LOCKED2FA]: "backoffice.common.locked",
            [USER_STATE.LOCKEDLOGIN]: "backoffice.common.locked",
        }
    },
    //#endregion

    //#region - TERMINAL
    [STATUS_TYPES.TERMINAL]: {
        color: {
            [TERMINAL_STATE.INACTIVE]: "grey",
            [TERMINAL_STATE.ACTIVE]: "green",
            [TERMINAL_STATE.BLOCKED]: "red",
        },
        translationSource: {
            [TERMINAL_STATE.INACTIVE]: "backoffice.common.inactive",
            [TERMINAL_STATE.ACTIVE]: "backoffice.common.active",
            [TERMINAL_STATE.BLOCKED]: "backoffice.common.blocked"
        }
    },
    //#endregion
}
