import React from 'react';

import { TERMINAL_SECTION_TYPE } from 'constants/terminalCustomize.constants';

import { makeHeaderMenuItemIcon, makeProductName, makeProviderName } from 'pages/terminalCustomize/helpers';

export const tableColumns = [
    {
        title: "backoffice.terminals.icon",
        dataIndex: "icon",
        render: (_, record) => (
            <i className={`${makeHeaderMenuItemIcon(record)} rt--font-bigest`} />
        ),
        width: 56
    },
    {
        title: "backoffice.terminals.navigationItem",
        dataIndex: "navigationItem",
        render: (_, record) => makeProductName(record, TERMINAL_SECTION_TYPE.HEADER)
    },
    {
        title: "backoffice.terminals.provider",
        dataIndex: "provider",
        render: (_, record) => makeProviderName(record, TERMINAL_SECTION_TYPE.HEADER) ? (
            <div className='rt--tag'>
                { makeProviderName(record, TERMINAL_SECTION_TYPE.HEADER) }
            </div>
        ) : "-"
    },
]