import React, { useMemo, useState, useEffect, Fragment, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Form, Row, Col, Radio, Spin, Space } from "antd";

import Search from 'components/common/search';
import NumericInput from 'components/common/numericInput';
import TextAreaInput from 'components/common/textAreaInput';
import Select from 'components/common/select';
import FavoriteAmounts from '../favoriteAmounts';

import {
    getPlayerInfo,
    playerDeposit,
    playerWithdrawal
} from "../../../../api";

import { WALLET_TRANSACTION_TYPE } from 'constants/wallet.constants';
import { USER_ROLE } from 'constants/user.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { PLAYER_STATE } from 'constants/player.constants';
import { FILTER_TYPE } from 'constants/common.constants';
import { AMOUNT_REGEX } from 'constants/regex.constants';
import { TRANSACTION_SOURCE, TRANSFER_ENTITY_TYPE, TRANSFER_OPERATION_GROUP, TRANSFER_POPUP_OK_ACTION_EVENT_NAME, TRANSFER_SUCCESS_EVENT_NAME } from '../../../../constants';

import useProjectType from 'hooks/useProjectType';
import useFormat from 'hooks/useFormat';

import useTransfer from '../../../../hooks/useTransfer';

import { getUser } from 'utils/auth';
import { hasPlayerNonDirectTransactionAccess } from 'utils/access';
import { isResponseSuccess } from 'utils/request';
import { isMobile, triggerCustomEvent } from 'utils/common';

import {amountValidator, formatNotes, isWalletConfigNonRelated} from '../../../../helpers';

import userInfoType from 'types/profile/userInfo.type';


const getOperationTypeByGroup = group => {
    if (group === TRANSFER_OPERATION_GROUP.DEPOSIT) {
        return WALLET_TRANSACTION_TYPE.PLAYER_DEPOSIT
    } else if (group === TRANSFER_OPERATION_GROUP.WITHDRAWAL) {
        return WALLET_TRANSACTION_TYPE.PLAYER_WITHDRAWAL
    } else {
        return WALLET_TRANSACTION_TYPE.PLAYER_DEPOSIT
    }
}

const operationsMapping = {
    [WALLET_TRANSACTION_TYPE.PLAYER_DEPOSIT]: playerDeposit,
    [WALLET_TRANSACTION_TYPE.PLAYER_WITHDRAWAL]: playerWithdrawal
}


const PlayerTransferForm = ({
    userInfo,
    currency
}) => {
    const { t } = useTranslation();

    const { transferData, setSaving, setTransferResultData } = useTransfer();

    const { userId, currencyCode, group, additionalData } = transferData ?? {};

    const [operationType, setOperationType] = useState(getOperationTypeByGroup(group));

    const [selectedPlayer, setSelectedPlayer] = useState(userId ?? null)

    const [selectedPlayerInfo, setSelectedPlayerInfo] = useState( currencyCode ? { currencyCode, ...( additionalData ?? {})} : null);
    const [isCurrencyLoading, setIsCurrencyLoading] = useState(false);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue, getFieldValue } = formInstance;

    const { hasLiteMode } = useProjectType();

    const { formatNumber, formatCurrencyWithSymbol } = useFormat();

    /** Transaction source field */

    const showTransactionSourceField = useMemo(() => {
        if(hasLiteMode){
            return false;
        }
        if( isWalletConfigNonRelated()){
            return false
        }
        return getUser()?.role !== USER_ROLE.ADMIN;

    }, [hasLiteMode]);

    /** Load Player Info */
    useEffect(() => {
        if( selectedPlayer ){
            setIsCurrencyLoading(true);
            getPlayerInfo(selectedPlayer)
                .then(
                    result => setSelectedPlayerInfo(result)
                )
                .finally(
                    () => setIsCurrencyLoading(false)
                );
            }
    }, [selectedPlayer])


    /** Handle player search change */
    const handlePlayerSearch = searchedItem => {
        if (searchedItem) {
            setSelectedPlayer(searchedItem.id)
        } else {
            setSelectedPlayer(null);
            setFieldsValue({ userId: undefined });
            setSelectedPlayerInfo(null)
        }
    }

    const handleFavAmountClick = value => {
        const currentValue = getFieldValue("amount") ?? 0;
        setFieldsValue({amount: Number(currentValue) + value})
    }

    const onTransferRequest = data => {
        if (isResponseSuccess(data)) {
            const resp = data.value;
            if(resp){
                setTransferResultData(resp?.wallets);
                triggerCustomEvent(
                    TRANSFER_SUCCESS_EVENT_NAME,
                    {
                        transactionType : operationType,
                        entityType: TRANSFER_ENTITY_TYPE.PLAYER,
                        wallets: resp?.wallets
                    }
                );
            }
        }
    }

    const handler = useCallback(() => {
        validateFields()
            .then(data => {
                setSaving(true);

                const d = {
                    notes: formatNotes(data.notes),
                    amount: Number(data.amount),
                    playerId: userId ? userId : selectedPlayer,
                    currencyCode: selectedPlayerInfo?.currencyCode
                }

                if(showTransactionSourceField){
                    d.transactionSource = data.transactionSource;
                } else {
                    d.transactionSource = TRANSACTION_SOURCE.FROM_BALANCE;
                }

                operationsMapping[operationType]?.(d)
                    .then(data => onTransferRequest(data))
                    .finally(() => setSaving(false));

            }).catch(ex => {
                console.log(ex)
            })

    }, [operationType, selectedPlayer, selectedPlayerInfo, hasLiteMode])

    /** On Transfer */
    useEffect(() => {
        document.addEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);

        return () => {
            document.removeEventListener(TRANSFER_POPUP_OK_ACTION_EVENT_NAME, handler);
        }
    }, [handler])

    return (
        <Form
            className="rt--form"
            form={formInstance}
            colon={false}
            requiredMark={false}
            layout="vertical"
            initialValues={{
                transactionSource: TRANSACTION_SOURCE.FROM_BALANCE
            }}
        >
            <Row gutter={[16, 0]}>
                {
                    !group && (
                        <Col span={24}>
                            <div className='rt--mb-24'>
                                <Form.Item
                                    label=""
                                    className='rt--form-item-inline rt--form-item-radio-buttons'
                                >
                                    <Radio.Group
                                        optionType="button"
                                        options={
                                            [
                                                {
                                                    label: <span title={t('backoffice.wallet.deposit')}>{t('backoffice.wallet.deposit')}</span>,
                                                    value: WALLET_TRANSACTION_TYPE.PLAYER_DEPOSIT
                                                },
                                                {
                                                    label: <span title={t('backoffice.wallet.withdrawal')}>{t('backoffice.wallet.withdrawal')}</span>,
                                                    value: WALLET_TRANSACTION_TYPE.PLAYER_WITHDRAWAL
                                                }
                                            ]
                                        }
                                        value={operationType}
                                        onChange={e => setOperationType(e.target.value)}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                    )
                }

                {
                    showTransactionSourceField && (
                        <Col span={24}>
                             <Form.Item
                                label={`${t("backoffice.wallet.source")} *`}
                                name="transactionSource"
                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                                className='rt--form-item-radio'
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            label: t('backoffice.wallet.fromBalance'),
                                            value: TRANSACTION_SOURCE.FROM_BALANCE,
                                        },
                                        {
                                            label: t('backoffice.wallet.fromCredit'),
                                            value: TRANSACTION_SOURCE.BY_CASH
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    )
                }

                {
                    !userId && (
                        <Col span={24}>
                            <Form.Item
                                label={`${t('backoffice.common.player')} *`}
                                name="userId"
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') }
                                ]}
                            >
                                    <Search
                                        placeholder={t("backoffice.players.searchUsernameOrId")}
                                        onSearch={(_, searchedItem) => handlePlayerSearch(searchedItem)}
                                        autoSuggestion={{
                                            type: AUTOSUGGESTION_TYPE.PLAYER,
                                            autoGet: false,
                                            searchFieldName: "userNameOrId",
                                            actionParams: {
                                                filterType: !hasPlayerNonDirectTransactionAccess(userInfo) ? FILTER_TYPE.DIRECT : FILTER_TYPE.NETWORK,
                                                state: PLAYER_STATE.ACTIVE
                                            }
                                        }}
                                        isInForm={true}
                                        openWithModalInMobile={true}
                                    />
                            </Form.Item>
                        </Col>
                    )
                }

                {
                    !isCurrencyLoading ? (
                        <Fragment>
                            {
                                selectedPlayerInfo && (
                                    <Col span={24}>
                                        <div className='rt--mt-8 rt--mb-16 rt--transfer-info rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16'>
                                            {
                                                userId && (
                                                    <div className='rt--flex rt--justify-between rt--align-center rt--mb-6'>
                                                        <span className='rt--text-secondary rt--font-normal rt--font-regular'>{t("backoffice.common.username")}:</span>
                                                        <span className='rt--title rt--font-normal rt--font-bold'>{selectedPlayerInfo?.userName}</span>
                                                    </div>
                                                )
                                            }
                                            <div className='rt--flex rt--justify-between rt--align-center rt--mb-6'>
                                                <span className='rt--text-secondary rt--font-normal rt--font-regular'>{t("backoffice.common.balance")}:</span>
                                                <span className='rt--title rt--font-normal rt--font-bold'>{formatNumber(selectedPlayerInfo?.balance)}</span>
                                            </div>
                                            <div className='rt--flex rt--justify-between rt--align-center'>
                                                <span className='rt--text-secondary rt--font-normal rt--font-regular'>{t("backoffice.players.withdrawableBalance")}:</span>
                                                <span className='rt--title rt--font-normal rt--font-bold'>{formatNumber(selectedPlayerInfo?.withdrawableBalance)}</span>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            }
                            <Col span={24}>
                                {
                                    hasLiteMode ? (
                                        <Form.Item
                                            label={
                                                `${formatCurrencyWithSymbol(t('backoffice.wallet.amountToSend'), selectedPlayerInfo?.currencyCode || currency)} *`}
                                            name="amount"
                                            rules={[
                                                { required: true, message: t('backoffice.validation.fieldRequired') },
                                                { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                { validator: amountValidator }
                                            ]}
                                            validateFirst
                                            className="rt--general-form-item"
                                            data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                        >

                                            <NumericInput
                                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                autoFocus={!isMobile()}
                                            />
                                        </Form.Item>
                                    ) : (
                                        <Form.Item
                                            label={`${t('backoffice.wallet.amountToSend')} *`}
                                            className="rt--form-item-without-margin rt--spend-amount"
                                        >
                                            <Space.Compact
                                                className='rt--transfer-amount'
                                            >
                                                <Form.Item
                                                    className={ 'rt--transfer-amount-currency' + (!userId ? " rt--form-item-disabled" : "") }
                                                >
                                                    <Select
                                                        placeholder="CUR"
                                                        value={selectedPlayerInfo?.currencyCode ?? null}
                                                        options={[]}
                                                        getPopupContainer={() => document.getElementsByClassName("rt--dashboard-layout")[0]}
                                                        showArrow={false}
                                                        disabled={Boolean(selectedPlayerInfo?.currencyCode)}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name="amount"
                                                    rules={[
                                                        { required: true, message: t('backoffice.validation.fieldRequired') },
                                                        { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                                        { validator: amountValidator }
                                                    ]}
                                                    validateFirst
                                                    className="rt--transfer-amount-amount rt--general-form-item"
                                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                >
                                                    <NumericInput
                                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                                        autoFocus={false}
                                                    />
                                                </Form.Item>

                                            </Space.Compact>
                                        </Form.Item>
                                    )
                                }
                            </Col>
                            <Col span={24}>
                                <FavoriteAmounts
                                    handleClick={handleFavAmountClick}
                                    entityType={TRANSFER_ENTITY_TYPE.PLAYER}
                                    currencyCode={selectedPlayerInfo?.currencyCode}
                                />
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label={t('backoffice.wallet.notes')}
                                    name="notes"
                                    rules={[
                                        {max: 1000, message: t('backoffice.validation.fieldInvalid')}
                                    ]}
                                    className="rt--form-item-without-margin rt--general-form-item"
                                    data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                                >
                                    <TextAreaInput
                                        placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                                        maxLength={1000}
                                        rows={6}
                                    />
                                </Form.Item>
                            </Col>

                        </Fragment>
                    ) : (
                        <div className='rt--width-full rt--transfer-loading'>
                            <Spin />
                        </div>
                    )
                }
            </Row>
        </Form>
    )
}

/** PlayerTransferForm propTypes
    * PropTypes
*/
PlayerTransferForm.propTypes = {
    /** Redux state property, the user info */
    userInfo: userInfoType
}

const mapStateToProps = state => {
    return {
        userInfo: state.profile.userInfo,
        currency: state.profile.currency,
    }
}

export default connect(mapStateToProps, null)(PlayerTransferForm);
