import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";

import { Spin } from "antd"

import Container from 'pages/common/container';

import { initTranslations } from 'translations/config';

import { isFranchisingMode } from "utils/common";
import { isAuthenticated} from 'utils/auth';
import sessionStorageUtils from 'utils/sessionStorage';
import LanguageUtils from 'utils/languages';

import { getLanguages } from 'store/actions/auth/translations.action';
import { getUserInfo } from "store/actions/dashboard/profile/userInfo.action";

import Styles from '../../components//styles'
import Hotjar from "components/common/hotjar";


const Layout = ({
    getLanguages,
    translationsLoaded,
    getUserInfo,
}) => {
    /** Set Page title */
    useEffect(() => {
        const titleElement = document.getElementsByTagName("title")[0];
        if (titleElement) {
            titleElement.innerText = isFranchisingMode() ? "Retail Solution" : "Agent System"
        }
    }, [])

    /** Get system languages, Get user info */
    useEffect(() => {
        if(isAuthenticated()){
            if(!sessionStorageUtils.get("userInfo")){
                getUserInfo();
            } else {
                const info = sessionStorageUtils.get("userInfo");
                initTranslations(LanguageUtils.getSelectedLanguage(), info?.translationPath)
            }
            getLanguages();
        }
    }, [])

    if(!isAuthenticated()){
        return <Outlet />;
    }

    return translationsLoaded ? (
        <Container>
            <Styles/>
            <Hotjar />
            <Outlet />
        </Container>
    ) : <Spin spinning={true} />
}

/** Layout propTypes
    * PropTypes
*/
Layout.propTypes = {
    /** Redux action to get system languages */
    getLanguages: PropTypes.func,
    /** Redux state property , is true when translations are loaded*/
    translationsLoaded: PropTypes.bool,
    /** Redux action to get user info */
    getUserInfo: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        translationsLoaded: state.auth.translations.loaded,
    }
}

const mapDispatchToProps = dispatch => (
    {
        getLanguages: () => {
            dispatch(getLanguages());
        },

        getUserInfo: () => {
            dispatch(getUserInfo())
        },
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

