import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import {
    SET_CALCULATE_REPORTS_ACTION_BEFORE,
    SET_CALCULATE_REPORTS_ACTION_FINISH
} from "../../../../actionTypes";

const setCalculateReportsActionBefore = () => ({
    type: SET_CALCULATE_REPORTS_ACTION_BEFORE,
});

const setCalculateReportsActionFinished = () => ({
    type: SET_CALCULATE_REPORTS_ACTION_FINISH,
});

export const calculatePlayersPerformance = dateTime => {
    return dispatch => {
        dispatch(setCalculateReportsActionBefore());

        return axios({
            url: ApiUrls.CALCULATE_PLAYERS_PERFORMANCE,
            method: Methods.POST,
            data: {
                dateTime
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
            }

            dispatch(setCalculateReportsActionFinished());
        })
        .catch(() => {
            dispatch(setCalculateReportsActionFinished());
        })
    }
}

export const calculateAgentsPerformance = dateTime => {
    return dispatch => {
        dispatch(setCalculateReportsActionBefore());

        return axios({
            url: ApiUrls.CALCULATE_AGENTS_PERFORMANCE,
            method: Methods.POST,
            data: {
                dateTime
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
            }

            dispatch(setCalculateReportsActionFinished());
        })
        .catch(() => {
            dispatch(setCalculateReportsActionFinished());
        })
    }
}

export const calculateBetshopsPerformance = dateTime => {
    return dispatch => {
        dispatch(setCalculateReportsActionBefore());

        return axios({
            url: ApiUrls.CALCULATE_BETSHOPS_PERFORMANCE,
            method: Methods.POST,
            data: {
                dateTime
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
            }

            dispatch(setCalculateReportsActionFinished());
        })
        .catch(() => {
            dispatch(setCalculateReportsActionFinished());
        })
    }
}

export const calculateDashboardOperations = dateTime => {
    return dispatch => {
        dispatch(setCalculateReportsActionBefore());

        return axios({
            url: ApiUrls.CALCULATE_DASHBOARD_OPERATIONS,
            method: Methods.POST,
            data: {
                dateTime
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
            }

            dispatch(setCalculateReportsActionFinished());
        })
        .catch(() => {
            dispatch(setCalculateReportsActionFinished());
        })
    }
}

export const calculateCategoriesPerformance = dateTime => {
    return dispatch => {
        dispatch(setCalculateReportsActionBefore());

        return axios({
            url: ApiUrls.CALCULATE_CATEGORIES_PERFORMANCE,
            method: Methods.POST,
            data: {
                dateTime
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
            }

            dispatch(setCalculateReportsActionFinished());
        })
        .catch(() => {
            dispatch(setCalculateReportsActionFinished());
        })
    }
}
