import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Button, Spin } from 'antd';

import RichEditor from "components/common/richEditor";

import {
    getTerminalTermsAndConditions,
    saveTerminalTermsAndConditions
} from 'store/actions/dashboard/terminalCustomize/sections.action';

import { TERMINAL_SECTION_TYPE } from 'constants/terminalCustomize.constants';

const FORM_FIELD_NAMES = {
    TERMS_AND_CONDITIONS: "termsAndConditions",
}

const TermsAndConditions = ({
    termsAndConditions,
    saveTerminalTermsAndConditions,
    getTerminalTermsAndConditions,
    isSaving,
    isLoading,
    customizeLanguage,
}) => {
    const { t } = useTranslation();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [formInstance] = Form.useForm();
    const { setFieldsValue, validateFields } = formInstance;

    useEffect(() => {
        getTerminalTermsAndConditions()
    }, [customizeLanguage])

    useEffect(() => {
        setFieldsValue({
            [FORM_FIELD_NAMES.TERMS_AND_CONDITIONS]: termsAndConditions?.text ?? ""
        })
    }, [termsAndConditions]) 

    const handleForm = () => {
        validateFields()
            .then(data => {
                saveTerminalTermsAndConditions(data);
                setIsFormTouched(false);
            }).catch(() => { })
    }

    return (

        <div className='rt--customize-widget-editor'>
            <Spin spinning={isLoading}>
                <Form
                    className="rt--form"
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                name={FORM_FIELD_NAMES.TERMS_AND_CONDITIONS}
                                className="rt--general-form-item"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                ]}
                            >
                                <RichEditor
                                    height={325}
                                    initialValue={termsAndConditions?.text ?? ""}
                                    onChange={() => {
                                        setIsFormTouched(true)
                                    }}
                                    placeholder={`${t("backoffice.common.enter")} ${t("backoffice.terminals.termsAndConditions")}`}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
            <div className="rt--flex rt--justify-end rt--align-center">
                <Button
                    type="primary"
                    className="rt--button rt--button-primary"
                    onClick={handleForm}
                    loading={isSaving}
                    disabled={!isFormTouched}
                >
                    <span>{t("backoffice.common.save")}</span>
                </Button>
            </div>
        </div>
    )

}

/** TermsAndConditions propTypes
 * PropTypes
*/
TermsAndConditions.propTypes = {
    termsAndConditions: PropTypes.object,
    /** Redux action to save promo section translations */
    saveTerminalTermsAndConditions: PropTypes.func,
    /** Redux action to reset promo section translations */
    getTerminalTermsAndConditions: PropTypes.func,
    /** Redux state property, is true when terminal saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when loading portal customization data */
    isLoading: PropTypes.bool,
    /** Redux state property, customize page language */
    customizeLanguage: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
    getTerminalTermsAndConditions: () => {
        dispatch(getTerminalTermsAndConditions())
    },
    saveTerminalTermsAndConditions: value => {
        dispatch(saveTerminalTermsAndConditions(value))
    }
});

const mapStateToProps = (state) => {
    return {
        isSaving: state.terminalCustomize.isSaving,
        termsAndConditions: state.terminalCustomize.sections[TERMINAL_SECTION_TYPE.TERMS_AND_CONDITIONS].data,
        isLoading: state.terminalCustomize.isLoading,
        customizeLanguage: state.terminalCustomize.customizeLanguage
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);