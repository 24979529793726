import React from 'react';

import i18n from 'translations/config';

import { Radio } from "antd";

import Flag from "components/common/flag";

export const getTableColumns = additionalProps => {
    const {
        defaultLanguageCode,
        handleDefaultLanguageChange,
    } = additionalProps;

    return [
        {
            title: "backoffice.terminals.availableLanguage",
            dataIndex: "languageCode",
            render: (languageCode) => {
                return (
                    <div className="rt--flex rt--align-center">
                        <Flag code={languageCode} />
                        <span className='rt--pl-4'>{i18n.t(`backoffice.languages.${languageCode?.toLowerCase()}`)}</span>
                    </div>
                )
            }
        },
        {
            title: "backoffice.common.default",
            dataIndex: "",
            render: (_, record) => {
                return (
                    <div className='rt--ml-12'>
                        <Radio
                            checked={record.languageCode.toLowerCase() === defaultLanguageCode.toLowerCase()}
                            disabled={!record.enabled}
                            onChange={(e) => handleDefaultLanguageChange(e, record)}
                        />
                    </div>
                )
            }
        },
    ]
}