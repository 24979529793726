import { message } from 'antd';

import axios from "axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { setAgentsActionBefore, setAgentsActionFinished, setAgentsSaveActionBefore, setAgentsSaveActionFinished } from './agents.action';

import { SET_AGENT_GENERAL_INFO } from "../../../../actionTypes";

const setAgentGeneralInfo = info => ({
    type: SET_AGENT_GENERAL_INFO,
    payload: { info }
})

export const getAgentGeneralInfo = userNameOrId => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.GET_AGENT_GENERAL_INFO,
            method: Methods.GET,
            params: {
                userNameOrId
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setAgentGeneralInfo(info))
            dispatch(setAgentsActionFinished());

            return info;
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

export const getAgentGeneralInfoLatAm = userNameOrId => {
    return dispatch => {
        dispatch(setAgentsActionBefore());

        return axios({
            url: ApiUrls.GET_AGENT_GENERAL_INFO_LAT_AM,
            method: Methods.GET,
            params: {
                userNameOrId
            }
        })
        .then(({data : {value: info}}) => {
            dispatch(setAgentGeneralInfo(info))
            dispatch(setAgentsActionFinished());

            return info;
        })
        .catch((ex) => {
            dispatch(setAgentsActionFinished());
        })
    }
}

export const saveAgentGeneralInfo = info => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AGENT_GENERAL_INFO,
            method: Methods.POST,
            data: {
                ...info
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAgentGeneralInfo(data.value));
            }
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}

export const saveAgentGeneralInfoLatAm = info => {
    return dispatch => {
        dispatch(setAgentsSaveActionBefore());
        return axios({
            url: ApiUrls.SAVE_AGENT_GENERAL_INFO_LAT_AM,
            method: Methods.POST,
            data: {
                ...info
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
                dispatch(setAgentGeneralInfo(data.value));
            }
            dispatch(setAgentsSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAgentsSaveActionFinished());
        })
    }
}
