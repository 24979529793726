import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import { Col, Row, Form } from 'antd';

import Modal from 'components/common/modal';
import ImageUploader from 'components/common/imageUploader';
import Input from 'components/common/input';


import { isFunction } from 'utils/common';
import { IMAGE_TYPE, POPUP_SIZE } from 'constants/common.constants';


const FORM_FIELD_NAMES = {
    TITLE : "title",
    FILE: "file"
}

const BannerAddEditComponent = ({
    onClose,
    onOk,
    isSaving,
    data={}
}) => {
    const { t } = useTranslation();

    const isEditMode = data?.mode === "edit";
    
    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;


    useEffect(() => {
        if(isEditMode){
            setFieldsValue({
                [FORM_FIELD_NAMES.TITLE] : data.title,
            })
        }
    }, [])

    const handleForm = () => {
        validateFields()
            .then(fieldsValue => {
                if (isFunction(onOk)) {
                    const requestBody = {
                        title: fieldsValue[FORM_FIELD_NAMES.TITLE],
                        file: fieldsValue[FORM_FIELD_NAMES.FILE]
                    }

                    if(data.rowId){
                        requestBody.rowId = data.rowId;
                    }

                    onOk(requestBody);
                    onClose();
                }

            }).catch(ex => {
                console.log(ex)
            })
    }

    return (
        <Modal
            title={ isEditMode ? t('backoffice.terminals.editBanner') : t('backoffice.terminals.addBanner')}
            cancelText={t('backoffice.common.cancel')}
            okText={ isEditMode ? t('backoffice.common.save') : t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            size={POPUP_SIZE.SMALLEST}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.terminals.title')} *`}
                            name={FORM_FIELD_NAMES.TITLE}
                            rules={[
                                { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <Input
                                placeholder={`${t('backoffice.common.enter')} ${t('backoffice.terminals.title')}`}
                                maxLength={30}
                            />
                        </Form.Item>
                    </Col>
                    
                    <Col span={24}>
                        <Form.Item
                            label={`${t('backoffice.terminals.banner')} *`}
                            name={FORM_FIELD_NAMES.FILE}
                            rules={[
                                { required: data.mode === "add", message: t('backoffice.validation.fieldRequired') },
                            ]}
                        >
                            <ImageUploader
                                size={3 * 1024 * 1024}
                                acceptedFormats={ [ IMAGE_TYPE.JPEG, IMAGE_TYPE.PNG, IMAGE_TYPE.WEBP, IMAGE_TYPE.SVG, IMAGE_TYPE.GIF ]}
                                defaultFile={
                                    data.file ? {
                                        url: `${import.meta.env.SYSTEM_CDN_URL}/${data.file.toLowerCase()}`,
                                        status: "done",
                                        percent: 100
                                    } : null
                                }
                            />
                        </Form.Item>
                    </Col>
                    
                </Row>
            </Form>
        </Modal>
    );
}

/** BannerAddEditComponent propTypes
    * PropTypes
*/
BannerAddEditComponent.propTypes = {
    /** Fires on popup close */
    onClose: PropTypes.func,
    /** Fires on popup add button clicked */
    onOk: PropTypes.func,
    /** true when saving data */
    isSaving: PropTypes.bool,
    /** Data */
    data: PropTypes.object
}

export default BannerAddEditComponent;