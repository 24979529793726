import i18n from 'translations/config';

import { TERMINAL_FOOTER_ITEM_TYPE_TRANSLATIONS } from 'constants/terminalCustomize.constants';

export const tableColumns = [
    {
        title: "backoffice.terminals.item",
        dataIndex: "type",
        render: value => i18n.t(TERMINAL_FOOTER_ITEM_TYPE_TRANSLATIONS[value])
    }
]