import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

const TINYMCE_SCRIPT_URL = `${import.meta.env.SYSTEM_CDN_URL}/system/static/js/tinymce.min.js`

const RichEditor = ({
    onChange,
    height = 300,
    initialContent,
    maxCharacterLimit,
    placeholder,
    ...rest
}) => {

    const editorRef = useRef(null);

    const [characterCount, setCharacterCount] = useState(initialContent ? initialContent.length : 0);

    const handleEditorChange = (content, editor) => {
        const currentLength = editor.getContent({ format: 'text' }).length;
        setCharacterCount(currentLength);
        onChange(content, editor);
    };

    return (
        <div>
            <Editor
                tinymceScriptSrc={TINYMCE_SCRIPT_URL}
                onEditorChange={handleEditorChange}
                init={{
                    placeholder: placeholder,
                    height: height,
                    menubar: false,
                    branding: false,
                    target_list: false,
                    default_link_target: "_blank",
                    link_title: false,
                    relative_urls: false,
                    max_chars_indicator: ".maxCharsSpan",
                    elementpath: false,
                    directionality: 'auto',
                    document_base_url: "/",
                    max_chars: maxCharacterLimit ? maxCharacterLimit : undefined,
                    setup: maxCharacterLimit ? (ed) => {
                        editorRef.current = ed;
                        ed.on('KeyDown', (evt) => {
                            const currentLength = ed.getContent({ format: 'text' }).length;
                            if (currentLength >= maxCharacterLimit && evt.keyCode !== 8 && evt.keyCode !== 46) {
                                evt.preventDefault();
                                evt.stopPropagation();
                                return false;
                            }
                        });

                        ed.on('Paste', (evt) => {
                            const pastedText = (evt.clipboardData || window.clipboardData).getData('text');
                            const currentLength = ed.getContent({ format: 'text' }).length;
                            const totalLength = currentLength + pastedText.length;

                            if (totalLength > maxCharacterLimit) {
                                evt.preventDefault();
                                evt.stopPropagation();
                                return false;
                            }
                        });
                    } : undefined,
                    toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | forecolor backcolor'
                }}
                initialValue={initialContent}
                {...rest}
            />
            {maxCharacterLimit && <div className='rt--editor-count rt--pt-4'>{characterCount} / {maxCharacterLimit}</div>}
        </div>
    );
};

RichEditor.propTypes = {
    onChange: PropTypes.func,
    height: PropTypes.number,
    initialContent: PropTypes.string,
    maxCharacterLimit: PropTypes.number
};

export default RichEditor;
