export const PROJECT_TYPE = {
    AGENT_SYSTEM: 1 << 0,
    RETAIL_SYSTEM: 1 << 1,
    TERMINAL: 1 << 2
}

export const PROJECT_SHARE_FROM_TOTAL_SOURCE_TYPE = {
    NGR: 1,
    GGR: 2
}

export const PROJECT_PROVIDER_TYPE = {
    SPORTBOOK: 1,
    VIRTUAL_SPORTS: 2,
    GOLDEN_RACE: 4
}

export const REGISTRATION_FORM_TYPE = {
    AGENT: 1 << 0,
    PLAYER: 1 << 1,
    PLAYER_DEPOSIT: 1 << 2,
    PLAYER_WITHDRAWAL: 1 << 4,
}

export const PROJECT_INTEGRATION_MODE = {
    REAL: 1,
    TEST: 2
}

export const PROJECT_GAME_PROVIDER_TYPE = {
    SPORT: 1
}

export const PAYMENT_METHODS = {
    STANDART: 1 << 0,
    BANK_TRANSFER: 1 << 1,
    EPOS: 1 << 2,
}

export const WALLET_CONFIGS = {
    RELATED: 1,
    NON_RELATED: 2,
}

export const BANK_STATUS = {
    ACTIVE: 1,
    DEACTIVATED: 2,
    DELETED: 4,
}

export const WIDGET_TRANSACTION_TYPE = {
    DEPOSIT: 1,
    WITHDRAWAL: 2
}

export const INTEGRATION_TYPE = {
    PLATFORM: 1,
    RETAIL: 2
}

export const REQUEST_DIRECTION = {
    IN: 1,
    OUT: 2
}

export const BETSHOP_LIMIT_INCREASE_TYPE = {
    BY_WON: 1 << 0,
    BY_PAYOUT: 1 << 1
}
