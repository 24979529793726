//#region react
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
//#endregion

//#region components
import { Row, Col } from "antd";
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
//#endregion

//#region actions
import { getDBConnections, killConnection } from "store/actions/dashboard/developer/dbConnections/dbConnections.action";
//#endregion

//#region constants
import { DB_CONNECTION_STATE } from "constants/common.constants";
import { getTableColumns } from "./columns";
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region types
import dbConnectionType from "types/dbConnection/dbConnection.type";
//#endregion


const DBConnectionsComponent = ({
    getDBConnections,
    dbConnections,
    isLoading,
    killConnection
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        getDBConnections();
    }, [])

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.dbConnections') }]
        }
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                handleTerminate: killConnection
            }
        })
    }, [killConnection])
    //#endregion

    const connections = useMemo(() => {
        const connections = Object.keys(dbConnections).map(connection => ({
            ip: connection,
            items: dbConnections[connection].map(c => ({...c })),
            idleConnectionsCount: dbConnections[connection].filter(c => c.state === DB_CONNECTION_STATE.IDLE).length,
            activeConnectionsCount: dbConnections[connection].filter(c => c.state === DB_CONNECTION_STATE.ACTIVE).length
        }))

        connections.sort((connection1, connection2) => connection2.items?.length - connection1.items?.length )

        return connections;
    }, [dbConnections])

    return (
        <MainDashboardLayout
            header={headerPartsData}
        >
            <Row gutter={[16, 24]}>
                {
                    connections.map(connection => (
                        <Col md={24} sm={24} xs={24} lg={12} key={connection.ip}>
                            <div style={{height: "300px"}} className="rt--chart-section rt--chart-section-block">
                                <div className="rt--flex rt--flex-col rt--pl-24 rt--pr-24 rt--pt-24 rt--pb-24 rt--chart-section-block-inner">
                                    <div className="rt--flex rt--flex-col">
                                        <span className="rt--chart-section-title rt--font-extra-bold  rt--font-bigest rt--mb-8">{connection.ip}</span>
                                        <span className="rt--chart-section-subTitle rt--font-regular rt--font-big rt--mb-2">
                                            {t("backoffice.common.totalActiveConnections")}:
                                            <b className="rt--font-bold rt--pl-8 rt--pr-8 rt--success-text">{connection.activeConnectionsCount}</b>
                                        </span>
                                        <span className="rt--chart-section-subTitle rt--font-regular rt--font-big rt--mb-2">
                                            {t("backoffice.common.totalIdleConnections")}:
                                            <b className="rt--font-bold rt--pl-8 rt--pr-8 rt--pending-text">{connection.idleConnectionsCount}</b>
                                        </span>
                                    </div>
                                    <div className="rt--mt-16 rt--chart-section-block-table">
                                        <Table
                                            loading={isLoading}
                                            columns={mainTableColumns}
                                            data={connection.items}
                                            noPagination={true}
                                            disableFullView={true}
                                        />
                                    </div>

                                </div>
                            </div>
                        </Col>
                    ))
                }

            </Row>
        </MainDashboardLayout>
    )
};

/** DBConnectionsComponent propTypes
 * PropTypes
*/
DBConnectionsComponent.propTypes = {
    /** Redux action to get DB Connections */
    getDBConnections: PropTypes.func,
    /** Redux action to kill Connections */
    killConnection: PropTypes.func,
    /** Redux state property, represents the list of DB Connections  */
    dbConnections: PropTypes.objectOf(PropTypes.arrayOf(dbConnectionType)),
    /** Redux state property, is true when loading DB Connections */
    isLoading: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
    getDBConnections: () => {
        dispatch(getDBConnections());
    },

    killConnection: id => {
        dispatch(killConnection(id));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.dbConnections.isLoading,
        dbConnections: state.dbConnections.dbConnections
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DBConnectionsComponent);
