import React from "react";
import { useTranslation } from "react-i18next";

import terminalEmptyImg from "assets/images/terminalEmpty.svg"

const Empty = ({
    text="",
    description=""
}) => {

    const { t } = useTranslation()

    return (
        <div className="rt--customize-table-empty rt--flex rt--flex-col rt--align-center rt--justify-center">
            <img src={terminalEmptyImg} />
            <b className="rt--font-regular rt--font-normal">
                { text }
            </b>
            <span className="rt--font-regular rt--font-normal">
                { description }
            </span>
        </div>
    );
}

export default Empty;