import {FILTER_USER_TYPE} from "constants/user.constants";

const getUserTypeOptions = (t) => [
    {
        text: t('backoffice.reports.agent'),
        value: FILTER_USER_TYPE.AGENT
    },
    {
        text: t('backoffice.reports.player'),
        value: FILTER_USER_TYPE.PLAYER
    }
];

export default getUserTypeOptions;
