import {
    SET_PLAYERS_ACTION_BEFORE,
    SET_PLAYERS_ACTION_FINISH,
    SET_PLAYERS,
    SET_PLAYERS_SORTING,
    SET_PLAYERS_FILTERS,
    SET_PLAYERS_SAVE_ACTION_BEFORE,
    SET_PLAYERS_SAVE_ACTION_FINISH,
    SET_UPDATE_PLAYER_DATA,
    SET_PLAYER_GENERAL_INFO,
    SET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID,
    SET_PLAYER_WITHDRAWAL,
    SET_PLAYER_BETS,
    SET_PLAYER_BETS_SORTING,
    SET_PLAYER_BETS_FILTERS,
    SET_PLAYER_REGISTRATION_FORM_BEFORE,
    SET_PLAYER_REGISTRATION_FORM_FINISH,
    SET_PLAYER_REGISTRATION_FORM,
    SET_PLAYER_TRANSACTIONS_HISTORY,
    SET_PLAYER_TRANSACTIONS_HISTORY_SORTING,
    SET_PLAYER_TRANSACTIONS_HISTORY_FILTERS
} from "../../actionTypes";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case SET_PLAYERS_ACTION_BEFORE:
            return {
                ...state,
                isLoading: true,
            };
        case SET_PLAYERS_ACTION_FINISH:
            return {
                ...state,
                isLoading: false,
            };
        case SET_PLAYERS:
            return {
                ...state,
                players: !payload.add ? payload.players.item2 : state.players.concat(payload.players.item2),
                total: payload.players.item1,
            };
        case SET_UPDATE_PLAYER_DATA:
            return {
                ...state,
                players: state.players.map(player => player.id === payload.id ? payload.data : player)
            };
        case SET_PLAYERS_FILTERS:
            return {
                ...state,
                filters: payload.filters,
                sorting: {
                    ...state.sorting,
                    page: 1
                }
            };
        case SET_PLAYERS_SORTING:
            return {
                ...state,
                sorting: payload.sorting,
            };
        case SET_PLAYERS_SAVE_ACTION_BEFORE:
            return {
                ...state,
                isSaving: true,
            };
        case SET_PLAYERS_SAVE_ACTION_FINISH:
            return {
                ...state,
                isSaving: false,
            };
        case SET_PLAYER_GENERAL_INFO:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    general: {
                        ...payload.info
                    }
                }
            }
        case SET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID:
            return {
                ...state,
                info: payload.info ? {
                    ...payload.info
                } : null,
                withdrawal: null
            }
        case SET_PLAYER_WITHDRAWAL:
            return {
                ...state,
                withdrawal: payload.withdrawal
            }
        case SET_PLAYER_BETS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    bets: {
                        ...state.edit.bets,
                        bets: !payload.add ? payload.bets.bets.item2 : state.edit.bets.bets.concat(payload.bets.bets.item2),
                        total: payload.bets.bets.item1,
                        totals: payload.bets.totals
                    }
                }
            }
        case SET_PLAYER_BETS_SORTING:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    bets: {
                        ...state.edit.bets,
                        sorting: payload.sorting,
                    }
                }
            }
        case SET_PLAYER_BETS_FILTERS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    bets: {
                        ...state.edit.bets,
                        filters: payload.filters,
                        sorting: {
                            ...state.edit.bets.sorting,
                            page: payload.keepPage ? state.edit.bets.sorting.page : 1
                        }
                    }
                }
            }
        case SET_PLAYER_REGISTRATION_FORM_BEFORE:
            return {
                ...state,
                isRegistrationFormLoading: true,
            };
        case SET_PLAYER_REGISTRATION_FORM_FINISH:
            return {
                ...state,
                isRegistrationFormLoading: false,
            };
        case SET_PLAYER_REGISTRATION_FORM:
            return {
                ...state,
                registrationForm: payload.registrationForm
            }
        case SET_PLAYER_TRANSACTIONS_HISTORY:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    playerTransactionsHistory: {
                       ...state.edit.playerTransactionsHistory,
                        history: !payload.add ? payload.history.playerTransactions : state.edit.playerTransactionsHistory.history.concat(payload.history.playerTransactions),
                        hasNext: payload.history.hasNext,
                    }
                }
            }
        case SET_PLAYER_TRANSACTIONS_HISTORY_SORTING:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    playerTransactionsHistory: {
                       ...state.edit.playerTransactionsHistory,
                        sorting: payload.sorting,
                    }
                }
            }
        case SET_PLAYER_TRANSACTIONS_HISTORY_FILTERS:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    playerTransactionsHistory: {
                       ...state.edit.playerTransactionsHistory,
                        filters: payload.filters,
                        sorting: {
                           ...state.edit.playerTransactionsHistory.sorting,
                            page: payload.keepPage? state.edit.playerTransactionsHistory.sorting.page : 1
                        }
                    }
                }
            }
        default:
            return state;
    }
};
